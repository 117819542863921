<template>
    <modal class="batchPaySelectModel" :isShow="isShow">
        <div class="header">其它支付方式 <span class="unPaidMoney">剩余应付:<span class="red">¥{{paymentMoney}}</span> </span></div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel panel-table" >
                        <div class="panel-heading">
                            <div class="tr">
                                <div class="td name">付款方式</div>
                                <div class="td money">支付金额</div>
                            </div>
                        </div>
                        <div class="panel-body"  v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.tr'}">
                            <div class="tr" :class="{'current-row':currentIndex==index}" v-for="(item,index) in payTypeList" :key="item" @click="currentIndex=index;">
                                <div class="td name">
                                    <div class="cell">
                                        <div class="nowrap2">{{item.PAY_NAME}}</div>
                                    </div>
                                </div>
                                <div class="td money">
                                    <div class="cell">
                                        <input-pattern class="key-focus" pattern="money" :ref="(e)=>payMoneyInput[index]=e" v-model="item.payMoney"  @focus="(e)=>{currentIndex=index;}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard" >
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 
//其它支付方式
export default {
    name:'batchPaySelectModel',
    emits:['confirm','close'],
    props:{
        //是否显示弹层
        isShow:Boolean,
        //付款方式
        payType:{
            type:Array,
            default:null
        },
        //付款明细数据
        payConsumeList:{
            type:Array,
            default:null
        },
        //待付款金额
        paymentMoney:{
            type:Number,
            default:0
        }
    },
    data(){
        return{
            //金额 输入框
            payMoneyInput:[],
            //高亮
            currentIndex:null,
            //付款方式 数据
            payTypeList:[]
        }
    },
    mounted(){
        initKeyBoard.call(this,this.$el);
        this.$nextTick(()=> {
            this.init();
        })
    },
    computed:{
        isAllCheck(){
            if(this.consumeList.length>0){
                return !this.consumeList.some(it=>!it.selected);
            }
            return false;
        }
    },
    watch:{
        isShow(){
            if(this.isShow){
                this.init();
            }
        },
        currentIndex(newVal){
            if(newVal>=0){
                this.payMoneyInput[newVal]?.focus();
                let totalMoney=0;
                this.payTypeList.forEach((it,index)=>{
                    if(index!=newVal && it.payMoney>0){
                        totalMoney+=Number(it.payMoney);
                    }
                })
                let money=Number((this.paymentMoney-totalMoney).toFixed(2));
                if(money>0){
                    this.payTypeList[newVal].payMoney= money;
                }
            }
        }
    },
    methods:{
        //初始化
        init(){
            //普通支付 或 移动POS银联商务接口支付 
            this.payTypeList=this.payType?.filter(it=>it.INTERFACE_TYPE==2 || it.INTERFACE_TYPE==9021001).map(it=>{
                let pay=this.payConsumeList?.find(pay=>pay.PAY_ID==it.PAY_ID);
                if(this.payConsumeList?.some(pay=>pay.PAY_ID==it.PAY_ID)){
                    return Object.assign({selected:true,payMoney:pay.payMoney},it);
                }
                return Object.assign({selected:false,payMoney:''},it);
            });
        },
        //点击选中 或 取消
        selectClick(item,isBo,input){
            if(isBo!=undefined){

                if(item.selected==isBo){
                    return;
                }
                item.selected=isBo;
                if(input){
                    this.$nextTick(()=>{
                        input.focus();
                        input.$refs.input.select();
                    })
                }
            }

            if(!item.selected){
                item.payMoney='';
            }
        },
        allCheck(){
            let isbo=!this.isAllCheck;
            this.consumeList.forEach((item)=>{
                item.selected=isbo;
                if(item.selected){
                    item.newEat_Number=item.Eat_Number;
                    item.newEat_AssNumber=item.Eat_AssNumber;
                }else{
                    item.newEat_Number='';
                    item.newEat_AssNumber='';
                }
            })
        },
        hide(){
            this.$emit("close");
        },
        confirm(){
            let data=this.payTypeList.filter(it=>it.payMoney>0);
            if(data.length==0){
                this.$message.warning('请选择支付方式输入金额!');
                this.payMoneyInput[0]?.focus();
                return ;
            }

            let totalMoney=0;
            data.forEach(it=>{
                totalMoney+=Number(it.payMoney);
            });
            totalMoney=Number(totalMoney.toFixed(2));
            if(totalMoney>this.paymentMoney){
                this.$message.warning('金额不能大于待支付金额!');
                return ;
            }
            this.$emit("confirm",data,totalMoney);
        }
    }
}
</script>

<style lang="scss">
@import './batchPaySelectModel.scss';
</style>