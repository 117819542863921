<template>
    <modal class="transferFoodModel" :isShow="isShow">
        <div class="header">
            转菜操作：桌号{{orderInfo.Eat_DeskName}}
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel panel-table" >
                        <div class="panel-heading">
                            <div class="tr">
                                <div class="td selectChecked">选择</div>
                                <div class="td name">菜品名称</div>
                                <div class="tdnum">数量</div>
                                <div class="tds">单位</div>
                                <div class="td price">单价</div>
                                <div class="td">小计</div>
                            </div>
                        </div>
                        <div class="panel-body"  v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper'}" v-if="orderMenuList">
                            <div class="slide-wrapper" :class="{selected:item.Food_Status>=0,selectedRow:item.selected}" v-for="(item,index) in orderMenuList"  :key="item" v-show="item.Food_Status!=-1">
                                <div class="animate-slide-start">
                                    <div class="slide-content" :title="item.EAT_XFName" >
                                        <div class="selectChecked verticalCenter" @click="foodClick(item)">
                                            <div class="check">
                                                <span class="iconfont " :class="item.selected?'icon-iconoption1':'icon-iconoption'"></span>
                                            </div>
                                        </div>
                                        <div class="detailed">
                                            <div class="tr" @click="foodClick(item)">
                                                <div class="td name nowrap">{{item.EAT_XFName}}</div>
                                                <div class="tdnum">{{item.Eat_Number}}</div>
                                                <div class="tds" :title="item.Eat_XFSize">{{item?.Eat_XFSize.substring(0,1)}}</div>
                                                <div class="td" style="font-size: 13px;" >{{(item.Eat_SalePrice).toFixed(2)}}</div>
                                                <div class="td" style="font-size: 13px;">{{(item.Eat_SalePrice * item.Eat_Number).toFixed(2) }}</div>
                                            </div>
                                            <div class="describe" @click="foodClick(item)">
                                                <div class="inlineBlock float tag-box">
                                                    <span class="tag tao" v-if="item.Food_Type==2 || item.Food_Type==3 || item.FoodSetmeals" >套</span>
                                                </div>
                                            </div>
                                            <div v-if="(item.Food_Type==2 || item.Food_Type==3 || item.FoodSetmeals) && (dianji==false || packageShow!=index)" style="position:relative;float:right;" @click="foldClick(item,index)">
                                                <i class="iconfont icon-fangxiang-down"></i>
                                            </div>
                                            <div v-if="(item.Food_Type==2 || item.Food_Type==3 || item.FoodSetmeals) && dianji==true && packageShow==index " style="position:relative;float:right;" @click="foldClick(item,index)">
                                                <i class="iconfont icon-fangxiang-up"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <shrink-view>
                                <div class="more-box" :class="{selected:item.Food_Status>=0,selectedRow:item.selected}" v-show="item.Eat_TcItemList && dianji  && eatXFBMID==item.Eat_ConsumeID">
                                    <!-- <div style="border-bottom:1px solid #e9e9ee"></div> -->
                                    <div class="slide-content" style="position: relative;" v-for="(food,foodindex) in item.Eat_TcItemList" :key="foodindex" @click="EatTcClick(food,foodindex)" >
                                        <div style="position: relative">
                                            <div class="tr">
                                                <div class="td name nowrap" :title="item.EAT_XFName" >{{food.EAT_XFName}}</div>
                                                <div class="tdnum">{{food.Eat_Number}}</div>
                                                <div class="tds" :title="food.Eat_XFSize" >
                                                    {{food.Eat_XFSize.substring(0,1)}}
                                                </div>
                                                <div class="td" style="font-size: 13px;">{{(food.Eat_SalePrice).toFixed(2)}}</div>
                                                <div class="td" style="font-size: 13px;">{{(food.Eat_SalePrice * food.Eat_Number).toFixed(2) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </shrink-view>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="selectChecked" @click="allCheck">
                            <span class="iconfont icon-iconoption" :class="isAllCheck?'icon-iconoption1':'icon-iconoption'"></span>全选
                        </div>
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="category-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage',isNavFirstSticky:true}">
                            <div class="nav-li" :class="{selected:filternav==-1}" @click="NavigationClick()">
                                <span class="lable-text">全部</span>
                            </div>
                           <div class="nav-li" :class="{ selected: filternav == index }" 
                                v-for="(item,index) in PosRooms" :key="item"  
                                @click="NavigationClick(item,index)" >
                            {{item.Room_Name}}
                            </div>
                        </div>
                        <div class="float-right page-box">
                            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                            <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                        </div>
                    </div>

                    <div class="menu" >
                        <!-- 菜单 -->
                        <div class="scrollBox clearfix" v-if="getPosDesks()" >
                            <!--0：空台 1：开台 2：预定 3：预结 4：超时 5：清台-->
                            <div class="desk-box" @click="DeskClick(posDesk)" 
                                v-for="posDesk in getPosDesks()" :key="posDesk" 
                                :class="{selected:posDeskIndex==posDesk.Desk_AutoID, 'empty': posDesk.Desk_Status == 0,'opening':posDesk.Desk_Status==1,'reserve':posDesk.Desk_Status==2,'preKnot':posDesk.Desk_Status==3,'overtime':posDesk.Desk_Status==4,'clean':posDesk.Desk_Status==5}">
                                <i class="iconfont icon-suo" style="position:absolute;padding-left:45px;" v-if="posDesk.Is_Desk_Locked"></i>
                                <div class="top" v-if="posDesk.OpenDeskInfo" >
                                    <span v-if="posDesk.Desk_Status!=2" >{{posDesk.OpenDeskInfo.Person_Num}}人</span> <!-- 人数-->
                                    <span v-if="posDesk.Desk_Status==2" >定</span>
                                    <span v-if="posDesk.OpenDeskInfo.Eat_FoodCounts>=0 && posDesk.Desk_Status!=2" class="float-right" >{{posDesk.OpenDeskInfo.Crossed_FoodCounts}}/{{posDesk.OpenDeskInfo.Eat_FoodCounts}}</span>  <!--已划菜数量/点菜总数量-->
                                    <span v-if="posDesk.ReserveDeskInfo && posDesk.Desk_Status==2" class="float-right" >{{posDesk.ReserveDeskInfo.Cstm_Name}}</span> <!--预定客户-->
                                </div>
                                
                                <p class="title">{{posDesk.Desk_Name}}</p>
                                <div class="bottom" v-if="posDesk.OpenDeskInfo" >
                                    <span v-if="posDesk.Desk_Status!=2" >{{DifferenceTime(posDesk.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                                    <span v-if="posDesk.ReserveDeskInfo && posDesk.Desk_Status==2 " >{{posDesk.ReserveDeskInfo.Mobile}}</span> <!--预定手机号-->
                                    <span v-if="posDesk.OpenDeskInfo.Eat_SaleMoney && posDesk.Desk_Status!=2 && MapConsumeMoney==false" class="float-right">￥{{posDesk.OpenDeskInfo.Eat_CheckMoney}}</span> <!--点餐总金额-->
                                </div>
                                <!--  -->
                                <i class="iconfont icon-gou1"></i>
                            </div>
                            
                        </div>
                    </div>
                    <div class="search-box">
                        <div class="search">
                            <input type="text" placeholder="按桌台模糊检索"  v-model="varietySelect" ref="searchBox" @blur="searchInputBlur" />
                            <div class="deskAuto"  @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()" >
                                <span class="iconfont icon-jianpan"></span>
                            </div>
                        </div>
                        <div class="float-right">
                            <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                            <span class="page-number">{{ posDesksPage.pageIndex }}/{{ posDesksPage.pageSize }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" :disabled="btnflag" @click="confirm()">确定</button>
        </div>
        <analog-key-board ref="keyBoard"></analog-key-board>
        <!--开台弹出层-->
        <modal-load :isShow="TransOpenisShow">
            <opening :openDeskName="posDesks.DeskName" :opentype="1" :openDeskCode="posDesks.DeskCode" :openisShow="TransOpenisShow" :openDeskAuto="DeskAutoID" :Reserve_Operator="Reserve_Operator" :isTransFoodOpen="isTransFoodOpen" @CloseClick="CloseClick" @TransFoodOpenDeskInfoReturn="TransFoodOpenDeskInfoReturn"></opening>
        </modal-load>
    </modal>
</template>

<script>
import {initKeyBoard,deepClone,differenceTime} from '/src/common' 
import { httpAES,auth,cacheData } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus";
import shrinkView from '../ShrinkView/ShrinkView.vue'
import opening from '../../model/opening.vue'

/**全局初始化数据 */
let _data={
    RunCloudPosHideDeskMapConsumeMoney:false, /**启用触摸屏隐藏桌态图【消费金额】信息，true: 隐藏，false:不隐藏 */
};

/**转菜操作 */
export default {
    name:"transferFoodModel",
    components:{
        shrinkView,
        opening
    },
    props:{
        isShow:Boolean,
        /**坐台订单数据 */
        orderInfo:Object
    },
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RunCloudPosHideDeskMapConsumeMoney = globalSysSetting.RunCloudPosHideDeskMapConsumeMoney
        }
        return {
            btnflag:false, //控制按钮的disabled属性
            /**用户姓名 */
            Login_Name:'',
            /**导航 */
            filternav:-1,
            /**餐厅数据 */
            PosRooms:[],
            /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            posDeskIndex:undefined,
            /**桌台数据*/
            posDesks:[],
            /**选择的桌台数据 */
            choiePosDesks:'',
            /**桌台页码 */
            posDesksPage:{
                pageIndex:1,
                pageSize:1
            },
            /**桌台检索 */
            varietySelect:'',
            orderMenuList:[],
            /**是否全选 */
            isAllCheck:false,
            /**登录的用户信息*/
            userInfo:'',
            PosTimestamps:'', //桌台时间戳
            /**大类数据 */
            foodMainCategory:[],
            mainCategoryIndex:0,
            /**小类数据 */
            foodSubCategorys:[],
            /**全部小类数据 */
            allfoodSubCategorys:[],
            subCategoryIndex:0,
             /**菜品数据  */
            posFoods:[],
             /**点击 */
            dianji:false,
            eatXFBMID:'',
            packageShow:-1,
            MapConsumeMoney:false,
            //开台弹层信息
            isTransFoodOpen:false,
            TransOpenisShow:false,
            DeskAutoID:'',
            DeskCode:'',
            DeskName:'',
            Reserve_Operator:'',
            OpenDeskInfo:{},//开台信息
        }
    },
    mounted(){
        //获取桌台数据
        if(this.getPosRooms){
            this.PosRooms=this.getPosRooms;
            let _allDesk=[];//所有桌台
            (this.PosRooms||[]).forEach((room)=>{
                _allDesk=_allDesk.concat(room.PosDesks);
            })
            console.log(this.PosRooms)
            if(!this.PosRooms._allDesk){
                this.PosRooms._allDesk=_allDesk;
            }else{
                this.PosRooms._allDesk.splice(0,this.PosRooms._allDesk.length);//清空数据
                Object.assign(this.PosRooms._allDesk,_allDesk);
            }
        }
        this.RefreshData()
        this.NavigationClick(-1);//全部 
        let userInfo=this.$auth.getUserInfo(); //获取用户id
        this.Login_Name=userInfo.Login_Name; //用户姓名
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.foodMainCategory=d?.data;
            this.orderMenuList=JSON.parse(JSON.stringify(this.orderInfo.Order_ConsumeList));
            this.orderMenuList.map(order=>{
                this.foodMainCategory.some(food=>{
                    food.FoodSubCategorys.some(sub=>{
                        sub.PosFoods.some(pos=>{
                            if(order.EAT_XFBMID==pos.Food_ID && order.Food_Type!=4){
                                order.Eat_SalePrice=pos.Food_Price
                                return true
                            }
                        })
                    })
                })
            })
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        this.MapConsumeMoney=_data.RunCloudPosHideDeskMapConsumeMoney
    }, 
    watch:{
        isShow(newVal) {
            if(newVal){
                this.orderMenuList=this.orderInfo.Order_ConsumeList;
                this.posDeskIndex=undefined
                this.isAllCheck = false
                this.RefreshData()
            }
        },
        /**桌台搜索 */
        varietySelect(){
            if(this.varietySelect!=''){
                this.filternav=-1;
                this.mainCategoryIndex=0;
                let MenuTxt=this.varietySelect.toLowerCase();
                this.posDesks =this.PosRooms._allDesk.filter(pos=>(pos.Desk_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Desk_Code||"").toLowerCase().indexOf(MenuTxt)>=0);
            }else{
                this.posDesks =this.PosRooms._allDesk;
            }
        },
        posDesks(){
            let size=4*6;
            this.posDesksPage.pageIndex=1;
            this.posDesksPage.pageSize=Math.ceil(this.posDesks.filter(item=>item.Desk_AutoID!=this.orderInfo.Eat_DeskID && (item.Desk_Status==0 || item.Desk_Status==1 || item.Desk_Status==3 || item.Desk_Status==4)).length/size);
        },
        getPosRooms:{
            handler(newVal,oldVal){
                if(this.getPosRooms){
                    this.PosRooms=this.getPosRooms;
                    let _allDesk=[];//所有桌台
                    (this.PosRooms||[]).forEach((room)=>{
                        _allDesk=_allDesk.concat(room.PosDesks);
                    })
                    if(!this.PosRooms._allDesk){
                        this.PosRooms._allDesk=_allDesk;
                    }else{
                        this.PosRooms._allDesk.splice(0,this.PosRooms._allDesk.length);//清空数据
                        Object.assign(this.PosRooms._allDesk,_allDesk);
                    }
                }
            },
            immediate:true,
            deep:true
        }
    },
    computed:{
        getPosRooms(){
            return this.$global.PosRooms||[];
        }
    },
    methods:{
        /**重新去获取桌台信息 */
        RefreshData(){
            let userInfo=this.$auth.getUserInfo()
            const loading = this.$loading({
                text: '数据加载中',
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0)",
            });
            this.$httpAES.post('Bestech.CloudPos.GetBriefDeskMap',{
                Body_ID:newGuid(),
                User_ID:userInfo?.User_ID,
                DeskRange_Type:0
            }).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode==0){
                    let _PosRooms=data.ResponseBody?.PosRooms||[];
                    console.log(this.PosRooms);
                    this.PosRooms.forEach(room=>{
                        room.PosDesks.forEach(pos=>{
                            _PosRooms.forEach(_room=>{
                                _room.PosDesks.forEach(_pos=>{
                                    if(_pos.Desk_AutoID==pos.Desk_AutoID && _pos.Desk_Status!=pos.Desk_Status){
                                        pos.Desk_Status=_pos.Desk_Status
                                        if(_pos.Desk_Status==1||_pos.Desk_Status==0){
                                            pos = Object.assign(pos,{Order_ID:_pos.Order_ID})
                                        }
                                    }
                                    if(_pos.Desk_AutoID==this.orderInfo.Eat_DeskID && this.orderInfo.Eat_DeskID ==pos.Desk_AutoID){
                                        pos.Timestamps==_pos.Timestamps.toString()
                                        this.PosTimestamps = _pos.Timestamps.toString()
                                    }
                                })
                            })
                        })
                    })
                    this.PosRooms._allDesk=[];//所有桌台
                    (this.PosRooms||[]).forEach((room)=>{
                        this.PosRooms._allDesk=this.PosRooms._allDesk.concat(room.PosDesks);
                    })
                    this.NavigationClick(-1);//全部 
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**选择菜品 */
        foodClick(item){
            this.orderMenuList.forEach((order)=>{
                if(order.Food_Status!=-1 && order.EAT_XFBMID==item.EAT_XFBMID && order.Eat_ConsumeID==item.Eat_ConsumeID){
                    order.selected=!order.selected
                }
            })
        },
        /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. posDesksPage.pageIndex<this.posDesksPage.pageSize){
                    this.posDesksPage.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.posDesksPage.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.posDesksPage.pageIndex<=1?1:this.posDesksPage.pageIndex--;
            }
        },
        /**下拉 */
        foldClick(item,index){
            this.packageShow = index
            if(this.dianji==true && this.eatXFBMID!=item.Eat_ConsumeID){
                this.dianji=true
            }else{
                this.dianji=!this.dianji
            }
            this.eatXFBMID = item.Eat_ConsumeID
        },
        /**菜品分页显示 */ 
        getPosDesks(){
            let size=4*6;
            return this.posDesks.filter(item=>item.Desk_AutoID!=this.orderInfo.Eat_DeskID && (item.Desk_Status==0 || item.Desk_Status==1 || item.Desk_Status==3 || item.Desk_Status==4)).slice((this.posDesksPage.pageIndex-1)*size,this.posDesksPage.pageIndex*size);
        },
        //餐厅名称导航条切换
        NavigationClick(item,index) {
            if(index>=0){
                this.varietySelect='';
                this.filternav = index;
                this.posDesks=this.PosRooms[index].PosDesks;                
            }else{
                this.filternav=-1;
                this.posDesks=this.PosRooms._allDesk;
            }
        },
        //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            let _time =differenceTime(beginTime);
            if(_time=='-1:-1'){
                _time='0:0'
            }
            return _time;
        },
        /**全选 */
        allCheck(){
            this.isAllCheck=!this.isAllCheck;
            this.orderMenuList.forEach((item)=>{
                if(item.Food_Status!=-1){
                    item.selected=this.isAllCheck
                }
            })
        },
        DeskClick(posDesk){
            if(posDesk.Desk_Status==1||posDesk.Desk_Status==3||posDesk.Desk_Status==4){
                if(posDesk.Is_Desk_Locked==true){
                this.$message.warning('该桌台已被加锁,无法转入')
                }else{
                    this.choiePosDesks=posDesk
                    this.posDeskIndex=posDesk.Desk_AutoID;
                }
            }
            else if(posDesk.Desk_Status==0){
                console.log(this.choiePosDesks)
                console.log(this.posDeskIndex)
                this.isTransFoodOpen=true;
                this.TransOpenisShow = true;
                this.DeskAutoID=posDesk.Desk_AutoID;
                this.DeskCode=posDesk.Desk_Code;
                this.DeskName=posDesk.Desk_Name;
                this.Reserve_Operator=posDesk.ReserveDeskInfo?.Reserve_Operator
                this.choiePosDesks=posDesk
                this.posDeskIndex=posDesk.Desk_AutoID;

            }
            
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**确定和回车事件 */
        confirm(){
            this.btnflag=true
            let _data=[]
            let _orderType=0
            this.orderMenuList.map(order=>{
                if(order.selected==true && order.Food_Status!=-1){
                    _data.push(order.Eat_ConsumeID)
                    _orderType=1
                }
            })
            if(_orderType==0){
                this.$message.error('请先选择需要转移的菜品');
                this.btnflag=false
                return
            }
            if(!this.choiePosDesks){
                this.$message.error('请先选择转出桌台');
                this.btnflag=false
                return
            }
            this.$confirm('你确定要将菜品转入'+this.choiePosDesks.Desk_Name+'号桌台吗?', '提示', {
                confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
            .then(() => {
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                this.PosRooms.map(pos=>{
                    pos.PosDesks.map(desk=>{
                        if(desk.Desk_AutoID==this.orderInfo.Eat_DeskID){
                            this.PosTimestamps = desk.Timestamps.toString()
                        }
                    })
                })
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID,
                    Operator_Name:this.userInfo?.Login_Name,
                    Eat_ConsumeIDList:_data, //菜品明细ID
                    BeforeDeskID:this.orderInfo.Eat_DeskID, //原桌台
                    TargetDeskID:this.choiePosDesks.Desk_AutoID, //目标桌台
                    DeskTimestamps:this.PosTimestamps, //原桌台时间戳
                    OrderTimestamps:this.orderInfo.Timestamps.toString(),  //订单时间戳
                    IsOpenTargetDesk:this.isTransFoodOpen,
                    OpenDeskRequest:this.OpenDeskInfo
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                const loading = this.$loading({
                    text: "转菜中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.TransferFood",param).then((data)=>{
                    loading.close();
                    this.btnflag=false
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.$emit("transferConfirm",data)
                    }
                }).catch((e)=>{
                    this.btnflag=false
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }).catch(() => {
                this.btnflag=false
            });
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }  
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        },
        //关闭开台信息弹出框
        CloseClick(){
            this.TransOpenisShow = false;
            this.choiePosDesks='';
            this.posDeskIndex=undefined;
        },
        TransFoodOpenDeskInfoReturn(OpenDeskInfo){
            this.OpenDeskInfo=OpenDeskInfo;
            this.TransOpenisShow = false;
        }
    }
}
</script>

<style lang="sass">
@import "./transferFoodModel.scss";
</style>