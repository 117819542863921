<template>
    <modal class="editCodeModal" :isShow="isShow">
        <div class="header">修改外卖订单信息 </div>
        <div class="modal-body">
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">菜品编号：</span>
                    <div class="from-input">{{data?.Food_Code}}</div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">菜品名称：</span>
                    <div class="from-input">{{data?.Food_Name}}</div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">新菜品编号：</span>
                    <div class="from-input">
                        <select-tree @change="selectFood" placeholder="请选择" :data="treeFoodData" title="选择菜品"
                            :isMultiple="false"
                            :clearable="false"
                            searchPlaceholder="菜品模糊检索"
                            is-search
                            :filter-node-method="filterNode"
                        ></select-tree>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()"> 确认</button>
        </div>
    </modal>
</template>

<script>
// 修改菜品编号
export default {
    name:'editCodeModal',
    props:{
        isShow:Boolean,
        data:{
            type:Object
        }
    },
    data(){
        return {
            //新编号
            code:'',
            /**选择菜品弹层 */
            isFood:false,
            posFoodData:[]
        }
    },
    computed:{
        treeFoodData(){
            let tree=[];
            this.posFoodData?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    children.push({
                        id:sub.SubCategory_ID,
                        name:sub.SubCategory_Code+"-"+sub.SubCategory_Name,
                        SubCategory_ID:sub.SubCategory_ID,
                        children:sub.PosFoods.map(it=>{
                            return {
                                id:it.Food_ID,
                                name:it.Food_Name,
                                Food_Code:it.Food_Code,
                                Food_Name:it.Food_Name,
                                Food_Size:it.Food_Size,
                                Food_PinYin:it.Food_PinYin,
                            }
                        })
                    })
                });
                if(children.length>0){
                    tree.push({
                        id:category.MainCategory_ID,
                        name:category.MainCategory_Code+"-"+category.MainCategory_Name,
                        children:children
                    });
                }
            })
            return tree;
        }
    },
    watch:{
        isShow:{
            handler(newVal){
                if(newVal){
                    this.code="";
                    this.$nextTick(()=>{
                        this.$refs.input?.focus()
                    })
                }
            },
            immediate:true,
        },
    },
    mounted(){
        this.$nextTick(()=>{
            this.loadData();
        });
    },
    methods:{
        loadData(){
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            //菜品数据结构
            this.$cacheData.AllPosFoods().then((d)=>{
                loading.close()
                this.posFoodData=d;
            }).catch(e=>{
                loading.close()
                console.log('e:'+e)
                this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
            })
        },
        hide(){
            this.$emit("close");
        },
        confirm(){
            this.$emit("confirm",this.code)
        },
        selectFood(data){
            this.code=(data.Food_Code||"").trim();
        },
         /**搜索 */
        filterNode(value, data){
            console.log(value)
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.Food_Code?.toLocaleUpperCase().includes(value) || data.Food_Name?.toLocaleUpperCase().includes(value) || data.Food_PinYin?.toLocaleUpperCase().includes(value);
        },
    }
}
</script>

<style lang="scss">
@import './editCodeModal.scss'
</style>