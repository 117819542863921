<template>
  <modal class="passwordConfirmModel" :isShow="isShow">
      <div class="header">密码验证</div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">密码验证</div>
                    <div class="tr-li">
                        <div class="lable-txt">请输入密码</div>
                        <div class="from-input"><input type="password" class="key-focus"  ref="priceInput" v-model="password" @keyup.enter="confirm()"/></div>
                    </div>
                    <div class="tr-li" v-if="wliveCardMember">
                        <div class="lable-txtcard">当前验证的会员卡号:</div>
                        <div class="lable-txtNo">{{wliveCardMember.Card_No}}</div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>

import {initKeyBoard} from '/src/common'
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

export default {
    name:'passwordConfirmModel',
    props:{
        isShow:Boolean,
        wliveCardMember:Object
    },
    data(){
        return{
            /**密码验证 */
            password:''
        }
    },
    watch: {
        isShow() { 
            if(this.isShow==true){
                this.password=''
                this.EmptyDatas();
            }
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.password=''
            initKeyBoard.call(this,this.$el,this.$refs.recharRight);
            setTimeout(() => {
                this.$refs.priceInput?.focus();
            },500);
        })
    },
    methods:{
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.priceInput?.focus();
            },500);
        },
        /**确认 */
        confirm(){
            this.$emit("passwordConfirm",this.password)
        },
        /**取消 */
        hide(){
            this.$emit("closeModel")
        }
    }
}
</script>

<style lang="sass">
@import './passwordConfirmModel.scss';
</style>