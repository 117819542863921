<template>
    <modal class="westSoftCouponspayModel" :isShow="isShow">
        <div class="header">西软卡券核销<span class="close" @click="hide()">×</span></div>
        <div ref="code">
        <div class="The-head">
            请输入手机号：
            <div class="from-input" >
                <i class="iconfont icon-a-saoma13x"></i>
                <input type="text" ref="input" class="key-focus" v-mode-en data-model='idCode' v-model="idCode"  @focus="(e)=>e.target.select()" @keyup.enter="readCard()" />
            </div>
            <button class="btn" @click="readCard()">查询</button>
            <span class="radio-st" >
            <label class="checkbox" @click="radioClick(1)"><input type="radio" name="_fa_radio" :checked="tickChecke" />
            <i class="iconfont icon-a-danxuanweixuanzhong"></i>按手机号查询</label></span>
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel">
                        <div class="panel-heading">券列表</div>
                        <div class="panel-body item-box clearfix" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.item-li',isRow:false}">
                            <div class="item-li coupons" :class="{selected:selectedIndex === index}" @click="onTicket(item,index)"
                            v-for="(item, index) in ticketInfos" :key="index">
                                <div class="coupons-name">
                                    <div class="coupon-right" :class="{selected:selectedIndex === index}">
                                        <div class="coupon-top">
                                            <span class="tk-txt">{{item.tickettypedes}}</span>
                                            <span class="tk-time">{{item.ticketno}}</span>
                                            <span class="tk-time">有效期:{{new Date(item.arr).Format("yyyy-MM-dd")}}至{{new Date(item.dep).Format("yyyy-MM-dd")}}</span>
                                        </div>
                                        <span class="cou-ge"></span>
                                        <i class="iconfont icon-gou-fine"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                    <div class="box-bottom">
                        <div><span class="btm-span">待支付金额:</span>
                            <span class="btm-span-money">
                                {{invoMoney}}
                            </span>
                        </div>
                        <div class="tick-div"><span class="tick-span">券抵扣:</span><span class="tick-money">{{Number(this.tickDeduMoney).toFixed(2)}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="select()">重新查询核销结果</button>
            <button class="btn btn-confirm" @click="confirm()">确定</button>
        </div>
    </modal>
</template>
<script>

import { deepClone,initKeyBoard } from '/src/common' 
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

/**现金券 或 商品券 选择 */
export default {
    name:"westSoftCouponspayModel",
    components:{
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        unionEatAutoID:Object,
        choicePayType:Object,
        unionType:Boolean
    },
    data(){
        return {
            numShow:false,
            idCode:'',
            /**券数据 */
            ticketInfos:[],
            /**当前选中的券 */
            presentCoupon:'',
            /**菜品信息 */
            posFoodData:'',
            tickChecke:true,
            //mobleChecke:false,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**交易记录 */
            Payments:[],
            /**临时订单数据 */
            memberorderInfo:'',  
            unPaidMoney:0,//待付金额
            userInfo:'', //登录用户


            tickDeduMoney:0,//券抵扣金额
            DeduMoney:0,

            selectedIndex:-1,//选择券的index
            UseCouponInfo:{}//
        }
    },
    mounted(){
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData = d.data
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.code);
        })
        this.init();
    }, 
    computed:{
        //结账金额
        invoMoney(){
            let invoMoney=0;
            if(Number(this.unPaidMoney).toFixed(2) - Number(this.tickDeduMoney).toFixed(2)>0){
                invoMoney = Number(Number(this.unPaidMoney) - Number(this.tickDeduMoney)).toFixed(2)
            }
            return invoMoney
        }
    },
    watch: {
        orderInfo:{
            immediate:true,
            handler(vla){
                this.init();
            }
        },
        isShow(newVal) {
            if(newVal){
                this.init();
            }
        }
    },
    methods:{
        init(){
            //this.mobleChecke=false
            this.idCode=''
            this.ticketInfos=[],this.Payments=[],this.payPreviewData=''
            this.tickChecke=true,this.tickDeduMoney=0
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            this.userInfo=this.$auth.getUserInfo()
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        //重新查询核销结果
        select(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.memberorderInfo.Eat_AutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Default_ChannelID, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
            }
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    if(data.ResponseBody?.Timestamps){
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    }
                    //订单时间戳已变更需要去刷新订单时间
                    if(data.ResponseHeader.ResultCode==3002){
                        this.$emit("refreshOrder")
                    }
                }else{
                    if(data.ResponseHeader.ResultDesc){
                        ElMessage.success({ message:data.ResponseHeader.ResultDesc});
                        this.$emit("refreshOrder")
                    }
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    return;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        radioClick(type){
            if(type==1){
                this.tickChecke=true
                //this.mobleChecke=false
            }else{
                this.tickChecke=false
                //this.mobleChecke=true
            }
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        extenClick(item){
            if(item.extendShow==true){
                item.extendShow=false
            }else{
                item.extendShow=true
            }
        },
        readCard(){
            if(!this.idCode){
                this.$message.warning('手机号不能为空');
                return
            }
            // const  reg = /^1[0-9]{10}$/
            // if(this.idCode.length<10 || !reg.test(this.idCode)){
            //     this.$message.error('请输入正确的手机号');
            //     return
            // }
            //获取平台券信息
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Query_Mode:6, //6券查询 Query_Code固定为手机号，7券优惠查询 Query_Code固定为券号
                Query_Code:this.idCode,
            }
            const loading = this.$loading({
                text: "获取券信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetXrXMSCorpInfo",param).then((data)=>{
                loading.close();
                this.$refs.input?.select();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    if(data?.ResponseBody==null)
                    {
                        this.$message.error('未查询到该手机号对应的券信息');
                        return
                    }

                    this.ticketInfos = data?.ResponseBody
                    this.ticketInfos.forEach(tick=>{
                        tick = Object.assign(tick,{extendShow:false,Check:false,deDuMoney:0,newCoupon_Id:newGuid()})
                    })
                    this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                    //合并联台的订单信息
                    if(this.unionType==true){
                        this.memberorderInfo?.UnionOrderInfoList.map(union=>{
                            union.Order_ConsumeList.map(unOrder=>{
                                this.memberorderInfo.Order_ConsumeList.push(unOrder)
                            })
                        })
                    }
                    if(this.memberorderInfo.Order_ConsumeList){
                        this.memberorderInfo.Order_ConsumeList.forEach((order)=>{
                            order = Object.assign(order,{isCoupon:false,CouponNumber:0})
                        })
                    }
                    this.memberorderInfo.Order_ConsumeList.sort(this.compare('Eat_CheckPrice',''));
                    this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                    this.tickDeduMoney=0
                    return
                    
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        //关闭
        hide(){
            this.$emit("closeModel")
        },
        //点击券
        onTicket(item,index){
            if(this.selectedIndex==index)
            {
                this.presentCoupon='';
                this.tickDeduMoney=0;
                this.selectedIndex=-1;
                return;
            }
            //时间段控制 0所有时段,1指定时段
            let date = new Date(); //当前时间
            let startDate=new Date(item.arr);//开始日期
            let endDate=new Date(item.dep);//结束日期

            if((startDate.getFullYear()>=date.getFullYear() && startDate.getMonth()>=date.getMonth() && startDate.getDate()>date.getDate() ) || 
                (endDate.getFullYear()<=date.getFullYear() && endDate.getMonth()<=date.getMonth() && endDate.getDate()<date.getDate() )){
                this.selectedIndex=-1;
                this.presentCoupon='';
                this.tickDeduMoney=0;
                this.$message.warning('不在该券可使用的时间范围内!');
                return
            }
            //获取平台券信息
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Query_Mode:7, //6券查询 Query_Code固定为手机号，7券优惠查询 Query_Code固定为券号
                Query_Code:item.ticketno,
                Eat_Autoid:this.orderInfo?.Eat_AutoID
            }
            const loading = this.$loading({
                text: "获取券优惠信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetXrXMSCorpInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    this.UseCouponInfo=data?.ResponseBody
                    if(this.UseCouponInfo?.flag=='M')
                    {
                        this.selectedIndex=index;
                        this.presentCoupon=item;
                        if(this.UseCouponInfo.useprice>Number(this.unPaidMoney))
                        {
                            this.tickDeduMoney=Number(this.unPaidMoney);
                        }
                        else
                        {
                            this.tickDeduMoney=this.UseCouponInfo.useprice;
                        }
                        console.log(data?.ResponseBody);
                    }
                    else if(this.UseCouponInfo?.flag=='E')
                    {
                        this.presentCoupon='';
                        this.tickDeduMoney=0;
                        this.selectedIndex=-1;
                        this.$message.warning(this.UseCouponInfo.msg);
                        return
                    }
                    else
                    {
                        this.presentCoupon='';
                        this.tickDeduMoney=0;
                        this.selectedIndex=-1;
                    }
                    
                }
            }).catch((e)=>{
                this.presentCoupon='';
                this.tickDeduMoney=0;
                this.selectedIndex=-1;
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        //确定支付
        confirm(){
            if(this.presentCoupon.length==0){
                this.$message.warning('请先选择优惠券!');
                return
            }
            /**支付方式 */
            let _unpaiMoney = Number(this.unPaidMoney)
            let _payment=[]
            let _choice={
                id:this.presentCoupon.id,
                ticketno:this.presentCoupon.ticketno,
                mobile:this.idCode,
                tickettypedes:this.presentCoupon.tickettypedes
            }
            //多收金额
            let OverChargeMoney=0
            if(Number(this.tickDeduMoney) * 1 >= _unpaiMoney){
                OverChargeMoney = Number(this.tickDeduMoney) * 1 - _unpaiMoney
                _unpaiMoney=0
            }else{
                _unpaiMoney = _unpaiMoney - Number(this.tickDeduMoney) * 1
            }
            _payment.push({
                Payment_ID:this.choicePayType.PAY_ID,  //支付方式ID
                Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                Payment_Money: Number(this.tickDeduMoney), //支付金额
                OverCharge_Money:OverChargeMoney,  //多收金额
                Payment_FactMoney:0, //计营业额
                Qr_Code:'', //扫码付款的码值
                Card_No:'', //卡号
                XrXMSTKExtendInfo:_choice, //券的详情
            })
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                UnionOrders:this.unionEatAutoID,//联台订单ID集合
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
                Operator_Name:this.userInfo?.Login_Name,//操作员
                Eat_DeskName:this.memberorderInfo.Eat_DeskName, //桌牌号
                Service_Charge:this.memberorderInfo.Service_Money,  //服务费
                MantissaAdjustment_Money:this.memberorderInfo.Mantissa_Money,  //尾数调整金额
                Member:'',//会员信息
                Payments:_payment, //支付方式列表
            }
            this.$emit("publicPayPreview",param,'westsoftCouponShow')
        },
        /**数组排序 */
        compare(attr,Time) {
            return function(a,b){
                var val1 = a[attr];
                var val2 = b[attr];
                var Times1 = a[Time];
                var Times2 = b[Time];
                return val2 - val1 || Times1 - Times2;
            }
        }
    }
}
</script>

<style lang="sass">
@import "./westSoftCouponspayModel.scss";
</style>