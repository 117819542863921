<template>
  <modal class="cardAbuttingModel" :isShow="isShow">
    <div class="header">海鼎卡支付<span class="close" @click="hide()">×</span></div>
        <tabs class="modal-body" >
            <div ref="CardMoney">
            <div class="filter-nav">
                <div class="nav-box">
                    <tab-nav class="nav-li" @click="CardPayClick()">按会员卡号支付</tab-nav>
                </div>
            </div>
             <div></div>
            <div class="clearfix" >
                <div class="left-box">
                    <tab-pane>
                        <div class="tr-li">
                            请输入付款码：
                            <div class="from-input" style="width:325px">
                                <input type="text" ref="input" class="key-focus" data-model='idCode' v-model="idCode" @keyup.enter="onMemberInfo()" />
                                <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                            </div>
                            <button class="btn" @click="readCard()">读卡</button>
                        </div>
                        <div class="table-box">
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo?.Card_No}}</span></div>
                                <div class="lable-txt" style="width:180px;">会员等级：<span style="color: #000;font-size: 14px;">{{meberInfo?.Bestech_Card_KindName}}</span></div>
                                <div class="lable-txt" style="width:145px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo?.MemberName}}</span></div>
                            </div>
                            <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">
                                    <span style="letter-spacing:0.5em;">手机</span>号:
                                    <span style="color: #000;font-size: 14px;">{{meberInfo?.Mobile}}</span>
                                </div>
                                <div class="lable-txt" style="width:180px;">
                                    性<span style="padding-left:25px;">别</span>：
                                    <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Gender>-1">{{meberInfo?.Gender==0?'男':'女'}}</span>
                                </div>
                                <div class="lable-txt" style="width:160px;">余额：
                                    <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Card_Money>0">{{Number(meberInfo?.Card_Money).toFixed(2)}}</span>
                                    <span style="color: red;"  v-if="meberInfo?.Gift_Money>0" >（赠{{Number(meberInfo?.Gift_Money)}}）</span>
                                </div>
                                
                            </div>
                        </div>
                    </tab-pane> 
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="money-info">
                <!-- -->
                <div class="lable-txt">待支付金额：
                    <b class="noney red" v-if="unPaidMoney>0">{{(Conversion((unPaidMoney.toFixed(2)-Number(PointPayCouponMoney)).toFixed(2)))}}</b> 
                    <b class="noney red" v-if="unPaidMoney<=0">0</b>
                </div>
                <div class="lable-txt" v-if="is_CardPayShow">储值支付：
                    <input type="text" class="key-focus" style="color:red;" ref="couponsPayinput" data-model='couponsPay' v-model="couponsPay" @click="onCouponPayClick()" />
                </div>
            </div>
            </div>
        </tabs>
        <div class="footer-box">
            <div style="position:absolute;width:91%;height:10%;" v-if="PayResultCode==1" ></div>
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="select()">重新查询支付结果</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
     <!--会员卡信息-->
    <modal-load :isShow="cardMemberShow">
        <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
    </modal-load>
    <modal-load :isShow="cardCodeShow">
        <card-code-model :isShow="cardCodeShow" v-on:closeModel="cardCodeShow=false" @cardCodeConfirm="cardCodeConfirm"></card-code-model>
    </modal-load>
    <!--密码验证-->
    <modal-load :isShow="passwordShow">
        <password-confirm-model :isShow="passwordShow" v-on:closeModel="passwordShow=false" @passwordConfirm="passwordConfirm"></password-confirm-model>
    </modal-load>
  </modal>
</template>


<script>
import cardCodeModel from '../model/cardCodeModel.vue'
import passwordConfirmModel from '../model/passwordConfirmModel.vue'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard,NumberConversion} from '/src/common' 
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"
import ModalLoad from '../../../../template/modal/modalLoad.vue'
import Modal from '../../../../template/modal/modal.vue'

/**会员支付 */
export default {
    name:"cardAbuttingModel",
    components:{
        cardMemberModel,
        cardCodeModel,
        ModalLoad,
        passwordConfirmModel,
        Modal
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        choicePayType:Object,
        payType:Object,
        unionEatAutoID:Object,
        unionType:Boolean
    },
    data(){
        return {
            cardMemberShow:false,
            payTypeSSPay:'',
            RechargeType:0,
            is_CardPayShow:true,
            cardCodeShow:false,
            passwordShow:false,
            is_AllowMixUsing:false, //是否有不可混用券
            is_AllowMixUsingCoupon_Name:'', //不可混用券
            idCode:'',//身份码
            payCode:'',//付款码
            meberInfo:'', //会员信息
            cashCoupon:'', //代金券
            commodityVoucher:'',//商品券
            couponsCount:0,  //选择的券总数量
            couponsMoney:0, //券的抵扣金额
            couponsPay:'', //储值支付金额
            PointPayCouponMoney:'',
            unPaidMoney:0, //待付金额
            choiceCoupons:[], //选择的券
            errCounc:1,
            memberorderInfo:'',  //临时订单数据
            /**选择券 */
            ispayid:0,
            ispayName:'',
            otherPay:'',
            Payment_Code:'',
            Payment_Type:0,
            scanPayCode:'',
            /**联台结账订单新时间戳 */
            UnionOrders:'',
            /**是否触发过订单提交接口*/
            PayResultCode:0,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**card_AutoID */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /**是否直接用动态身份码支付 false:否,true:是*/
            isActiveCodePay:false,
            /**错误提示 */
            errorTitle:'',
            cardMemberList:[], //会员卡信息
            verifyCode:'', //验证码
            PointChecked:false, //是否参与线上积分支付
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            terminationID:'',//配置的终端ID
            serverId:'', //配置的服务端ID
            isZk:0,//是否折扣
        }
    },
    mounted(){
       this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.CardMoney);
           initKeyBoard.call(this,this.$refs.zf);
           //获取海鼎配置的终端
            this.$webBrowser.getEntityCards().then(d=>{
                if(d){
                    this.terminationID=d.TerminationID;
                    this.serverID=d.ServerID;
                    this.openimp()
                }else{
                    //this.terminationID='6121';
                    //this.serverID='6121';
                    this.openimp()
                }
            })
        })
    },
    watch: {
        orderInfo:{
            immediate:true,
            handler(vla){
                this.EmptyDatas();
                this.couponsPay=0
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                if(this.memberorderInfo.Order_ConsumeList){
                    //合并联台的订单信息
                    if(this.unionType==true){
                        this.memberorderInfo.UnionOrderInfoList.map(union=>{
                            union.Order_ConsumeList.map(unOrder=>{
                                this.memberorderInfo.Order_ConsumeList.push(unOrder)
                            })
                        })
                    }
                    this.memberorderInfo.Order_ConsumeList.map((order,i)=>{
                        order = Object.assign(order,{isCoupon:false,CouponNumber:1})
                        if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                            this.memberorderInfo.Order_ConsumeList.splice(i, 1)
                            return 
                        }
                    })
                }
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                if(this.memberorderInfo.Member_CardNo){
                    if(this.idCode==''){
                        this.idCode = this.memberorderInfo.Member_CardNo
                    }
                }
                this.cardInfoShow()
                this.PointPayCouponMoneyInfo(0)
                //this.unPaidMoney=this.snackPaidMoney
                
            }
        },
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                //获取海鼎配置的终端
                this.$webBrowser.getEntityCards().then(d=>{
                    if(d){
                        this.terminationID=d.TerminationID;
                        this.serverID=d.ServerID;
                        this.openimp()
                    }else{
                        // this.terminationID='6121';
                        // this.serverID='6121';
                        this.openimp()
                    }
                })
            }
        },
        couponsPay(){
            if(this.couponsPay<0){
               this.couponsPay=0
            }
            this.PointPayCouponMoneyInfo(1)
        },
    },
    methods:{
        openimp(){
            this.EmptyDatas();
            this.idCode='',this.errorTitle='',this.isZk=0
            this.initializaTion()
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
            if(this.memberorderInfo.Order_ConsumeList){
                //合并联台的订单信息
                if(this.unionType==true){
                    this.memberorderInfo.UnionOrderInfoList.map(union=>{
                        union.Order_ConsumeList.map(unOrder=>{
                            this.memberorderInfo.Order_ConsumeList.push(unOrder)
                        })
                    })
                }
                this.memberorderInfo.Order_ConsumeList.map((order,i)=>{
                    order = Object.assign(order,{isCoupon:false,CouponNumber:1})
                    if(order.Is_Gift==true || (order.Eat_Number==0 && order.Refund_Number>0)){
                        this.memberorderInfo.Order_ConsumeList.splice(i, 1)
                        return 
                    }
                })
            }
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            if(this.memberorderInfo.Member_CardNo){
                this.idCode = this.memberorderInfo.Member_CardNo
                this.onMemberInfo()
            }
            this.PointPayCouponMoneyInfo(0)
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        blurRechargeMoney(){
            if(this.unPaidMoney.toFixed(2)<0){
                this.unPaidMoney =0
            }
        },
        /**实体卡读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**查询支付结果 */
        select(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.memberorderInfo.Eat_AutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Default_ChannelID, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
            }
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                }else{
                    if(data.ResponseHeader.ResultDesc){
                        ElMessage.success({ message:data.ResponseHeader.ResultDesc});
                    }
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    //this.ispaySuccess=0;
                    return;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**会员支付 */
        CardPayClick(){
            this.is_CardPayShow=true
            this.isActiveCodePay=false
            let _unPaidMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)
            if(this.meberInfo.Card_Money){
                if(_unPaidMoney< Number(this.meberInfo.Card_Money)){
                    this.couponsPay = _unPaidMoney
                }else{
                    this.couponsPay = Number(this.meberInfo.Card_Money)
                }
            }
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        /**获取会员信息 */
        onMemberInfo(){
            if(!this.idCode){
                this.$message.error('查询码不能为空');
                return
            }
            this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.initializaTion()
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                Member_Type:9, //2：正品CRM3.0 会员 ，4：微生活会员。(如果不传，则按照顺序判断系统开通了哪个会员对接)，5：新享会，6：正品G9会员卡 9：海鼎卡
                HdExtendInfo:{terminalId:this.terminationID,serverId:this.serverID,media:''}
            }
            //console.log('param:'+JSON.stringify(param))
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.idCode=''
                }else{
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                         
                        if(this.meberInfo.OverDraftLimitedMoney){
                            this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
                        }
                        this.cardInfoShow()
                        this.idCode = this.meberInfo.Card_No;
                        //是否开启卡类全单折扣 0：否， 1：是
                        if(this.meberInfo?.Open_CardKind_Discount==1 && this.isZk==0 && this.memberorderInfo.IsMemberPrice==false){
                            //卡类全单折扣率
                            this.DealWithTheDiscount()
                        }
                        // if(this.meberInfo.Is_Have_ParPrice==1){
                        //     this.memberVipMoney(this.meberInfo)
                        // }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**全单折扣率操作*/
        DealWithTheDiscount(){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                EAT_AUTOID:this.memberorderInfo.Eat_AutoID,
                From_Scene:"HD",//来源场景, HD:海鼎卡
                Kind:3,
                DiscountScheme_ZkRate:Number(this.meberInfo.CardKind_DiscountRate),
                IsReturn_CheckOutInfo:true,
                Is_UnionOrder:true,
                Timestamps:this.memberorderInfo.Timestamps.toString()
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            const loading = this.$loading({
                text: "全单折扣中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.DealWithTheDiscount",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    ElMessage.success({ message: "全单折扣计算完成"});
                    this.isZk=1
                    this.$emit("hdMemberdiscount",data)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**取消全单折扣*/
        cancelClick(){
            if(this.meberInfo?.Open_CardKind_Discount==1 && this.isZk==1){
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID,
                    Operator_Name:this.userInfo?.Login_Name,
                    Eat_AutoID:this.memberorderInfo.Eat_AutoID,
                    IsReturn_CheckOutInfo:true,
                    Is_UnionOrder:true,
                    Timestamps:this.memberorderInfo.Timestamps.toString()
                }
                this.$httpAES.post("Bestech.CloudPos.CancelBillDiscount",param).then((data)=>{
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.$emit("hdMemberdiscount",data)
                    }
                }).catch((e)=>{
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }  
        },
        hide(){
            if(this.PayResultCode!=1){
                this.cancelClick()
                this.$emit("hdMemberReturn",this.memberorderInfo,this.meberInfo,1)
            }
        },
        /**确定付款 */
        confirm(){
            if(!this.meberInfo){
                this.$message.warning('请先读取会员卡');
                return
            }
            if(Number(this.couponsPay)>0){
                if(Number(this.couponsPay)> Number(this.unPaidMoney.toFixed(2))){
                    this.$message.error('储值金额超过待支付金额');
                    return
                }
            }
            if(Number(this.couponsPay)>Number(this.meberInfo.Card_Money)){
                this.$message.error('卡内余额不足,本次支付所需'+this.couponsPay+'，卡内余额：'+this.meberInfo.Card_Money);
                return
            }
            // if(Number(this.couponsPay)<Number(this.unPaidMoney.toFixed(2))){
            //         this.$message.error('储值金额不满足待支付金额');
            //         return
            // }
            if(this.PayResultCode==0){
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                let _order = this.memberorderInfo
                let _meberInfo=''
                /** 预支付总金额 */
                let _counMoney=0
                /**支付方式 */
                let _payment=[]
                if(this.isActiveCodePay==true){
                    _meberInfo={
                        Member_Sys:9,
                        DynamicType:'', //查询码类型 5i会员类型：1手机会员、2卡会员
                        Card_AutoID:'', //卡主键
                        Card_No:this.payCode, //卡号
                        Member_Name:'', //姓名
                        MemberCard_Kind:'', //会员等级
                        Mobile:'',//会员手机号
                        NotJoinPointProgram:false,//不参与线上积分
                        Invoice_Money:0, //本次消费开票金额
                        Invoice_No:'',//发票号码
                        is_ActiveCodePay:true,
                        HdExtendInfo:{terminalId:this.terminationID,serverId:this.serverID,media:''}
                    }
                    _payment.push({
                        Payment_ID:'CardMoneyPay',  //支付方式ID
                        Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                        Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                        Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                        Payment_Money: Number(this.unPaidMoney), //支付金额
                        Payment_FactMoney:0, //计营业额
                        Qr_Code:'', //扫码付款的码值
                        Card_No:this.payCode, //卡号
                        CouponInfo:null, //券的详情
                    })
                    _counMoney = Number(this.unPaidMoney)
                }else{  
                    let _meber = this.meberInfo
                    /**会员信息 */
                    _meberInfo ={
                        Member_Sys:9, //会员系统类型 2：正品CRM3.0，4：微生活
                        DynamicType:_meber.DynamicType, //查询码类型 5i会员类型：1手机会员、2卡会员
                        Card_AutoID:_meber.Bestech_Card_AutoID, //卡主键
                        Card_No:_meber.Card_No, //卡号
                        Member_Name:_meber.MemberName, //姓名
                        MemberCard_Kind:_meber.Bestech_Card_KindName, //会员等级
                        Mobile:_meber.Mobile,//会员手机号
                        NotJoinPointProgram:this.PointChecked,//不参与线上积分
                        Invoice_Money:0, //本次消费开票金额
                        Invoice_No:'',//发票号码
                        HdExtendInfo:{terminalId:this.terminationID,serverId:this.serverID,media:''}
                    }
                    //储值
                    if(this.couponsPay>0){
                        _payment.push({
                            Payment_ID:'CardMoneyPay',  //支付方式ID
                            Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                            Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                            Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                            Payment_Money: Number(this.couponsPay), //支付金额
                            Payment_FactMoney:Number(this.couponsPay) * ((Number(this.meberInfo?.Card_Money)-Number(this.meberInfo?.Gift_Money))/Number(this.meberInfo?.Card_Money)), //计营业额
                            Qr_Code:'', //扫码付款的码值
                            Card_No:_meber.Card_No, //卡号
                            CouponInfo:null, //券的详情
                        })
                        _counMoney = _counMoney + Number(this.couponsPay)
                    }
                }
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: _order.Eat_AutoID,//结账单ID
                    UnionOrders:this.unionEatAutoID,//联台订单ID集合
                    Timestamps:_order.Timestamps.toString(), //账单时间戳
                    Operator_Name:this.userInfo?.Login_Name,//操作员
                    Eat_DeskName:_order.Eat_DeskName, //桌牌号
                    Service_Charge:_order.Service_Money,  //服务费
                    MantissaAdjustment_Money:_order.Mantissa_Money,  //尾数调整金额
                    Member:_meberInfo,//会员信息
                    Payments:_payment, //支付方式列表
                }
                /**支付预览 */
                const loading = this.$loading({
                    text: "支付预览中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.PayPreview",param).then((data)=>{
                    loading.close();
                    console.log('支付预览返回:'+JSON.stringify(data))
                    this.payPreviewData = data
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                        if(data.ResponseBody?.Timestamps){
                            this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        }
                        if(this.isActiveCodePay==true){
                            this.payCode=''
                            this.$refs.payCode.focus();
                        }
                    }else{
                        if(data.ResponseBody?.OnlineDiscountMoney>0){
                            /**刷新订单数据 */
                                this.$emit("refreshOrder")
                        }
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        this.memberorderInfo.Mantissa_Money = data.ResponseBody.MantissaAdjustMoney
                        this.tempUserId = data.ResponseBody?.User_ID
                        this.tempOperatorName = data.ResponseBody?.User_Name
                        /**保存新时间戳 */
                        if(data.ResponseBody?.UnionOrders){
                            this.UnionOrders=data.ResponseBody.UnionOrders
                        }
                        /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                        if(data.ResponseBody.Is_NeedSMSCode==1){
                            this.cardCodeShow = true
                            return
                        }
                        /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                        if(data.ResponseBody.Is_NeedPassWord==1){
                            this.passwordShow=true
                            return
                        }
                        /**支付提交判断 0不需要 1需要 */
                        if(data.ResponseBody.Is_NeedSubmit==0){
                            if(this.Conversion((this.unPaidMoney.toFixed(2)-Number(this.PointPayCouponMoney)).toFixed(2))<=0){
                                this.PerformingCheckOut(data)
                            }else{
                                this.$emit("hdMemberReturn",this.memberorderInfo,data.ResponseBody)
                            }
                        }
                        if(this.scanCodePay==false){
                            this.PaySubmit(data)
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else if(this.PayResultCode==1){
                this.PaySubmit(this.payPreviewData)
            }
        },
        /**支付提交方法 */
        PaySubmit(data){
            if(data.ResponseBody.Is_NeedSubmit==1){
               let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                    Member_Type:data.ResponseBody.Member_Type,  //会员类型
                    Biz_ID:data.ResponseBody.Biz_ID, //业务交易流
                    Verify_Code:this.verifyCode, //验证码,密码
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Timestamps:data.ResponseBody.Timestamps.toString(),
                    Payments:data.ResponseBody.Payments
                }
                if(this.tempUserId!='' && this.tempOperatorName!=''){
                    param.User_ID = this.tempUserId
                    param.Operator_Name = this.tempOperatorName
                }
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.PaySubmit",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        if(data.ResponseBody.Timestamps){
                            this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        }
                        this.errorTitle = data.ResponseHeader.ResultDesc
                        if(data.ResponseHeader.ResultCode==4001){
                            this.PayResultCode=1
                        }
                        if(data.ResponseHeader.ResultCode==4003){
                            this.PayResultCode=0
                            this.scanPayCode=''
                        }
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        if(data.ResponseBody.TryCheckOut==true){
                            this.PerformingCheckOut(data)
                        }else{
                            this.$emit("memberReturn",this.memberorderInfo,this.meberInfo,2,data.ResponseBody)
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**结账方法 */
        PerformingCheckOut(data){
            let EatAutoIDList=[]
            let orderEatAutoList={Order_ID:this.memberorderInfo.Eat_AutoID,Timestamps:data.ResponseBody.Timestamps.toString(),Service_Charge:this.memberorderInfo.Service_Money}
            EatAutoIDList.push(orderEatAutoList)
            if(this.memberorderInfo.UnionOrderInfoList!=null){
                this.memberorderInfo.UnionOrderInfoList.map(union=>{
                    if(this.UnionOrders){
                        this.UnionOrders.map(preunion=>{
                            if(union.Eat_AutoID==preunion.Order_ID){
                                union.Timestamps = preunion.Timestamps.toString()
                            }
                        })
                    }
                    orderEatAutoList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps.toString(),Service_Charge:union.Service_Money}
                    EatAutoIDList.push(orderEatAutoList)
                })
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID, //登陆用户ID
                CheckOutOrders:EatAutoIDList, //结账订单ID
                MantissaAdjust_Money:this.memberorderInfo.Mantissa_Money, //尾数调整金额
                Service_Charge:this.memberorderInfo.Service_Money,//服务费
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Print_YN:true, //是否返回结账单打印内容
                ElectronicInvoice_PrintForbidden:this.$global.invoiceOff,//是否选择禁止打印发票二维码 false: 默认打印发票二维码,true: 不打印发票二维码
                PhaseID:this.$global.PhaseID,//指定时段ID
            }
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.tempOperatorName
            }
            const loading = this.$loading({
                text: "结账中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.PerformingCheckOut",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    if(data.ResponseBody?.Timestamps){
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    }
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    if(param.Print_YN==true){//返回结账单打印内容
                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                    }
                    if(data.ResponseBody.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    ElMessage.success({ message: "结账成功"});
                }
                this.$emit("memberPayClick",data)
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**获取星期 */
        setNowTimes(){
            let myDate = new Date()
            let wk = myDate.getDay()
            let weeks = ['7', '1', '2', '3', '4', '5', '6']
            let week = weeks[wk]
            return week;
        },
        /**数据初始化 */
        initializaTion(){
            this.meberInfo=''
            this.payCode=''
            this.couponsCount=0
            this.couponsMoney=0
            this.couponsPay=''
            this.PointPayCouponMoney=''
            this.choiceCoupons=[]
            this.cashCoupon=''
            this.commodityVoucher=''
            this.RechargeType=0
            this.is_AllowMixUsing=false
            this.is_AllowMixUsingCoupon_Name=''
            this.scanPayCode=''
            this.PayResultCode=0
            this.payPreviewData=''
            this.PointChecked = false
            this.tempUserId='',this.tempOperatorName=''
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            this.memberorderInfo.Order_ConsumeList.map(order=>{
                order.isCoupon=false,
                order.CouponNumber=1
            })
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            if(this.meberInfo.OverDraftLimitedMoney){
                this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
            }
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.cardInfoShow()
            // if(this.meberInfo.Is_Have_ParPrice==1){
            //     this.memberVipMoney(this.meberInfo)
            // }
        },
        /**会员卡信息展示 */
        cardInfoShow(){
            let _cashArr = [];  //定义一个数组
            let _commArr=[];
            if(this.meberInfo?.Coupons){
                this.meberInfo.Coupons.map(item=>{
                    if(item.Coupon_Kind==1){
                        _cashArr.push(Object.assign(item,{Check:false}))
                        this.cashCoupon=_cashArr;
                    }else{
                        _commArr.push(Object.assign(item,{Check:false}))
                        this.commodityVoucher=_commArr;
                    }
                })
            }
            let _unPaidMoney =0
            if((this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)==0){
                _unPaidMoney = this.unPaidMoney.toFixed(2)
            }else{
                _unPaidMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)
            }
            if(_unPaidMoney< Number(this.meberInfo.Card_Money)){
                this.couponsPay = _unPaidMoney
            }else{
                this.couponsPay = Number(this.meberInfo.Card_Money)
            }
        },
        /**验证码返回 */
        cardCodeConfirm(code){
            this.verifyCode=code
            this.cardCodeShow = false
            this.PaySubmit(this.payPreviewData)
        },
        passwordConfirm(pwd){
            this.verifyCode=pwd
            this.passwordShow = false
            this.PaySubmit(this.payPreviewData)
        },
        PointPayCouponMoneyInfo(type){
            if(this.meberInfo){
                if(type==0){
                    this.PointPayCouponMoney= Number(this.couponsPay)
                }
                if(type==1){
                    this.PointPayCouponMoney= Number(this.couponsPay)
                }
                if(type==2){
                    if(Number(this.couponsPay)<this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay)
                        if(this.isActiveCodePay==false){
                            this.couponsPay = Number(this.couponsPay)+Number(this.accSub(0,this.unPaidMoney))
                        }
                    }else if(Number(this.couponsPay)==this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay)
                    }else{
                        this.couponsPay =this.accSub(0,this.unPaidMoney) 
                        this.PointPayCouponMoney = this.unPaidMoney
                    }
                }
            }
            else{
                this.PointPayCouponMoney=0
            }
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        onCouponPayClick(){
            setTimeout(()=>{
                this.$refs.couponsPayinput?.select();
            },100)
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return ((arg2*m-arg1*m)/m).toFixed(n);
        },
    }
}
</script>

<style lang="sass">
@import "./cardAbuttingModel.scss";
</style>