<template>
    <modal class="discountReadCardModel" :isShow="isShow">
      <div class="header">会员全单折扣<span class="close" @click="hide()">×</span></div>
          <tabs class="modal-body" >
              <div ref="CardMoney">
              <div class="filter-nav">
                  <div class="nav-box">
                      <tab-nav class="nav-li">按会员卡号</tab-nav>
                  </div>
              </div>
               <div></div>
              <div class="clearfix" >
                  <div class="left-box">
                      <tab-pane>
                          <div class="tr-li">
                              请扫码或输入会员码：
                              <div class="from-input" style="width:325px">
                                  <input type="text" ref="input" class="key-focus" data-model='idCode' @focus="(e)=>e.target.select()" v-model="idCode" @keyup.enter="onMemberInfo()" />
                                  <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                              </div>
                              <button class="btn" @click="readCard()">读卡</button>
                          </div>
                          <div class="table-box">
                              <div class="tr-li">
                                  <div class="lable-txt" style="width:220px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo?.Card_No}}</span></div>
                                  <div class="lable-txt" style="width:200px;">会员等级：<span style="color: #000;font-size: 14px;">{{meberInfo?.Bestech_Card_KindName}}</span></div>
                                  <div class="lable-txt" style="width:145px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo?.MemberName}}</span></div>
                              </div>
                              <div class="tr-li">
                                  <div class="lable-txt" style="width:220px;">
                                      <span style="letter-spacing:0.5em;">手机</span>号:
                                      <span style="color: #000;font-size: 14px;">{{meberInfo?.Mobile}}</span>
                                  </div>
                                  <div class="lable-txt" style="width:200px;">
                                      性<span style="padding-left:25px;">别</span>：
                                      <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Gender>-1">{{meberInfo?.Gender==0?'男':'女'}}</span>
                                  </div>
                                  <div class="lable-txt" style="width:145px;">生日：<span style="color: #000;font-size: 14px;">{{meberInfo?.Birthday}}</span></div>
                              </div>
                              <div class="tr-li">
                                <div class="lable-txt" style="width:220px;">是否可折：<span style="color: #000;font-size: 14px;" v-if="meberInfo?.Open_CardKind_Discount>-1">{{meberInfo?.Open_CardKind_Discount==0?'否':'是'}}</span></div>
                                <div class="lable-txt" style="width:220px;">
                                      <span style="letter-spacing:0.5em;">折扣</span>率:
                                      <span style="color: #000;font-size: 14px;" v-if="meberInfo?.CardKind_DiscountRate>0">{{meberInfo?.CardKind_DiscountRate}}%</span>
                                  </div>
                              </div>
                          </div>
                      </tab-pane>
                  </div>
                  <div class="right-box">
                      <div class="keyboard">
                          <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                  data-value="9">9</span></div>
                          <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                  data-value="6">6</span></div>
                          <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                  data-value="3">3</span></div>
                          <div class="key-li"><span data-value="." v-if="false">.</span><span data-value="0">0</span><span
                                  data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                      </div>
                  </div>
              </div>
              </div>
          </tabs>
          <div class="footer-box">
              <button class="btn btn-cancel" @click="hide()">取消</button>
              <button class="btn btn-confirm" @click="confirm()">确认</button>
          </div>
        <!--会员卡信息-->
        <modal-load :isShow="cardMemberShow">
            <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
        </modal-load>
    </modal>
  </template>
  
<script>
import { newGuid } from '/src/common/index.js'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard,NumberConversion} from '/src/common' 
export default {
    name:'discountReadCardModel',
    emits:["closeModel","confirm"],
    props:{
        isShow:{
            typeof:Boolean,
            default:false
        },
    },
    components:{
        cardMemberModel
    },
    data(){
        return{
            // ordereatList:[
            // ],
            idCode:'',//身份码
            meberInfo:'', //会员信息
            
            cardMemberShow:false,
            cardMemberList:[], //会员卡信息
            /**card_AutoID */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /**是否触发过会员查询 */
            isGetMember:false,
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            tempidCode:'',
        }
    },
    watch:{
        isShow(value){
            if(value){//显示弹层时 初始化数据
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
           initKeyBoard.call(this,this.$refs.CardMoney);
           this.$refs.input?.focus();
        })
    },
    computed(){
        cardMemberModel
    },
    methods:{
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        // /**加载节点详情*/
        // loadData(){
            
        // },
        /**实体卡读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**获取会员信息 */
        onMemberInfo(){
            if(!this.idCode){
                this.$message.error('查询码不能为空');
                return
            }
            this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
            this.$refs.input?.select();
            if(this.isGetMember==true){
                if(this.tempidCode!=this.idCode){
                    this.idCode = this.tempidCode
                }
                return
            }else{
                this.isGetMember=true
            }
            if(!this.tempidCode){
                this.tempidCode=this.idCode
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.initializaTion()
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                Operator_Name:this.userInfo?.Login_Name, //操作员
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                IsGetCouponUsingRules:false,
            }
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                this.isGetMember=false
                this.tempidCode=''
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.idCode=''
                }else{
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                        this.idCode = this.meberInfo.Card_No;
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.isGetMember=false
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
        },
        /**数据初始化 */
        initializaTion(){
            this.meberInfo=''
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            if(!this.meberInfo){
                this.$message.warning('请先读取会员卡');
                return
            }
            if(!this.meberInfo?.Open_CardKind_Discount){
                this.$message.error('此会员卡不可全单折扣!');
                return
            }
            this.$emit("MemberCardZKReturn",this.meberInfo);
        }
    }
}
</script>
<style lang="scss">
    @import "./discountReadCardModel.scss"
</style>