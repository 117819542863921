
import {newGuid,config} from './index'
import { ElMessageBox} from 'element-plus'
import pako from 'pako'

// if(process.env.NODE_ENV=== "development"){//开发环境
// 	window.webview={
// 		raiseEvent(){}
// 	}
// }
const pushFun={};
/********Android 交互********/
if(window.webview){
	/*window.androidViw=function(methodName,evtArgs)
	 *@param methodName 方法名称
	 * @param evtArgs 参数 {data:调用方法的参数,cllBackName:回调方法的 方法名称}
	 */
	window.androidView=function(methodName,evtArgs){
		if(pushFun[methodName]){
			pushFun[methodName]=pushFun[methodName].filter(fun=>typeof(fun)=='function' && !fun._expire);//过滤掉无用的
			pushFun[methodName].forEach((fun)=>{
				fun(evtArgs);
			})
		}
	}
	let SubGroupNames;//过滤接收参数用
	/**mq 收到的消息 */
	pushFun['emq_msg_receive']=[(d)=>{
		if(d){//解压数据
			let strData = atob(d);
			let charData = strData.split('').map(function(x){return x.charCodeAt(0);});
			let binData  = new Uint8Array(charData);
			d=pako.ungzip(binData, { to: 'string'} ); 
			try {
				d=JSON.parse(d);
			} catch (error) {
				console.log(error);
			}
		}
		if(Array.isArray(SubGroupNames) && SubGroupNames.indexOf(d.DataType)>=0){
			window.androidView('mqCloudPosData',d);
		}
		//window.androidView('mqCloudPosData',d);
	}]

	/**window.webview.raiseEvent=function(methodName,evtArgs)
	 * @param methodName 方法名称
	 * @param evtArgs 参数 {data:调用方法的参数,cllBack:回调方法的名称}
	 */
	window.webViewEvent=(methodName,evtArgs)=>{
		return new Promise((resolve,reject) => {
			try {
				if(methodName=="close"){
					window.webview.raiseEvent('close','');
				}else if(methodName=='getConfig'){//获取配置
					let getConfig=(d)=>{
						try {
							d=JSON.parse(d);
						} catch (error) {
							console.log(error);
						}
						if(d.key=='config'){
							try {
								var data=JSON.parse(d.data);
								resolve(data);
							} catch (error) {
								resolve();
							}
							getConfig._expire=true;
						}
						
					}
					if(!pushFun['cache']){pushFun['cache']=[];}
					pushFun['cache'].push(getConfig);
					window.webview.raiseEvent('cache',JSON.stringify({method:'get',key:'config'}));
				}else if(methodName=='saveConfig'){//保存配置
					let data=JSON.stringify({method:'set',key:'config',data:JSON.stringify(evtArgs).replace(/[\\"]/g,"\\\"")});
					window.webview.raiseEvent('cache',data);
				}else if(methodName=='hasCamera'){//判断是否 可扫描
					pushFun['hasCamera']=[(d)=>{
						resolve(d);
					}]
					window.webview.raiseEvent('hasCamera','');
				}else if(methodName=='qr'){//启动扫描
					pushFun['qr']=[(d)=>{
						resolve(d);
					}]
					window.webview.raiseEvent('qr','');
				}else if(methodName=='openMQTT'){//开启mq服务
					pushFun['emq_start']=[(d)=>{//开启mq服务成功
						resolve(d);
						//window.webview.raiseEvent('emq_msg',JSON.stringify({"str":'测试推送消息'}));
					}]
					//ElMessageBox.alert(JSON.stringify(evtArgs))
					//evtArgs={emq_url:'地址','emq_name':'mq 账号',emq_password:'mq 密码',emq_id:'',emq_top:''}
					SubGroupNames=evtArgs.SubGroupNames;
					window.webview.raiseEvent('emq_start',JSON.stringify(evtArgs)); 
				}else if(methodName=='getDevices'){//获取设备信息
					let getDevices=(d)=>{
						try {
							d=JSON.parse(d);
							resolve(d);
						} catch (error) {
							resolve();
						}
						getDevices._expire=true;
					}
					if(!pushFun['devices']){pushFun['devices']=[];}
					pushFun['devices'].push(getDevices);
					window.webview.raiseEvent('devices','');
				}else if(methodName=='saveEntityCards'){//保存实体卡配置信息
					let data=JSON.stringify({method:'set',key:'CardMap',data:JSON.stringify(evtArgs).replace(/[\\"]/g,"\\\"")});
					window.webview.raiseEvent('cache',data);
					resolve({state:0});
				}else if(methodName=='getEntityCards'){//获取实体卡配置信息
					let getCardMap=(d)=>{
						try {
							d=JSON.parse(d);
						} catch (error) {
							console.log(error);
						}
						if(d.key=='CardMap'){
							try {
								var data=JSON.parse(d.data);
								resolve(data);
							} catch (error) {
								resolve();
							}
							getCardMap._expire=true;
						}
						
					}
					if(!pushFun['cache']){pushFun['cache']=[];}
					pushFun['cache'].push(getCardMap);
					window.webview.raiseEvent('cache',JSON.stringify({method:'get',key:'CardMap'}));
				}else if(methodName=='saveBaseSetup'){//保存基础配置
					let data=JSON.stringify({method:'set',key:'BaseSetup',data:JSON.stringify(evtArgs).replace(/[\\"]/g,"\\\"")});
					window.webview.raiseEvent('cache',data);
					resolve({state:0});
				}else if(methodName=='getBaseSetup'){//获取基础配置
					let getBaseSetup=(d)=>{
						try {
							d=JSON.parse(d);
						} catch (error) {
							console.log(error);
						}
						if(d.key=='BaseSetup'){
							try {
								var data=JSON.parse(d.data);
								resolve(data);
							} catch (error) {
								resolve();
							}
							getBaseSetup._expire=true;
						}
					}
					if(!pushFun['cache']){pushFun['cache']=[];}
					pushFun['cache'].push(getBaseSetup);
					window.webview.raiseEvent('cache',JSON.stringify({method:'get',key:'BaseSetup'}));
				}else if(methodName=="saveCustomConfig"){
					let data=JSON.stringify({method:'set',key:evtArgs.keyName,data:JSON.stringify(evtArgs.data).replace(/[\\"]/g,"\\\"")});
					window.webview.raiseEvent('cache',data);
					resolve({state:0});
				}else if(methodName=="getCustomConfig"){
					let getBaseSetup=(d)=>{
						try {
							d=JSON.parse(d);
						} catch (error) {
							console.log(error);
						}
						if(d.key==evtArgs.keyName){
							try {
								var data=JSON.parse(d.data);
								resolve(data);
							} catch (error) {
								resolve();
							}
							getBaseSetup._expire=true;
						}
					}
					if(!pushFun['cache']){pushFun['cache']=[];}
					pushFun['cache'].push(getBaseSetup);
					window.webview.raiseEvent('cache',JSON.stringify({method:'get',key:evtArgs.keyName}));
				}else if(methodName=="updater"){
					window.webview.raiseEvent('updateApp',evtArgs.DownUrl);
				}
				else if(methodName=="openPrintService"){//设置开启打印服务
					window.webview.raiseEvent('openPrintService','');
				}else if(methodName=="startPrintService"){//开启打印服务
					window.webview.raiseEvent('startPrintService','');
				}else if(methodName=="closePrinterService"){//关闭打印服务
					window.webview.raiseEvent('closePrinterService','');
				}else if(methodName=='openWindKeyBoard'){
					//开启系统键盘
					window.webview.raiseEvent('openWindKeyBoard','');
				}else if(methodName=='PosPrint'){
					window.webview.raiseEvent('PosPrint',JSON.stringify(evtArgs));
				}else if(methodName=='setDepartmentLogo'){//保存logo 图片
					window.webview.raiseEvent('setDepartmentLogo',evtArgs.logoUrl);
				}else if(methodName=='setBillDiyPrintImages'){ //设置小票自定义打印图片
					console.log('evtArgs:'+JSON.stringify(evtArgs))
					window.webview.raiseEvent('setBillDiyPrintImages',evtArgs.diyImages.toString());
				}else if(methodName=='redCardM1'){//读取明华M1卡数据
					let getDevices=(d)=>{
						resolve(d);
						getDevices._expire=true;
					}
					if(!pushFun['redCardM1']){pushFun['redCardM1']=[];}
					pushFun['redCardM1'].push(getDevices);
					window.webview.raiseEvent('redCardM1','');
				}else if(methodName=='redCardSNR'){//读取未制卡前 的内卡号
					let getDevices=(d)=>{
						resolve(d);
						getDevices._expire=true;
					}
					if(!pushFun['redCardSNR']){pushFun['redCardSNR']=[];}
					pushFun['redCardSNR'].push(getDevices);
					window.webview.raiseEvent('redCardSNR','');
				}else if(methodName=='makeCardMHM1'){//制作明华M1卡
					let getDevices=(d)=>{
						resolve(d);
						getDevices._expire=true;
					}
					if(!pushFun['makeCardMHM1']){pushFun['makeCardMHM1']=[];}
					pushFun['makeCardMHM1'].push(getDevices);
					window.webview.raiseEvent('makeCardMHM1',JSON.stringify(evtArgs));
				}else if(methodName=='openCashBox'){//打开钱箱 
					let getDevices=(d)=>{
						resolve(d);
						getDevices._expire=true;
					}
					if(!pushFun['openCashBox']){pushFun['openCashBox']=[];}
					pushFun['openCashBox'].push(getDevices);
					//打开钱箱
					window.webview.raiseEvent('openCashBox',JSON.stringify(evtArgs));
				}else if(methodName=='auxiliaryScreenShow'){//显示副屏
					window.webview.raiseEvent('auxiliaryScreenShow',JSON.stringify(evtArgs));
				}else if(methodName=='auxiliaryScreenClose'){//关闭副屏
					window.webview.raiseEvent('auxiliaryScreenClose','');
				}else if(methodName=='openPosScale'){//打开电子秤  
					let getDevices=(d)=>{
						resolve(d);
						getDevices._expire=true;
					}
					if(!pushFun['openPosScale']){pushFun['openPosScale']=[];}
					pushFun['openPosScale'].push(getDevices);
					console.log("打开电子秤:window.webview.raiseEvent('openPosScale','"+JSON.stringify(evtArgs)+"')")
					//打开电子秤
					window.webview.raiseEvent('openPosScale',JSON.stringify(evtArgs));
				}else if(methodName=='closePosScale'){//关闭电子秤
					console.log("关闭电子秤:window.webview.raiseEvent('closePosScale','')")
					window.webview.raiseEvent('closePosScale','');
				}else if(methodName=='tts'){//语音播报
					let outTime=(evtArgs.text||"").length/2*1000;
					let time=setTimeout(()=>{
						resolve();
					},outTime);
					pushFun['tts_finish']=[(d)=>{
						if(time) clearTimeout(time);
						resolve(d);
					}]
					window.webview.raiseEvent('tts',evtArgs.text);
				}else if(methodName=='ttsConfig'){//语音播报速度配置
					window.webview.raiseEvent('ttsConfig',evtArgs);
				}else if(methodName=='openYoyoAi'){//打开AI识别服务
					let getDevices=(d)=>{
						console.log(methodName+" : 回调 返回数据:"+JSON.stringify(d));
						resolve(d);
						getDevices._expire=true;
					}
					if(!pushFun[methodName]){
						pushFun[methodName]=[];
					}
					pushFun[methodName].push(getDevices);
					console.log("window.webview.raiseEvent('"+methodName+"',evtArgs)",evtArgs);
					window.webview.raiseEvent('openYoyoAi',JSON.stringify(evtArgs));
				}else{//其它
					let getDevices=(d)=>{
						console.log(methodName+" : 回调 返回数据:"+JSON.stringify(d));
						resolve(d);
						getDevices._expire=true;
					}
					pushFun[methodName]=[getDevices];
					console.log("window.webview.raiseEvent('"+methodName+"',evtArgs)",evtArgs);
					window.webview.raiseEvent(methodName,JSON.stringify(evtArgs));
				}

			} catch (error) {
				reject(error);
			}
		});
	}
}

/**winform 调用js的处理 */
/**给 form 调用js 用 
 * @methodName 处理方法名称
 * @evtArgs 参数
 * @id      唯一(针对明确注册方法处理)
 */
window.jsRegister=function(methodName,evtArgs,id){
	if(pushFun[methodName]){
		let data=[];
		if(id!=undefined){
			pushFun[methodName].forEach((fun)=>{
				if(id==fun._id){
					data.push({id:fun._id,data:fun(evtArgs)});
				}
			})
		}else{
			pushFun[methodName].forEach((fun)=>{
				data.push({id:fun._id,data:fun(evtArgs)});
			})
		}
		
		return data;
	}
}
/**js 与壳交互 
 * @param methodName  方法名称   
 * @param evtArgs 参数
 * */
const registerEvent=async (methodName,evtArgs)=>{
	if(window.CefSharp){ //winform 壳 交互
		if(window.JSExchange==undefined){
			await window.CefSharp.BindObjectAsync("JSExchange");
			console.log("BindObjectAsync",window.JSExchange)
		}
		if(window.JSExchange && window.JSExchange.raiseEvent){
			return window.JSExchange.raiseEvent(methodName,evtArgs);
		}
	}else if(window.webview){//与安卓壳 交互
		return window.webViewEvent(methodName,evtArgs);
	}
	
	return new Promise(resolve => {
		setTimeout(()=>{resolve()},0);
	});
}






const webBrowser = window.webBrowser = {
	install(Vue){
		// 添加全局对象
		Vue.config.globalProperties.$webBrowser = webBrowser;
	},
	/**判断当前设备 */
	isMobile:{
		//安卓设备
		Android: window.webview?true:false,
		//pc 设备
		Windows: window.CefSharp?true:false
	},
	/**调用关闭form方法 */
	close(isConfirm) {
		if(isConfirm===false){
			registerEvent('close');
		}else{
			ElMessageBox.confirm('是否确认关闭系统', "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
				callback: (name) => {
					if(name=='confirm')
					registerEvent('close');
				},
			});
		}
	},
	/**最小化 */
	formMini(){
		registerEvent('formMini');
	},
	/**最大化 */
	formMax(){
		registerEvent('formMax');
	},
	/**全屏 */
	formFull(){
		registerEvent('formFull');
	},
	/**关机 */
	shutdown(isConfirm){
		if(isConfirm===false){
			registerEvent('shutdown');
		}else{
			ElMessageBox.confirm('是否确认关机', "提示", {
				confirmButtonText: "确定",
				cancelButtonText:"取消",
				callback: (name) => {
					if(name=='confirm')
					registerEvent('shutdown');
				},
			});
		}
	},
	/**关机重启 */
	restart(){
		ElMessageBox.confirm('是否确认关机重启', "提示", {
			confirmButtonText: "确定",
			cancelButtonText:"取消",
			callback: (name) => {
				if(name=='confirm')
				registerEvent('restart');
			},
		});
	},
	/**保存基础配置 */
	saveBaseSetup(data){
		if(data){
			this.getBaseSetup._data=data;//缓存
			return registerEvent('saveBaseSetup',data);
		}
	},
	/**获取基础配置 */
	getBaseSetup(){
		if(this.getBaseSetup._data){
			return new Promise((resolve) => {resolve(this.getBaseSetup._data)});
		}
		return registerEvent('getBaseSetup').then(d=>{
			this.getBaseSetup._data=d;//缓存
			return d;
		});
	},
	openInputBoard(){//打开写字板
		if(window.webview){//安卓直接弹出键盘
			return window.webViewEvent('openWindKeyBoard');
		}
		return registerEvent("openInputBoard");
	},
	/**保存 钱箱配置信息 */
	saveCashbox(data){
		if(data){
			return registerEvent('saveCustomConfig',{keyName:'saveCashbox',data:data});
		}
	},
	/**获取 钱箱配置信息 */
	getCashbox(){
		return registerEvent('getCustomConfig',{keyName:'saveCashbox'});
	},
	/**打开钱箱 
	 * @param port 端口
	 * @param ip ip地址  端口为网口是需要
	 * @param param1 指令参数1
	 * @param param2 指令参数2
	 * @param param2 指令参数3
	 */
	openCashBox(port,ip,param1,param2,param3){
		return registerEvent("openCashBox",{port:port,ip:ip,param1:param1,param2:param2,param3:param3});
	},
	/**点击开钱箱按钮 去开钱箱*/
	openCashBoxBnt(){
		return new Promise((resolve) => {
			this.getCashbox().then((d)=>{
				if(d){
					if(!d.isCashbox){
						resolve({state:0});
						//resolve({state:-1,message:'未启用钱箱，请先去配置!'});
					}else{
						this.openCashBox(d.port,d.ip,d.param1,d.param2,d.param3).then(it=>{
							resolve(it);
						});
					}
				}else{
					resolve({state:0});
				}
			})
		});
	},
	/**保存 电子秤配置信息 */
	saveElectronicScale(data){
		if(data){
			return registerEvent('saveCustomConfig',{keyName:'saveElectronicScale',data:data});
		}
	},
	/**获取 电子秤配置信息 */
	getElectronicScale(){
		return registerEvent('getCustomConfig',{keyName:'saveElectronicScale'});
	},
	openWindKeyBoard(){//打开系统键盘
		return registerEvent("openWindKeyBoard");
	},
	/**获取设备信息 */
	getDevices(){
		return registerEvent('getDevices');
	},
	/**获取设备信息 wind 壳 升级用 */
	getSysVersion(){
		return registerEvent('getSysVersion');
	},
	/**更新壳程序 @param DownUrl 下载地址 @param Upgrade_LotID 升级发布批次ID */
	updater(DownUrl,Upgrade_LotID){
		return registerEvent('updater',{DownUrl:DownUrl,Upgrade_LotID:Upgrade_LotID});
	},
	/**保存logo 图片 @param logoUrl 下载地址 */
	setDepartmentLogo(logoUrl){
		return registerEvent('setDepartmentLogo',{logoUrl:logoUrl});
	},
	/**小票自定义打印图片 @param diyImages*/
	setBillDiyPrintImages(diyImages){
		return registerEvent('setBillDiyPrintImages',{diyImages:diyImages})
	},
	/**获取配置 */
	getConfig(){
		return registerEvent('getConfig');
	},
	/**保存配置 */
	saveConfig(data){
		if(data){
			config.Access_Key=data.Access_Key;
			config.Secret_key=data.Secret_key;
			config.OpenAPIUrl=data.OpenAPIUrl;
			config.UUID = data.UUID;
			registerEvent('saveConfig',data);
		}
	},
	/**保存实体卡配置信息 */
	saveEntityCards(data){
		if(data){
			return registerEvent('saveEntityCards',data);
		}
	},
	/**获取实体卡配置信息 */
	getEntityCards(){
		return registerEvent('getEntityCards');
	},
	/**读取明华M1卡数据 返回数据格式 { state://状态 0 成功 -1 失败 ,data:数据,message:错误提示 }*/
	redCardM1(data){
		return registerEvent('redCardM1',data);
	},
	/**读取未制卡前 的内卡号 */
	redCardSNR(){
		return registerEvent('redCardSNR');
	},
	/**制作明华M1卡 data={cardID:'卡id',cardNo:'卡号'} */
	makeCardMHM1(data){
		if(data){
			return registerEvent('makeCardMHM1',data);
		}
	},
	/**结账打印 */
	posPrint(data){
		if(data && data instanceof Array && data.length>0){
			registerEvent('PosPrint',JSON.parse(JSON.stringify(data)));
		}
	},
	/**厨房单打印 */
	KitPrint(data){
		if(data && data instanceof Array && data.length>0){
			registerEvent('KitPrint',JSON.parse(JSON.stringify(data)));
		}
	},
	/**ipad  判断是否可扫码 */
	hasCamera(){
		if(window.webview){//只有ipad 才能扫码
			return window.webViewEvent('hasCamera');
		}
		return new Promise(resolve=>resolve());
	},
	/**开启打印服务 */
	openPrintService(){
		return registerEvent('openPrintService');
	},
	startPrintService(){
		return registerEvent('startPrintService');
	},
	/**关闭打印服务 */
	closePrinterService(){
		return registerEvent('closePrinterService');
	},
	/**获取打印机 */
	getPrinter(){
		return registerEvent('getPrinter');
	},
	/**新打印机替换旧打印机 
	 * @param data {oldPrintName:"名称",oldPrintKind:"类型",newPrintName:"新名称",newPrintKind:"新类型"}
	*/
	printerNewOldExchange(data){
		return registerEvent('printerNewOldExchange',data);
	},
	/**ipad  扫码 */
	qr(){
		if(window.webview){//只有ipad 才能扫码
			return window.webViewEvent('qr');
		}
		return new Promise(resolve=>resolve());
	},
	/**ipad  语音播报 */
	tts(text){
		if(window.webview){//只有ipad 语音播报
			return window.webViewEvent('tts',{text:text});
		}

		
		return registerEvent('tts',{text:text});
	},
	/**ipad  语音播报速度配置 */
	ttsConfig(data){
		if(window.webview){//只有ipad 语音播报速度
			return window.webViewEvent('ttsConfig',data);
		}
		
		return registerEvent('ttsConfig',data);
	},
	/**显示副屏 */
	auxiliaryScreenShow(d){
		if(d==undefined || d==null){
			localStorage.removeItem("auxiliaryScreenData");
		}else{
			localStorage.setItem("auxiliaryScreenData",JSON.stringify(d));
		}
		
		return registerEvent('auxiliaryScreenShow',d);
	},
	//打开副屏 会先判断是否启动副屏
	openAuxiliaryScreen(data){
		this.getBaseSetup().then((d)=>{
			if(d){
				if(d.isAuxiliaryScreen){
					this.auxiliaryScreenShow(data)
				}
			}
		})
	},
	/**关闭副屏 */
	auxiliaryScreenClose(){
		return registerEvent('auxiliaryScreenClose');
	},
	/**打开电子秤 
	 * @param brand 电子秤品牌 (1 [BESTECHTYPE])
	 * @param port 串口 
	 * @param baud 端口 */
	openPosScale(brand,port,baud){
		return registerEvent('openPosScale',{brand:brand,port:port, baud:baud});
	},
	/**关闭电子秤 */
	closePosScale(){
		return registerEvent('closePosScale');
	},
	/**保存 读卡点菜配置信息 */
	saveReadCardOrder(data){
		if(data){
			return registerEvent('saveCustomConfig',{keyName:'readCardOrder',data:data});
		}
	},
	/**获取 读卡点菜配置信息 */
	getReadCardOrder(){
		return registerEvent('getCustomConfig',{keyName:'readCardOrder'});
	},
	/**打开 读卡点菜 
	 * @param brand 品牌 (1 [BESTECHTYPE])
	 * @param port 串口 
	 * @param baud 端口 */
	readCardOrder(brand,port,baud){
		return registerEvent('readCardOrder',{brand:brand,port:port, baud:baud});
	},
	/**打开 读卡点菜 
	 * @param brand 品牌 (1 [BESTECHTYPE])
	 * @param port 串口 
	 * @param baud 端口 */
	openReadCardOrder(brand,port,baud){
		return registerEvent('openReadCardOrder',{brand:brand,port:port, baud:baud});
	},
	/**关闭 读卡点菜 */
	closeReadCardOrder(){
		return registerEvent('closeReadCardOrder');
	},
	/**保存 订阅语音播报配置信息 */
	saveSubscribeVoice(data){
		if(data){
			return registerEvent('saveCustomConfig',{keyName:'subscribeVoice',data:data});
		}
	},
	/**获取 订阅语音播报菜配置信息 */
	getSubscribeVoice(){
		return registerEvent('getCustomConfig',{keyName:'subscribeVoice'});
	},
	/**保存 AI识别功能配置信息 */
	saveAiConfig(data){
		if(data){
			return registerEvent('saveCustomConfig',{keyName:'aiConfig',data:data});
		}
	},
	/**获取 AI识别功能配置信息 */
	getAiConfig(){
		return registerEvent('getCustomConfig',{keyName:'aiConfig'});
	},
	/**
	 * 开启ai识别
	*/
	openYoyoAi(){
		if(window.webview){//只有安卓 
			return window.webViewEvent('openYoyoAi');
		}
		return new Promise(resolve=>resolve());
	},
	/**
	 * 回传选择结果，当物资采集时调用此方法回传训练结果用于学习训练结果标记 
	 * @param data {itemCode:"",itemName:"",markType:1}
	 */
	selectAiData(data){
		//其中当选择ai识别触发的物资时markType为1、选择非触发的物资时标记为2 表示手动搜索的选择的结果
		if(window.webview){//只有安卓 
			return window.webViewEvent('selectAiData',data);
		}
		return new Promise(resolve=>resolve());
	},
	/**
	 * 保存识别结果，退出菜单界面调用方法保存此次训练的数据
	*/
	saveAiData(){
		if(window.webview){//只有安卓 
			return window.webViewEvent('saveAiData');
		}
		return new Promise(resolve=>resolve());
	},
	/**
	 * 多台称数据同步使用，获取当前本地已学习的所有物资数据，String 类型 需要上传接口进行保存
	*/
	getAiStudyData(){
		if(window.webview){//只有安卓 
			return window.webViewEvent('getAiStudyData');
		}
		return new Promise(resolve=>resolve());
	},
	/**
	 * 多台称数据同步使用，拉取线上数据后调用此方法进行同步操作
	*/
	setAiStudyData(){
		if(window.webview){//只有安卓 
			return window.webViewEvent('setAiStudyData');
		}
		return new Promise(resolve=>resolve());
	},
	/**
	 * 传称操作，同步菜单数据,每次app启动时回传以下结构的json markType = 0;//默认为0即可
	 * @param data [{itemCode:"",itemName:"",markType:0}]
	*/
	setAiItemInfo(data){
		if(window.webview){//只有安卓
			return window.webViewEvent('setAiItemInfo',data);
		}
		return new Promise(resolve=>resolve());
	},
	/**切换为大写输入法 */
	keyUpper(){
		return registerEvent('keyUpper');
	},
	/**开启mq服务
	 * @param data {emq_url:'','emq_name':'',emq_password:'',emq_id:'',emq_top:''}
	 */
	mqStart(data){
		return registerEvent('openMQTT',data);
	},
	/**关闭mq服务 */
	mqStop(data){
		return registerEvent('stopMQTT',data);
	},
	/**重启mq服务 pc 用
	 * @param id 机构id
	 * @param code 机构编号
	*/
	mqRestart(id,code){
		return registerEvent('restartMQTT',{DEPARTMENTID:id,DEPARTMENTCODE:code});
	},
	/**注册要监听form的方法 */
	on(eventName,cllBack){
		if(typeof(cllBack)=='function'){
			if(!pushFun[eventName]){
				pushFun[eventName]=[];
			}
			cllBack._id=newGuid();//给注册的监听打标记
			pushFun[eventName].push(cllBack);
		}
	},
	/**移除要监听form的方法 */
	off(eventName,cllBack){
		if(pushFun[eventName]){
			if(typeof(cllBack)=="function"){
				for(let i=0;i<pushFun[eventName].length;i++){
					if(cllBack==pushFun[eventName][i] || cllBack._id==pushFun[eventName][i]._id){
						pushFun[eventName][i].splice(i,1);
					}
				}
			}else if(cllBack===undefined){
				delete pushFun[eventName];
			}
		}
	}
}
//设置语音播报配置 语速 正常语速50
webBrowser.ttsConfig(30);
export default webBrowser
