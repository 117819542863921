<template>
    <modal class="numberModal" :isShow="isShow">
        <div class="header">{{title}}
            <i class="close iconfont icon-guanbi" @click="close()"></i>
        </div>
        <div class="modal-body">
            <div class="input-box">
                <span class="unit">￥</span><input-pattern :isFocSelect="false" ref="moneyInput" pattern="number" :dplaces="2" v-model="money"/>
            </div>
            <div class="grid-num-box">
                <div class="grid-item" @click="input(1)">{{1}}</div>
                <div class="grid-item" @click="input(2)">{{2}}</div>
                <div class="grid-item" @click="input(3)">{{3}}</div>
                <div class="grid-item item-back" @click="back()"><i class="iconfont icon-huitui"></i></div>
                <div class="grid-item" @click="input(4)">{{4}}</div>
                <div class="grid-item" @click="input(5)">{{5}}</div>
                <div class="grid-item" @click="input(6)">{{6}}</div>
                <div class="grid-item item-clean" @click="clean()">清空</div>
                <div class="grid-item" @click="input(7)">{{7}}</div>
                <div class="grid-item" @click="input(8)">{{8}}</div>
                <div class="grid-item" @click="input(9)">{{9}}</div>
                <div class="grid-item" @click="input('.')">.</div>
                <div class="grid-item" @click="input(0)">{{0}}</div>
                 <div class="grid-item" @click="input('00')">00</div>
                <div class="grid-item item-confirm verticalCenter" @click="confirm()">确认修改</div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name:"numberModal",
    emits:["close","confirm"],
    props:{
        /**是否显示 */
        isShow:{
            type:Boolean,
            default:false
        },
        /**标题 */
        title:{
            type:String,
            default:"修改金额"
        },
        /**要修改的数量 */
        value:{
            type:[String,Number],
            default:""
        }
    },
    watch:{
        isShow:{
            handler(newVal){
                if(newVal){
                    this.money= Number(this.value).toFixed(2);
                    setTimeout(()=>{
                        this.$refs?.moneyInput?.focus();
                        this.$refs?.moneyInput?.$refs.input?.select();
                    },100);
                }
            },
            immediate:true
        }
    },
    computed:{
        inputObj(){
            return this.$refs?.moneyInput?.$refs?.input;
        }
    },
    data(){
        return {
            money:""
        }
    },
    methods:{
        /**关闭弹层 */
        close(){
            this.$emit("close");
        },
        /**输入 */
        input(key){
            let value=this.money+"";
           
            if(document.activeElement==this.inputObj){
                let leng=(key+"").length;
                //当前input上有焦点
                if(this.inputObj.selectionStart<=this.inputObj.selectionEnd){
                    let selectStart=this.inputObj.selectionStart;
                    this.money=value.slice(0,this.inputObj.selectionStart)+key+value.slice(this.inputObj.selectionEnd);
                    this.$nextTick(()=>{
                        if(this.inputObj && selectStart>=0){
                            this.inputObj.selectionStart=selectStart+leng;
                            this.inputObj.selectionEnd=selectStart+leng;
                        }
                    })
                }else{
                    this.money=value+key;
                }
            }else{
                this.money=value+key;
                this.$nextTick(()=>{
                    this.inputObj.focus();
                    let leng=(this.money+"").length;
                    this.inputObj.selectionStart=leng;
                    this.inputObj.selectionEnd=leng;
                })
            } 
        },
        /**清空 */
        clean(){
            this.money='';
        },
        /**回退 */
        back(){
            let value=this.money+"";
            if(this.inputObj && document.activeElement==this.inputObj){
                let selectStart=this.inputObj.selectionStart;
                if(selectStart<this.inputObj.selectionEnd){
                    console.log(this.inputObj.selectionStart,this.inputObj.selectionEnd)
                    value=value.slice(0,selectStart)+value.slice(this.inputObj.selectionEnd);
                    this.money=value;
                    this.$nextTick(()=>{
                        this.inputObj.selectionStart=selectStart;
                        this.inputObj.selectionEnd=selectStart;
                    })
                }else{
                    if(selectStart>0){
                        value=value.slice(0,selectStart-1)+value.slice(this.inputObj.selectionEnd);
                    }else{
                        value=value.slice(this.inputObj.selectionEnd);
                    }
                    
                    this.money=value;
                    this.$nextTick(()=>{
                        selectStart=selectStart-1;
                        if(selectStart<0){
                            selectStart=0;
                        }
                        this.inputObj.selectionStart=selectStart;
                        this.inputObj.selectionEnd=selectStart;
                    })
                   
                }
            }
        },
        /**确定 */
        confirm(){
            this.$emit("confirm",this.money);
        }
    }
}
</script>

<style lang="scss">
.numberModal{
    .modal-dialog{ 
        width: 380px;
        max-width:380px;
       
        .modal-content{
            right: inherit;
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
        }
    }
 
    .header{
        position: relative;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        border-radius: 8px;
        border-bottom-left-radius:0 ;
        border-bottom-right-radius:0 ;
        font-weight: bold;
        font-size: 18px;
        color: #333;

        .icon-guanbi{
            margin-right: 0px;
            font-size: 18px;
        }
    }

    .modal-body{
        padding: 0;

        .input-box{
            margin: 10px 20px;
            border: 2px solid rgba(72,123,177,0.5);
            border-radius: 4px;
            display: flex;

            .unit{
                line-height: 60px;
                font-size: 30px;
                padding-left: 10px;
            }

            input{
                flex:1;
                line-height: 60px;
                height: 60px;
                font-size: 45px;
                border: none;
                box-shadow: none;
                overflow: hidden;
            }
        }

        .grid-num-box{
            border-top:1px solid rgba(0, 0, 0, 0.1);
            display: grid;
            grid-gap:1px;
            grid-template-columns: 1fr 1fr 1fr 1fr ;
            background-color: rgba(0, 0, 0, 0.1);

            .grid-item{
                background: #FFFFFF;
                line-height: 60px;
                font-size: 30px;
                color: #000000;
                font-weight: 500;
                text-align: center;
            }

            .item-clean{
                font-size: 16px;
            }

            .item-confirm{
                grid-row: 3 / span 4;
                grid-column: 4;
                font-size: 16px;
                background: #487BB1;
                color: #fff;
            }
        }
    }
}
</style>