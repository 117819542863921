<template>
    <modal class="lockDeskModel" :isShow="isShow">
        <div class="header">
           {{!isUnLock?'锁台操作':'解锁操作'}} 
        </div>
        <div class="modal-body">
            <div class="clearfix" v-if="isShow">
                <div class="filter-box clearfix">
                    <div class="filter-bnt" :class="{selected:DeskType==-1}" @click="DeskTypeClick(-1)">全部({{RoomDeskType.RoomDeskSum}})</div>
                    <!-- <div class="filter-bnt bnt-empty" :class="{ selected: DeskType == 0 }" @click="DeskTypeClick(0)"><b class="bnt-bg"></b>空台(<span class="num">{{RoomDeskType.emptyNum}}</span>)</div> -->
                    <div class="filter-bnt bnt-opening" :class="{ selected: DeskType == 1 }" @click="DeskTypeClick(1)"><b class="bnt-bg"></b>开台(<span class="num">{{RoomDeskType.openingNum}}</span>)</div>
                    <div class="filter-bnt bnt-reserve"  :class="{ selected: DeskType == 2 }" @click="DeskTypeClick(2)"><b class="bnt-bg"></b>预定(<span class="num">{{RoomDeskType.reserveNum}}</span>)</div>
                    <div class="filter-bnt bnt-preKnot"  :class="{ selected: DeskType == 3 }" @click="DeskTypeClick(3)"><b class="bnt-bg"></b>预结(<span class="num">{{RoomDeskType.preKnotNum}}</span>)</div>
                    <div class="filter-bnt bnt-overtime" :class="{ selected: DeskType == 4 }" @click="DeskTypeClick(4)"><b class="bnt-bg"></b>超时(<span class="num">{{RoomDeskType.overtimeNum}}</span>)</div>
                    <!-- <div class="filter-bnt bnt-clean"  :class="{ selected: DeskType == 5 }" @click="DeskTypeClick(5)"><b class="bnt-bg"></b>清台(<span class="num">{{RoomDeskType.cleanNum}}</span>)</div> -->
                </div>
                <div class="deskBox">
                    <div class="category-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage',isNavFirstSticky:true}">
                            <div class="nav-li" :class="{selected:filternav==-1}" @click="NavigationClick()">
                                <span class="lable-text">全部</span>
                            </div>
                            <div class="nav-li" :class="{ selected: filternav == index }" 
                                v-for="(item,index) in PosRooms" :key="item"  
                                @click="NavigationClick(item,index)" >
                            {{item.Room_Name}}
                            </div>
                        </div>
                        <div class="float-right page-box">
                            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                            <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                        </div>
                    </div>

                    <div class="menu" >
                        <!-- 菜单 -->
                        <div class="scrollBox clearfix"  >
                            <!--0：空台 1：开台 2：预定 3：预结 4：超时 5：清台-->
                            <div class="desk-box" @click="selectData[posDesk.Desk_AutoID]=selectData[posDesk.Desk_AutoID]?null:posDesk" 
                                v-for="posDesk in getPosDesks" :key="posDesk" 
                                :class="{selected:selectData[posDesk.Desk_AutoID]!=null,'empty': posDesk.Desk_Status == 0,'opening':posDesk.Desk_Status==1,'reserve':posDesk.Desk_Status==2,'preKnot':posDesk.Desk_Status==3,'overtime':posDesk.Desk_Status==4,'clean':posDesk.Desk_Status==5}">
                                <div class="top" v-if="posDesk.OpenDeskInfo" >
                                    <span v-if="posDesk.Desk_Status!=2" >{{posDesk.OpenDeskInfo.Person_Num}}人</span> <!-- 人数-->
                                    <span v-if="posDesk.Desk_Status==2" >定</span>
                                    <span v-if="posDesk.OpenDeskInfo.Eat_FoodCounts>=0 && posDesk.Desk_Status!=2" class="float-right" >{{posDesk.OpenDeskInfo.Crossed_FoodCounts}}/{{posDesk.OpenDeskInfo.Eat_FoodCounts}}</span>  <!--已划菜数量/点菜总数量-->
                                    <span v-if="posDesk.ReserveDeskInfo && posDesk.Desk_Status==2" class="float-right" >{{posDesk.ReserveDeskInfo.Cstm_Name}}</span> <!--预定客户-->
                                </div>
                                <p class="title">{{posDesk.Desk_Name}}</p>
                                <div class="bottom" v-if="posDesk.OpenDeskInfo" >
                                    <span v-if="posDesk.Desk_Status!=2" >{{DifferenceTime(posDesk.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                                    <span v-if="posDesk.ReserveDeskInfo && posDesk.Desk_Status==2 " >{{posDesk.ReserveDeskInfo.Mobile}}</span> <!--预定手机号-->
                                    <span v-if="posDesk.OpenDeskInfo.Eat_SaleMoney && posDesk.Desk_Status!=2 " class="float-right">￥{{posDesk.OpenDeskInfo.Eat_SaleMoney}}</span> <!--点餐总金额-->
                                </div>
                                <i class="iconfont icon-suo" v-if="posDesk.Is_Desk_Locked"></i>
                                <i class="iconfont icon-gou1"></i>
                            </div>
                        </div>
                    </div>
                    <div class="search-box">
                        <div class="search">
                            <input type="text" placeholder="桌号检索"  v-model="varietySelect" ref="searchBox" @blur="searchInputBlur"/>
                            <div class="deskAuto"  @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()">
                                <span class="iconfont icon-jianpan"></span>
                            </div>
                        </div>
                        <div class="float-right">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                            <span class="page-number">{{ posDesksPage.pageIndex }}/{{ posDesksPage.pageSize }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确定</button>
        </div>
        <analog-key-board ref="keyBoard" ></analog-key-board>
    </modal>
</template>

<script>
import { differenceTime} from '/src/common';
/**锁台操作 */
export default {
    name:"lockDeskModel",
    props:{
        isShow:Boolean,
        /**是否是桌台解锁 */
        isUnLock:{
            default:false,
            type:Boolean
        }
    },
    data(){
        return {
            /**导航 */
            filternav:-1,
            /**餐厅数据 */
            PosRooms:[],
            /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            posDeskIndex:undefined,
            /**桌台数据*/
            posDesks:[],
            /**桌台页码 */
            posDesksPage:{
                pageIndex:1,
                pageSize:1
            },
            /**桌台检索 */
            varietySelect:'',
            /**当前桌台状态类型 筛选 */
            DeskType:-1,
            /**桌台各种类型数量 */
            RoomDeskType:{
                /**空台数 */
                emptyNum:0,
                /**开台 */
                openingNum:0,
                /**预定 */
                reserveNum:0,
                /**预结 */
                preKnotNum:0,
                /**超时 */
                overtimeNum:0,
                /**清台 */
                cleanNum:0
            },
            /**选中需要锁定的数据 */
            selectData:{}
        }
    },
    computed:{
        //过滤数据
        getDeskFilter(){
            let list=[];
            if(this.DeskType!=-1){
                list=this.posDesks.filter(item=>(!this.isUnLock && !item.Is_Desk_Locked) || (this.isUnLock && item.Is_Desk_Locked))
                .filter(item=>item.Desk_Status==this.DeskType);
            }else{
                list=this.posDesks.filter(item=>(!this.isUnLock && !item.Is_Desk_Locked) || (this.isUnLock && item.Is_Desk_Locked))
                .filter(item=>item.Desk_Status!=0 && item.Desk_Status!=5);
            }
            return list;
        },
        /**菜品分页显示 */
        getPosDesks(){
            let size=6*7;
            return this.getDeskFilter?.slice((this.posDesksPage.pageIndex-1)*size,this.posDesksPage.pageIndex*size);
        },
        getPosRooms(){
            return this.$global.PosRooms||[];
        }
    },
    mounted(){
        this.setPosRooms();
    }, 
    watch: {
        getPosRooms(){
            this.setPosRooms();
        },
        isShow(newVal) {
            if(newVal){
                this.selectData={};
                this.DeskType=-1;
                this.varietySelect='';
                this.NavigationClick(-1);//全部 
            }
        },
        /**桌台搜索 */
        varietySelect(){
            if(this.varietySelect!=''){
                this.filternav=-1;
                this.mainCategoryIndex=0;
                let MenuTxt=this.varietySelect.toLowerCase();
                this.posDesks =this.PosRooms._allDesk.filter(pos=>(pos.Desk_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Desk_Code||"").toLowerCase().indexOf(MenuTxt)>=0);
            }else{
                this.posDesks =this.PosRooms._allDesk;
            }
        },
        posDesks(){
            this.posDesksPage.pageIndex=1;
        },
        getPosDesks(){
            let size=6*7;
            let pageSize=Math.ceil(this.getDeskFilter.length/size);
            if(pageSize==0){pageSize=1;}
            this.posDesksPage.pageSize=pageSize;
        },
        getDeskFilter(){
            ///**获取全部桌台数量,并根据数量展示分页，总页数,桌台数等 */
            let emptyNum=0,openingNum=0,reserveNum=0,preKnotNum=0,overtimeNum=0,cleanNum=0, RoomDeskSum=0;
            (this.posDesks||[]).filter(item=>(!this.isUnLock && !item.Is_Desk_Locked) || (this.isUnLock && item.Is_Desk_Locked))
            .forEach((desk)=>{
                if(desk.Desk_Status==0){
                    emptyNum++;
                }else if(desk.Desk_Status==1){
                    openingNum++;
                }else if(desk.Desk_Status==2){
                    reserveNum++;
                }else if(desk.Desk_Status==3){
                    preKnotNum++;
                }else if(desk.Desk_Status==4){
                    overtimeNum++;
                }else if(desk.Desk_Status==5){
                    cleanNum++;
                }
                if(desk.Desk_Status!=0 && desk.Desk_Status!=5)
                RoomDeskSum++;
            });
            this.RoomDeskType.emptyNum=emptyNum;
            this.RoomDeskType.openingNum=openingNum;
            this.RoomDeskType.reserveNum=reserveNum;
            this.RoomDeskType.preKnotNum=preKnotNum;
            this.RoomDeskType.overtimeNum=overtimeNum;
            this.RoomDeskType.cleanNum=cleanNum;
            this.RoomDeskType.RoomDeskSum=RoomDeskSum;
        }
    },
    methods:{
        setPosRooms(){
            if(this.getPosRooms){
                this.PosRooms=this.getPosRooms;
                let _allDesk=[];//所有桌台
                (this.PosRooms||[]).forEach((room)=>{
                    _allDesk=_allDesk.concat(room.PosDesks);
                })
                if(!this.PosRooms._allDesk){
                    this.PosRooms._allDesk=_allDesk;
                }else{
                    this.PosRooms._allDesk.splice(0,this.PosRooms._allDesk.length);//清空数据
                    Object.assign(this.PosRooms._allDesk,_allDesk);
                }
                console.log(this.PosRooms)
                this.NavigationClick(-1);//全部 
            }
        },
        /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. posDesksPage.pageIndex<this.posDesksPage.pageSize){
                    this.posDesksPage.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.posDesksPage.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.posDesksPage.pageIndex<=1?1:this.posDesksPage.pageIndex--;
            }
        },
        //桌台类型检索
        DeskTypeClick(index) {
            this.DeskType = index;
        },
        //餐厅名称导航条切换
        NavigationClick(item,index) {
            if(index>=0){
                this.filternav = index;
                this.posDesks=this.PosRooms[index].PosDesks;                
            }else{
                this.filternav=-1;
                this.posDesks=this.PosRooms._allDesk;
            }
        },
        //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            let _time =differenceTime(beginTime);
            if(_time=='-1:-1'){
                _time='0:0'
            }
            return _time;
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel",false);
        },
        /**确定和回车事件 */
        confirm(){
            let Desk_InfoList=[];
            if(!this.isUnLock){//锁台数据
                for(let key in this.selectData){
                    let desk=this.selectData[key];
                    if(desk && !desk.Is_Desk_Locked && desk.Desk_Status!=0 && desk.Desk_Status!=5){
                        Desk_InfoList.push(desk);
                    }
                }
            }else{//解锁数据
                for(let key in this.selectData){
                    let desk=this.selectData[key];
                    if(desk && desk.Is_Desk_Locked && desk.Desk_Status!=0 && desk.Desk_Status!=5){
                        Desk_InfoList.push(desk);
                    }
                }
            }
            
            if(Desk_InfoList.length==0){
                this.$message.warning('请先选择需要'+(this.isUnLock?'解锁':'加锁')+'的桌台');
                return;
            }
            let Desk_Name=Desk_InfoList.map(item=>'【'+item.Desk_Name+'】').join('与');
            this.$confirm('您确定将台号'+Desk_Name+(this.isUnLock?'解锁':'加锁')+'吗？', "提示", {
                confirmButtonText: "确定",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.lockRequest(Desk_InfoList,!this.isUnLock);
                    }
                },
            });
            
        },
        /**锁台 或 解锁 请求 */
        lockRequest(Desk_InfoList,Desk_Lock){
            console.log('Desk_InfoList:'+JSON.stringify(Desk_InfoList))
            console.log('Desk_Lock:'+Desk_Lock)
            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Desk_InfoList:Desk_InfoList,
                Desk_Lock:Desk_Lock
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            this.$httpAES.post("Bestech.CloudPos.DeskLockOperate",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success((this.isUnLock?'解锁':'加锁')+"操作成功");
                    (d.ResponseBody).forEach(item=>{
                        let desk=Desk_InfoList.find(desk=>item.Desk_AutoID==desk.Desk_AutoID);
                        if(desk){
                            desk.Is_Desk_Locked=Desk_Lock;
                            desk.Timestamps=item.Timestamps;
                        }
                    })
                    this.$emit("closeModel",true);
                }else{
                    this.$message.error((this.isUnLock?'解锁':'加锁')+"操作失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error((this.isUnLock?'解锁':'加锁')+'操作失败：'+e);
                console.log((this.isUnLock?'解锁':'加锁')+'操作失败：'+e);
            })
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        }
    }
}
</script>

<style lang="sass">
@import "./lockDeskModel.scss";
</style>