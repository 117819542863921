<template>
  <modal class="urgeFoodModel" :isShow="isShow">
    <div class="header">催菜操作</div>
        <div class="modal-body">
            <div class="filter-nav">
                <div class="nav-box">
                    <div class="nav-li" :class="{selected:navType==1}" @click="clickNav(1)">按类别起菜</div>
                    <div class="nav-li" :class="{selected:navType==2}" @click="clickNav(2)">按菜品起菜</div>
                    <div class="nav-li" :class="{selected:navType==3}" @click="clickNav(3)">按类别催菜</div>
                    <div class="nav-li" :class="{selected:navType==4}" @click="clickNav(4)">按菜品催菜</div>
                </div>
                <div class="float-right page-box"><span class="last-page iconfont icon-fangxiangquan-left"></span>
                    <span class="page-number">1/2 页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" ></span>
                </div>
            </div>
            <div class="clearfix">
                <div class="left-box" >
                    <div class="item-box"  v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li'}" >
                        <div class="tab-pane" v-if="navType==1">
                            <div class="item-li category " :class="{selected:item.is_Type==1}" v-for="item in tempfoodSub" :key="item" @click="onFoodSubClick(item)">
                                <div class="category-title">{{item.SubCategory_Name}}</div>
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                        <div class="tab-pane" v-else-if="navType==2">
                            <div class="item-li" :class="{selected:item.is_Type==1}" 
                                v-for="item in temporderMenuList?.filter(it=>it.Food_Status<3 && it.Eat_Number>0 && it.KitSpeed_Dimension=='等')" :key="item" 
                                @click="onOrderMenuClick(item)">
                                <p class="title">{{item.EAT_XFName}}</p>
                                <div class="describe " :class="{selected:item.is_Type==1}" :title="hoverShow(item.Eat_Time,item.Food_Speed,item.Eat_MasName)">
                                    <span>{{Login_Name}}({{DifferenceTime(item.Eat_Time,0)}})</span>
                                    <span>{{item.Food_Speed}}</span>
                                    <span>已过{{DifferenceTime(item.Eat_Time,1)}}分钟</span>
                                    <span v-if="item.Eat_MasName">口味：{{item.Eat_MasName}}</span>
                                    <span v-else></span>
                                </div> 
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                        <div class="tab-pane" v-else-if="navType==3">
                            <div class="item-li category " :class="{selected:item.hasten_Type==1}" v-for="item in tempfoodSub" :key="item" @click="onFoodSubHastenClick(item)">
                                <div class="category-title">{{item.SubCategory_Name}}</div>
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                        <div class="tab-pane" v-else-if="navType==4">
                            <div class="item-li" :class="{selected:item.hasten_Type==1}" 
                                v-for="item in temporderMenuList?.filter(it=>it.Food_Status<3 && it.Eat_Number>0 && it.Food_Speed!='等叫')" :key="item" 
                                @click="onOrderMenuHastenClick(item)">
                                <p class="title">{{item.EAT_XFName}}</p>
                                <div class="describe " :class="{selected:item.hasten_Type==1}" :title="hoverShow(item.Eat_Time,item.Food_Speed,item.Eat_MasName)">
                                    <span>{{Login_Name}}({{DifferenceTime(item.Eat_Time,0)}})</span>
                                    <span>{{item.Food_Speed}}</span>
                                    <span>已过{{DifferenceTime(item.Eat_Time,1)}}分钟</span>
                                    <span v-if="item.Eat_MasName">口味：{{item.Eat_MasName}}</span>
                                    <span v-else></span>
                                </div> 
                                <i class="iconfont icon-gou-fine"></i>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <label class="checkbox allBox" @change="allClick()"><input type="checkbox" :checked="disRadioCheck" /><i class="iconfont icon-iconoption"></i>全选</label>
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="item-up" ><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="item-down" ><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                    <div class="tip-box">
                        <!--提示：此处显示提示文本，没有提示则不显示-->
                    </div>
                </div>
                <div class="right-box">
                    <button class="btn" :class="{selected:wholeType=='WaitCall'}" @click="foodSpeedOperateClick('WaitCall','整桌等叫')">整桌等叫</button>
                    <button class="btn" :class="{selected:wholeType=='CallUp'}" @click="foodSpeedOperateClick('CallUp','整桌起菜')">整桌起菜</button>
                    <button class="btn" :class="{selected:wholeType=='RushFood'}" @click="foodSpeedOperateClick('RushFood','整桌催菜')">整桌催菜</button>
                    <button class="btn" :class="{selected:wholeType=='AllOnDesk'}" @click="foodSpeedOperateClick('AllOnDesk','整桌上齐')">整桌上齐</button>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>
import { differenceTimeseconds } from '/src/common' 

export default {
    name:"urgeFoodModel",
    data(){
        return {
            navPage:{pageIndex:0,pageSize:0},
            /**小类菜品 */
            tempfoodSub:[],
            /**临时菜品 */
            temporderMenuList:[],
            foodSubIndex:-1,
            /**导航条选择 */
            navType:1,
            /**是否全选 */
            isSelectAll:false,
            disRadioCheck:false,
            /**选择的菜品速度 */
            isFoodSpeed:'',
            /**整桌 */
            wholeType:'',
            /**用户信息 */
            userInfo:'',
            /**用户姓名 */
            Login_Name:'',
            istypeName:''
        }
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        orderMenuList:Object,
        allfoodSubCategorys:Object,
    },
     watch:{
        /**监控，打开厨房页面 */
        isShow(){
            if(this.isShow){
                this.openimp()
            }
        }
    },
    mounted(){
       this.$nextTick(()=> {
           this.openimp()
        })
    },
    methods:{
        /**打开执行 */
        openimp(){
            this.wholeType=''
            this.temporderMenuList=[];
            this.userInfo=this.$auth.getUserInfo(); 
            this.Login_Name=this.userInfo.Login_Name; 
            this.navType=1;
            this.allfoodSubCategorys.forEach(all=>{
                Object.assign(all, {is_Type:0,hasten_Type:0})
            })

            this.tempfoodSub=[];
            let foodSub={};//分类
            /**菜品 */
            this.orderMenuList.forEach(temp=>{
                if(temp.Food_Status<3){
                    if(!foodSub[temp.SubCategory_ID]){
                        foodSub[temp.SubCategory_ID]=true;
                        let sub=this.allfoodSubCategorys.find(it=>it.SubCategory_ID==temp.SubCategory_ID);
                        if(sub){
                            this.tempfoodSub.push(sub);
                        }
                    }

                    temp.Eat_ComboItemList?.forEach(item=>{
                        if(!foodSub[item.SubCategory_ID]){
                            foodSub[item.SubCategory_ID]=true;
                            let sub=this.allfoodSubCategorys.find(it=>it.SubCategory_ID==item.SubCategory_ID);
                            if(sub){
                                this.tempfoodSub.push(sub);
                            }
                        }
                    })
                }
            })
            this.orderMenuList.forEach(temp=>{
                /**套餐 */
                if(temp.Food_Type==2){
                    temp.Eat_TcItemList.forEach(eat=>{
                        let subType=0
                        this.tempfoodSub.some(food=>{
                            if(food.SubCategory_ID==temp.SubCategory_ID){
                                subType=1
                                return true
                            }
                        })
                        if(subType==1){
                            eat = Object.assign(eat, {is_Type:0,hasten_Type:0})
                            this.temporderMenuList.push(eat)
                        }
                    })
                /**即配套餐 */
                }else if(temp.Food_Type==3){
                    temp.Eat_TcItemList.forEach(eat=>{
                        let subType=0
                        this.tempfoodSub.some(food=>{
                            if(food.SubCategory_ID==temp.SubCategory_ID){
                                subType=1
                                return true
                            }
                        })
                        if(subType==1){
                            eat = Object.assign(eat, {is_Type:0,hasten_Type:0})
                            this.temporderMenuList.push(eat)
                        }
                    })
                }else if(temp.Food_Type!=4){
                    if(this.tempfoodSub.some(it=>it.SubCategory_ID==temp.SubCategory_ID)){
                        Object.assign(temp, {is_Type:0,hasten_Type:0});
                        this.temporderMenuList.push(temp);
                        //组合菜品明细
                        temp.Eat_ComboItemList?.forEach(it=>{
                            this.temporderMenuList.push(Object.assign(it,{
                                is_type:0,
                                hasten_Type:0,
                            }))
                        })
                    }
                }
            })
            /**套餐 */
            this.tempfoodSub = this.unique(this.tempfoodSub);
            console.log('temporderMenuList:'+JSON.stringify(this.temporderMenuList))
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**鼠标悬浮展示 */
        hoverShow(Eat_Time,Food_Speed,Eat_MasName){
            let title=''
            if(Eat_MasName==''||Eat_MasName==null){
                title=''+this.Login_Name+'('+this.DifferenceTime(Eat_Time,0)+')'+Food_Speed+'已过'+this.DifferenceTime(Eat_Time,1)+'分钟'
            }else{
                title=''+this.Login_Name+'('+this.DifferenceTime(Eat_Time,0)+')'+Food_Speed+'已过'+this.DifferenceTime(Eat_Time,1)+'分钟,口味:'+Eat_MasName
            }
            return title;
        },
        /**确认 */
        confirm(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let _eatConsumeIDList=[];
            let _eatKindID =[]
            let _kind =0;
            let _foodSpeed='';
            if(this.navType==1){
                this.tempfoodSub.map(item=>{
                    if(item.is_Type==1){
                        _eatKindID.push(item.SubCategory_ID);
                    }
                })
                _kind = 2;
                _foodSpeed='CallUp';
            }
            if(this.navType==2){
                this.temporderMenuList.map(item=>{
                    if(item.is_Type==1){
                        _eatConsumeIDList.push(item.Eat_ConsumeID);
                    }
                })
                _kind=3;
                _foodSpeed='CallUp';
            }
            if(this.navType==3){
                this.tempfoodSub.map(item=>{
                    if(item.hasten_Type==1){
                        _eatKindID.push(item.SubCategory_ID);
                    }
                })
                _kind = 2;
                _foodSpeed='RushFood';
            }
            if(this.navType==4){
                this.temporderMenuList.map(item=>{
                    if(item.hasten_Type==1){
                        _eatConsumeIDList.push(item.Eat_ConsumeID);
                    }
                })
                _kind=3;
                _foodSpeed='RushFood';
            }
            if(_eatConsumeIDList.length==0 && _eatKindID==0){
                this.$alert('请先选择需要催菜的菜品', '提示', {
                            confirmButtonText: '确定',
                    });
                return;
            }
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                EAT_AUTOID:this.orderInfo.Eat_AutoID,
                Eat_ConsumeID:_eatConsumeIDList,  //菜品时传
                EAT_KINDID:_eatKindID, //分类时传
                Kind:_kind,
                Food_Speed:_foodSpeed,
                Timestamps:this.orderInfo.Timestamps
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            const loading = this.$loading({
                text: "提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.FoodSpeedOperate",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                   this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.$message.success({ message: this.istypeName+"已提交"});
                    this.$emit("urgeFoodReturn",data,data.ResponseBody.Order_ConsumeList)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**整桌催菜/叫起/等叫/上齐 */
        foodSpeedOperateClick(foodSpeedEnum,title){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.wholeType = foodSpeedEnum
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                EAT_AUTOID:this.orderInfo.Eat_AutoID,
                Eat_ConsumeID:[],  //菜品时传
                EAT_KINDID:[], //分类时传
                Kind:1,
                Food_Speed:foodSpeedEnum,
                Timestamps:this.orderInfo.Timestamps
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            this.$confirm('确认要'+title+'吗?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                .then(() => {
                    const loading = this.$loading({
                        text: "提交中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.FoodSpeedOperate",param).then((data)=>{
                        loading.close();
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            this.$message.success({ message: title+"成功"});
                            this.$emit("urgeFoodReturn",data,data.ResponseBody.Order_ConsumeList)
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                }).catch(() => {
                    this.$message({type: 'info',message: ''+title+'取消'});          
            });
        },
        /**按类别叫起 */
        onFoodSubClick(item){
            if(item.is_Type==0){
                item.is_Type=1;
                this.istypeName='类别起菜'
            }else{
                item.is_Type=0;
            }
            
        },
        /**按类别催菜 */
        onFoodSubHastenClick(item){
            if(item.hasten_Type==0){
                item.hasten_Type=1;
                this.istypeName='类别催菜'
            }else{
                item.hasten_Type=0;
            }
        },
        /**按菜品叫起*/
        onOrderMenuClick(item){
            if(item.is_Type==0){
                item.is_Type=1;
                this.istypeName='菜品起菜'
            }else{
                item.is_Type=0;
            }
        },
        /**按菜品催菜 */
        onOrderMenuHastenClick(item){
             if(item.hasten_Type==0){
                item.hasten_Type=1;
                this.istypeName='菜品催菜'
            }else{
                item.hasten_Type=0;
            }
        },
        /**导航条点击 */
        clickNav(type){
            if(type==1){
                this.disRadioCheck = true;
                this.tempfoodSub.map(item=>{
                    if(item.is_Type==0){
                        this.disRadioCheck = false;
                    }
                })
            }
            if(type==2){
                this.disRadioCheck = true;
                this.temporderMenuList.map(item=>{
                    if(item.is_Type==0){
                        this.disRadioCheck = false;
                    }
                })
            }
            if(type==3){
                this.disRadioCheck = true;
                this.tempfoodSub.map(item=>{
                    if(item.hasten_Type==0){
                        this.disRadioCheck = false;
                    }
                })
            }
            if(type==4){
                this.disRadioCheck = true;
                this.temporderMenuList.map(item=>{
                    if(item.hasten_Type==0){
                        this.disRadioCheck = false;
                    }
                })
            }
            this.navType = type;
        },
        /**全选 */
        allClick(){
            if(this.navType==1){
                if(this.disRadioCheck){
                    this.disRadioCheck = false;
                    this.tempfoodSub.map(item=>{
                        item.is_Type=0;
                    })
                }else{
                    this.disRadioCheck = true;
                    this.tempfoodSub.map(item=>{
                        item.is_Type=1;
                    })
                }   
            }
            if(this.navType==2){
                if(this.disRadioCheck){
                    this.disRadioCheck = false;
                    this.temporderMenuList.map(item=>{
                        item.is_Type=0;
                    })
                }else{
                    this.disRadioCheck = true;
                    this.temporderMenuList.map(item=>{
                        item.is_Type=1;
                    })
                }
            }
            if(this.navType==3){
                if(this.disRadioCheck){
                    this.disRadioCheck = false;
                    this.tempfoodSub.map(item=>{
                        item.hasten_Type=0;
                    })
                }else{
                    this.disRadioCheck = true;
                    this.tempfoodSub.map(item=>{
                        item.hasten_Type=1;
                    })
                }   
            }
            if(this.navType==4){
                if(this.disRadioCheck){
                    this.disRadioCheck = false;
                    this.temporderMenuList.map(item=>{
                        item.hasten_Type=0;
                    })
                }else{
                    this.disRadioCheck = true;
                    this.temporderMenuList.map(item=>{
                        item.hasten_Type=1;
                    })
                }
            }
        },
        DifferenceTime(beginTime,type) {
            let time =''
            if(type==0){
                time =new Date(beginTime).getHours()+':'+new Date(beginTime).getMinutes()
            }else{
                time =differenceTimeseconds(beginTime,type);
            }
            return time
        },
        /**去除重复 */
        unique(newarr) {
            const res = new Map();
            return newarr.filter((newarr) => !res.has(newarr.SubCategory_ID) && res.set(newarr.SubCategory_ID, 1));
        }
    }
}
</script>

<style lang="sass">
@import "./urgeFoodModel.scss";
</style>