<template>
  <modal class="fastNumPriceModel" :isShow="isShow">
      <div class="header">改价操作</div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">基本信息</div>
                    <div class="tr-li">
                        <div class="lable-txt">菜品名称：</div>
                        <div class="from-input" style="width:310px"><input type="text" :value="disRadioOrder.EAT_XFName" readonly /></div>
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt">菜品原价：</div>
                        <div class="from-input" style="width:115px"><input type="text" :value="disRadioOrder.Eat_CheckPrice" readonly /></div>
                        <div class="lable-txt">改后单价：</div>
                        <div class="from-input" style="width:115px"><input type="text" class="key-focus"  ref="priceInput" data-model='price' @focus="$event.target.select()"  v-model="price" onkeyup="value=this.value.replace(/\D+.\D+/g,'')" /></div>
                        <div class="title">选择改价原因</div>
                        <div class="reason-box" v-mouse-scroll>
                            <div class="reason-tag" :class="{selected:item.OperateReason_AutoID==reasontype}" v-for="(item,index) in RefundReasonsList" :key="index" @click="clickReason(item)" >{{item.OperateReason_Name}}</div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>

import {initKeyBoard} from '/src/common'
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

export default {
    name:'changeNumPriceModel',
    props:{
        isShow:Boolean,
        isCheckOut:Boolean,
        isPrice:{//是否改价
            type:Boolean,
            default:false
        },
        /**点单数量 */
        orderNum:Number,
       disRadioOrder:Object,
       orderInfo:Object
    },
    data(){
        return{
            /**改后数量 */
            num:'',
            /**改后单价 */
            price:'',
            /**改后辅助数量 */
            assSizeNumber:'',
            /**用户数据 */
            userInfo:'',
            RefundReasonsList:'',
            reasontype:0,
            reasontContent:'',
        }
    },
    watch: {
        isShow() { 
            this.num=''
            this.price=''
            this.assSizeNumber=''
            this.reasontype=0;
            this.reasontContent='';
            this.EmptyDatas();
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$el,this.$refs.recharRight);
            this.EmptyDatas();
        })
    },
    methods:{
        changeRate(e,input){
            e.target.value=(e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
            this.input=e.target.value
        },
        EmptyDatas(){
            this.OperateReason()
            console.log('disRadioOrder:'+JSON.stringify(this.disRadioOrder))
            this.price=this.disRadioOrder?.Eat_CheckPrice;
            setTimeout(() => {
                this.$refs.priceInput.focus();
            },100);
        },
        /**原因 */
        clickReason(item){
            if(this.isPrice==true){
                this.reasontype = item.OperateReason_AutoID;
                this.reasontContent = item.OperateReason_Name;
            }else{
                this.reasontype = item.OperateReason_AutoID;
                this.reasontContent = item.OperateReason_Name;
            }
        },
        /**操作原因*/
        OperateReason(){
            this.$cacheData.OperateReasons().then((d)=>{
                this.RefundReasonsList = d;
            }).catch((e)=>{
                this.$alert("数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**确认 */
        confirm(){
            if(this.reasontContent==''){
                this.$message.warning("改价原因不能为空!");
                return
            }
            ElMessage.success({ message: "改价成功"});
            this.$emit("changepriceClick",Number(this.price).toFixed(2),this.reasontype,this.reasontContent)
            
        },
        /**取消 */
        hide(){
            this.$emit("closeModel")
        },
    }
}
</script>

<style lang="sass">
@import './fastNumPriceModel.scss';
</style>