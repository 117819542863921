<template>
    <modal class="copyFoodModel" :isShow="isShow">
        <div class="header">
            复制菜品
        </div>
        <div class="modal-body">
            <div class="clearfix" v-if="isShow">
                <div class="left-box">
                    <div class="category-nav">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage',isNavFirstSticky:true}">
                            <div class="nav-li" :class="{selected:filternav==-1}" @click="NavigationClick()">
                                <span class="lable-text">全部</span>
                            </div>
                           <div class="nav-li" :class="{ selected: filternav == index }" 
                                v-for="(item,index) in PosRooms" :key="item"  
                                @click="NavigationClick(item,index)" >
                            {{item.Room_Name}}
                            </div>
                        </div>
                        <div class="float-right page-box">
                            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                            <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                        </div>
                    </div>

                    <div class="menu" >
                        <!-- 菜单 -->
                        <div class="scrollBox clearfix"  >
                            <!--0：空台 1：开台 2：预定 3：预结 4：超时 5：清台-->
                            <div class="desk-box" @click="DeskClick(posDesk)"  v-show="posDesk.Desk_AutoID!=this.orderInfo.Eat_DeskID"
                                v-for="posDesk in getPosDesks()" :key="posDesk" 
                                :class="{selected:posDeskIndex==posDesk.Desk_AutoID, 'empty': posDesk.Desk_Status == 0,'opening':posDesk.Desk_Status==1,'reserve':posDesk.Desk_Status==2,'preKnot':posDesk.Desk_Status==3,'overtime':posDesk.Desk_Status==4,'clean':posDesk.Desk_Status==5}">
                                <div class="top" v-if="posDesk.OpenDeskInfo" >
                                    <span v-if="posDesk.Desk_Status!=2" >{{posDesk.OpenDeskInfo.Person_Num}}人</span> <!-- 人数-->
                                    <span v-if="posDesk.Desk_Status==2" >定</span>
                                    <span v-if="posDesk.OpenDeskInfo.Eat_FoodCounts>=0 && posDesk.Desk_Status!=2" class="float-right" >{{posDesk.OpenDeskInfo.Crossed_FoodCounts}}/{{posDesk.OpenDeskInfo.Eat_FoodCounts}}</span>  <!--已划菜数量/点菜总数量-->
                                    <span v-if="posDesk.ReserveDeskInfo && posDesk.Desk_Status==2" class="float-right" >{{posDesk.ReserveDeskInfo.Cstm_Name}}</span> <!--预定客户-->
                                </div>
                                <p class="title">{{posDesk.Desk_Name}}</p>
                                <div class="bottom" v-if="posDesk.OpenDeskInfo" >
                                    <span v-if="posDesk.Desk_Status!=2" >{{DifferenceTime(posDesk.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                                    <span v-if="posDesk.ReserveDeskInfo && posDesk.Desk_Status==2 " >{{posDesk.ReserveDeskInfo.Mobile}}</span> <!--预定手机号-->
                                    <span v-if="posDesk.OpenDeskInfo.Eat_SaleMoney && posDesk.Desk_Status!=2 && MapConsumeMoney==false" class="float-right">￥{{posDesk.OpenDeskInfo.Eat_CheckMoney}}</span> <!--点餐总金额-->
                                </div>
                                <i class="iconfont icon-gou1"></i>
                            </div>
                        </div>
                    </div>
                    <div class="search-box">
                        <div class="search">
                            <input type="text" placeholder="按桌台模糊检索"  v-model="varietySelect" ref="searchBox" @blur="searchInputBlur" />
                            <div class="deskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()" >
                                <span class="iconfont icon-jianpan"></span>
                            </div>
                        </div>
                        <div class="float-right">
                            <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                            <span class="page-number">{{ posDesksPage.pageIndex }}/{{ posDesksPage.pageSize }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="panel panel-table" >
                        <div class="panel-heading">
                            <div class="tr">
                                <div class="td selectChecked">选择</div>
                                <div class="td name">菜品名称</div>
                                <div class="tdnum">数量</div>
                                <div class="tds">单位</div>
                                <div class="td price">单价</div>
                                <div class="td">小计</div>
                            </div>
                        </div>
                        <div class="panel-body"  v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper'}" v-if="orderMenuList">
                            <div class="slide-wrapper" :class="{selected:item.Food_Status>=0,selectedRow:item.selected}" v-for="(item,index) in orderMenuList"  :key="item" v-show="item.Eat_Number>0 && item.Food_Type!=4">
                                <div class="animate-slide-start">
                                    <div class="slide-content" :title="item.EAT_XFName" >
                                        <div class="selectChecked verticalCenter"  @click="item.selected=!item.selected">
                                            <div class="check">
                                                <span class="iconfont " :class="item.selected?'icon-iconoption1':'icon-iconoption'"></span>
                                            </div>
                                        </div>
                                        <div class="detailed">
                                            <div class="tr"  @click="item.selected=!item.selected">
                                                <div class="td name nowrap">{{item.EAT_XFName}}</div>
                                                <div class="tdnum">{{item.Eat_Number}}</div>
                                                <div class="tds" :title="item.Eat_XFSize" >{{item.Eat_XFSize.substring(0,1)}}</div>
                                                <div class="td" style="font-size: 13px;" >{{(item.Eat_SalePrice).toFixed(2)}}</div>
                                                <div class="td" style="font-size: 13px;">{{(item.Eat_SalePrice * item.Eat_Number).toFixed(2) }}</div>
                                            </div>
                                            <div class="describe"  @click="item.selected=!item.selected">
                                                <div class="inlineBlock float tag-box">
                                                    <span class="tag tao" v-if="item.Food_Type==2 || item.Food_Type==3 || item.FoodSetmeals" >套</span>
                                                </div>
                                            </div>
                                            <div v-if="(item.Food_Type==2 || item.Food_Type==3 || item.FoodSetmeals) && (dianji==false || packageShow!=index)" style="position:relative;float:right;" @click="foldClick(item,index)">
                                                <i class="iconfont icon-fangxiang-down"></i>
                                            </div>
                                            <div v-if="(item.Food_Type==2 || item.Food_Type==3 || item.FoodSetmeals) && dianji==true && packageShow==index " style="position:relative;float:right;" @click="foldClick(item,index)">
                                                <i class="iconfont icon-fangxiang-up"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <shrink-view>
                                    <div class="more-box" :class="{selected:item.Food_Status>=0,selectedRow:item.selected}" v-show="item.Eat_TcItemList && dianji  && eatXFBMID==item.Eat_ConsumeID">
                                        <div class="slide-content" style="position: relative;" v-for="(food,foodindex) in item.Eat_TcItemList" :key="foodindex">
                                            <div style="position: relative">
                                                <div class="tr">
                                                    <div class="td name nowrap" :title="item.EAT_XFName" >{{food.EAT_XFName}}</div>
                                                    <div class="tdnum">{{food.Eat_Number}}</div>
                                                    <div class="tds" :title="food.Eat_XFSize" >
                                                        {{food.Eat_XFSize.substring(0,1)}}
                                                    </div>
                                                    <div class="td" style="font-size: 13px;">{{(food.Eat_SalePrice).toFixed(2)}}</div>
                                                    <div class="td" style="font-size: 13px;">{{(food.Eat_SalePrice * food.Eat_Number).toFixed(2) }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </shrink-view>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <div class="selectChecked" @click="allCheck">
                            <span class="iconfont icon-iconoption" :class="isAllCheck?'icon-iconoption1':'icon-iconoption'"></span>全选
                        </div>
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确定</button>
        </div>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </modal>
</template>

<script>
import { differenceTime,newGuid } from '/src/common';
import { httpAES,auth,cacheData } from '/src/services'
import { ElMessage } from "element-plus";
import shrinkView from '../ShrinkView/ShrinkView.vue'

/**全局初始化数据 */
let _data={
    RunCloudPosHideDeskMapConsumeMoney:false, /**启用触摸屏隐藏桌态图【消费金额】信息，true: 隐藏，false:不隐藏 */
};

/**复制菜品 */
export default {
    name:"copyFoodModel",
    components:{
        shrinkView
    },
    props:{
        isShow:Boolean,
        orderInfo:Object
    },
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RunCloudPosHideDeskMapConsumeMoney = globalSysSetting.RunCloudPosHideDeskMapConsumeMoney
        }
        return {
            /**导航 */
            filternav:-1,
            /**餐厅数据 */
            PosRooms:[],
            /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            posDeskIndex:undefined,
            /**桌台数据*/
            posDesks:[],
            /**桌台页码 */
            posDesksPage:{
                pageIndex:1,
                pageSize:1
            },
            /**桌台检索 */
            varietySelect:'',
            orderMenuList:[],
            /**是否全选 */
            isAllCheck:false,
            /**大类数据 */
            foodMainCategory:[],
            mainCategoryIndex:0,
            /**小类数据 */
            foodSubCategorys:[],
            /**全部小类数据 */
            allfoodSubCategorys:[],
            subCategoryIndex:0,
            /**菜品数据  */
            posFoods:[],
            /**临时菜品数据 */
            TempposFoods:[],
            /**对应套餐数据 */
            posFoodSetMealInfos:[],
            /**桌台时间戳 */
            DeskTimestamps:'',
             /**点击 */
            dianji:false,
            eatXFBMID:'',
            packageShow:-1,
            MapConsumeMoney:false
        }
    },
    mounted(){
         this.$nextTick(()=> {
            //获取桌台数据
            if(this.getPosRooms){
                this.PosRooms=this.getPosRooms;
                let _allDesk=[];//所有桌台
                (this.PosRooms||[]).forEach((room)=>{
                    _allDesk=_allDesk.concat(room.PosDesks);
                })
                console.log(this.PosRooms)
                if(!this.PosRooms._allDesk){
                    this.PosRooms._allDesk=_allDesk;
                }else{
                    this.PosRooms._allDesk.splice(0,this.PosRooms._allDesk.length);//清空数据
                    Object.assign(this.PosRooms._allDesk,_allDesk);
                }
            }
            this.RefreshData()
            this.NavigationClick(-1);//全部 
            //菜品数据结构
            this.$cacheData.PosFoods().then((d)=>{
                this.foodMainCategory=d.data;
                let all=[];//全部大类
                this.foodMainCategory?.forEach(category=> {
                    if(category.FoodSubCategorys && category.FoodSubCategorys.length>0){
                        all=all.concat(category.FoodSubCategorys);
                    }
                });
                all._all=[];//全部小类
                if(all.length>0){
                    all.forEach(sub => {
                        let _SubCategory={SubCategory_Name:sub.SubCategory_Name,SubCategory_ID:sub.SubCategory_ID}
                        this.allfoodSubCategorys.push(_SubCategory);
                        if(sub.PosFoods && sub.PosFoods.length>0)
                        all._all=all._all.concat(sub.PosFoods)
                    });
                }
                this.foodMainCategory._all=all; //小分类
                this.posFoods=all[0].PosFoods;
            }).catch(e=>{
                console.log('e:'+e)
                this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
            })  
            this.MapConsumeMoney=_data.RunCloudPosHideDeskMapConsumeMoney
        })
    },

    watch:{
        isShow(){
            if(this.isShow==true){
                this.posDeskIndex=undefined
                this.isAllCheck = false
                this.orderMenuList=[]
                this.RefreshData()
            }
        },
        /**桌台搜索 */
        varietySelect(){
            if(this.varietySelect!=''){
                this.filternav=-1;
                this.mainCategoryIndex=0;
                let MenuTxt=this.varietySelect.toLowerCase();
                this.posDesks =this.PosRooms._allDesk.filter(pos=>(pos.Desk_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Desk_Code||"").toLowerCase().indexOf(MenuTxt)>=0);
            }else{
                this.posDesks =this.PosRooms._allDesk;
            }
        },
        posDesks(){
            let size=4*6;
            this.posDesksPage.pageIndex=1;
            this.posDesksPage.pageSize=Math.ceil(this.posDesks.filter(item=>item.Desk_Status==1 || item.Desk_Status==3 || item.Desk_Status==4).length/size);
        },
        getPosRooms:{
            handler(newVal,oldVal){
                if(this.getPosRooms){
                    this.PosRooms=this.getPosRooms;
                    let _allDesk=[];//所有桌台
                    (this.PosRooms||[]).forEach((room)=>{
                        _allDesk=_allDesk.concat(room.PosDesks);
                    })
                    if(!this.PosRooms._allDesk){
                        this.PosRooms._allDesk=_allDesk;
                    }else{
                        this.PosRooms._allDesk.splice(0,this.PosRooms._allDesk.length);//清空数据
                        Object.assign(this.PosRooms._allDesk,_allDesk);
                    }
                }
            },
            immediate:true,
            deep:true
        }
    },
    computed:{
        getPosRooms(){
            return this.$global.PosRooms||[];
        }
    },
    methods:{
        /**重新去获取桌台信息 */
        RefreshData(){
            let userInfo=this.$auth.getUserInfo()
            const loading = this.$loading({
                text: '数据加载中',
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0)",
            });
            this.$httpAES.post('Bestech.CloudPos.GetBriefDeskMap',{
                Body_ID:newGuid(),
                User_ID:userInfo?.User_ID,
                DeskRange_Type:0
            }).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode==0){
                    let _PosRooms=data.ResponseBody?.PosRooms||[];
                    this.PosRooms.forEach(room=>{
                        room.PosDesks.forEach(pos=>{
                            _PosRooms.forEach(_room=>{
                                _room.PosDesks.forEach(_pos=>{
                                    if(_pos.Desk_AutoID==pos.Desk_AutoID && _pos.Desk_Status!=pos.Desk_Status){
                                        pos.Desk_Status=_pos.Desk_Status
                                        if(_pos.Desk_Status==1){
                                            pos = Object.assign(pos,{Order_ID:_pos.Order_ID})
                                        }
                                    }
                                })
                            })
                        })
                    })
                    this.PosRooms._allDesk=[];//所有桌台
                    (this.PosRooms||[]).forEach((room)=>{
                        this.PosRooms._allDesk=this.PosRooms._allDesk.concat(room.PosDesks);
                    })
                    this.NavigationClick(-1);//全部 
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. posDesksPage.pageIndex<this.posDesksPage.pageSize){
                    this.posDesksPage.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.posDesksPage.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.posDesksPage.pageIndex<=1?1:this.posDesksPage.pageIndex--;
            }
        },
        /**下拉 */
        foldClick(item,index){
            this.packageShow = index
            if(this.dianji==true && this.eatXFBMID!=item.Eat_ConsumeID){
                this.dianji=true
            }else{
                this.dianji=!this.dianji
            }
            this.eatXFBMID = item.Eat_ConsumeID
        },
        /**菜品分页显示 */
        getPosDesks(){
            let size=4*6;
            return this.posDesks.filter(item=>item.Desk_Status==1 || item.Desk_Status==3 || item.Desk_Status==4).slice((this.posDesksPage.pageIndex-1)*size,this.posDesksPage.pageIndex*size);
        },
        //餐厅名称导航条切换
        NavigationClick(item,index) {
            if(index>=0){
                this.filternav = index;
                this.posDesks=this.PosRooms[index].PosDesks;                
            }else{
                this.filternav=-1;
                this.posDesks=this.PosRooms._allDesk;
            }
        },
        //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            let _time =differenceTime(beginTime);
            if(_time=='-1:-1'){
                _time='0:0'
            }
            return _time;
        },
        /**全选 */
        allCheck(){
            this.isAllCheck=!this.isAllCheck;
            this.orderMenuList.forEach((item)=>{
                if(item.Eat_Number>0){
                    item.selected=this.isAllCheck
                }
            })
        },
        DeskClick(posDesk){
            this.posDeskIndex=posDesk.Desk_AutoID;
            //自定义套餐
            let DiyTcInfo =false
            this.$cacheData.DeskPosconfigure().then((d)=>{
                if(d.DiyTcInfo?.IS_PERAVGPRICETC || d.DiyTcInfo?.IS_PERAVGPRICETC==0){
                    if(d.DiyTcInfo?.IS_PERAVGPRICETC==1){DiyTcInfo=true}
                }
            }).catch(e=>{
                console.log('e:'+e)
            })
            const loading = this.$loading({
                text: '数据加载中',
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0)",
            });
            let userInfo=this.$auth.getUserInfo()
            let param={
                Body_ID:newGuid(),
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Eat_DeskID:posDesk.Desk_AutoID, //桌台ID
                EAT_AUTOID:'',
                Is_UnionOrder:false
            }
            if(posDesk?.Order_ID){
                param.EAT_AUTOID=posDesk?.Order_ID
            }else{
                if(posDesk.OpenDeskInfo?.Eat_AutoID){
                    param.EAT_AUTOID=posDesk.OpenDeskInfo.Eat_AutoID//订单Id
                }
            }
            //获取订单信息
            this.$httpAES.post('Bestech.CloudPos.GetOrderInfo',param).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode==0){
                    this.orderMenuList = data.ResponseBody.Order_ConsumeList;
                    this.orderMenuList.forEach(order=>{
                        //即配套餐
                        if(order.Food_Type==3){
                            order = Object.assign(order,{Is_SetMealCountAvgPrice:DiyTcInfo})
                        }
                        this.foodMainCategory.some(food=>{
                            food.FoodSubCategorys.some(sub=>{
                                sub.PosFoods.some(pos=>{
                                    if(order.EAT_XFBMID==pos.Food_ID){
                                        order.Eat_SalePrice=pos.Food_Price
                                        //套餐
                                        if(order.Food_Type==2){
                                            if(pos?.Is_SetMealCountAvgPrice){
                                                order = Object.assign(order,{Is_SetMealCountAvgPrice:pos?.Is_SetMealCountAvgPrice})
                                            }
                                        }
                                        return true
                                    }
                                })
                            })
                        })
                    })
                    this.isAllCheck=false;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**确定和回车事件 */
        confirm(){
            if(this.posDeskIndex==undefined){
                 this.$message.error('请先选择需要复制的桌台');
                 return
            }
            this.copyFoodInsert()
        },
        /**把需要复制的菜品保存并展示到前端页面上 */
        copyFoodInsert(){
            let copyType=0
            let _orderList=[]
            this.orderMenuList.map(order=>{
                /**&& order.Eat_Number>0 && order.Is_Gift==false (order.selected==true || order.Is_Gift==true) */
                if(order.selected==true && order.Food_Type!=4){
                    copyType=1
                     let  _item = {
                        EAT_XFBMID:order.EAT_XFBMID,
                        EAT_XFCode:order.EAT_XFCode,
                        EAT_XFName:order.EAT_XFName,
                        Eat_Number:order.Eat_Number,
                        Eat_CheckPrice:order.Eat_SalePrice,
                        ModiPrice:0,
                        Eat_SalePrice:order.Eat_SalePrice,
                        Food_CostPrice:order.Food_CostPrice,
                        Eat_MasName:'',
                        Food_Status:-1,
                        Eat_XFSize:order.Eat_XFSize,
                        FoodModifys:null,
                        FoodSetmeals:null,
                        Is_Discounted:order.Is_Discounted,
                        Is_ChangedNum:order.Is_ChangedNum,
                        indenttion:newGuid(),
                        Food_Type:order.Food_Type,
                        DiyTcType:order.Food_Type,
                        Eat_ConsumeID:newGuid(),
                        AssSizeNumber:'',
                        Food_AssSize:'',
                        Promotion_Price:0,
                        ConsumeOperations:[],
                        Is_SetMealCountAvgPrice:false,
                    };
                    if(order?.Is_SetMealCountAvgPrice){
                        _item.Is_SetMealCountAvgPrice = order.Is_SetMealCountAvgPrice
                    }
                    if(order.Eat_AssSize!=''){
                        _item.Food_AssSize= order.Eat_AssSize
                        _item.AssSizeNumber=order.Eat_AssNumber
                    }
                    let _FoodSetmeals=[];
                    if(order.Eat_TcItemList){
                        order.Eat_TcItemList.map(eat=>{
                                if(eat.Eat_Number>0){
                                    _FoodSetmeals.push({
                                        //FoodSetmeal_ID:eat.Eat_ConsumeID,
                                        FoodSetmeal_ID:eat.EAT_XFBMID,
                                        FoodSetmeal_Code:eat.EAT_XFCode,
                                        FoodSetmeal_Name:eat.EAT_XFName,
                                        FoodSetmeal_Number:eat.Eat_Number,
                                        FoodSetmeal_Price:eat.Eat_SaleMoney,
                                        FoodSaleMoney:eat.Eat_SaleMoney,
                                        FoodModifys:null,
                                        Eat_MasName:'',
                                        Food_Status:-1,
                                        Eat_ConsumeID:newGuid()})
                                }
                        })
                    }
                    if(_FoodSetmeals.length>0){
                        _item.FoodSetmeals = _FoodSetmeals
                    }
                    _orderList.push(_item)
                }
            })
            if(copyType==0){
                 this.$message.error('请选择需要复制的菜品');
            }else{
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
                this.$emit("cpoyFoodReturn",_orderList)
            }
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }  
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        },
    }
}
</script>

<style lang="sass">
@import "./copyFoodModel.scss";
</style>