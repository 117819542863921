<template>
    <modal class="cardAbuttingModel" :isShow="isShow">
      <div class="header">海鼎卡支付<span class="close" @click="hide()">×</span></div>
          <tabs class="modal-body" >
              <div ref="CardMoney">
              <div class="filter-nav">
                  <div class="nav-box">
                      <tab-nav class="nav-li">按会员卡号支付</tab-nav>
                  </div>
              </div>
               <div></div>
              <div class="clearfix" >
                  <div class="left-box">
                      <tab-pane>
                          <div class="tr-li">
                              请输入付款码：
                              <div class="from-input" style="width:325px">
                                  <input type="text" ref="input" class="key-focus" data-model='idCode' v-model="idCode" @keyup.enter="onMemberInfo()" />
                                  <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                              </div>
                              <button class="btn" @click="readCard()">读卡</button>
                          </div>
                          <div class="table-box">
                              <div class="tr-li">
                                  <div class="lable-txt" style="width:220px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo?.Card_No}}</span></div>
                                  <div class="lable-txt" style="width:180px;">会员等级：<span style="color: #000;font-size: 14px;">{{meberInfo?.Bestech_Card_KindName}}</span></div>
                                  <div class="lable-txt" style="width:145px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo?.MemberName}}</span></div>
                              </div>
                              <div class="tr-li">
                                  <div class="lable-txt" style="width:220px;">
                                      <span style="letter-spacing:0.5em;">手机</span>号:
                                      <span style="color: #000;font-size: 14px;">{{meberInfo?.Mobile}}</span>
                                  </div>
                                  <div class="lable-txt" style="width:180px;">
                                      性<span style="padding-left:25px;">别</span>：
                                      <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Gender>-1">{{meberInfo?.Gender==0?'男':'女'}}</span>
                                  </div>
                                  <div class="lable-txt" style="width:160px;">余额：
                                      <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Card_Money>0">{{Number(meberInfo?.Card_Money).toFixed(2)}}</span>
                                      <span style="color: red;"  v-if="meberInfo?.Gift_Money>0" >（赠{{Number(meberInfo?.Gift_Money)}}）</span>
                                  </div>
                                  
                              </div>
                          </div>
                      </tab-pane> 
                  </div>
                  <div class="right-box">
                      <div class="keyboard">
                          <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                  data-value="9">9</span></div>
                          <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                  data-value="6">6</span></div>
                          <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                  data-value="3">3</span></div>
                          <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                  data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                      </div>
                  </div>
              </div>
              <div class="money-info">
                  <!-- -->
                  <div class="lable-txt">待支付金额：
                      <b class="noney red" v-if="unPaidMoney>0">{{(Conversion((unPaidMoney.toFixed(2)-Number(PointPayCouponMoney)).toFixed(2)))}}</b> 
                      <b class="noney red" v-if="unPaidMoney<=0">0</b>
                  </div>
                  <div class="lable-txt">储值支付：
                      <input type="text" class="key-focus" style="color:red;" ref="couponsPayinput" data-model='couponsPay' v-model="couponsPay" @click="onCouponPayClick()" />
                  </div>
              </div>
              </div>
          </tabs>
          <div class="footer-box">
              <div style="position:absolute;width:91%;height:10%;" v-if="PayResultCode==1" ></div>
              <button class="btn btn-cancel" @click="hide()">取消</button>
              <button class="btn btn-border" @click="select()">重新查询支付结果</button>
              <button class="btn btn-confirm" @click="confirm()">确认</button>
          </div>
       <!--会员卡信息-->
      <modal-load :isShow="cardMemberShow">
          <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
      </modal-load>
      <modal-load :isShow="cardCodeShow">
          <card-code-model :isShow="cardCodeShow" v-on:closeModel="cardCodeShow=false" @cardCodeConfirm="cardCodeConfirm"></card-code-model>
      </modal-load>
      <!--密码验证-->
      <modal-load :isShow="passwordShow">
          <password-confirm-model :isShow="passwordShow" v-on:closeModel="passwordShow=false" @passwordConfirm="passwordConfirm"></password-confirm-model>
      </modal-load>
    </modal>
  </template>
<script>
import cardCodeModel from '../../diningDetail/model/cardCodeModel.vue'
import passwordConfirmModel from '../../diningDetail/model/passwordConfirmModel.vue'
import cardMemberModel from '../../model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard,NumberConversion} from '/src/common' 
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"
import ModalLoad from '../../../../template/modal/modalLoad.vue'
import { json } from 'body-parser'

/**会员支付 */
export default {
    name:"cardAbuttingModel",
    components:{
        cardMemberModel,
        cardCodeModel,
        ModalLoad,
        passwordConfirmModel
    },
    props:{
        isShow:Boolean,
        orderInfo:Object,
        choicePayType:Object,
        payType:Object,
        snackPaidMoney:Number,
        orderMenuList:Object,
        MantissaMoney:Number,
        headingInfo:Object,
        Channellike:String,
        RoomAutoID:String,
        DeskName:String,
        EatAutoIDs:String,
        Timestamps:String,
        paymentHistory:Object,
        totalMoney:Number,
        discountMoneuy:Number
    },
    data(){
        return {
            cardMemberShow:false,
            payTypeSSPay:'',
            RechargeType:0,
            cardCodeShow:false,
            passwordShow:false,
            idCode:'',//身份码
            payCode:'',//付款码
            meberInfo:'', //会员信息
            commodityVoucher:'',//商品券
            couponsCount:0,  //选择的券总数量
            couponsMoney:0, //券的抵扣金额
            couponsPay:0, //储值支付金额
            PointPayCouponMoney:'',
            unPaidMoney:0, //待付金额
            choiceCoupons:[], //选择的券
            errCounc:1,
            memberorderInfo:'',  //临时订单数据
            /**选择券 */
            ispayid:0,
            ispayName:'',
            otherPay:'',
            Payment_Code:'',
            Payment_Type:0,
            scanPayCode:'',
            eatAutoID:'',
            /**联台结账订单新时间戳 */
            UnionOrders:'',
            /**是否触发过订单提交接口*/
            PayResultCode:0,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**card_AutoID */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /**错误提示 */
            errorTitle:'',
            cardMemberList:[], //会员卡信息
            verifyCode:'', //验证码
            PointChecked:false, //是否参与线上积分支付
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            terminationID:'',//配置的终端ID
            serverId:'', //配置的服务端ID
            isZk:0,//是否折扣
        }
    },
    mounted(){
        this.$nextTick(()=> {
            initKeyBoard.call(this,this.$refs.CardMoney);
            initKeyBoard.call(this,this.$refs.zf);
            //获取海鼎配置的终端
            this.$webBrowser.getEntityCards().then(d=>{
                if(d){
                    this.terminationID=d.TerminationID;
                    this.serverID=d.ServerID;
                    this.openimp()
                }else{
                    //this.terminationID='6121';
                    //this.serverID='6121';
                    this.openimp()
                }
            })
        })
    },
    watch: {
        snackPaidMoney:{
            immediate:true,
            handler(vla){
                this.EmptyDatas();
                this.couponsPay=0
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
                if(this.memberorderInfo.Order_ConsumeList){
                    for(let i=0; i<this.memberorderInfo.length; i++){
                        this.memberorderInfo[i] = Object.assign(this.memberorderInfo[i],{isCoupon:false,CouponNumber:0})
                        if(this.memberorderInfo[i].Is_Gift==true || (this.memberorderInfo[i].Eat_Number==0 && this.memberorderInfo[i].Refund_Number>0)){
                            this.memberorderInfo.splice(i,1)
                            i--
                        }
                    }
                }
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                if(this.memberorderInfo.Member_CardNo){
                    if(this.idCode==''){
                        this.idCode = this.memberorderInfo.Member_CardNo
                    }
                }
                this.PointPayCouponMoneyInfo(0)
                this.unPaidMoney=this.snackPaidMoney
                this.cardInfoShow()
                
            }
        },
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow==true){
                //获取海鼎配置的终端
                this.$webBrowser.getEntityCards().then(d=>{
                    if(d){
                        this.terminationID=d.TerminationID;
                        this.serverID=d.ServerID;
                        this.openimp()
                    }else{
                        //this.terminationID='6121';
                        //this.serverID='6121';
                        this.openimp()
                    }
                })
            }
        },
        couponsPay(){
            if(this.couponsPay<0){
                this.couponsPay=0
            }
            this.PointPayCouponMoneyInfo(1)
        },
    },
    methods:{
        openimp(){
            this.EmptyDatas();
            this.idCode='',this.errorTitle='',this.isZk=0
            this.initializaTion()
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
            if(this.memberorderInfo){
                for(let i=0; i<this.memberorderInfo.length; i++){
                    this.memberorderInfo[i] = Object.assign(this.memberorderInfo[i],{isCoupon:false,CouponNumber:0})
                    if(this.memberorderInfo[i].Is_Gift==true || (this.memberorderInfo[i].Eat_Number==0 && this.memberorderInfo[i].Refund_Number>0)){
                        this.memberorderInfo.splice(i,1)
                        i--
                    }
                }
            }
            this.unPaidMoney= Number(Number(this.snackPaidMoney).toFixed(2))
            this.PointPayCouponMoneyInfo(0)
            
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        blurRechargeMoney(){
            if(this.unPaidMoney.toFixed(2)<0){
                this.unPaidMoney =0
            }
        },
        /**实体卡读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**查询支付结果 */
        select(){
            if(this.EatAutoIDs!=''){
                this.eatAutoID = this.EatAutoIDs
                this.ordertimestamps = this.Timestamps
            }
            if(this.eatAutoID==''){
                this.$message.error('没有需要查询的支付结果');
                return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                    text: "查询支付结果中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
            });
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.eatAutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Site_Serial, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.ordertimestamps.toString(), //账单时间戳
            }
            if(this.ordertimestamps!=''){
                param.Timestamps = this.ordertimestamps.toString();
            }
            httpAES.post("Bestech.CloudPos.RequeryThePaymentResult", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.errorTitle = d.ResponseHeader.ResultDesc
                    this.ispaySuccess=1
                    if(d.ResponseHeader.ResultCode==4003){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        this.ispaySuccess=0
                        this.payCode=''
                        this.ordertimestamps = d.ResponseBody?.Timestamps.toString();
                    }
                    return;
                }else{
                    let ResultDesc = '未查询到订单的支付结果'
                    let ResuType=0
                    if(d.ResponseHeader.ResultDesc==''){
                        d.ResponseHeader.ResultDesc = ResultDesc
                        ResuType = 1
                    }
                    this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.ordertimestamps = d.ResponseBody.Timestamps;
                            this.ispaySuccess=0;
                            this.$emit("ThePaymentResultClic",d,this.payCode)
                        }
                    });
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**获取会员信息 */
        onMemberInfo(){
            if(!this.idCode){
                this.$message.error('查询码不能为空');
                return
            }
            this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            this.initializaTion()
            let ConsumeList=[]
            this.orderMenuList.map(order=>{
                let item={
                    Eat_ConsumeID:order.Eat_ConsumeID,
                    Eat_XFBMID:order.EAT_XFBMID,
                    Eat_XFBMName:order.EAT_XFName,
                    Eat_Number:order.Eat_Number,
                    Eat_Price:order.Eat_SalePrice, //折前价格
                    Eat_ZkPrice:order.Eat_CheckPrice,//折扣后的价格
                }
                ConsumeList.push(item)
            })
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                Member_Type:9, //2：正品CRM3.0 会员 ，4：微生活会员。(如果不传，则按照顺序判断系统开通了哪个会员对接)，5：新享会，6：正品G9会员卡 9：海鼎卡
                HdExtendInfo:{terminalId:this.terminationID,serverId:this.serverID,media:''},
                OrderConsumes:ConsumeList, //核算账单优惠时，请求时传入账单菜品明细 
            }
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.idCode=''
                }else{
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                        if(this.meberInfo.OverDraftLimitedMoney){
                            this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
                        }
                        this.cardInfoShow()
                        this.idCode = this.meberInfo.Card_No;
                        //是否开启卡类全单折扣 0：否， 1：是
                        if(this.meberInfo?.Open_CardKind_Discount==1 && this.isZk==0){
                            //卡类全单折扣率
                            this.DealWithTheDiscount()
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**全单折扣率操作*/
        DealWithTheDiscount(){
            let _orderConsumes=[];
            this.memberorderInfo.map((item)=>{
                if(!item?.is_taste){
                    if(item.Food_Status==-1 && !item.is_taste && item.ConsumeOperations.length==0){
                        let SizeNumber = Number(item?.AssSizeNumber)
                        let Food_Types =1;
                        let _seep= item.Food_Speed
                        if(item.Food_Speed=='' || item.Food_Speed==null){
                            _seep='即'
                        }
                        if(item.FoodSetmeals!=null){
                            Food_Types=2
                            SizeNumber=0
                        }
                        if(item.DiyTcType){
                            Food_Types=item.DiyTcType
                        }
                        let orders ={Food_ID:item.EAT_XFBMID,Food_Code:item.EAT_XFCode,
                                            Food_Type:Food_Types,Food_Speed:_seep,Food_Size:item.Eat_XFSize,
                                            Food_Number:item.Eat_Number,Food_Price:item.Eat_SalePrice,Food_CheckPrice:item.Eat_CheckPrice,
                                            FoodModifys:item.FoodModifys,FoodSetmeals:item.FoodSetmeals,
                                            Food_Name:item?.EAT_XFName,Food_AssNumber:SizeNumber,Eat_ConsumeID:item.Eat_ConsumeID};
                        _orderConsumes.push(orders);
                    } 
                }
            })
            if(_orderConsumes.length==0){
                this.$message.warning('没有可折扣的菜品,请确认菜品没做过赠送,改价,会员价之类的操作');
                return;
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Kind:3,
                DiscountScheme_AutoID:this.disRadioclass,
                DiscountScheme_ZkRate:Number(this.meberInfo.CardKind_DiscountRate),
                OrderConsumes:_orderConsumes,
            }
            const loading = this.$loading({
                text: "全单折扣中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.DealWithTheSnackDiscount",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    ElMessage.success({ message: "全单折扣计算完成"});
                    this.isZk=1
                    this.$emit("discountSchemeClic",data)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**取消全单折扣*/
        cancelClick(){
            if(this.meberInfo?.Open_CardKind_Discount==1 && this.isZk==1){
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID,
                    Operator_Name:this.userInfo?.Login_Name,
                    Eat_AutoID:this.memberorderInfo.Eat_AutoID,
                    IsReturn_CheckOutInfo:true,
                    Is_UnionOrder:true,
                    Timestamps:this.memberorderInfo.Timestamps.toString()
                }
                this.$httpAES.post("Bestech.CloudPos.CancelBillDiscount",param).then((data)=>{
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.$emit("hdMemberdiscount",data)
                    }
                }).catch((e)=>{
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }  
        },
        hide(){
            if(this.PayResultCode!=1){
                this.$emit("memberCloseModel")
            }
        },
        /**确定付款 */
        confirm(){
            if(!this.meberInfo){
                this.$message.warning('请先读取会员卡');
                return
            }
            if(Number(this.couponsPay)>0){
                if(Number(this.couponsPay)> Number(this.unPaidMoney.toFixed(2))){
                    this.$message.error('储值金额超过待支付金额');
                    return
                }
            }
            if(Number(this.couponsPay)>Number(this.meberInfo.Card_Money)){
                this.$message.error('卡内余额不足,本次支付所需'+this.couponsPay+'，卡内余额：'+this.meberInfo.Card_Money);
                return
            }
            // if(Number(this.couponsPay)<Number(this.unPaidMoney.toFixed(2))){
            //         this.$message.error('储值金额不满足待支付金额');
            //         return
            // }
            if(this.PayResultCode==0){
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                let _order = this.memberorderInfo
                let _meberInfo=''
                /** 预支付总金额 */
                let _counMoney=0
                /**支付方式 */
                let _payment=[]  
                let _meber = this.meberInfo
                /**会员信息 */
                _meberInfo ={
                    Member_Sys:9, //会员系统类型 2：正品CRM3.0，4：微生活
                    DynamicType:_meber.DynamicType, //查询码类型 5i会员类型：1手机会员、2卡会员
                    Card_AutoID:_meber.Bestech_Card_AutoID, //卡主键
                    Card_No:_meber.Card_No, //卡号
                    Member_Name:_meber.MemberName, //姓名
                    MemberCard_Kind:_meber.Bestech_Card_KindName, //会员等级
                    Mobile:_meber.Mobile,//会员手机号
                    NotJoinPointProgram:this.PointChecked,//不参与线上积分
                    Invoice_Money:0, //本次消费开票金额
                    Invoice_No:'',//发票号码
                    HdExtendInfo:{terminalId:this.terminationID,serverId:this.serverID,media:''}
                }
                //储值
                if(this.couponsPay>0){
                    _payment.push({
                        Payment_ID:'CardMoneyPay',  //支付方式ID
                        Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                        Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                        Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                        Payment_Money: Number(this.couponsPay), //支付金额
                        Payment_FactMoney:Number(this.couponsPay) * ((Number(this.meberInfo?.Card_Money)-Number(this.meberInfo?.Gift_Money))/Number(this.meberInfo?.Card_Money)), //计营业额
                        Qr_Code:'', //扫码付款的码值
                        Card_No:_meber.Card_No, //卡号
                        CouponInfo:null, //券的详情
                    })
                    _counMoney = _counMoney + Number(this.couponsPay)
                }
                /**结合其他付款方式 */
                if(this.paymentHistory){
                    this.paymentHistory.forEach(pay=>{
                        if(pay.is_pay==false){
                            _payment.push({
                                Payment_ID:pay.Payment_ID,  //支付方式ID
                                Payment_Code:pay.Payment_Code, //支付方式编号
                                Payment_Name:pay.Payment_Name, //支付方式名称
                                Payment_Type:pay.Payment_Type, //支付方式类型
                                Payment_Money: pay.Payment_Money, //支付金额
                                Payment_FactMoney:pay.Payment_FactMoney, //计营业额
                                Qr_Code:pay.Qr_Code, //扫码付款的码值
                                Card_No:pay.Card_No, //卡号
                                CouponInfo:pay.CouponInfo, //券的详情
                            })
                        }
                    })
                }
                let _orderConsumes=this.$global.getFastOrderConsumes(this.orderMenuList);
                let param={
                    Body_ID:newGuid(),
                        User_ID:this.userInfo?.User_ID, //登陆用户ID
                        Eat_AutoID:'',//结账单ID
                        Timestamps:'', //账单时间戳
                        Operator_Name:this.userInfo?.Login_Name,//操作员
                        Eat_PersonNum:this.headingInfo?.DinersNum,//人数
                        Channel_ID:this.Channellike,//订单渠道ID
                        Room_ID:this.RoomAutoID,//餐厅ID
                        Eat_DeskID:this.headingInfo?.DeskAutoID, //桌台ID
                        Eat_DeskName:this.DeskName, //桌台名称
                        Eat_TakeNo:this.headingInfo?.brandNumber,//牌号
                        Eat_AllRemark:'', //订单全单备注
                        Order_Remark:this.headingInfo.orderRemark,//订单备注
                        Order_BatchNo:newGuid(),//下单批次号，每次提交下单，调用方生成一个唯一批次号，接口判断防止重复下单
                        NotSendToKitPrint:false, //下单不送打厨房
                        MantissaAdjustment_Money: Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                        Member:_meberInfo,//会员信息
                        Payments:_payment, //支付方式列表
                        OrderConsumes:_orderConsumes,//订单消费明细
                }
                if(this.EatAutoIDs){
                    param.Eat_AutoID = this.EatAutoIDs
                    param.Timestamps = this.Timestamps
                }
                /**支付预览 */
                const loading = this.$loading({
                    text: "支付预览中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.SnackPayPreview",param).then((data)=>{
                    loading.close();
                    this.payPreviewData = data
                    if(data.ResponseBody?.Timestamps){
                        this.ordertimestamps = data.ResponseBody?.Timestamps.toString();
                        this.eatAutoID = data.ResponseBody?.Eat_AutoID
                    }
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.tempUserId = data.ResponseBody?.User_ID
                        this.tempOperatorName = data.ResponseBody?.User_Name
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                        if(data.ResponseBody.Is_NeedSMSCode==1){
                            this.cardCodeShow = true
                            return
                        }
                        /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                        if(data.ResponseBody.Is_NeedPassWord==1){
                            this.passwordShow=true
                            return
                        }
                        /**支付提交判断 0不需要 1需要 */
                        if(data.ResponseBody.Is_NeedSubmit==1){
                            this.PaySubmit(data)
                        }else{
                            if(data.ResponseBody?.IsMultipleCardPay==false || !data.ResponseBody?.IsMultipleCardPay){
                                if(data.ResponseBody?.PrintsInfo){
                                    setTimeout(()=>{
                                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                                    },100);
                                }
                                ElMessage.success({ message: "支付完成"});
                                this.$emit('paySuccessConfirm');
                            }else if(data.ResponseBody?.IsMultipleCardPay==true){
                                this.$emit('payMultipleConfirm',data.ResponseBody);
                            }
                            
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else if(this.PayResultCode==1){
                this.PaySubmit(this.payPreviewData)
            }
        },
        /**支付提交方法 */
        PaySubmit(data){
            if(data.ResponseBody.Is_NeedSubmit==1){
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: data.ResponseBody?.Eat_AutoID,//结账单ID
                    MantissaAdjustMoney:data.ResponseBody?.MantissaAdjustMoney,//尾数调整金额
                    Member_Type:data.ResponseBody?.Member_Type,  //会员类型
                    Biz_ID:data.ResponseBody?.Biz_ID, //业务交易流
                    Verify_Code:this.verifyCode, //验证码
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Timestamps:data.ResponseBody.Timestamps.toString(),
                    Payments:data.ResponseBody.Payments
                }
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                this.$httpAES.post("Bestech.CloudPos.SnackPaySubmit",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        if(data.ResponseBody?.Timestamps){
                            this.payPreviewData.ResponseBody.Timestamps = data.ResponseBody.Timestamps.toString()
                        }
                        //Timestamps = data.ResponseBody.Timestamps.toString()
                        this.errorTitle = data.ResponseHeader.ResultDesc
                        if(data.ResponseHeader.ResultCode==4001){
                            this.PayResultCode=1
                        }
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        ElMessage.success({ message: "支付完成"});
                        this.$emit('paySuccessConfirm');
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**获取星期 */
        setNowTimes(){
            let myDate = new Date()
            let wk = myDate.getDay()
            let weeks = ['7', '1', '2', '3', '4', '5', '6']
            let week = weeks[wk]
            return week;
        },
        /**数据初始化 */
        initializaTion(){
            this.meberInfo=''
            this.payCode=''
            this.couponsCount=0
            this.couponsMoney=0
            this.couponsPay=''
            this.PointPayCouponMoney=''
            this.choiceCoupons=[]
            this.commodityVoucher=''
            this.RechargeType=0
            this.scanPayCode=''
            this.PayResultCode=0
            this.payPreviewData=''
            this.PointChecked = false
            this.tempUserId='',this.tempOperatorName='',this.eatAutoID=''
            this.unPaidMoney = this.snackPaidMoney
            this.memberorderInfo.map(order=>{
                order.isCoupon=false,
                order.CouponNumber=1
            })
        },
        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            if(this.meberInfo.OverDraftLimitedMoney){
                this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
            }
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.cardInfoShow()
            // if(this.meberInfo.Is_Have_ParPrice==1){
            //     this.memberVipMoney(this.meberInfo)
            // }
        },
        /**会员卡信息展示 */
        cardInfoShow(){
            let _cashArr = [];  //定义一个数组
            let _commArr=[];
            if(this.meberInfo?.Coupons){
                this.meberInfo.Coupons.map(item=>{
                    if(item.Coupon_Kind==1){
                        _cashArr.push(Object.assign(item,{Check:false}))
                        this.cashCoupon=_cashArr;
                    }else{
                        _commArr.push(Object.assign(item,{Check:false}))
                        this.commodityVoucher=_commArr;
                    }
                })
            }
            let _unPaidMoney =0
            if((this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)==0){
                _unPaidMoney = this.unPaidMoney.toFixed(2)
            }else{
                _unPaidMoney = (this.unPaidMoney.toFixed(2)-Number(this.couponsPay)).toFixed(2)
            }
            if(_unPaidMoney< Number(this.meberInfo?.Card_Money)){
                this.couponsPay = _unPaidMoney
            }else{
                this.couponsPay = Number(this.meberInfo?.Card_Money)
            }
        },
        /**验证码返回 */
        cardCodeConfirm(code){
            this.verifyCode=code
            this.cardCodeShow = false
            this.PaySubmit(this.payPreviewData)
        },
        passwordConfirm(pwd){
            this.verifyCode=pwd
            this.passwordShow = false
            this.PaySubmit(this.payPreviewData)
        },
        PointPayCouponMoneyInfo(type){
            if(this.meberInfo){
                if(type==0){
                    this.PointPayCouponMoney= Number(this.couponsPay)
                }
                if(type==1){
                    this.PointPayCouponMoney= Number(this.couponsPay)
                }
                if(type==2){
                    if(Number(this.couponsPay)<this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay)
                        if(this.isActiveCodePay==false){
                            this.couponsPay = Number(this.couponsPay)+Number(this.accSub(0,this.unPaidMoney))
                        }
                    }else if(Number(this.couponsPay)==this.unPaidMoney){
                        this.PointPayCouponMoney= Number(this.couponsPay)
                    }else{
                        this.couponsPay =this.accSub(0,this.unPaidMoney) 
                        this.PointPayCouponMoney = this.unPaidMoney
                    }
                }
            }
            else{
                this.PointPayCouponMoney=0
            }
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        onCouponPayClick(){
            setTimeout(()=>{
                this.$refs.couponsPayinput?.select();
            },100)
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
            var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return ((arg2*m-arg1*m)/m).toFixed(n);
        },
    }
}
</script>

<style lang="sass">
@import "./cardAbuttingModel.scss";
</style>