<template>
    <modal class="lockersModel" :isShow="isShow" :isIfShow="true">
        <div class="header">
            提取暂存
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="title">暂存列表</div>
                    <div class="panel panel-table">
                        <div class="panel-heading">
                            <div class="tr">
                                <div class="tdnum">序号</div>
                                <div class="td name">名称</div>
                                <div class="td">暂存时间</div>
                            </div>
                        </div>
                        <div class="panel-body" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.tr'}">
                            <div class="tr" :class="{selected:item.id==selectIndex}" v-for="(item,index) in loakersList" :key="item" 
                             @click="selectIndex=item.id">
                                <div class="tdnum">{{index+1}}</div>
                                <div class="td name"><div class="text nowrap2">{{item.name}}</div></div>
                                <div class="td">{{new Date(item.time).Format('hh:mm')}}</div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="title"></div>
                    <div class="panel panel-table">
                        <div class="panel-heading">
                            <div class="tr">
                                <div class="td">菜品名称</div>
                                <div class="td">数量</div>
                                <div class="td">单位</div>
                                <div class="td">单价</div>
                                <div class="td">小计</div>
                            </div>
                        </div>
                        <div class="panel-body" v-scroll-anime="{up:'right-order-up',down:'right-order-down',liClass:'.tr'}">
                            <div class="tr" v-for="item in loakersDetailed" :key="item">
                                <div class="td">{{item.EAT_XFName}}</div>
                                <div class="td">{{item.Eat_Number}}</div>
                                <div class="td">{{item.Eat_XFSize}}</div>
                                <div class="td">{{Number(item.Eat_CheckPrice)?.toFixed(2)}}</div>
                                <div class="td">{{((Number(item.Eat_CheckPrice) * item.Eat_Number)||0).toFixed(2)}}</div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-left txt" v-if="selectIndex">
                                <span class="money">￥{{deskCount(1)}}<b></b></span>&nbsp; <span>共{{deskCount(3)}}道菜 &nbsp; {{deskCount(2)}}份</span>
                            </div>
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="right-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="right-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认提取</button>
        </div>
    </modal>
</template>

<script>

/**提取暂存 */
export default {
    name:"lockersModel",
    props:{
        isShow:Boolean
    },
    data(){
        return {
            /**暂存分类 */
            loakersList:[],
            /**暂存明细 */
            loakersDetailed:[],
            selectIndex:undefined
        }
    },
    mounted(){
        this.loakersList=this.$cacheData.lockersCache.getLockersList(480);
    }, 
    watch: {
        isShow(newVal) {
            if(newVal){
                this.loakersList=this.$cacheData.lockersCache.getLockersList(480);
                this.loakersDetailed=[];
                this.selectIndex=undefined;
            } 
        },
        selectIndex(newVal){
            if(newVal)
            this.loakersDetailed=this.$cacheData.lockersCache.getLockersData(newVal);
            console.log('loakersDetailed:'+JSON.stringify(this.loakersDetailed))
        }
    },
    methods:{
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**计算账单总金额 数量 份数 */
        deskCount(type){
            let placeFoodsTotal = 0;
            let placeFoodsSumPortion = 0;
            let placeFoodsSum=0;
            this.loakersDetailed.map(item=>{
                if(item.Eat_Number>0){
                    placeFoodsSum++; //总数量
                    if(item.Eat_TcItemList){
                        item.Eat_TcItemList.map(eat=>{
                            placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(eat.Eat_Number); //总份量
                            if(item.ModiPrice){
                                placeFoodsTotal = placeFoodsTotal+((eat.Eat_CheckPrice+eat.ModiPrice) * eat.Eat_Number)  //总价格
                            }else{
                            placeFoodsTotal = placeFoodsTotal+(eat.Eat_CheckPrice * eat.Eat_Number)  //总价格
                            }
                        })
                    }
                    if(item.FoodSetmeals){
                        item.FoodSetmeals.map(food=>{
                            placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(food.FoodSetmeal_Number); //总份量
                            placeFoodsTotal = placeFoodsTotal+((food.FoodSetmeal_Price+food.FoodSaleMoney) * food.FoodSetmeal_Number)  //总价格
                        })
                    }
                    placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(item.Eat_Number); //总份量
                    if(item.ModiPrice){
                        placeFoodsTotal = placeFoodsTotal+((item.Eat_CheckPrice+item.ModiPrice) * item.Eat_Number)  //总价格
                    }else{
                        placeFoodsTotal = placeFoodsTotal+(item.Eat_CheckPrice * item.Eat_Number)  //总价格
                    }
                }
            })
            if(type==1){
                return placeFoodsTotal.toFixed(2);
            }
            if(type==2){
                return placeFoodsSumPortion.toFixed(1);
            }
            if(type==3){
                return placeFoodsSum.toFixed(2);
            }
        },
        /**确定和回车事件 */
        confirm(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$confirm('您确定提取当前选择的暂存菜品?', "提示", {
                confirmButtonText: "确定",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.$cacheData.lockersCache.delete(this.selectIndex)
                        this.$emit("lockersConfirm",this.loakersDetailed)
                    }
                },
            });
            
        }
    }
}
</script>

<style lang="sass">
@import "./lockersModel.scss";
</style>