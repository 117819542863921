<template>
    <div class="ipdUserOrderMode">
        <div class="head-box">
            <div class="back" @click="close()">
                <i class="icon-arrow-left iconfont"></i>
                <i class="home-icon icon-a-mendian3x iconfont"></i>
            </div>
            
            <div class="name-box">
                <div class="name">{{userInfo?.Department_Name}}
                    <span class="desk">{{orderInfo?.Eat_DeskName}}</span><span class="number">({{orderInfo?.Eat_ManNum}}人)</span>
                </div>
                <div class="text">欢迎光临！</div>
            </div>
            <button class="bnt-box bnt-select" :class="{selected:isArrow}" @click="isArrow=!isArrow" @blur="isArrow=false">
                <span class="name" v-if="displayMode==0"><i class="iconfont icon-xiaotumoshi"></i>小图</span>
                <span class="name" v-else-if="displayMode==1"><i class="iconfont icon-datumoshi"></i>大图</span>
                <span class="name" v-else-if="displayMode==2"><i class="iconfont icon-svg-path"></i>无图</span>
                <i class="icon-arrow-down iconfont"></i>
                <div class="select-box" v-if="isArrow">
                    <div class="li name" :class="{selected:displayMode==0}" @click="displayMode=0;"><i class="iconfont icon-xiaotumoshi"></i>小图</div>
                    <div class="li name" :class="{selected:displayMode==1}" @click="displayMode=1;"><i class="iconfont icon-datumoshi"></i>大图</div>
                    <div class="li name" :class="{selected:displayMode==2}" @click="displayMode=2;"><i class="iconfont icon-svg-path"></i>无图</div>
                </div>
            </button>
            <div class="bnt-box">
                <span class="name" @click="myOrder()"><i class="iconfont icon-wodedingdan"></i>我的订单</span>
            </div>
            <div class="search" @click="isSearchShow=true">搜索菜品</div>
        </div>
        <div class="body-box">
            <el-scrollbar ref="categoryScroll" class="left-nav" max-height="100%" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                <div class="menu-box">
                    <div class="li"  v-for="(category,index) in foodCategoryData" :class="{selected:navIndex==index}" :key="index" @click="navIndex=index">
                        <div class="name">{{category.SubCategory_Name}} <span class="num" v-if="categoryNumTag[category.SubCategory_ID]>0">{{categoryNumTag[category.SubCategory_ID]}}</span></div>
                    </div>
                </div>
            </el-scrollbar>
            <div class="selectFood-box">
                <min-food-display :foodCategoryData="foodCategoryData" :currentIndex="navIndex" v-if="displayMode==0" @currentChange="(index)=>navIndex=index"
                    :cartNumber="cartNumber"
                    @addItem="addItem" @removeItem="removeItem"></min-food-display>
                <max-food-display :foodCategory="foodCategoryData?.length>0?foodCategoryData[navIndex]:[]" v-else-if="displayMode==1" 
                    :cartNumber="cartNumber"
                    @addItem="addItem" @removeItem="removeItem"></max-food-display>
                <food-display :foodCategoryData="foodCategoryData" :currentIndex="navIndex" v-else @currentChange="(index)=>navIndex=index"
                    :cartNumber="cartNumber"
                    @addItem="addItem" @removeItem="removeItem"></food-display>
            </div>
        </div>
        <div class="cart-bnt" @click="isCartShow=true">
            <i class="iconfont icon-gouwuche1"></i>
            <span class="num" >{{cartCount}}</span>
        </div>
        <!--套餐-->
        <modal-load :isShow="isSetMealShow">
            <ipd-set-meal-model :isShow="isSetMealShow" @close="isSetMealShow=false" :data="setMealFood" @confirm="setMealClick"></ipd-set-meal-model>
        </modal-load>
        <!--口味做法-->
        <modal-load :isShow="isDemandShow">
            <ipd-demand-model ref="demandModel" :isShow="isDemandShow" @close="isDemandShow=false" :data="demandFood" @confirm="demandClick"></ipd-demand-model>
        </modal-load>
        <!--多单位-->
        <modal-load :isShow="isUnitShow">
            <nulti-unit-model :isShow="isUnitShow" @close="isUnitShow=false" :data="nultiUnitFood" @confirm="multiUnitClick"></nulti-unit-model>
        </modal-load>
        <!--购物车-->
        <modal-load :isShow="isCartShow">
            <shopping-cart-model :isShow="isCartShow" @close="isCartShow=false" :data="cartList" @confirm="placeOrder"
                @itemDemand="itemDemand"
                @clearCart="clearCart" @cartAdd="cartAdd" @cartRemove="cartRemove"></shopping-cart-model>
        </modal-load>
        <!--我的订单-->
        <modal-load :isShow="isOrderShow">
            <user-order-model :isShow="isOrderShow" @close="isOrderShow=false" :orderInfo="orderInfo" ></user-order-model>
        </modal-load>
        <!--搜索-->
        <modal-load :isShow="isSearchShow">
            <search-food-model :isShow="isSearchShow" @close="isSearchShow=false" :cartNumber="cartNumber" :foodAll="foodAll" @addItem="addItem" @removeItem="removeItem"></search-food-model>
        </modal-load>
        <!-- 下单成功提示 -->
        <modal-load :isShow="isSuccessShow">
            <success-model :isShow="isSuccessShow" @close="isSuccessShow=false"></success-model>
        </modal-load>
        <!-- 权限验证 -->
        <modal-load :isShow="isAuthShow">
            <authorization-model :isShow="isAuthShow" @close="isAuthShow=false" @confirm="authCllBack"></authorization-model>
        </modal-load>
    </div>
</template>

<script>
import { newGuid } from '../../../common'
import minFoodDisplay from './minFoodDisplay/minFoodDisplay.vue' 
import maxFoodDisplay from './maxFoodDisplay/maxFoodDisplay.vue' 
import foodDisplay from './foodDisplay/foodDisplay.vue' 
import ipdSetMealModel from './ipdSetMealModel/ipdSetMealModel.vue' 
import ipdDemandModel from './ipdDemandModel/ipdDemandModel.vue' 
import nultiUnitModel from './nultiUnitModel/nultiUnitModel.vue'
import shoppingCartModel from './shoppingCartModel/shoppingCartModel.vue' 
import userOrderModel from './userOrderModel/userOrderModel.vue' 
import searchFoodModel from './searchFoodModel/searchFoodModel.vue'
import successModel from './successModel/successModel.vue'
import authorizationModel from './authorizationModel/authorizationModel.vue'
//菜单点餐
export default {
    name:"ipdUserOrderMode",
    components:{
        minFoodDisplay,
        maxFoodDisplay,
        foodDisplay,
        ipdSetMealModel,
        ipdDemandModel,
        nultiUnitModel,
        shoppingCartModel,
        userOrderModel,
        searchFoodModel,
        successModel,
        authorizationModel
    },
    emits:["close"],
    props:{
        data:{
            type:Object,
            default:null,
        }
    },
    data(){
        return {
            userInfo:null,
            /**菜品显示方式 0 小图 1大图 2无图 */
            displayMode:0,
            isArrow:false,
            /**菜品 */
            posFoodData:null,
            /**菜品分类 当前选中类 */
            navIndex:0,
            /**添加的菜品 购物车 */
            cartList:[],
            //套餐
            isSetMealShow:false,
            setMealFood:null,
            //是否 显示多单位弹层
            isUnitShow:false,
            nultiUnitFood:null,
            //是否 显示口味做法
            isDemandShow:false,
            demandFood:null,
            //是否 显示购物车弹层
            isCartShow:false,
            //我的订单 弹层
            isOrderShow:false,
            //检索弹层
            isSearchShow:false,
            //桌台id
            Desk_AutoID:null,
            //账单id 
            Eat_AutoID:null,
            //订单数据
            orderInfo:null,
            /** 配置信息 */
            globalSysSetting:null,
            /**成功弹层 */
            isSuccessShow:false,
            /**权限验证 */
            isAuthShow:false,
            /**权限点击确认回调 */
            authCllBack:()=>{}
        }
    },
    computed:{
        foodDataList(){
            return this.posFoodData?.data||[];
        },
        //栏目
        foodCategoryData(){
            let category=[];
            this.foodDataList.forEach(it=> {
                if(it.FoodSubCategorys && it.FoodSubCategorys.length>0){
                    category=category.concat(it.FoodSubCategorys);
                }
            });
            return category;
        },
        /**全部菜品 */
        foodAll(){
            let foods=[];
            this.foodCategoryData?.forEach(it=>{
                foods=foods.concat(it.PosFoods);
            })
            return foods;
        },
        /**分类 加入购物车的菜品数量 */
        categoryNumTag(){
            let list={};
            this.foodCategoryData?.forEach(it=>{
                list[it.SubCategory_ID]=0;
                it.PosFoods.forEach(item=>{
                    list[it.SubCategory_ID]+=this.cartNumber[item.Food_ID]?.Eat_Number||0;
                })
            })
            return list;
        },
        /**购物车 菜品 数量 */
        cartNumber(){
            let list={};
            this.cartList?.forEach((it,index)=>{
                if(it.is_taste) return;
                let key=it.EAT_XFBMID;
                if(it.MultipleSizeParent){//多单位子级
                    key=it.MultipleSizeParent;
                }
                if(!list[key]){
                    list[key]={Eat_Number:0};
                }
                list[key].Eat_Number+=it.Eat_Number;
                list[key].index=index;
            })
            return list;
        },
        /**已选多少份 */
        cartCount(){
            let count=0;
            this.cartList?.forEach((it)=>{
                if(it.Food_Type!=4 && !it?.is_taste){
                    count += Number(it.Eat_Number); //总份量
                }
                if(it?.FoodSetmeals){
                    it.FoodSetmeals.map(food=>{
                        if(food?.Food_Type!=4){
                            count += Number(food.FoodSetmeal_Number); //总份量
                        }
                    })
                }
            })
            return parseFloat(count.toFixed(4));
        }
    },
    mounted(){
        this.userInfo=this.$auth.getUserInfo();
        this.Desk_AutoID= this.$route.params?.Desk_AutoID;//桌台id
        this.Eat_AutoID = this.$route.params?.Eat_AutoID;//账单id 
        if(!this.Desk_AutoID || !this.Eat_AutoID){//参数不对 返回
            this.$router.back();
            return;
        }
        

        this.$cacheData.DeskPosconfigure().then((d)=>{
            this.globalSysSetting=d;
        }).catch(e=>{
            console.log('获取失败 DeskPosconfigure:'+e)
        })

        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData=d;
            
            this.$nextTick(()=>{
                //开台方案带入的菜品
                if(this.$route.params?.OrderConsumes){
                    let SoloOutfood=[],limitedProject=[];
                    let _orderConsumes=JSON.parse(this.$route.params?.OrderConsumes);
                    _orderConsumes?.forEach(food=>{
                        let pos=this.foodAll.find(it=>it.Food_ID==food.Eat_XFBMID);
                        if(pos){
                            //已被沽清的菜品
                            if(pos.Is_SoldOut){
                                SoloOutfood.push(pos.Food_Name)
                                return 
                            }
                            //是否限量销售
                            if(pos.Is_Limited){
                                if(pos.RemainNum==0){
                                    //是否有限量销售方案
                                    if(pos.Is_LimitedProject){
                                        limitedProject.push(pos.Food_Name);
                                        return 
                                    }
                                }
                            }
                            this.addFood(pos,food.Eat_Number)
                        }
                    })
                    if(SoloOutfood.length>0){
                        this.$message.warning( SoloOutfood.join(",") +'是沽清的菜品,无法添加到订单中');
                    }
                    if(limitedProject.length>0){
                        this.$message.warning(SoloOutfood.join(",") +'菜品已售完,无法添加到订单中');
                    }
                }

            })

        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        this.loadOrder();
    },
    methods:{
        /**加载订单数据 */
        loadOrder(){
            const loading = this.$loading({
                text: '数据加载中',
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0)",
            });
            //获取订单信息
            let param={
                User_ID:this.userInfo.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_DeskID:this.Desk_AutoID, //桌台ID
                EAT_AUTOID:this.Eat_AutoID, //订单Id
                Is_UnionOrder:true,
                Is_LockDesk:true,
            }
            this.$httpAES.post('Bestech.CloudPos.GetOrderInfo',param).then((data)=>{
                loading.close();
                if(data?.ResponseHeader.ResultCode!=0){
                    this.$router.push({path:'diningTable',name:'diningTable'});
                    if(data?.ResponseHeader.ResultDesc==''){
                        this.$message.error('网络错误,请稍后再尝试');
                    }else{
                        this.$message.error(data?.ResponseHeader.ResultDesc);
                    }
                }else{
                    this.orderInfo = data.ResponseBody;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**关闭 */
        close(){
            if(this.cartList?.length>0){
                this.$confirm('您已点菜，但未下单到厨房，请确认继续退出吗?', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info'
                }).then(() => {
                    this.authShow(()=>{
                        this.$router.push({name: 'diningDetail', 
                            params:{
                                Desk_AutoID:this.Desk_AutoID,//桌台id
                                Eat_AutoID:this.Eat_AutoID,//账单id 
                                urlType:0
                            }
                        });
                    },1)
                });
            }else{
                this.authShow(()=>{
                    this.$router.push({name: 'diningDetail', 
                        params:{
                            Desk_AutoID:this.Desk_AutoID,//桌台id
                            Eat_AutoID:this.Eat_AutoID,//账单id 
                            urlType:0
                        }
                    });
                },1)
            }
        },
        /**清空购物车 */
        clearCart(){
            if(this.cartList?.length>0){
                this.$confirm('是否确认清空购物车', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info'})
                .then(() => {
                    this.cartList=[]
                });
            }
        },
        /**验证用户 
         * @param cllBack 验证成功回调
         * @param type 1 菜单点餐 2 或不传 下单
        */
        authShow(cllBack,type){
            if(type==1){
                //正品电子菜谱退出是否不需要验证用户，true: 不验证，false: 需要验证用户
                if(!this.globalSysSetting?.Is_BestMenu_QuitWithoutVerifyUser){
                    this.isAuthShow=true;
                    this.authCllBack=cllBack;
                }else{
                    cllBack();
                }
            }else{
                //正品电子菜谱下单是否不需要验证用户，true: 不验证，false: 需要验证用户
                if(!this.globalSysSetting?.Is_BestMenu_OrderWithoutVerifyUser){
                    this.isAuthShow=true;
                    this.authCllBack=cllBack;
                }else{
                    cllBack(this.userInfo);
                }
            }
        },
        /**我的订单 */
        myOrder(){
            if(this.orderInfo?.Order_ConsumeList?.length>0){
                this.isOrderShow=true;
            }else{
                this.$message.warning('暂无菜品！');
            }
        },
        /**返回 加入购物车 的菜品 数据格式  */
        getCartItem(item){
            let _item={
                Eat_ConsumeID:newGuid(),
                SubCategory_ID:item.SubCategory_ID,
                SubCategory_Code:item.SubCategory_Code,
                Food_ID:item.Food_ID,
                EAT_XFBMID:item.Food_ID,
                EAT_XFCode:item.Food_Code,
                EAT_XFName:item.Food_Name,
                Eat_Number:1,
                Food_Price:item.Food_Price,
                Eat_CheckPrice:item.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:item.Food_Price,
                Eat_VipPrice:item.Food_VipPrice,
                Eat_MasName:'',
                Food_Status:-1,
                Food_AssSize:item.Food_AssSize,
                AssSizeNumber:'',
                Eat_XFSize:item.Food_Size,
                FoodModifys:null,
                FoodSetmeals:null,
                Is_Special:item.Is_Special,//是否特色菜
                Is_Discounted:item.Is_Discounted,
                Is_Seasonal:item.Is_Seasonal,
                RemainNum:item.RemainNum,
                Is_Limited:item.Is_Limited,
                Is_ChangedNum:item.Is_ChangedNum,
                Is_ChargeForService:item.Is_ChargeForService,
                Is_SetMeal:item.Is_SetMeal,
                Is_Promotion:item.Is_Promotion,
                MultipleSizeParent:item.MultipleSizeParent,
                Promotion_Price:0,
                ConsumeOperations:[]
            }
            //促销价
            if(item?.Promotion_Price>0){
                _item.Eat_CheckPrice = item?.Promotion_Price
                _item.Promotion_Price = item?.Promotion_Price
                _item.ConsumeOperations=[{OperateType:5}]
            }
            return _item;
        },
        addFood(pos,number){
            let _item=this.getCartItem(pos);
            if(_item){
                _item.Eat_Number=number;
                this.cartList.push(_item);
            }
        },
        /**增加菜品 */
        addItem(item){
            if(item.Is_SoldOut){
                this.$message({message: '该菜品已售完',type: 'warning'});
                return
            }

            if(item.Is_Limited){//是否限量销售 true:是， false:否
                if(item.RemainNum==0){//限售菜品剩余数量
                    if(item.Is_LimitedProject){
                        this.$message({message: '该菜品已售完',type: 'warning'});
                        return;
                    }
                }else{
                    if((this.cartNumber[item.Food_ID]?.Eat_Number||0)+1>item.RemainNum){
                        this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                        return;
                    }
                }
            }

            /**多单位 */
            if(item.Is_MultipleSize){
                this.isUnitShow=true;
                this.nultiUnitFood=item;
                return;
            }
            /**是否是套餐 */
            if(item.Is_SetMeal){
                if(!item.SetMealInfo){
                    this.$message.warning('该套餐后台没有搭配对应的菜品');
                    return
                }else{
                    this.setMealFood=item;
                    this.isSetMealShow=true;
                }
                return;
            }

            /**判断是否是绑定做法菜品 */
            if(item.Is_BindEatMas){
                if(!item.FoodMasGroups){
                    this.$message.warning('该菜品有默认口味,先请先去后台配置相对应的菜品口味信息');
                    return
                }
                this.isDemandShow=true;
                this.demandFood=item;
                return
            }

            

            if(this.cartNumber[item.Food_ID]?.Eat_Number>0){
                let foodInfo=this.cartList[this.cartNumber[item.Food_ID].index];
                foodInfo.Eat_Number++;
            }else{
                
                let _item=this.getCartItem(item);
                if(_item){
                    this.cartList.push(_item);
                }
            }
        },
        /**减少菜品 */
        removeItem(item){
            let foodInfo=this.cartList[this.cartNumber[item.Food_ID].index];
            if(foodInfo){
                this.cartRemove(foodInfo);
            }
        },
        //购物车 菜品 数量加
        cartAdd(item,number){
            if(!(number>0)){
                number=1;//默认增加1
            }
            let food;
            if(item.MultipleSizeParent){//多单位
                food=this.foodAll?.find(it=>it.Food_ID==item.MultipleSizeParent);
            }else{
                food=this.foodAll?.find(it=>it.Food_ID==item.Food_ID);
            }

            if(food?.Is_SoldOut){
                this.$message({message: '该菜品已售完',type: 'warning'});
                return
            }
            if(food?.Is_Limited){//是否限量销售 true:是， false:否
                if(food.RemainNum==0){//限售菜品剩余数量
                    if(food.Is_LimitedProject){
                        this.$message({message: '该菜品已售完',type: 'warning'});
                        return;
                    }
                }else{
                    if(Number(((this.cartNumber[food.Food_ID]?.Eat_Number||0)+number).toFixed(4))>food.RemainNum){
                        this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                        return
                    }
                }
            }

            item.Eat_Number=Number((item.Eat_Number+number).toFixed(4));

            if(item.is_taste){//加价做法
                this.cartList.filter(it=>!it.is_taste).find(it=>{
                    if(it.Eat_ConsumeID==item.Eat_ConsumeID){//查找到口味对应的菜品
                        let num= it.FoodModifys.find(num=>num.Mas_AutoID==item.Mas_AutoID && num.IsAllRequired==item.IsAllRequired);
                        if(num){//对应口味
                            num.Mas_Num=item.Eat_Number;
                        }
                        return true;
                    }else if(it.FoodSetmeals?.length>0){//有套餐的菜品
                        return it.FoodSetmeals.some(set=>{
                            if(set.Eat_ConsumeID==item.Eat_ConsumeID){//查找到口味对应的菜品
                                let num= set.FoodModifys?.find(num=>num.Mas_AutoID==item.Mas_AutoID && num.IsAllRequired==item.IsAllRequired);
                                if(num){//对应口味
                                    num.Mas_Num=item.Eat_Number;
                                }
                                return true;
                            }
                            return false
                        })
                    }
                    return false;
                });
            }
            else{
                if(item.FoodSetmeals){//套餐
                    item.FoodSetmeals.forEach(set=>{
                        set.FoodSetmeal_Number=set.Food_Number * item.Eat_Number;
                        if(set.FoodModifys){//口味
                            set.FoodModifys.forEach(num=>{
                                if(num.Is_AddMoney && num.Price_Kind==0){//是否加价做法
                                    num.Mas_Num=set.FoodSetmeal_Number;
                                    let foodM= this.cartList.find(it=> it.is_taste && it.Eat_ConsumeID==set.Eat_ConsumeID && it.Mas_AutoID==num.Mas_AutoID);//口味菜品
                                    if(foodM){
                                        foodM.Eat_Number=set.FoodSetmeal_Number;
                                    }
                                }
                            })
                        }
                    })
                }
                if(item.FoodModifys){//口味
                    item.FoodModifys.forEach(num=>{
                        if(num.Is_AddMoney && num.Price_Kind==0){//是否加价做法
                            let foodM= this.cartList.find(it=> it.is_taste && it.Eat_ConsumeID==item.Eat_ConsumeID && it.Mas_AutoID==num.Mas_AutoID);//口味菜品
                            if(foodM){
                                foodM.Eat_Number=Number((foodM.Eat_Number+number).toFixed(4));
                                num.Mas_Num=foodM.Eat_Number;
                            }
                        }
                    })
                }
            }
        },
        //购物车 菜品 数量减
        cartRemove(item,number){
            if(!(number>0)){
                number=1;//默认减少1
            }
            console.log(number)
            if(item.Eat_Number>number){
                item.Eat_Number=Number((item.Eat_Number-number).toFixed(4));
                if(item.is_taste){//加价做法
                    this.cartList.filter(it=>!it.is_taste).find(it=>{
                        if(it.Eat_ConsumeID==item.Eat_ConsumeID){//查找到口味对应的菜品
                            let num= it.FoodModifys.find(num=>num.Mas_AutoID==item.Mas_AutoID && num.IsAllRequired==item.IsAllRequired);
                            if(num){//对应口味
                                num.Mas_Num=item.Eat_Number;
                            }
                            return true;
                        }else if(it.FoodSetmeals?.length>0){//有套餐的菜品
                            return it.FoodSetmeals.some(set=>{
                                if(set.Eat_ConsumeID==item.Eat_ConsumeID){//查找到口味对应的菜品
                                    let num= set.FoodModifys?.find(num=>num.Mas_AutoID==item.Mas_AutoID && num.IsAllRequired==item.IsAllRequired);
                                    if(num){//对应口味
                                        num.Mas_Num=item.Eat_Number;
                                    }
                                    return true;
                                }
                                return false
                            })
                        }
                        return false;
                    });
                }else{
                    if(item.FoodSetmeals){//套餐
                        item.FoodSetmeals.forEach(set=>{
                            set.FoodSetmeal_Number=set.Food_Number * item.Eat_Number;
                            if(set.FoodModifys){//口味
                                set.FoodModifys.forEach(num=>{
                                    if(num.Is_AddMoney && num.Price_Kind==0){//是否加价做法
                                        num.Mas_Num=set.FoodSetmeal_Number;
                                        let foodM= this.cartList.find(it=> it.is_taste && it.Eat_ConsumeID==set.Eat_ConsumeID && it.Mas_AutoID==num.Mas_AutoID && it.IsAllRequired==num.IsAllRequired);//口味菜品
                                        if(foodM && foodM.Eat_Number>1){
                                            foodM.Eat_Number=set.FoodSetmeal_Number;
                                        }
                                    }
                                })
                            }
                        })
                    }
                    if(item.FoodModifys){//口味
                        item.FoodModifys.forEach(num=>{
                            if(num.Is_AddMoney && num.Price_Kind==0){//是否加价做法
                                let foodM= this.cartList.find(it=> it.is_taste && it.Eat_ConsumeID==item.Eat_ConsumeID && it.Mas_AutoID==num.Mas_AutoID && it.IsAllRequired==num.IsAllRequired);//口味菜品
                                if(foodM && foodM.Eat_Number>1){
                                    foodM.Eat_Number=Number((foodM.Eat_Number-number).toFixed(4));
                                    num.Mas_Num=foodM.Eat_Number;
                                }
                            }
                        })
                    }
                }
            }else{
                let index= this.cartList.findIndex(it=>it.is_taste==item.is_taste && it.Eat_ConsumeID==item.Eat_ConsumeID && it.Mas_AutoID==item.Mas_AutoID && it.IsAllRequired==item.IsAllRequired);
                if(index>=0){
                    this.cartList.splice(index,1);
                }
                if(!item.is_taste){//移除菜品的同时 移除 口味中的加价做法的菜品
                    item.FoodModifys?.filter(it=>it.Is_AddMoney)?.forEach(()=>{
                        if(this.cartList?.length>0){
                            for(let i=index;i<this.cartList?.length;i++){//当前菜品位置 往后找
                                let it=this.cartList[i];
                                if(it.is_taste && it.Eat_ConsumeID==item.Eat_ConsumeID){
                                    this.cartList.splice(i,1);
                                    i--;
                                    break;
                                }
                            }
                        }
                    })
                    
                    item.FoodSetmeals?.forEach(set=>{//套餐 中有加价做法
                        set.FoodModifys?.filter(it=>it.Is_AddMoney)?.forEach(()=>{
                            if(this.cartList?.length>0){
                                for(let i=index;i<this.cartList?.length;i++){//当前菜品位置 往后找
                                    let it=this.cartList[i];
                                    if(it.is_taste && it.Eat_ConsumeID==set.Eat_ConsumeID){
                                        this.cartList.splice(i,1);
                                        i--;
                                        break;
                                    }
                                }
                            }
                        })
                    })
                } else{//移除口味 菜品的同时 移除 对应 菜品中的口味信息
                    this.cartList.filter(it=>!it.is_taste).find(it=>{
                        if(it.Eat_ConsumeID==item.Eat_ConsumeID){//查找到口味对应的菜品
                            let indexM= it.FoodModifys.findIndex(num=>num.Mas_AutoID==item.Mas_AutoID && num.IsAllRequired==item.IsAllRequired);
                            if(indexM>=0){//移除对应口味
                                if(!it.FoodModifys[indexM].IsAllRequired){//非全单要求
                                    let i=it._modifys.findIndex(num=>num.Mas_AutoID==item.Mas_AutoID);
                                    if(i>=0){
                                        it._modifys.splice(i,1);
                                    }
                                    
                                }
                                it.FoodModifys.splice(indexM,1);
                            }
                            it.Eat_MasName=it.FoodModifys?.map(num=>num.Mas_Name)?.join('/');//口味名称 显示用
                            return true;
                        }else if(it.FoodSetmeals?.length>0){//有套餐的菜品
                            return it.FoodSetmeals.some(set=>{
                                if(set.Eat_ConsumeID==item.Eat_ConsumeID){//查找到口味对应的菜品
                                    let indexM= set.FoodModifys?.findIndex(num=>num.Mas_AutoID==item.Mas_AutoID && num.IsAllRequired==item.IsAllRequired);
                                    if(indexM>=0){//移除对应口味
                                        if(!set.FoodModifys[indexM].IsAllRequired){//非全单要求
                                            let i=set.FoodModifys.findIndex(num=>num.Mas_AutoID==item.Mas_AutoID);
                                            if(i>=0){
                                                set._modifys.splice(i,1);
                                            }
                                        }
                                        set.FoodModifys.splice(indexM,1);
                                    }
                                    set.Eat_MasName=set.FoodModifys?.map(num=>num.Mas_Name)?.join('/');//口味名称 显示用
                                    return true;
                                }
                                return false
                            })
                        }
                        return false;
                    });
                }
                
            }
        },
        /**套餐加入购物车 */
        setMealClick(setMealNum,posFoodSetMeal){
            let food= this.foodAll?.find(it=>it.Food_ID==posFoodSetMeal.Food_ID);
            if(food?.Is_SoldOut){
                this.$message({message: '该菜品已售完',type: 'warning'});
                return
            }
            if(food?.Is_Limited){//是否限量销售 true:是， false:否
                if(food.RemainNum==0){//限售菜品剩余数量
                    if(food.Is_LimitedProject){
                        this.$message({message: '该菜品已售完',type: 'warning'});
                        return;
                    }
                }else{
                    if((this.cartNumber[food.Food_ID]?.Eat_Number||0)+setMealNum>food.RemainNum){
                        this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                        return
                    }
                }
            }

            if(!posFoodSetMeal.SetMealInfo || !posFoodSetMeal.SetMealInfo.SetMealKinds){
                this.$message.warning("套餐没有明细无法加入购物车！")
                return;
            }

            let foodList=[];//记录套餐明细 口味做法
            /**套餐数据 */
            let _FoodSetmeals=[];
            posFoodSetMeal.SetMealInfo.SetMealKinds.map(item=>{
                item.SetMealDetails.map(food=>{
                    if(food.Food_Number>0){
                        let eatConsume={
                            FoodSetmeal_ID:food.Food_ID,
                            FoodSetmeal_Code:food.Food_Code,
                            FoodSetmeal_Name:food.Food_Name,
                            Food_Number:food.Food_Number,
                            FoodSetmeal_Number:food.Food_Number * setMealNum,
                            FoodSetmeal_Price:food.Food_TcPrice,
                            FoodSetmeal_Size:food.Food_Size,
                            FoodSaleMoney:0,
                            FoodModifys:null,
                            Eat_MasName:'',
                            ModiPrice:0,
                            Food_Status:-1,
                            Eat_ConsumeID:newGuid()
                        }
                        _FoodSetmeals.push(eatConsume);

                        if(food._modifys){
                            foodList.push({
                                item:eatConsume,
                                FoodModifys:food._modifys
                            });
                        }
                    }
                })
            })
            if(_FoodSetmeals.length==0){
                this.$message.warning("套餐没有明细无法加入购物车！")
                return;
            }
            let _item={
                Eat_ConsumeID:newGuid(),
                SubCategory_ID:posFoodSetMeal.SubCategory_ID,
                SubCategory_Code:posFoodSetMeal.SubCategory_Code,
                Food_ID:posFoodSetMeal.Food_ID,
                EAT_XFBMID:posFoodSetMeal.Food_ID,
                EAT_XFCode:posFoodSetMeal.Food_Code,
                EAT_XFName:posFoodSetMeal.Food_Name,
                Eat_Number:setMealNum,
                Food_Price:posFoodSetMeal.Food_Price,
                Eat_CheckPrice:posFoodSetMeal.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:posFoodSetMeal.Food_Price,
                Eat_VipPrice:posFoodSetMeal.Food_VipPrice,
                Eat_MasName:'',
                Food_Status:-1,
                Food_Speed:'',
                Food_AssSize:posFoodSetMeal.Food_AssSize,
                AssSizeNumber:'',
                Eat_XFSize:posFoodSetMeal.Food_Size,
                FoodModifys:null,
                FoodSetmeals:_FoodSetmeals,
                Is_Special:posFoodSetMeal.Is_Special,//是否特色菜
                Is_Discounted:posFoodSetMeal.Is_Discounted,
                Is_Seasonal:posFoodSetMeal.Is_Seasonal,
                RemainNum:posFoodSetMeal.RemainNum,
                Is_Limited:posFoodSetMeal.Is_Limited,
                Is_ChangedNum:posFoodSetMeal.Is_ChangedNum,
                Is_ChargeForService:posFoodSetMeal.Is_ChargeForService,
                Is_SetMeal:posFoodSetMeal.Is_SetMeal,
                FoodProperty_Dimension:'套',
                Promotion_Price:0,
                ConsumeOperations:[],//菜品操作详情，
                Is_BindEatMas:posFoodSetMeal.Is_BindEatMas,//是否绑定做法 
                FoodEatMasList:posFoodSetMeal.FoodEatMasList,//做法详情
                FoodMasGroups:posFoodSetMeal.FoodMasGroups,//做法分类+做法 二级结构列表
            };
            //促销价
            if(posFoodSetMeal?.Promotion_Price){
                _item.Eat_CheckPrice = posFoodSetMeal?.Promotion_Price
                _item.Promotion_Price = posFoodSetMeal?.Promotion_Price
                _item.ConsumeOperations=[{OperateType:5}]
            }
            /**判断是否是绑定做法菜品 */
            if(_item.Is_BindEatMas){
                this.isDemandShow=true;
                this.demandFood=_item;

                this.isSetMealShow=false;
                this.setMealFood=null;
                return
            }
            this.cartList.push(_item);
            this.isSetMealShow=false;
            let editIndex=this.cartList.length-1;
            /**给套餐明细 添加口味做法 */
            foodList?.forEach(it=>{
                it.item._modifys=it.FoodModifys;//记录单个口味做法
                this.setFoodModifys(it.item,it.FoodModifys,editIndex,true);
            })
        },
        /**口味返回 
         * @param foodModifys 口味做法
        */
        demandClick(foodModifys){
            if(!this.demandFood || this.demandFood?.isAll){//整单口味要求
                let editList=this.cartList.filter(it=>!it.is_taste);//多个
                editList.forEach(item=>{
                    
                    let modifys=Object.assign([],item._modifys);
                    item._allModifys=foodModifys;//记录全单口味
                    if(foodModifys){//全单口味
                        foodModifys.forEach(it=>{
                            it.IsAllRequired=1;
                            modifys.push(it);
                        })
                    }
                    let editIndex=this.cartList.findIndex(it=>it.Eat_ConsumeID==item.Eat_ConsumeID);
                    this.setFoodModifys(item,modifys,editIndex);
                    
                    if(item.FoodSetmeals?.length>0){//套餐
                        item.FoodSetmeals.forEach(it=>{
                            let modifys=Object.assign([],it._modifys);
                            it._allModifys=foodModifys;//记录全单口味
                            if(foodModifys){//全单口味
                                foodModifys.forEach(it=>{
                                    modifys.push(it);
                                })
                            }
                            this.setFoodModifys(it,modifys,editIndex,true);
                        })
                    }
                })
                //清除数据
                this.isDemandShow=false;
                this.demandFood=null;
            }else{
                let item=this.demandFood;
                let editIndex=-1;//是否是修改口味
                if(!this.demandFood.Eat_ConsumeID){
                    item=this.getCartItem(this.demandFood);
                    item.Is_BindEatMas=this.demandFood.Is_BindEatMas;//是否绑定做法 
                    item.FoodEatMasList=this.demandFood.FoodEatMasList;//做法详情
                    item.FoodMasGroups=this.demandFood.FoodMasGroups;//做法分类+做法 二级结构列表
                }else{
                    editIndex=this.cartList.findIndex(it=>it.Eat_ConsumeID==item.Eat_ConsumeID);
                }
                
                if(item){
                    foodModifys.forEach(it=>{
                        it.IsAllRequired=0;//单口味要求
                    })
                    item._modifys=foodModifys;//记录单个口味做法
                    let modifys=Object.assign([],foodModifys);
                    if(item._allModifys){//全单口味
                        item._allModifys.forEach(it=>{
                            it.IsAllRequired=1;
                            modifys.push(it);
                        })
                    }
                    this.setFoodModifys(item,modifys,editIndex);
                    //清除数据
                    this.isDemandShow=false;
                    this.demandFood=null;
                }
            }
        },
        /**口味
         * @param item 要修改的数据
         * @param foodModifys 口味数据
         * @param editIndex 如果是修改 这个是第几个数据
         * @param isSetMeal 是否是套餐
         */
        setFoodModifys(item,foodModifys,editIndex,isSetMeal){
            let masName=[];
            let masFood=[];//口味菜品
            item.FoodModifys=foodModifys.map((num)=>{
                masName.push(num.Mas_Name);//口味名称
                let json={
                    Mas_AutoID:num.Mas_AutoID,
                    Mas_Code:num.Mas_Code,
                    Mas_Name:num.Mas_Name,
                    Mas_Num:1,
                    Mas_Price:num.Mas_Price,
                    Price_Kind:num.Price_Kind,
                    Is_AddMoney:num.Is_AddMoney,
                    IsAllRequired:num.IsAllRequired
                }
                if(num.Is_AddMoney){//是否加价做法
                    let _item=Object.assign({},item);
                    if(isSetMeal){
                        _item.Is_SetMeal=true;
                        _item.EAT_XFBMID=item.FoodSetmeal_ID;
                        _item.EAT_XFCode=item.FoodSetmeal_Code;
                        _item.Eat_Number=item.FoodSetmeal_Number;
                    }
                    //按量加价
                    if(num.Price_Kind==0){
                        json.Mas_Num=_item.Eat_Number;
                    }
                    
                    /**把口味添加到 购物车 */
                    let param={
                        Eat_ConsumeID:_item.Eat_ConsumeID,
                        Mas_AutoID:json.Mas_AutoID,
                        EAT_XFBMID:_item.EAT_XFBMID,
                        EAT_XFCode:_item.EAT_XFCode,
                        EAT_XFName:json.Mas_Name+'@'+json.Mas_Price,
                        Eat_Number:json.Mas_Num,
                        Eat_CheckPrice:json.Mas_Price,
                        Eat_SalePrice:json.Mas_Price,
                        ModiPrice:0,
                        Food_Status:-1,
                        Is_Discounted:_item.Is_Discounted,
                        Is_Seasonal:_item.Is_Seasonal,
                        RemainNum:_item.RemainNum,
                        Is_Limited:_item.Is_Limited,
                        Is_ChangedNum:_item.Is_ChangedNum,
                        Is_ChargeForService:_item.Is_ChargeForService,
                        Is_SetMeal:_item.Is_SetMeal,
                        is_taste:true,//是否是加价菜品
                        IsAllRequired:json.IsAllRequired,
                        Price_Kind:json.Price_Kind,
                    }
                    
                    masFood.push(param);
                }
                return json;
            })
            item.Eat_MasName=masName.join('/');//口味名称 显示用
            
            if(editIndex>=0){//修改口味
                let lastIndex=editIndex;
                for(let i=editIndex+1;i<this.cartList?.length;i++){//当前菜品位置 往后找
                    let it=this.cartList[i];
                    if(it.is_taste){
                        if(it.Eat_ConsumeID==item.Eat_ConsumeID){
                            let masIndex=masFood.findIndex(num=>num.Eat_ConsumeID==it.Eat_ConsumeID && num.Mas_AutoID==it.Mas_AutoID && num.IsAllRequired==it.IsAllRequired);
                            if(masIndex>=0){//相同的剔除
                                masFood.splice(masIndex,1);
                            }else{//查找多余的口味 移除
                                this.cartList.splice(i,1);
                                i--;
                            }
                        }
                    }else{
                        break;
                    }
                    lastIndex=i;
                }
                masFood.forEach((it)=>{
                    this.cartList.splice(lastIndex+1,0,it);
                    lastIndex++;
                })
            }else{
                this.cartList.push(item);
                masFood.forEach((it)=>{
                    this.cartList.push(it);
                })
            }
        },
        /**多单位确定 返回 */
        multiUnitClick(item,number){
            /**判断是否是绑定做法菜品 */
            if(item.Is_BindEatMas){
                this.isDemandShow=true;
                this.demandFood=item;
                this.isUnitShow=false;
                return
            }
            let _item=this.getCartItem(item);
            if(_item){
                _item.Eat_Number=number;
                if(item?.Is_Limited){//是否限量销售 true:是， false:否
                    if(item.RemainNum==0){//限售菜品剩余数量
                        if(item.Is_LimitedProject){
                            this.$message({message: '该菜品已售完',type: 'warning'});
                            return;
                        }
                    }else{
                        let foodId=item.MultipleSizeParent||item.Food_ID;
                        if((this.cartNumber[foodId]?.Eat_Number||0)+number>item.RemainNum){
                            this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                            return
                        }
                    }
                }
                this.cartList.push(_item);
                this.isUnitShow=false;
            }
        },
        /**修改菜品要求 
         * @param item 需要修改的口味菜品
         * @param isAll 是否修整单口味要求
        */
        itemDemand(item,isAll){
            this.isDemandShow=true;
            if(isAll){
                this.demandFood={isAll:true};
            }else{
                this.demandFood=item;
            }
        },
        /**下订单 */
        placeOrder(){
            let _orderConsumes=[];
            let isRunOrderNum=0
            this.orderInfo?.Order_ConsumeList?.forEach((item)=>{
                //判断套餐
                if((item?.Food_Type==2 || item?.Food_Type==3) && (item.Food_Status==-1 || item.Food_Status==0)){
                    isRunOrderNum +=  Number(item.Eat_Number)||0;
                }
            })
            this.cartList.forEach((item)=>{
                //判断套餐
                if(item.FoodSetmeals!=null){
                    isRunOrderNum +=  Number(item.Eat_Number)||0;
                }
                if(item.Food_Status==-1 && !item.is_taste){
                    let SizeNumber = Number(item?.AssSizeNumber)
                    let Food_Types =1;
                    let _seep= item.Food_Speed;
                    if(item.isWaitCall){//等叫
                        _seep="等叫";
                    }else if(item.Food_Speed=='' || item.Food_Speed==null){
                        _seep='即'
                    }
                    if(item.FoodSetmeals!=null){
                        Food_Types=2
                        SizeNumber=0
                    }
                    if(item.DiyTcType){
                        Food_Types=item.DiyTcType
                    }
                    let orders ={
                        Food_ID:item.EAT_XFBMID,
                        Food_Code:item.EAT_XFCode,
                        Food_Type:Food_Types,
                        Food_Speed:_seep,
                        Food_Size:item.Eat_XFSize,
                        Food_Number:item.Eat_Number,
                        Food_Price:item.Eat_SalePrice,
                        FoodModifys:item.FoodModifys,
                        FoodSetmeals:item.FoodSetmeals,
                        Food_Name:item?.EAT_XFName,
                        Food_AssNumber:SizeNumber,
                        Food_CheckPrice:item.Eat_CheckPrice,
                        ConsumeOperations:item.ConsumeOperations
                    };
                    _orderConsumes.push(orders);
                } 
            })
            if(_orderConsumes.length==0){
                this.$alert('请先选择需要落单的菜品!', '提示', {confirmButtonText: '确定',});
                return
            }

            isRunOrderNum=Number(isRunOrderNum.toFixed(4))
            //开启了席数功能
            if(this.globalSysSetting?.RunFeastDeskNum==true && Number(isRunOrderNum)>0 && this.orderInfo.Feast_DeskNum>=1){
                if(Number(isRunOrderNum)!= Number(this.orderInfo.Feast_DeskNum)){
                    this.$confirm('本桌台为'+this.orderInfo.Feast_DeskNum+'席,但套餐份数为'+Number(isRunOrderNum)+'/份,请确认数量,是否继续下单?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'})
                    .then(() => {
                        this.placeOrderMethod(_orderConsumes)
                    }).catch(() => {});
                }else{
                    this.placeOrderMethod(_orderConsumes)
                }   
            }else{
                this.placeOrderMethod(_orderConsumes)
            }
        },
        /**调用下单接口 */
        placeOrderMethod(_orderConsumes){
            this.authShow((userInfo)=>{//权限验证
                //参数
                let param={
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Eat_DeskID:this.Desk_AutoID, //桌台ID
                    Order_ID:this.Eat_AutoID, //订单Id
                    Eat_AllRemark:'', //订单全单备注
                    Order_Remark:this.orderInfo.Order_Remark,
                    Order_BatchNo:newGuid(), //订单批次号
                    Pos_StationName:this.userInfo?.Site_Name, //站点名称
                    Eat_WaiterName:this.orderInfo?.Eat_WaiterName,
                    NotSendToKitPrint:false, //下单不送达厨房
                    NotLimitFoodInventory:false,//下单不关联菜品库存数
                    Timestamps:this.orderInfo?.Timestamps.toString(), //订单时间戳
                    OrderConsumes:_orderConsumes, //菜品
                }
            
                const loading = this.$loading({
                    text: "数据传输中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.SendOrderDish",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error({ message: data.ResponseHeader.ResultDesc,showClose:true,duration:0});
                    }else{
                        this.isSuccessShow=true;
                        if(this.$refs.demandModel){//清除全单选择的做法记录
                            this.$refs.demandModel.tempKindsList=null;
                        }
                        this.cartList=[];
                        this.isCartShow=false;
                        let info=data.ResponseBody;
                        console.log(info)
                        if(info){
                            /**重新填充订单数据 */
                            if(this.orderInfo.Order_ConsumeList.length>0){
                                info.Order_ConsumeList.map(it=>{
                                    this.orderInfo.Order_ConsumeList.push(it);
                                })
                            }else{
                                this.orderInfo.Order_ConsumeList=info.Order_ConsumeList;
                            }

                            Object.assign(this.orderInfo,{
                                IsPromotionPrice:info.IsPromotionPrice,
                                Timestamps:info.Timestamps.toString(),
                                Eat_SaleMoney:info.Eat_SaleMoney,
                                Eat_CostMoney:info.Eat_CostMoney,
                                Eat_CheckMoney:info.Eat_CheckMoney,
                                Eat_TotalCounts:info.Eat_TotalCounts,
                                Payable_Money:info.UnPaid_Money,
                            })
                            console.log(this.orderInfo)
                        
                            //下单后打印
                            if(info?.PrintsInfo){
                                this.$webBrowser.posPrint(info.PrintsInfo);
                            }
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            })
        },
    }
}
</script>

<style lang="scss">
    @import './ipdUserOrderMode.scss'
</style>