<template>
    <modal class="aboutUsModel" :isShow="isShow">
        <div class="header">关于我们</div>
        <div class="modal-body">
            <div class="content-box">
                <div class="flex-li">
                    <div class="logo"><img  src="/images/BSLogo.png" /></div>
                    <div class="logo-txt">正品贵德</div>
                </div>
                <div class="flex-li">
                    <div class="lable-text">许可给:</div>
                    <div class="right-text">{{userInfo?.Department_Name}}</div>
                </div>
                <div class="flex-li">
                    <div class="lable-text">设备名称:</div>
                    <div class="right-text">{{userInfo?.Site_Name}}</div>
                </div>
                <div class="flex-li">
                    <div class="lable-text">设备序列号:</div>
                    <div class="right-text">{{userInfo?.Site_Serial}}</div>
                </div>
                
                <div class="flex-li">
                    <div class="lable-text">版权所有©:</div>
                    <div class="right-text">上海正品贵德软件有限公司</div>
                </div>
                <div class="flex-li">
                    <div class="lable-text">咨询电话:</div>
                    <div class="right-text">400-888-2891</div>
                </div>
                <div class="flex-li">
                    <div class="lable-text">程序版本:</div>
                    <div class="right-text">({{$global.getSysVersion?.Version_No}}) <span class="updater" @click="updater()">升级更新></span></div>
                </div>
                <!-- <div class="flex-li">
                    <div class="lable-text"></div>
                    <div class="right-text">
                        <span class="updateLog" @click="isUpdateLog=true">更新日志</span>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="footer-box">
            <!-- <button class="btn btn-cancel" @click="hide()">正品贵德网页</button> -->
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <modal-load :isShow="isUpdater" :isUnload="true">
			<updater :isShow="isUpdater" :data="updateData" @closeModel="isUpdater=false;"></updater>
		</modal-load>
        <modal-load :isShow="isUpdateLog">
			<update-log-model :isShow="isUpdateLog" @closeModel="isUpdateLog=false;"></update-log-model>
		</modal-load>
    </modal>
</template>

<script>
import updater from '../updater/updater.vue'
// import updateLogModel from './updateLogModel/updateLogModel.vue'

export default {
    components: {
       updater,
       //updateLogModel
   },
    name:'aboutUsModel',
    props:{
        isShow:Boolean
    },
    data(){
        return{
            userInfo:'', //登录信息
            /**是否显示升级提示 */
            isUpdater:false,
            /**升级信息 */
            updateData:"",
            isUpdateLog:false,

        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.Loding()
        })
    },
    watch:{
        isShow(){
            if(this.isShow==true){
                this.Loding()
            }
        },
    },
    methods:{
        Loding(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
        },
        hide(){
            //http://www.bestechsoft.com/
            this.$emit("closeModel")
        },
        confirm(){
            this.$emit("closeModel")
        },
        updater() {
            const loading = this.$loading({
                text: "获取版本更新信息...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo();
            let param={
                User_ID:userInfo.User_ID, //登陆用户ID
                Sys_VersionID:this.$global.getSysVersion?.Version_ID
            }
            this.$httpAES.post("Bestech.CloudPos.CheckUpgrade",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody?.Audit_Type==4){
                        this.isUpdater=true;
                        this.updateData=d.ResponseBody.Audit_Data;
                    }else{
                        this.$alert("当前版本已是最新版本", "提示", {confirmButtonText: "我知道了"});
                    }
                }else{
                    this.$message.error("获取版本更新信息失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('获取版本更新信息失败：'+e.message);
                console.log('获取版本更新信息失败：',e);
            }) 
        },
    }
}
</script>

<style lang="scss">
@import './aboutUsModel.scss';
</style>