<template>
    <div class="selectTree">
        <div v-if="isButton" @click="isShow=true">
            <slot></slot>
        </div>
        <template v-else>
            <input class="tag-input" type="text" :placeholder="placeholder" :value="inputTxt" readonly @click="isShow=true" />
            <i class="el-tag__close el-icon-close" v-if="clearable && inputTxt" @click="close()"></i>
        </template>
        <modal-load :isShow="isShow">
            <modal class="selectTreeModel" :isShow="isShow">
                <div class="header">{{title}}</div>
                
                <div class="modal-body">
                    <div class="search-box" v-if="isSearch">
                        <key-board-input class="search"
                            type="text" v-model="searchText" :placeholder="searchPlaceholder"
                            isBoard isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                    </div>
                    <div class="check">
                        <el-tree v-if="isMultiple"
                            :data="data"
                            show-checkbox
                            :default-expand-all="defaultExpandAll"
                            :default-checked-keys="inputIds"
                            node-key="id"
                            ref="tree"
                            highlight-current
                            :filter-node-method="filterNodeMethod"
                            :props="defaultProps">
                        </el-tree>
                        <el-tree v-else
                            :data="data"
                            :default-expand-all="defaultExpandAll"
                            node-key="id"
                            ref="tree"
                            highlight-current
                            @current-change="currentChange"
                            :filter-node-method="filterNodeMethod"
                            :props="defaultProps">
                        </el-tree>
                    </div>
                </div>
                <div class="footer-box">
                    <button class="btn btn-cancel" @click="isShow=false">取消</button>
                    <button class="btn btn-confirm" @click="deparConfirm()">确认</button>
                </div>
            </modal>
        </modal-load>
    </div>
    
</template>

<script>

/**选择开卡人  */
export default {
    name:"selectTree",
    emits:['update:modelValue','change',"update:inputTxt"],
    props:{ 
        modelValue: {
            type: [Array,String]
        },
        title:{
            default:""
        },
        placeholder:{
            default:"请选择"
        },
        // 显示数据
        data:{
            default:[]
        },
        //显示文本
        label:{
            type:String,
            default:"label"
        },
        //保存用id
        value:{
            type:String,
            default:"value"
        },
        /**是否是按钮点击显示方式 */
        isButton:{
            type:Boolean,
            default:false
        },
        //是否默认展开所有节点
        defaultExpandAll:{
            type:Boolean,
            default:true
        },
        /**是否多选 默认多选 */
        isMultiple:{
            type:Boolean,
            default:true
        },
        /**当有id没有对应数据时 显示的id对应的数据名称 */
        defaultInputTxt:{
            type:String,
            default:""
        },
        /**是否检索 */
        isSearch:{
            type:Boolean,
            default:false
        },
        /**检索 */
        searchPlaceholder:{
            type:String,
            default:"模糊检索"
        },
        /**检索方法 */
        filterNodeMethod:{
            type:Function,
            default:null
        },
        /**是否显示清除按钮 默认存在*/
        clearable:{
            type:Boolean,
            default:true
        }
    },
    data(){
        return{
            isShow:false,
            /**搜索 */
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            val:this.getDefaultVal,
            searchText:''
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.val=this.getDefaultVal;
            console.log(this.val)
        })
    },
    watch:{
        isShow(newVal){
            if(newVal && this.isMultiple){
                this.$nextTick(()=>{
                    this.$refs.tree?.setCheckedKeys(this.modelValue||null);
                })
                
            }
        },
        data(){
            this.$nextTick(()=>{
                this.val=this.getDefaultVal;
            })
        },
        modelValue(){
            this.val=this.getDefaultVal;
            this.$emit('update:inputTxt',this.inputTxt);
        },
        searchText(newVal){
            this.$refs.tree?.filter(newVal);
        }
    },
    computed:{
        inputTxt(){
            if(this.isMultiple){
                let text=this.val?.map(it=>it.name).toString();
                if(!text && this.modelValue?.length>0){//有id 没有原数据时 显示传入内容
                    return this.defaultInputTxt;
                }
                return text;
            }else{
                if(!this.val?.name && this.modelValue){//有id 没有原数据时 显示传入内容
                    return this.defaultInputTxt;
                }
                return this.val?.name;
            }
        },
        inputIds(){
            if(this.isMultiple){
                return this.val?.map(it=>it.id);
            }else{
                return this.val?.id;
            }
        },
        getDefaultVal(){
            let val="";
            if(this.isMultiple){
               val=[];
                if(this.modelValue && this.modelValue.length>0){
                    let childrenNode=(item)=>{
                        if(item.children){
                            item.children.forEach(it=>{
                                childrenNode(it);
                            })
                        }else if(this.modelValue.indexOf(item.id)>=0){
                            val.push(item)
                        }
                    }
                    this.data?.forEach((item)=>{
                        childrenNode(item)
                    })
                }
            }else{
                if(this.modelValue){
                    let getChildrenNode=(node)=>{
                        if(node.children){
                            let _node;
                            node.children.find(it=>{
                                _node=getChildrenNode(it);
                                return _node;
                            });
                            if(_node){
                                return _node;
                            }
                        }else if(this.modelValue==node.id){
                            return node;
                        }
                    }
                    this.data?.find((item)=>{
                       let node= getChildrenNode(item);
                       if(node){
                            val=node;
                        }
                       return node;
                    });
                    
                }
            }
            return val;
        }
    },
    methods:{
        //多选 点击确认
        deparConfirm(){
            let deparList=this.$refs.tree.getCheckedNodes();
            if(deparList.length<=0){
                this.$message.warning('请至少选择一项！');
                return;
            }
            this.val=deparList;
            this.$emit('update:modelValue',this.inputIds);
            this.$emit('update:inputTxt',this.inputTxt);
            this.isShow=false
            if(this.isButton){
                this.$emit('change',this.inputIds,this.val)
            }else{
                this.$emit('change',this.val);
            }
        },
        //单选 选中
        currentChange(node){
            if(!this.isMultiple && !node.children){
                this.val=node;
                this.$emit('update:modelValue',this.inputIds);
                this.$emit('update:inputTxt',this.inputTxt);
                this.isShow=false
                if(this.isButton){
                    this.$emit('change',this.inputIds,this.val)
                }else{
                    this.$emit('change',this.val);
                }
            }
        },
        close(){
            if(this.isMultiple){
                this.val=[];
                this.$emit('update:modelValue',[]);
            }else{
                this.val="";
                this.$emit('update:modelValue',"");
            }
            
        }
    }
}
</script>

<style lang="scss">
@import "./selectTree.scss";
</style>