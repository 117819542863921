<template>
    <modal class="selectDepartmentModel" :isShow="isShow">
        <div class="header">选择门店</div>
        <div class="modal-body">
            <div class="search-box">
                <key-board-input class="search"
                    type="text" v-model="searchText" placeholder="请输入编码、名称检索"
                    isBoard isKeyEnterClose isBlurClose
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input>
            </div>
            <div class="panel panel-table" >
                <div class="panel-heading">
                    <div class="tr">
                        <div class="td selectChecked">选择</div>
                        <div class="td code">编码</div>
                        <div class="td name">名称</div>
                    </div>
                </div>
                <div class="panel-body" ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.tr'}">
                    <div class="tr" v-for="item in listFilter" :key="item" :class="{selected:item.Department_ID==seleInfo?.Department_ID}" @click="click(item)" @dblclick="dblClick(item)" > 
                        <div class="td selectChecked">
                            <span class="iconfont " :class="item.Department_ID==seleInfo?.Department_ID?'icon-iconoption1':'icon-iconoption'"></span>
                        </div>
                        <div class="td code"><span>{{item.Department_Code}}</span></div>
                        <div class="td name"><span>{{item.Department_Name}}</span></div>
                    </div>
                </div>
            </div>
            <div class="item-operation">
                <div class="float-right">
                    <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>

import { config } from '../../../common'
/**选择门店  */
export default {
    name:"selectDepartmentModel",
    emits:["closeModel","routerLoad"],
    props:{ 
        isShow:Boolean,
    },
    data(){
        return{
            /**开卡人 */
            posUsers:Object,
            posUsersCopy:Object,
            seleInfo:null,
            /**搜索 */
            searchText:'',
            userInfo:null
        }
    },
    computed:{
        listFilter(){
            let list=this.userInfo?.OrgDepartments||[];
            let MenuTxt=this.searchText.toLowerCase();
            if(MenuTxt){
                list=list.filter(it=>(it.Department_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (it.Department_Name||"").toLowerCase().indexOf(MenuTxt)>=0)
            }
            return list
        }
    },
    mounted(){
        this.userInfo=this.$auth.getUserInfo();
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        }
    },
    methods:{
        /**选择门店 */
        click(item){
            this.seleInfo = item
        },
        /**双击 */
        dblClick(item){
            this.seleInfo = item
            this.confirm()
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            if(!this.seleInfo){
                this.$message.warning('请勾选要选择的门店！');
                return;
            }

            if(!this.seleInfo.Access_Key || !this.seleInfo.Access_Key){
                this.$message.warning('选择的门店格式不正确！');
                return;
            }

            config.Access_Key=this.seleInfo.Access_Key;
			config.Secret_key=this.seleInfo.Access_Secret;

            this.$auth.setDepartment({
                Department_Code:this.seleInfo.Department_ID,
                Department_Name:this.seleInfo.Department_Name
            })
            const baseLoading = this.$loading({
                text: "基础数据加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            //清除缓存
            this.$parent.isCleanCacheView=true;
            this.$nextTick(()=>{
                this.$parent.isCleanCacheView=false;  
            })
            let dataNum=0;
            let hideLoaing=()=>{
                dataNum++;
                if(dataNum==2){
                    this.isLogin=false;
                    baseLoading.close();
                    this.$router.push({name:'diningTable',params:{isForced:true}}).then(()=>{
                        //刷新界面
                        this.$emit('routerLoad');
                    });
                }
            }; 
            this.$cacheData.initCache();
            this.$cacheData.cacheBaseAll(["GlobalSysSetting","OrderChannel","ConsumeTypes","SalesPromotions"]).then((e)=>{
                hideLoaing();
                if(e?.GlobalSysSetting && e.GlobalSysSetting.data){
                    this.$auth.setRpt_Date(e.GlobalSysSetting.data.Rpt_DateStr)
                }
                console.log(e)
            }).catch((e)=>{
                hideLoaing();
                console.log('基础数据加载失败:',e);
            })
            
            if(this.$webBrowser?.isMobile?.Android){
                /**安卓设备开启mq 服务 */
                this.$httpAES.post("Bestech.CloudPos.GetMqParams", {}).then((d)=>{
                    hideLoaing();
                    if(d && d.ResponseHeader.ResultCode == 0){
                        let mQTTSetClass=d.ResponseBody;
                        this.$webBrowser.mqStart({
                            emq_url:"wss://" + mQTTSetClass.Ons_NameSrv + "/mqtt",
                            emq_name:mQTTSetClass.Ons_AccessKey,
                            emq_password:mQTTSetClass.Ons_SecretKey,
                            emq_id:mQTTSetClass.Ons_GroupId + "@" + mQTTSetClass.MerID + "@" + mQTTSetClass.DEPARTMENTCODE + "@" + mQTTSetClass.Site_Serial + "@" + mQTTSetClass.Client_Random,
                            emq_top:mQTTSetClass.Ons_Topic + "/" + mQTTSetClass.MerID + "/" + mQTTSetClass.DEPARTMENTID
                        }).then(()=>{
                            console.debug('mq开启成功');
                        }).catch(e=>{
                            console.debug('mq 服务开启失败:',e);
                        });
                    }
                    
                }).catch(e=>{
                    hideLoaing();
                    console.debug('mq 服务开启失败:'+e)
                })
            }else{
                //更换mq门店并重启mq服务 pc 用
                this.$webBrowser.mqRestart(this.seleInfo.Department_ID,this.seleInfo.Department_Name);
                hideLoaing();
            }
        }
    }
}
</script>

<style lang="scss">
@import "./selectDepartmentModel.scss";
</style>