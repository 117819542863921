<template>
    <modal class="batchReturnModel" :isShow="isShow">
        <div class="header">批量退菜 </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel panel-table" >
                        <div class="panel-heading">
                            <div class="tr">
                                <div class="td selectChecked">选择</div>
                                <div class="td name">菜品名称</div>
                                <div class="td number">数量/单位</div>
                                <div class="td returnNum">退菜数量</div>
                                <div class="td assNumber">辅助数量</div>
                            </div>
                        </div>
                        <div class="panel-body"  v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.tr'}">
                            <div class="tr" :class="{'current-row':currentIndex==index}" v-for="(item,index) in consumeList" :key="item" @click="currentIndex=index">
                                <div class="td selectChecked">
                                    <div class="cell">
                                        <el-checkbox class="selectChecked" v-model="item.selected" @change="foodClick(item)"/>
                                    </div>
                                </div>
                                <div class="td name">
                                    <div class="cell">
                                        <div class="nowrap2">{{item.EAT_XFName}}</div>
                                        <div class="assNum" v-if="item.Eat_AssSize" >辅助单位:<span>{{item.Eat_AssNumber}}{{item.Eat_AssSize}}</span></div>
                                    </div>
                                </div>
                                <div class="td number">
                                    <div class="cell">
                                        {{item.Eat_Number}}<span class="unit">{{item.Eat_XFSize}}</span>
                                    </div>
                                </div>
                                <div class="td returnNum">
                                    <div class="cell" @click="foodClick(item,true,eatNumInput[index]);">
                                        <input-pattern pattern="number" 
                                            :ref="(e)=>eatNumInput[index]=e" v-model="item.newEat_Number" :dplaces="2" 
                                            @focus="(e)=>{currentIndex=index;focus(e)}" />
                                    </div>
                                </div>
                                <div class="td assNumber">
                                    <div class="cell" @click="foodClick(item,true,eatAssNumInput[index])">
                                        <input-pattern pattern="number" 
                                            :ref="(e)=>eatAssNumInput[index]=e" v-model="item.newEat_AssNumber" :dplaces="2" v-if="item.Eat_AssSize" 
                                            @focus="(e)=>{currentIndex=index;focus(e)}"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-operation">
                        <el-checkbox class="selectChecked" :model-value="isAllCheck" label="全选" @change="allCheck" />
                        <div class="float-right">
                            <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
                <div class="right-box" ref="recharRight">
                    <div class="keyboard" >
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                    <div class="title">选择退菜原因</div>
                    <div class="reason-box" v-mouse-scroll>
                        <div class="reason-tag" :class="{selected:item.RefundReason_AutoID==reasontype}" 
                            v-for="(item,index) in RefundReasonsList" :key="index" 
                            @click="clickReason(item)" >{{item.RefundReason_Name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 

export default {
    name:'batchReturnModel',
    emits:['confirm'],
    props:{
        //是否显示弹层
        isShow:Boolean,
        //订单数据
        orderInfo:Object,
        //是否结账
        isCheckOut:Boolean,
        //是否联台
        unionType:Boolean
    },
    data(){
        return{
            orderNum:'',
            reasontype:0,
            reasontContent:'',
            /**辅助数量 */
            assSizeNum:'',
             /**用户数据 */
            userInfo:'',
            RefundReasonsList:'',
            //可以退菜的订单明细数据
            consumeList:[],
            //退菜数量 输入框
            eatNumInput:[],
            //退菜辅助数量 输入框
            eatAssNumInput:[],
            //高亮
            currentIndex:null,
        }
    },
    mounted(){
        this._keyBoard=initKeyBoard.call(this,this.$refs.recharRight);
        this.$nextTick(()=> {
            this.init();
        })
    },
    computed:{
        isAllCheck(){
            if(this.consumeList.length>0){
                return !this.consumeList.some(it=>!it.selected);
            }
            return false;
        }
    },
    watch:{
        isShow(){
            if(this.isShow){
                this.init();
            }
        },
        orderNum(){
            if(Number(this.orderNum)<0){
                this.orderNum=0
            }
        },
        assSizeNum(){
            if(Number(this.assSizeNum)<0){
                this.assSizeNum=0
            }
        }
    },
    methods:{
        //初始化
        init(){
            this.reasontype=null,
            this.reasontContent=null,
            /**退菜原因*/
            this.RefundReasons();
            let data=[];
            this.orderInfo?.Order_ConsumeList?.forEach(it=>{
                if(it.Food_Status>=0 && it.Eat_Number>0){//已经下单并且 有剩余数量
                    data.push(Object.assign({selected:false},it,{
                        newEat_Number:'',//退菜数量
                        newEat_AssNumber:''//辅助数量
                    }))

                    //组合明细
                    it.Eat_ComboItemList?.forEach(item=>{
                        if(item.Eat_Number>0){//有剩余数量
                            data.push(Object.assign({selected:false},item,{
                                newEat_Number:'',//退菜数量
                                newEat_AssNumber:''//辅助数量
                            }))
                        }
                    })
                }
            })
            this.consumeList=data;
        },
        focus(e){
            this._keyBoard?.setInput(e.target);
        },
        foodClick(item,isBo,input){
            if(isBo!=undefined){

                if(item.selected==isBo){
                    return;
                }
                item.selected=isBo;
                if(input){
                    this.$nextTick(()=>{
                        input.focus();
                        input.$refs.input.select();
                    })
                }
            }

            if(item.selected){
                item.newEat_Number=item.Eat_Number;
                item.newEat_AssNumber=item.Eat_AssNumber;
            }else{
                item.newEat_Number='';
                item.newEat_AssNumber='';
            }
            
        },
        allCheck(){
            let isbo=!this.isAllCheck;
            this.consumeList.forEach((item)=>{
                item.selected=isbo;
                if(item.selected){
                    item.newEat_Number=item.Eat_Number;
                    item.newEat_AssNumber=item.Eat_AssNumber;
                }else{
                    item.newEat_Number='';
                    item.newEat_AssNumber='';
                }
            })
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**退菜原因*/
        RefundReasons(){
            this.$cacheData.RefundReasons().then((d)=>{
                this.RefundReasonsList = d;
            }).catch((e)=>{
                this.$alert("数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        },  
        confirm(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            if(this.consumeList.length==0){
                this.$message.error('没有可退菜品!');
                this.eatNumInput[0]?.focus();
                return;
            }
            if(!this.reasontContent){
                this.$message.warning('原因不能为空!');
                return;
            }
            let refundFood=[];

            for(let i=0;i<this.consumeList.length;i++){
                let item=this.consumeList[i];
                if(item.selected){
                    if(Number(item.newEat_Number||0)<=0){
                         this.$message.warning('退菜数量不能小于等于0!请修改退菜数量!');
                        this.eatNumInput[i]?.focus();
                        return;
                    }

                    if(item.Eat_Number<Number(item.newEat_Number)){
                        this.$message.warning('退菜数量不能超过点单数量!请修改退菜数量!');
                        this.eatNumInput[i]?.focus();
                        return;
                    }

                    if(item.Eat_AssSize){//有辅助单位
                        if(Number(item.Eat_AssNumber)<Number(item.newEat_AssNumber||0)){
                            this.$message.warning('辅助退菜数量超过最大值!');
                            this.eatAssNumInput[i]?.focus();
                            return
                        }
                    }
                    refundFood.push({
                        Eat_ConsumeID:item.Eat_ConsumeID,
                        Eat_Number:item.newEat_Number,
                        Eat_AssNumber:item.newEat_AssNumber
                    })
                }
            }
            if(refundFood.length==0){
                this.$message.warning('没有可退菜品!请先勾选要退的菜品!');
                this.eatNumInput[0]?.focus();
                return;
            }

            let IsReturnCheckOutInfo = this.unionType;
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                RefundFoodDetails:refundFood, //退菜品明细信息
                Reason:this.reasontContent,//退菜原因
                Is_UnionOrder:IsReturnCheckOutInfo,//是否获取联台订单
                Timestamps:this.orderInfo.Timestamps.toString()
            }
            if(sessionStorage.getItem("tempUserID")){//权限验证， 上一个权限
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }

            const loading = this.$loading({
                text: "退菜中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log(param)
            this.$httpAES.post("Bestech.CloudPos.RefundFoodBatch",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.$message.success({ message: "退菜成功"});
                    console.log(data)
                    this.$emit("confirm",data.ResponseBody)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**原因 */
        clickReason(item){
            this.reasontype = item.RefundReason_AutoID;
            this.reasontContent = item.RefundReason_Name;
        },
    }
}
</script>

<style lang="scss">
@import './batchReturnModel.scss';
</style>