<template>
<div class="orderMenuBox" :style="parent.checOutShow">
    <div class="stretch-box " :class="{open:parent.isChecOutOpen}" @mousedown="(e)=>{e.preventDefault()}">
        <div class="left-la-box verticalCenter" @click="parent.isChecOutOpen=false;"><i class="iconfont icon-fangxiangquan-left"></i></div>
        <div class="stretch-scroll-box">
            <div class="panel-heading">
                <div class="tr left-content">
                    <div class="td name">名称</div>
                    <div class="td tdnum">数量</div>
                    <div class="td tds">单价</div>
                    <div class="td subtotal">小计</div>
                    <button class="more-bnt"  @mousedown="(e)=>{e.stopPropagation()}"  @blur="parent.isChecOutOpen=false;" @click="parent.isChecOutOpen=!parent.isChecOutOpen;">
                        <i class="iconfont" :class="parent.isChecOutOpen?'icon-youla':'icon-zuola'" ></i>
                    </button> 
                </div>
                <div class="extend-right">
                    <div class="tr"> 
                        <div class="td" style="min-width:52px">折前单价</div>
                        <div class="td" style="min-width:35px">优惠</div>
                        <div class="td" style="min-width:60px">折前小计</div>
                        <div class="td" style="min-width:60px">辅助单位</div>
                        <div class="td" style="min-width:60px">达成率%</div>
                        <div class="td" style="min-width:40px">状态</div>
                        <div class="td" style="min-width:40px">下单</div>
                        <div class="td" style="min-width:80px">制作</div>
                        <div class="td" style="min-width:80px">划菜</div>
                        <div class="td" style="min-width:80px">上桌</div>
                    </div>
                </div>
            </div>
            <div class="scrollnot" :class="parent.isCheckOutclass"  v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper',paddingH:'25'}" >
                <div class="slide-wrapper" :class="oneLevelClass(item)" v-for="(item,index) in orderMenuFilter"  :key="item">
                    <div class="index">{{index+1}}</div>
                    <div class="animate-slide-start" >
                        <div class="slide-content flex-box" @click="radioClick(item)" :title="item.EAT_XFName" >
                            <div class="left-content" style="position: relative">
                                <div class="tr">
                                    <div class="td name nowrap"><i class="iconfont icon-tesecai" v-show="parent.sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.EAT_XFName}}</div>
                                    <div class="td tdnum">{{item.Eat_Number}}</div>
                                    <div class="td tds">¥{{Conversion(item.Eat_CheckPrice)}}/{{(item.Eat_XFSize||"").substring(0,2)}}</div>
                                    <div class="td subtotal nowrap">{{Conversion((item.Eat_CheckPrice * item.Eat_Number))}}</div>
                                </div>
                                <div class="describe flex-box">
                                    <div class="inlineBlock nowrap2 flavor">
                                        <span v-if="item.Eat_MasName" :title="item.Eat_MasName" @mousedown="(e)=>{e.stopPropagation()}" @click="radioClick(item);masClick()">
                                            <i class="iconfont icon-a-beizhu2" ></i>
                                            口味:<span>{{item.Eat_MasName}}</span>
                                        </span>
                                    </div>
                                    <div class="inlineBlock tag-box">
                                        
                                        <span class="tag pin" v-if="item.FoodProperty_Dimension=='拼' || (item.Food_Type==1 && item.Eat_TcAutoID_Batch && !item.Eat_TcName)">拼</span>
                                        <!--套-->
                                        <span class="tag tao" v-else-if="item.FoodProperty_Dimension" :style="{visibility:(item.FoodProperty_Dimension?'':'hidden')}" >{{item.FoodProperty_Dimension||'套'}}</span>
                                        <!--赠,折-->
                                        <span class="tag give" v-if="item.FoodDiscount_Dimension" :style="{visibility:(item.FoodDiscount_Dimension?'':'hidden')}" >{{item.FoodDiscount_Dimension||'无'}}</span>
                                        <!--厨房状态-->
                                        <span v-html="kitSpeedHtml(item.KitSpeed_Dimension,item.Food_Status)"></span>
                                        <!-- <span class="tag delimit" v-if="item.KitSpeed_Dimension" >{{item.KitSpeed_Dimension}}</span> -->
                                        <!--退菜-->
                                        <span class="tag retreat" v-if="item.Refund_Number>0">退{{item.Refund_Number}}</span>
                                        <span class="tag retreat" v-if="item.ChangeNum_Status==1">未</span>
                                    </div>
                                    <div class="more" v-if="(item.FoodSetmeals || item.OrderComboConsumes || item.Eat_TcItemList)?.length>0">
                                        <div class="more-bnt" v-if="moreConsumeID!=item.Eat_ConsumeID" @click="moreConsumeID=item.Eat_ConsumeID">
                                            <i class="iconfont icon-xiala"></i>
                                        </div>
                                        <div class="more-bnt" v-else @click="moreConsumeID=null">
                                            <i class="iconfont icon-shangla"></i>
                                        </div>
                                    </div>
                                    <div class="time-box nowrap" :style="{visibility:(item.Time_Notice?'':'hidden')}">
                                        <i class="time-icon"></i>
                                        <span>{{item.Time_Notice}}</span>
                                    </div>
                                </div>
                            </div> 
                            <div class="extend-right" v-show="parent.isChecOutOpen">
                                <div class="flex-box">
                                    <div class="td" style="min-width:52px"><span v-if="item.Eat_SalePrice" >{{Conversion(item.Eat_SalePrice)}}</span></div>
                                    <div class="td" style="min-width:35px">
                                        <span v-if="item.Eat_SalePrice && item.Eat_SaleMoney">{{Conversion(item.Eat_SaleMoney-item.Eat_CheckMoney)}}</span>
                                        <span v-else>0</span>
                                    </div>
                                    <div class="td" style="min-width:60px"><span v-if="item.Eat_SaleMoney">¥{{Conversion(item.Eat_SaleMoney)}}</span></div>
                                    <div class="td" style="min-width:60px">
                                        <span v-if="item.Eat_AssSize">{{item.Eat_AssNumber+'/'+item.Eat_AssSize}}</span>
                                        <span v-if="item.Food_AssSize">{{item.AssSizeNumber+'/'+item.Food_AssSize}}</span>
                                    </div>
                                    <div class="td" style="min-width:60px"><span v-if="item.CompleteRate">{{item.CompleteRate}}</span></div>
                                    <div class="td" style="min-width:40px">
                                        <div class="text" v-if="item.Food_Speed" >
                                            <p>{{item.Food_Speed}}</p>
                                            <p v-if="item.Food_Speed=='起菜'">{{new Date(item.Eat_UpTime).Format("hh:mm")}}</p>
                                        </div>
                                    </div>
                                    <div class="td" style="min-width:40px">
                                        <div class="text" v-if="item.Eat_Czy" >
                                            <p>{{item.Eat_Czy}}</p>
                                            <p>{{new Date(item.Eat_Time).Format("hh:mm")}}</p>
                                        </div>
                                    </div>
                                    <div class="td" style="min-width:80px">
                                        <div class="text" v-if="item.Cooker">
                                            <p>{{item.Cooker}}</p>
                                            <p>{{new Date(item.Cook_Time).Format("hh:mm")}}</p>
                                        </div>
                                    </div>
                                    <div class="td" style="min-width:80px">
                                        <div class="text" v-if="item.Crosser">
                                            <p>{{item.Crosser}}</p>
                                            <p>{{new Date(item.Cross_Time).Format("hh:mm")}}</p>
                                        </div>
                                    </div>
                                    <div class="td" style="min-width:80px">
                                        <div class="text" v-if="item.OnDesker">
                                            <p>{{item.OnDesker}}</p>
                                            <p>{{new Date(item.OnDesk_Time).Format("hh:mm")}}</p>
                                        </div>
                                        <div class="button" v-else>
                                            <div v-if="(!item.FoodSetmeals && !item.Mas_ParentID ) && item.Eat_Czy && parent.CheckONDesk" @click="parent.ConfirmOnDesk(parent.CheckONDesk,item);">
                                                {{parent.CheckONDesk?.ProjectItem_Name || parent.CheckONDesk?.ProjectSubItem_Name}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border"></div>
                    <!--套餐滑出-->
                    <!--下单前-->
                    <el-collapse-transition>
                    <template v-if="item.FoodSetmeals?.length>0">
                    <div class="more-box" v-show="moreConsumeID==item.Eat_ConsumeID">
                        <div class="slide-content" :class="{selected:food.FoodSetmeal_ID == parent.disRadioOrder?.FoodSetmeal_ID && food.FoodSetmeal_Name== parent.disRadioOrder?.FoodSetmeal_Name}"  
                            :title="item.EAT_XFName" v-for="(food,foodindex) in item.FoodSetmeals" :key="foodindex" @click="parent.EatTcClick(food,foodindex,item)" >
                            <div class="flex-box">
                                <div class="left-content" style="position: relative">
                                    <div class="tr">
                                        <div class="td name nowrap">{{food.FoodSetmeal_Name}}</div>
                                        <div class="td tdnum">{{food.FoodSetmeal_Number}}</div>
                                        <!--food.FoodSaleMoney+-->
                                        <div class="td tds" >¥{{Conversion(food.FoodSetmeal_Price)}}/{{(food?.FoodSetmeal_Size||"份").substring(0,1)}}</div>
                                        <div class="td subtotal nowrap" >{{Conversion((food.FoodSetmeal_Price) * food.FoodSetmeal_Number) }}</div>
                                    </div>
                                    <div class="describe flex-box">
                                        <div class="inlineBlock flavor nowrap2" >
                                            <span v-if="food.Eat_MasName" :title="food.Eat_MasName" @mousedown="(e)=>{e.stopPropagation()}" 
                                                @click="parent.EatTcClick(food,foodindex,item);masClick()">
                                                <i class="iconfont icon-a-beizhu2" ></i>
                                                口味:<span>{{food.Eat_MasName}}</span>
                                            </span>
                                        </div>
                                        <div class="inlineBlock tag-box">
                                            <span class="tag tao" style="visibility:hidden" >无</span>
                                            <!--套-->
                                            <!--赠,折-->
                                            <span class="tag give"  :style="{visibility:(food.FoodDiscount_Dimension?'':'hidden')}">{{food.FoodDiscount_Dimension||"无"}}</span>
                                            <!--厨房状态-->
                                            <span v-html="kitSpeedHtml(food.KitSpeed_Dimension,food.Food_Status)"></span>
                                            <!-- <span class="tag delimit" v-if="food.KitSpeed_Dimension">{{food.KitSpeed_Dimension}}</span> -->
                                        </div>
                                        <div class="more">&nbsp;&nbsp;</div>
                                        <div class="time-box nowrap" style="visibility:hidden">
                                            &nbsp;&nbsp;
                                        </div>
                                    </div> 
                                </div>
                                <div class="extend-right">
                                    <div class="flex-box">
                                        <div class="td" style="min-width:52px">
                                            <span v-if="food.Eat_SalePrice" >{{Conversion(food.Eat_SalePrice)}}</span>
                                            <span v-else>0</span>
                                        </div>
                                        <div class="td" style="min-width:35px">
                                            <span v-if="food.Eat_SalePrice && food.Eat_SaleMoney">{{Conversion(food.Eat_SaleMoney-food.Eat_CheckMoney)}}</span>
                                            <span v-else>0</span>
                                        </div>
                                        <div class="td" style="min-width:60px">
                                            <span v-if="food.Eat_SaleMoney">¥{{Conversion(food.Eat_SaleMoney)}}</span>
                                            <span v-else>0</span>
                                        </div>
                                        <div class="td" style="min-width:60px"><span v-if="food.Eat_AssSize">{{food.Eat_AssNumber+'/'+food.Eat_AssSize}}</span></div>
                                        <div class="td" style="min-width:60px"><span v-if="food.CompleteRate">{{food.CompleteRate}}</span></div>
                                        <div class="td" style="min-width:40px">
                                            <div class="text" v-if="item.Food_Speed" >
                                                <p>{{item.Food_Speed}}</p>
                                                <p v-if="item.Food_Speed=='起菜'">{{new Date(item.Eat_UpTime).Format("hh:mm")}}</p>
                                            </div>
                                        </div>
                                        <div class="td" style="min-width:40px">
                                            <div class="text" v-if="food.Eat_Czy" >
                                                <p>{{food.Eat_Czy}}</p>
                                                <p>{{new Date(food.Eat_Time).Format("hh:mm")}}</p>
                                            </div>
                                        </div>
                                        <div class="td" style="min-width:80px">
                                            <div class="text" v-if="food.Cooker">
                                                <p>{{food.Cooker}}</p>
                                                <p>{{new Date(food.Cook_Time).Format("hh:mm")}}</p>
                                            </div>
                                        </div>
                                        <div class="td" style="min-width:80px">
                                            <div class="text" v-if="food.Crosser">
                                                <p>{{food.Crosser}}</p>
                                                <p>{{new Date(food.Cross_Time).Format("hh:mm")}}</p>
                                            </div>
                                        </div>
                                        <div class="td" style="min-width:80px">
                                            <div class="text" v-if="food.OnDesker">
                                                <p>{{food.OnDesker}}</p>
                                                <p>{{new Date(food.OnDesk_Time).Format("hh:mm")}}</p>
                                            </div>
                                            <div class="button" v-else>
                                                <div v-if="item.Eat_Czy&&!item.Mas_ParentID&&parent.CheckONDesk" @click="ConfirmOnDesk(parent.CheckONDesk,food);">
                                                    {{parent.CheckONDesk?.ProjectItem_Name || parent.CheckONDesk?.ProjectSubItem_Name}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </template>
                    <template v-else-if="item.OrderComboConsumes?.length>0">
                        <div class="more-box" v-show="moreConsumeID==item.Eat_ConsumeID">
                            <div class="slide-content" :class="{selected:parent.disRadioOrder.Is_Combo && food.Eat_ConsumeID == parent.disRadioOrder?.Eat_ConsumeID}"
                                :title="food.EAT_XFName" v-for="(food,foodindex) in item.OrderComboConsumes" :key="foodindex" 
                                @click="radioClick(food)">
                                <div class="flex-box">
                                    <div class="left-content" style="position: relative">
                                        <div class="tr">
                                            <div class="td name nowrap">{{food.EAT_XFName}}</div>
                                            <div class="td tdnum">{{food.Eat_Number}}</div>
                                            <!--food.FoodSaleMoney+-->
                                            <div class="td tds" >¥{{Conversion(food.Eat_CheckPrice)}}/{{(food?.Eat_XFSize||"份").substring(0,1)}}</div>
                                            <div class="td subtotal nowrap" >{{Conversion((food.Eat_CheckPrice) * food.Eat_Number) }}</div>
                                        </div>
                                        <div class="describe flex-box">
                                            <div class="inlineBlock flavor nowrap2" >
                                                <span v-if="food.Eat_MasName" :title="food.Eat_MasName" @mousedown="(e)=>{e.stopPropagation()}" 
                                                    @click="radioClick(food);masClick()">
                                                    <i class="iconfont icon-a-beizhu2" ></i>
                                                    口味:<span>{{food.Eat_MasName}}</span>
                                                </span>
                                            </div>
                                            <div class="inlineBlock tag-box">
                                                <span class="tag tao" style="visibility:hidden" >无</span>
                                                <!--套-->
                                                <!--赠,折-->
                                                <span class="tag give"  :style="{visibility:(food.FoodDiscount_Dimension?'':'hidden')}">{{food.FoodDiscount_Dimension||"无"}}</span>
                                                <!--厨房状态-->
                                                <span v-html="kitSpeedHtml(food.KitSpeed_Dimension,food.Food_Status)"></span>
                                                <!-- <span class="tag delimit" v-if="food.KitSpeed_Dimension">{{food.KitSpeed_Dimension}}</span> -->
                                            </div>
                                            <div class="more">&nbsp;&nbsp;</div>
                                            <div class="time-box nowrap" style="visibility:hidden">
                                                &nbsp;&nbsp;
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="extend-right">
                                        <div class="flex-box">
                                            <div class="td" style="min-width:52px">
                                                <span v-if="food.Eat_SalePrice" >{{Conversion(food.Eat_SalePrice)}}</span>
                                                <span v-else>0</span>
                                            </div>
                                            <div class="td" style="min-width:35px">
                                                <span v-if="food.Eat_SalePrice && food.Eat_SaleMoney">{{Conversion(food.Eat_SaleMoney-food.Eat_CheckMoney)}}</span>
                                                <span v-else>0</span>
                                            </div>
                                            <div class="td" style="min-width:60px">
                                                <span v-if="food.Eat_SaleMoney">¥{{Conversion(food.Eat_SaleMoney)}}</span>
                                                <span v-else>0</span>
                                            </div>
                                            <div class="td" style="min-width:60px"><span v-if="food.Eat_AssSize">{{food.Eat_AssNumber+'/'+food.Eat_AssSize}}</span></div>
                                            <div class="td" style="min-width:60px"><span v-if="food.CompleteRate">{{food.CompleteRate}}</span></div>
                                            <div class="td" style="min-width:40px">
                                                <div class="text" v-if="food.Food_Speed" >
                                                    <p>{{food.Food_Speed}}</p>
                                                    <p v-if="food.Food_Speed=='起菜'">{{new Date(food.Eat_UpTime).Format("hh:mm")}}</p>
                                                </div>
                                            </div>
                                            <div class="td" style="min-width:40px">
                                                <div class="text" v-if="food.Eat_Czy" >
                                                    <p>{{food.Eat_Czy}}</p>
                                                    <p>{{new Date(food.Eat_Time).Format("hh:mm")}}</p>
                                                </div>
                                            </div>
                                            <div class="td" style="min-width:80px">
                                                <div class="text" v-if="food.Cooker">
                                                    <p>{{food.Cooker}}</p>
                                                    <p>{{new Date(food.Cook_Time).Format("hh:mm")}}</p>
                                                </div>
                                            </div>
                                            <div class="td" style="min-width:80px">
                                                <div class="text" v-if="food.Crosser">
                                                    <p>{{food.Crosser}}</p>
                                                    <p>{{new Date(food.Cross_Time).Format("hh:mm")}}</p>
                                                </div>
                                            </div>
                                            <div class="td" style="min-width:80px">
                                                <div class="text" v-if="food.OnDesker">
                                                    <p>{{food.OnDesker}}</p>
                                                    <p>{{new Date(food.OnDesk_Time).Format("hh:mm")}}</p>
                                                </div>
                                                <div class="button" v-else>
                                                    <div v-if="item.Eat_Czy&&!item.Mas_ParentID&&parent.CheckONDesk" @click="parent.ConfirmOnDesk(parent.CheckONDesk,food);">
                                                        {{parent.CheckONDesk?.ProjectItem_Name || parent.CheckONDesk?.ProjectSubItem_Name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    </el-collapse-transition>
                    <!--下单后-->
                    <el-collapse-transition>
                    <template v-if="item.Eat_TcItemList?.length>0">
                        <div class="more-box" v-show="moreConsumeID==item.Eat_ConsumeID">
                            <div class="slide-content" :class="{selected:food.Eat_ConsumeID == parent.disRadioOrder?.Eat_ConsumeID && food.EAT_XFName == parent.disRadioOrder?.EAT_XFName}"   
                                style="position: relative;" v-for="(food,foodindex) in item.Eat_TcItemList" :key="foodindex" 
                                @click="parent.EatTcClick(food,foodindex,item)" >
                                <div class="flex-box">
                                    <div class="left-content" style="position: relative">
                                        <div class="tr">
                                            <div class="td name nowrap2" :title="item.EAT_XFName" >{{food.EAT_XFName}}</div>
                                            <div class="td tdnum">{{food.Eat_Number}}</div>
                                            <div class="td tds">¥{{Conversion(food.Eat_CheckPrice)}}/{{(food.Eat_XFSize||"").substring(0,1)}}</div>
                                            <div class="td subtotal">{{Conversion(food.Eat_CheckPrice * food.Eat_Number)}}</div>
                                        </div>
                                        <div class="describe flex-box">
                                            <div class="inlineBlock flavor nowrap2">
                                                <span v-if="food.Eat_MasName" :title="food.Eat_MasName" @mousedown="(e)=>{e.stopPropagation()}" 
                                                    @click="parent.EatTcClick(food,foodindex,item);masClick()">
                                                    <i class="iconfont icon-a-beizhu2"></i>
                                                    口味:<span>{{food.Eat_MasName}}</span>
                                                </span>
                                            </div>
                                            <div class="inlineBlock tag-box">
                                                <span class="tag tao" style="visibility:hidden" >无</span>
                                                <!--套-->
                                                <!-- <span class="tag tao" v-if="item.FoodProperty_Dimension" >{{item.FoodProperty_Dimension}}</span> -->
                                                <!--赠,折-->
                                                <span class="tag give"  :style="{visibility:(food.FoodDiscount_Dimension?'':'hidden')}">{{food.FoodDiscount_Dimension||"无"}}</span>
                                                <!--厨房状态-->
                                                <span v-html="kitSpeedHtml(food.KitSpeed_Dimension,food.Food_Status)"></span>
                                                <!-- <span class="tag delimit" v-if="food.KitSpeed_Dimension">{{food.KitSpeed_Dimension}}</span> -->
                                                <!--退菜-->
                                                <span class="tag retreat" v-if="food.Refund_Number>0">退{{food.Refund_Number}}</span>
                                            </div>
                                            <div class="more">&nbsp;&nbsp;</div>
                                            <div class="time-box nowrap" :style="{visibility:(food.Time_Notice?'':'hidden')}" >
                                                <i class="time-icon"></i>
                                                <span>{{food.Time_Notice}}</span>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="extend-right">
                                        <div class="flex-box">
                                            <div class="td" style="min-width:52px">
                                                <span v-if="food.Eat_SalePrice" >{{Conversion(food.Eat_SalePrice)}}</span>
                                                <span v-else>0</span>
                                                </div>
                                            <div class="td" style="min-width:35px">
                                                <span v-if="food.Eat_SalePrice && food.Eat_SaleMoney">{{Conversion(food.Eat_SaleMoney-food.Eat_CheckMoney)}}</span>
                                                <span v-else>0</span>
                                            </div>
                                            <div class="td" style="min-width:60px">
                                                <span v-if="food.Eat_SaleMoney">¥{{Conversion(food.Eat_SaleMoney)}}</span>
                                                <span v-else>0</span>
                                            </div>
                                            <div class="td" style="min-width:60px"><span v-if="food.Eat_AssSize">{{food.Eat_AssNumber+'/'+food.Eat_AssSize}}</span></div>
                                            <div class="td" style="min-width:60px"><span v-if="food.CompleteRate">{{food.CompleteRate}}</span></div>
                                            <div class="td" style="min-width:40px">
                                                <div class="text" v-if="item.Food_Speed" >
                                                    <p>{{item.Food_Speed}}</p>
                                                    <p v-if="item.Food_Speed=='起菜'">{{new Date(item.Eat_UpTime).Format("hh:mm")}}</p>
                                                </div>
                                            </div>
                                            <div class="td" style="min-width:40px">
                                                <div class="text" v-if="food.Eat_Czy" >
                                                    <p>{{food.Eat_Czy}}</p>
                                                    <p>{{new Date(food.Eat_Time).Format("hh:mm")}}</p>
                                                </div>
                                            </div>
                                            <div class="td" style="min-width:80px">
                                                <div class="text" v-if="food.Cooker">
                                                    <p>{{food.Cooker}}</p>
                                                    <p>{{new Date(food.Cook_Time).Format("hh:mm")}}</p>
                                                </div>
                                            </div>
                                            <div class="td" style="min-width:80px">
                                                <div class="text" v-if="food.Crosser">
                                                    <p>{{food.Crosser}}</p>
                                                    <p>{{new Date(food.Cross_Time).Format("hh:mm")}}</p>
                                                </div>
                                            </div>
                                            <div class="td" style="min-width:80px">
                                                <div class="text" v-if="food.OnDesker">
                                                    <p>{{food.OnDesker}}</p>
                                                    <p>{{new Date(food.OnDesk_Time).Format("hh:mm")}}</p>
                                                </div>
                                                <div class="button" v-else>
                                                    <div v-if="item.Eat_Czy&&!item.Mas_ParentID&&parent.CheckONDesk" @click="parent.ConfirmOnDesk(parent.CheckONDesk,food);">
                                                        {{parent.CheckONDesk?.ProjectItem_Name || parent.CheckONDesk?.ProjectSubItem_Name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    </el-collapse-transition>
                </div>
            </div>
        </div>
    </div>
    <div v-show="false" ref="order-up"></div>
    <div v-show="false" ref="order-down"></div>
    <weighing v-model="parent.wgNum"></weighing>
</div>
</template>

<script>
import { animation } from '/src/common' 
//订单展示
export default {
    name:"orderMenuBox",
    props:{
    },
    computed:{
        //父级
        parent(){
            return this.$parent;
        },
        //订单过滤后的显示数据
        orderMenuFilter(){
            //组合套餐
            let comboList={};

            let list=[];
            this.parent.orderMenuList?.forEach((it,index)=>{
                it._orderIndex=index;//标记排序号
                if(it.Food_Status==-1 && it.Food_Type==1 && it.Eat_TcAutoID_Batch && !it.Eat_TcName && it.Eat_TcIndex>1){
                    it.Is_Combo=true;
                    if(comboList[it.Eat_TcAutoID_Batch]){
                        comboList[it.Eat_TcAutoID_Batch].push(it);
                    }else{
                        comboList[it.Eat_TcAutoID_Batch]=[it];
                    }
                }else{
                    list.push(it);
                }
            })
            list.forEach(it=>{
                if(comboList[it.Eat_TcAutoID_Batch]){
                    it.OrderComboConsumes=comboList[it.Eat_TcAutoID_Batch];
                }
            })
            return list;
        },
        //满减活动方案
        fullPomotionList(){
            return this.promotionList?.filter(it=>it.ScenList?.some(item=>item.Name=="POS堂食") && it.PromotionType==1)||[];
        }
    },
    data(){
        return {
            /*点菜单 列表是否展开 */
            isChecOutOpen:false,
            //记录 更多 打开的id
            moreConsumeID:null,
            //活动方案
            promotionList:null
        }
    },
    watch:{
        "parent.orderMenuList.length"(){

        }
    },
    mounted(){
        /**活动方案 */
        this.$cacheData.OnlinePromotions().then((d)=>{
            this.promotionList=d;
        }).catch(e=>{
            this.$alert("活动方案数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
    },
    methods:{
        //一级分类 类名
        oneLevelClass(item){
            let className=[];
            if(item.Food_Status>=0){
                className.push("selected");
            }

            if(this.parent.disRadioclass>=0){
                let trItem=this.parent.orderMenuList[this.parent.disRadioclass];
                if(this.parent.disRadioclass==item._orderIndex || (trItem && trItem.Is_Combo && item.OrderComboConsumes?.some(it=>it.Eat_ConsumeID==trItem.Eat_ConsumeID))){
                    className.push("selectedRow");
                }
            }
            
            return className;
        },
        //菜单选择菜品
        radioClick(item){
            this.$parent.radioClick(item._orderIndex,item);
        },
        //点击口味
        masClick(){
            this.$parent.MiddleMenuClick(this.$parent.QKOrderMain?.find(d=>d.ProjectItem_Key=='Mas'))
        },
        //没有小数点显示整数,有小数位 显示两位小数
        Conversion(num){
            if(parseFloat(num)===parseInt(num)){
                return parseInt(num);
            }else{
                return Number(num).toFixed(2);
            }
        },
        /** 厨房状态 标签 显示htnl
         * @param KitSpeed_Dimension 厨房速度维度 ： 即，等，起，催，划
         * @param Food_Status 划菜状态，0：待切配 ，1：已切配 待制作， 2：已制作 待划菜， 3：已划菜 待上桌 ，4：已上桌
        */
        kitSpeedHtml(KitSpeed_Dimension,Food_Status){
            //已上桌 状态 优先于其他厨房速度维度
            if(Food_Status==4){
                return "<span class='tag img'><img src='/images/served.png'/></span>";
            }else if(KitSpeed_Dimension=="划"){
                return "<span class='tag iconfont icon-yihuacai'></span>";
            }else if(KitSpeed_Dimension=="即"){
                return "<span class='tag tagJi'>即</span>";
            }else if(KitSpeed_Dimension=="等"){
                return "<span class='tag tagDeng'>等</span>";
            }else if(KitSpeed_Dimension=="起"){
                return "<span class='tag tagQi'>起</span>";
            }else if(KitSpeed_Dimension=="催"){
                return "<span class='tag tagCui'>催</span>";
            }else if(KitSpeed_Dimension){
                return '<span class="tag delimit">'+KitSpeed_Dimension+'</span>'
            }   
        },
        pageUp(){
            this.$refs['order-up'].dispatchEvent(new Event("click"));
        },
        pageDown(){
            this.$refs['order-down'].dispatchEvent(new Event("click"));
        },
        /**滚动到 选中位置 */
        scrollRow(){
            animation.scrollTopAnchor(this.$refs.box,".slide-wrapper.selectedRow")
        },
        /**计算促销活动 */
        promotions(){

        },
        /**满减 
         * @param totalMoney 总金额
        */
        fullReduction(totalMoney){
            if(totalMoney>0){
                let toDay=new Date();//当前时间
                let time=toDay.Format("hh:mm");//时分
                let weekDay=toDay.getDay();//周
                if(weekDay==0){
                    weekDay=7;
                }
                let promotion=this.fullPomotionList?.find(it=>it.BeginTime<=time && it.EndTime>=time && it.WeekDay?.indexOf(weekDay)>=0);
                if(promotion){//满减活动
                    let youHuiMoney=0;//优惠金额
                    let offPromotion=promotion.MoneyOffPromotion;//活动方案
                    let total=this.getFullTotalMoney(offPromotion);
                    let money=total.totalMoney;
                    
                    if(offPromotion.CheckMoneyType==1){//折后
                        total.totalMoney;
                    }else{
                        money=total.saleTotalMoney;
                    }
                    if(offPromotion.MoneyOffPromotionConsumes?.length>0){
                        if(offPromotion.PromKindType==2 || offPromotion.PromKindType==4){//只有一个
                            let consumes=offPromotion.MoneyOffPromotionConsumes[0];
                            if(consumes.FullMoney>0){//金额每满X元 减   要减的金额=向下取整(金额/每满金额)*减金额
                                youHuiMoney=Number((Math.floor(money/consumes.FullMoney)*consumes.ReduceMoney).toFixed(2));
                                if(youHuiMoney>consumes.MaxReduceMoney){//不能超过 最大优惠减金额
                                    youHuiMoney=consumes.MaxReduceMoney;
                                }
                            }
                        }else{//
                            let consumes=offPromotion.MoneyOffPromotionConsumes.find(it=>it.FullMoney<=money);
                            if(consumes){
                                if(consumes.FullMoney>=0){//按订单金额满x元减 OR 赠
                                    youHuiMoney=consumes.ReduceMoney;
                                }
                            }
                        }  
                    }
                    if(offPromotion.CheckMoneyType==0 && youHuiMoney>total.totalMoney){//如果折前金额 优惠力度大于 实际 结算金额 则优惠金额等于 结算金额
                        youHuiMoney=total.totalMoney;
                    }
                    if(youHuiMoney>0){
                        totalMoney=totalMoney-youHuiMoney;
                    }
                }
            }
            return totalMoney;
        },
        //按满减规则返回订单菜品
        getFullTotalMoney(offPromotion){
            let list=this.parent.orderMenuList||[];
            let totalMoney=0;//结算金额
            let saleTotalMoney=0;//销售金额
            //活动类型方式 //=1 按订单金额满x元减 OR 赠///= 2 按订单金额每满X元 减 OR 赠
            if(offPromotion.PromKindType==1 || offPromotion.PromKindType==2){ 
                //菜品有点菜数量
                list.filter(item=>item.Eat_Number>0).forEach(item=>{
                    if(item.Eat_TcItemList){//套餐
                        item.Eat_TcItemList.forEach(eat=>{
                            totalMoney = totalMoney+(eat.Eat_CheckPrice * eat.Eat_Number);  //结算总价格
                            saleTotalMoney=saleTotalMoney+(eat.Eat_SalePrice * eat.Eat_Number);  //总价格 
                        })
                    }else if(item.FoodSetmeals){//套餐
                        item.FoodSetmeals.forEach(food=>{
                            totalMoney = totalMoney+((food.FoodSetmeal_Price) * food.FoodSetmeal_Number);  //结算总价格
                            saleTotalMoney=saleTotalMoney+((food.FoodSetmeal_Price) * food.FoodSetmeal_Number);  //总价格 
                        })
                    }
                    if(item.Eat_CheckMoney){//如果有结算金额取结算金额，
                        totalMoney = totalMoney+ item.Eat_CheckMoney;
                    }else{
                        totalMoney = totalMoney+(item.Eat_CheckPrice * item.Eat_Number)  //结算总价格
                    }
                    if(item.Eat_SaleMoney){//如果有 金额取 金额
                        saleTotalMoney = saleTotalMoney+ item.Eat_SaleMoney;
                    }else{
                        saleTotalMoney = saleTotalMoney+(item.Eat_SalePrice * item.Eat_Number)  //总价格
                    }
                });
            }else{///= 3 按订单菜品满x元减 OR 赠///= 4 按订单菜品每满X元 减 OR 赠
                list= list.filter(item=>item.Eat_Number>0 && !item.is_taste)//排除 加价口味作法
                if(offPromotion.XfbmKind==1){//活动按菜品方式 /// =1 按菜品小类/// =2 按菜品指定
                    if(offPromotion.SubFoodKinds?.length>0){
                        list.filter(it=>offPromotion.SubFoodKinds.some(item=>item.Item_ID== it.SubCategory_ID)).forEach(item=>{
                            if(item.Eat_TcItemList){//套餐
                                item.Eat_TcItemList.filter(it=>offPromotion.SubFoodKinds.some(food=>food.Item_ID== it.SubCategory_ID)).forEach(eat=>{
                                    totalMoney = totalMoney+(eat.Eat_CheckPrice * eat.Eat_Number)  //结算总价格
                                    saleTotalMoney=saleTotalMoney+(eat.Eat_SalePrice * eat.Eat_Number);  //总价格 
                                })
                            }else if(item.FoodSetmeals){//套餐
                                item.FoodSetmeals.filter(it=>offPromotion.SubFoodKinds.some(food=>food.Item_ID== it.SubCategory_ID)).forEach(food=>{
                                    totalMoney = totalMoney+((food.FoodSetmeal_Price) * food.FoodSetmeal_Number)  //结算总价格
                                    saleTotalMoney=saleTotalMoney+((food.FoodSetmeal_Price) * food.FoodSetmeal_Number);  //总价格 
                                })
                            }
                            if(item.Eat_CheckMoney){//如果有结算金额取结算金额
                                totalMoney = totalMoney+ item.Eat_CheckMoney;
                            }else{
                                totalMoney = totalMoney+(item.Eat_CheckPrice * item.Eat_Number)  //结算总价格
                            }
                            if(item.Eat_SaleMoney){//如果有 金额取 金额
                                saleTotalMoney = saleTotalMoney+ item.Eat_SaleMoney;
                            }else{
                                saleTotalMoney = saleTotalMoney+(item.Eat_SalePrice * item.Eat_Number)  //总价格
                            }
                        })
                    }
                }else{/// =2 按菜品指定
                    if(offPromotion.Foods?.length>0){
                        list.filter(it=>offPromotion.Foods.some(item=>item.Item_ID== it.EAT_XFBMID)).forEach(item=>{
                            if(item.Eat_TcItemList){//套餐
                                item.Eat_TcItemList.filter(it=>offPromotion.Foods.some(food=>food.Item_ID== it.EAT_XFBMID)).forEach(eat=>{
                                    totalMoney = totalMoney+(eat.Eat_CheckPrice * eat.Eat_Number)  //结算总价格
                                    saleTotalMoney=saleTotalMoney+(eat.Eat_SalePrice * eat.Eat_Number);  //总价格 
                                })
                            }else if(item.FoodSetmeals){//套餐
                                item.FoodSetmeals.filter(it=>offPromotion.Foods.some(food=>food.Item_ID== it.FoodSetmeal_ID)).forEach(food=>{
                                    totalMoney = totalMoney+((food.FoodSetmeal_Price) * food.FoodSetmeal_Number)  //结算总价格
                                    saleTotalMoney=saleTotalMoney+((food.FoodSetmeal_Price) * food.FoodSetmeal_Number);  //总价格 
                                })
                            }
                            if(item.Eat_CheckMoney){//如果有结算金额取结算金额
                                totalMoney = totalMoney+ item.Eat_CheckMoney;
                            }else{
                                totalMoney = totalMoney+(item.Eat_CheckPrice * item.Eat_Number)  //结算总价格
                            }
                            if(item.Eat_SaleMoney){//如果有 金额取 金额
                                saleTotalMoney = saleTotalMoney+ item.Eat_SaleMoney;
                            }else{
                                saleTotalMoney = saleTotalMoney+(item.Eat_SalePrice * item.Eat_Number)  //总价格
                            }
                        })
                    }
                }
            }
            return {
                saleTotalMoney:Number(saleTotalMoney.toFixed(2)),//保留两位小数
                totalMoney: Number(totalMoney.toFixed(2))//保留两位小数
            } 
        }
    },
}
</script>

<style>

</style>