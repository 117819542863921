<template>
    <modal class="dismantleDeskModel" :isShow="isShow">
        <div class="header">
           {{({1:'拆虚拟台',2:'撤销拆台',3:'撤销开台'})[type]}}
        </div>
        <div class="modal-body" :class="{type3:type==3}">
            <desk-box v-if="isShow" :PosRooms="PosRooms" :deskFilter="deskFilter" v-on:select="click" :isFiter="type!=3"></desk-box>
        </div>
        <div class="footer-box">
            <button class="btn btn-back" @click="hide()">返回</button>
        </div>
        <modal-load :isShow="promptDesk">
            <prompt-desk-name-vue :isShow="promptDesk" :choodesk="choodesk" v-on:closeModel="promptDesk=false" @prompDesk="prompDesk"></prompt-desk-name-vue>
        </modal-load>
    </modal>
</template>

<script>
import deskBox from '../../../template/deskBox/deskBox.vue';
import promptDeskNameVue from './promptDeskName/promptDeskName.vue';

/**锁台操作 */
export default {
  components: { deskBox,promptDeskNameVue },
    name:"dismantleDeskModel",
    props:{
        isShow:Boolean,
        /**拆台类型  1 拆台  2撤销拆台  3撤销开台 */
        type:{
            default:1,
            type:Number
        }
    },
    data(){
        return {
            deskremin:'',
            custmize:'',
            cusinput:'',
            promptDesk:false,
            choodesk:'',
            disDeskName:""
        }
    },
    computed:{
        allDesk(){
            let allDesk=[];//所有桌台
            this.PosRooms.forEach((room)=>{
                allDesk=allDesk.concat(room.PosDesks);
            })
            return allDesk;
        },
        /**餐厅数据 */
        PosRooms(){
            return this.$global.PosRooms||[];
        }
    },
    mounted(){
    }, 
    methods:{
        deskFilter(desk){
            if(this.type==1){//拆台 非虚拟台的所有桌台
                return !desk.Is_VisualDesk;
            }else if(this.type==2){//2撤销拆台  虚拟桌台 且 空台
                return desk.Is_VisualDesk && desk.Desk_Status==0;
            }else {//3撤销开台 已开台未下单 且未锁台 的桌台
                return !desk.Is_Desk_Locked && (desk.Desk_Status==1 || desk.Desk_Status==4) && desk.OpenDeskInfo?.Eat_FoodCounts==0;
            }
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        prompDesk(deskName){
            this.promptDesk=false
            this.disDeskName=deskName
            this.deskOperate(this.choodesk,this.type);
        },
        click(desk){
            let name=({1:'拆分虚拟台',2:'撤销拆台',3:'撤销开台'})[this.type];
            if(this.type==1){
                this.promptDesk=true
                this.choodesk=desk
            }else{
                this.$confirm('您确定将【'+desk.Desk_Name+'】'+name+'吗', "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText:"取消",
                    callback: (name) => {
                        if(name=='confirm'){
                            if(this.type==3){
                            this.cancelDeskOpen(desk,this.type); 
                            }else if(this.type==1 || this.type==2){
                                this.deskOperate(desk,this.type);
                            }
                        }
                    },
                });
            }
        },
        /**桌台操作  拆分虚拟台 或 2：撤销拆分桌台
         * @param Operator_Kind 1：拆分虚拟桌台 2：撤销拆分桌台
         */
        deskOperate(desk,Operator_Kind){
            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let name=({1:'拆分虚拟台',2:'撤销拆台',3:'撤销开台'})[this.type];
            let userInfo= this.$auth.getUserInfo();
            let param={
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Desk_AutoID:desk.Desk_AutoID,
                Operator_Kind:Operator_Kind,//1：拆分虚拟桌台 2：撤销拆分桌台
                Timestamps:desk.Timestamps
            }
            if(this.disDeskName){
                param = Object.assign(param,{Visual_DeskName:this.disDeskName})
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            this.$httpAES.post("Bestech.CloudPos.VirtualDeskOperate",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success(name+"操作成功");
                    let Desk_Info=d.ResponseBody;
                    if(Operator_Kind==1){//增加桌台
                        let deskIndex=this.allDesk.findIndex(item=>item.Desk_AutoID==desk.Desk_AutoID);
                        if(deskIndex>=0){
                            this.allDesk.splice(deskIndex+1,0,Desk_Info);
                        }
                        console.log(this.PosRooms)
                        this.PosRooms.find(item=>{
                            for(let i=0;i<item.PosDesks.length;i++){
                                if(item.PosDesks[i].Desk_AutoID==desk.Desk_AutoID){
                                    item.PosDesks.splice(i+1,0,Desk_Info);
                                    return true;
                                }
                            }
                            return false;
                        });
                        this.$emit("updateDesk",Desk_Info.Timestamps);
                    }else{//删除桌台
                        let deskIndex=this.allDesk.findIndex(item=>item.Desk_AutoID==desk.Desk_AutoID);
                        if(deskIndex>=0){
                            this.allDesk.splice(deskIndex,1);
                        }
                        this.PosRooms.find(item=>{
                            for(let i=0;i<item.PosDesks.length;i++){
                                if(item.PosDesks[i].Desk_AutoID==desk.Desk_AutoID){
                                    item.PosDesks.splice(i,1);
                                    return true;
                                }
                            }
                            return false;
                        });
                        this.$emit("updateDesk",Desk_Info?.Timestamps);
                    }
                }else{
                    this.$message.error(name+"操作失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(name+'操作失败：'+e);
                console.log(name+'操作失败：'+e);
            })
        },
        /**撤销开台 */
        cancelDeskOpen(desk){
            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Desk_AutoID:desk.Desk_AutoID,
                Timestamps:desk.Timestamps
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            this.$httpAES.post("Bestech.CloudPos.CancelDeskOpen",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    let Desk_Info=d.ResponseBody;
                    this.$message.success("撤销开台操作成功");
                    desk.Desk_Status=0;
                    desk.Timestamps=Desk_Info?.Timestamps;
                    delete desk.ReserveDeskInfo;
                    delete desk.OpenDeskInfo;
                    this.$emit("updateDesk",Desk_Info?.Timestamps);
                }else{
                    this.$message.error("撤销开台操作失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('撤销开台操作失败：'+e);
                console.log('撤销开台操作失败：'+e);
            })
        }
    }
}
</script>

<style lang="sass">
@import "./dismantleDeskModel.scss";
</style>