<template>
    <modal class="makeupTCModel" :isShow="isShow" @keyup.stop="keyup">
        <div class="header">
            单品组合套餐
        </div>
        <div class="modal-body clearfix">
            <div class="left-box">
                <div class="panel panel-table" >
                    <div class="panel-heading">
                        <div class="tr">
                            <div class="td selectChecked">选择</div>
                            <div class="td name">菜品名称</div>
                            <div class="tdnum">数量</div>
                            <div class="tds">单位</div>
                            <div class="td price">单价</div>
                            <div class="td">小计</div>
                            <div class="td operate">操作</div>
                        </div>
                    </div>
                    <div class="panel-body"  v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper'}" v-if="orderMenuList">
                        <div class="slide-wrapper" :class="{selectedRow:selectList[item.Eat_ConsumeID]}" v-for="(item,index) in orderMenuList"  :key="item" v-show="item.Food_Status!=-1">
                            <div class="animate-slide-start">
                                <div class="slide-content" :title="item.EAT_XFName" >
                                    <div class="selectChecked verticalCenter" >
                                        <div class="check" @click="foodClick(item)" v-if="item.Food_Type==1">
                                            <span class="iconfont " :class="selectList[item.Eat_ConsumeID]?'icon-iconoption1':'icon-iconoption'"></span>
                                        </div>
                                    </div>
                                    <div class="detailed">
                                        <div class="tr" @click="foodClick(item)">
                                            <div class="td name nowrap">{{item.EAT_XFName}}</div>
                                            <div class="tdnum">{{item.Eat_Number}}</div>
                                            <div class="tds" :title="item.Eat_XFSize">{{item?.Eat_XFSize.substring(0,1)}}</div>
                                            <div class="td" style="font-size: 13px;" >{{item.Eat_CheckPrice}}</div>
                                            <div class="td" style="font-size: 13px;">{{item.Eat_CheckMoney}}</div>
                                        </div>
                                        <div class="describe" @click="foodClick(item)">
                                            <div class="inlineBlock float tag-box">
                                                <span class="tag tao" v-if="item.Food_Type==2 || item.Food_Type==3 || item.FoodSetmeals" >套</span>
                                            </div>
                                        </div>
                                        <div class="more" v-if="(item.Food_Type==2 || item.Food_Type==3 || item.FoodSetmeals)"  @click="foldClick(index)">
                                            <i class="iconfont icon-fangxiang-down" v-if="packageShow!=index"></i>
                                            <i class="iconfont icon-fangxiang-up" v-else></i>
                                        </div>
                                    </div>
                                    <div class="operate">
                                        <span class="operate" v-if="(item.Food_Type==3||item.Food_Type==2)" @click="DiySetmealSplit(item)">拆分套餐</span>
                                    </div>
                                </div>
                            </div>
                            <div class="more-box" :class="{selectedRow:selectList[item.Eat_ConsumeID]}" v-show="item.Eat_TcItemList && packageShow==index">
                                <!-- <div style="border-bottom:1px solid #e9e9ee"></div> -->
                                <div class="slide-content" style="position: relative;" v-for="(food,foodindex) in item.Eat_TcItemList" :key="foodindex">
                                    <div style="position: relative">
                                        <div class="tr">
                                            <div class="td name nowrap" :title="item.EAT_XFName" >{{food.EAT_XFName}}</div>
                                            <div class="tdnum">{{food.Eat_Number}}</div>
                                            <div class="tds" :title="food.Eat_XFSize" >
                                                {{food.Eat_XFSize.substring(0,1)}}
                                            </div>
                                            <div class="td" style="font-size: 13px;">{{food.Eat_CheckPrice}}</div>
                                            <div class="td" style="font-size: 13px;">{{food.Eat_CheckMoney }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-operation">
                    <div class="selectChecked" @click="allCheck">
                        <span class="iconfont icon-iconoption" :class="isAllCheck?'icon-iconoption1':'icon-iconoption'"></span>全选
                    </div>
                    <div class="float-right">
                        <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                        <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="title">组合新套餐信息:</div>
                <div class="tr-li food">
                    <div class="lable-name">选择菜品:</div>
                    <div class="from-input">
                        <div class="money"><span class="unit">￥</span>{{total.money}}</div>
                        <div class="small">共 {{total.FoodsSum}}道{{total.SumPortion}}份</div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-name">套餐名称:</div>
                    <div class="from-input">
                        <input type="text" ref="searchBox" v-model="tcName"/>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-name">单价:</div>
                    <div class="from-input">
                        <key-board-input
                            type="text" v-model="price" 
                            pattern="money"
                            isBlurClose isKeyEnterClose
                            :keyOptions="{isKeyDownEnter:true,isNumber:true}"
                        ></key-board-input>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-name">份数:</div>
                    <div class="from-input">
                        <key-board-input
                            type="text" v-model="number" 
                            pattern="number"
                            :dplaces="0"
                            :min="0"
                            isBlurClose isKeyEnterClose
                            :keyOptions="{isKeyDownEnter:true,isNumber:true}"
                        ></key-board-input>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-name">总价:</div>
                    <div class="from-input">
                        <div class="money"><span class="unit">￥</span>{{totalMoney}}</div>
                    </div>
                </div>
                <div class="details">
                    溢价金额 ￥{{premiumMoney}} &nbsp; &nbsp;<span v-if="ProfitRate">GM:<span class="gross">{{GMMoney}}%</span></span>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确定</button>
        </div>
    </modal>
</template>

<script>
import {deepClone} from '../../../../../common/index'
/**单品组合套餐 */
export default {
    name:"makeupTcModel",
    emits:["closeModel","changeOrderInfo"],
    props:{
        isShow:Boolean,
        orderInfo:Object,
        // 全部菜品
        AllFoods:Array,
        //判断 GM是否显示
        ProfitRate:Boolean,
        RunFixedSetmealSplitToSingleFoods:Boolean
    },
    watch: {
        isShow(newVal) {
            if(newVal){
                this.tcName="";
                this.price=0;
                this.number=1;
                this.selectList={};
                this.isAllCheck=false;
                this.packageShow=null;
            }
        },
    },
    data(){
        return {
            //勾选的明细
            selectList:{},
            //全选
            isAllCheck:false,
            packageShow:null,
            //套餐名称
            tcName:'',
            //单价
            price:0,
            //份数
            number:1
        }
    },
    computed:{

        orderMenuList(){
            console.log(this.orderInfo?.Order_ConsumeList)
            let orderconsumelist;
            if(this.RunFixedSetmealSplitToSingleFoods)
            {
                orderconsumelist=this.orderInfo?.Order_ConsumeList?.filter((it)=>it.Eat_Number>0 && (it.Food_Type==1 || it.Food_Type==3||it.Food_Type==2))
            }else{
                orderconsumelist=this.orderInfo?.Order_ConsumeList?.filter((it)=>it.Eat_Number>0 && (it.Food_Type==1 || it.Food_Type==3))
            }
            //1：单菜 ，3：临时套餐 
            return  deepClone(orderconsumelist).map((order)=>{
                this.AllFoods.find(food=>{
                    if(food.Food_ID==order.EAT_XFBMID){
                        order.Food_CostPrice=food.Food_CostPrice
                    }
                    //多规格
                    if(food.Is_MultipleSize){
                        food?.MultipleSizeFoods?.forEach(mu=>{
                            //如果是即配套餐
                            if(order.Food_Type==3 || order.Food_Type==2){
                                order.orderMenuTcItemList?.forEach(eat=>{
                                    if(eat.Eat_XFBMID==mu.Food_ID){
                                        eat = Object.assign(eat,{Food_CostPrice:mu.Food_CostPrice})
                                    }
                                })
                            }
                            if(order.Eat_XFBMID==mu.Food_ID){
                                order = Object.assign(order,{Food_CostPrice:mu.Food_CostPrice})
                            }
                        })
                    }
                });
                return order;
            });
        },
        total(){
            console.log("")
            let placeFoodsTotal = 0;
            let placeFoodsSumPortion = 0;
            let placeFoodsSum=0;
            let costMoney=0;//成本金额
            Object.values(this.selectList).forEach(item=>{
                if(item.Eat_Number>0){
                    placeFoodsSum++; //总数量
                    costMoney+=(item.Food_CostPrice||0)*item.Eat_Number;

                    if(item.Eat_TcItemList){
                        item.Eat_TcItemList.map(eat=>{
                            if(item.Food_Type!=4){
                                placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(eat.Eat_Number); //总份量
                            }
                            if(item.ModiPrice){
                                placeFoodsTotal = placeFoodsTotal+((eat.Eat_CheckPrice+eat.ModiPrice) * eat.Eat_Number)  //总价格
                            }else{
                            placeFoodsTotal = placeFoodsTotal+(eat.Eat_CheckPrice * eat.Eat_Number)  //总价格
                            }
                        })
                    }
                    if(item.FoodSetmeals){
                        item.FoodSetmeals.map(food=>{
                            if(food?.Food_Type!=4){
                                 placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(food.FoodSetmeal_Number); //总份量
                            }
                            placeFoodsTotal = placeFoodsTotal+((food.FoodSetmeal_Price+food.FoodSaleMoney) * food.FoodSetmeal_Number)  //总价格
                        })
                    }
                    if(item.Food_Type!=4){
                        placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(item.Eat_Number); //总份量
                    }
                    if(item.ModiPrice){
                        placeFoodsTotal = placeFoodsTotal+((item.Eat_CheckPrice+item.ModiPrice) * item.Eat_Number)  //总价格
                    }else{
                        placeFoodsTotal = placeFoodsTotal+(item.Eat_CheckPrice * item.Eat_Number)  //总价格
                    }
                }
            })
            return {
                money:placeFoodsTotal.toFixed(2),//总金额
                SumPortion:parseFloat(placeFoodsSumPortion.toFixed(2)),//份数
                FoodsSum:placeFoodsSum,//数量
                costMoney:costMoney.toFixed(2)
            }
        },
        //总价
        totalMoney(){
            return (this.price*this.number).toFixed(2);
        },
        //溢价金额=总价-选择菜品金额
        premiumMoney(){
            return (this.totalMoney-this.total.money).toFixed(2);
        },
        //GM 就是毛利率 用总价-选择的菜品成本计算
        GMMoney(){
            return this.totalMoney==0?0:((this.totalMoney-this.total.costMoney)/this.totalMoney*100).toFixed(2);
        },
    },
    mounted(){
        setTimeout(() => {
            this.$refs.searchBox.focus();
        },100);
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.PosFoodsData=d;
        }).catch(e=>{
            console.log('e:'+JSON.stringify(e))
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
    }, 
    methods:{
        keyup(e){
            if(e.key=="Escape"){
                this.hide()
            }
        },
        /**选择菜品 */
        foodClick(item){
            if(item.Food_Type==1){
                if(this.selectList[item.Eat_ConsumeID]){
                    delete this.selectList[item.Eat_ConsumeID];
                }else{
                    this.selectList[item.Eat_ConsumeID]=item;
                }
            }
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**全选 */
        allCheck(){
            this.isAllCheck=!this.isAllCheck;
            if(this.isAllCheck){
                this.orderMenuList.forEach((item)=>{
                    if(item.Food_Type==1){
                        this.selectList[item.Eat_ConsumeID]=item;
                    }
                })
            }else{
                this.selectList={};
            }
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**下拉 */
        foldClick(index){
            if(this.packageShow == index){//收起
                this.packageShow = null;
            }else{//展开
                this.packageShow = index;
            }
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        hide(){
            this.$emit("closeModel")
        },
        //拆分套餐
        DiySetmealSplit(item){
            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.DiySetmealSplit",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Eat_AutoID:this.orderInfo.Eat_AutoID,
                Eat_ConsumeID:item.Eat_ConsumeID,
                OrderTimestamps:this.orderInfo.Timestamps
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("拆分套餐成功");
                    //订单被修改了
                    this.$emit("changeOrderInfo",d.ResponseBody);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('拆分套餐失败：'+e.message);
                console.log('拆分套餐失败：',e);
            })
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        //点击确认
        confirm(){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            let Eat_ConsumeIds=Object.keys(this.selectList);
            if(Eat_ConsumeIds.length==0){
                this.$message.warning("请先选择左边的菜品!");
                return;
            }

            if(this.price<=0){
                this.$message.warning("单价不能小于等于0!");
                return;
            }

            if(this.number<=0){
                this.$message.warning("份数不能小于等于0!");
                return;
            }

            //单品重组为即配套餐
            const loading = this.$loading({
                text: "请求操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.DiySetmealRegroup",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Eat_TcName:this.tcName,
                Eat_SalePrice:this.price,
                Eat_Number:this.number,
                Eat_AutoID:this.orderInfo.Eat_AutoID,
                Eat_ConsumeIds:Eat_ConsumeIds,
                OrderTimestamps:this.orderInfo.Timestamps
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    //订单被修改了
                    this.$emit("confirm",d.ResponseBody);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('保存失败：'+e.message);
                console.log('保存失败：',e);
            })
        }
    }
}
</script>

<style lang="sass">
@import "./makeupTCModel.scss";
</style>