<template>
    <modal class="giveReturnModel" :isShow="isShow">
        <div class="header">{{ isReturn?'退菜操作':'赠送操作' }} </div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">基本信息</div>
                    <div class="tr-li">
                        <div class="lable-txt">菜品名称：</div>
                        <div class="from-input" style="width:310px">
                            <input type="text"  readonly :value="disRadioOrder.EAT_XFName" />
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt">点单数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" placeholder="0"  readonly :value="disRadioOrder.Eat_Number" /></div>
                        <div class="lable-txt">{{ isReturn?'退菜':'赠送' }}数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" placeholder="0" class="key-focus" data-model='orderNum' ref="input" @focus="$event.target.select()"  v-model="orderNum" oninput="value=value.replace(/[^\d.]/g,'')" /></div>
                    </div>
                    <div class="title" v-show="disRadioOrder.Eat_AssSize && isReturn">辅助单位:{{disRadioOrder.Eat_AssSize}}</div>
                    <div class="tr-li" v-show="disRadioOrder.Eat_AssSize && isReturn">
                        <div class="lable-txt">辅助数量</div>
                        <div class="from-input" style="width:115px"><input type="text" placeholder="0"  readonly :value="disRadioOrder.Eat_AssNumber" /></div>
                        <div class="lable-txt">退菜数量：</div>
                        <div class="from-input" style="width:115px"><input type="text" placeholder="0" class="key-focus" data-model='assSizeNum' ref="SizeNum" @focus="$event.target.select()"  v-model="assSizeNum" oninput="value=value.replace(/[^\d.]/g,'')" /></div>
                    </div>
                    <div class="title">选择{{ isReturn?'退菜':'赠送' }}原因</div>
                    <div class="reason-box" v-mouse-scroll v-if="isReturn==true">
                        <div class="reason-tag" :class="{selected:item.RefundReason_AutoID==reasontype}" v-for="(item,index) in RefundReasonsList" :key="index" @click="clickReason(item)" >{{item.RefundReason_Name}}</div>
                    </div>
                    <div class="reason-box" v-mouse-scroll v-else>
                        <div class="reason-tag" :class="{selected:item.OperateReason_AutoID==reasontype}" v-for="(item,index) in RefundReasonsList" v-show="item.OperateReason_Kind==1" :key="index" @click="clickReason(item)" >{{item.OperateReason_Name}}</div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

export default {
    name:'giveReturnModel',
    props:{
        isShow:Boolean,
        isReturn:{//是否退菜  默认是赠送
            type:Boolean,
            default:false
        },
        disRadioOrder:Object,
        orderInfo:Object,
        isCheckOut:Boolean,
    },
    data(){
        return{
            orderNum:'',
            reasontype:0,
            reasontContent:'',
            /**辅助数量 */
            assSizeNum:'',
             /**用户数据 */
            userInfo:'',
            RefundReasonsList:'',
            /**考虑到联台情况获取对应时间戳 */
            Timestamps:''
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.Time()
            /**退菜原因*/
            if(this.isReturn==true){
                /**退菜原因*/
                this.RefundReasons()
            }else{
                /**赠送原因 */
                this.GiveReasons()
            }
            initKeyBoard.call(this,this.$refs.recharRight);
            this.orderNum=this.disRadioOrder?.Eat_Number;
            
            setTimeout(()=>{
                this.$refs.input.focus();
            },100)
            //this.EmptyDatas()
        })
    },
    watch:{
        isShow(){
            this.assSizeNum=''
            if(this.isShow==true){
                this.Time();
                this.reasontype=0;
                this.reasontContent='';
                if(this.isReturn==true){
                    /**退菜原因*/
                    this.RefundReasons()
                }else{
                    /**赠送原因 */
                    this.GiveReasons()
                }
                this.orderNum=this.disRadioOrder?.Eat_Number
                this.$nextTick(()=> {
                    this.$refs.input.focus();
                })
                //this.EmptyDatas()
            }
            //this.$refs.input.focus();
        },
        orderNum(){
            if(Number(this.orderNum)<0){
                this.orderNum=0
            }
        },
        assSizeNum(){
            if(Number(this.assSizeNum)<0){
                this.assSizeNum=0
            }
        }
    },
    methods:{
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**退菜原因*/
        RefundReasons(){
            this.$cacheData.RefundReasons().then((d)=>{
                this.RefundReasonsList = d;
            }).catch((e)=>{
                this.$alert("数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**赠送原因 */
        GiveReasons(){
            this.$cacheData.OperateReasons().then((d)=>{
                this.RefundReasonsList = d;
            }).catch((e)=>{
                this.$alert("数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        },   
        confirm(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            //let IsReturnCheckOutInfo = this.isCheckOut
            let IsReturnCheckOutInfo = true
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_ConsumeID:this.disRadioOrder.Eat_ConsumeID, //菜品明细ID
                Eat_Number:this.orderNum,
                Reason:this.reasontContent.toString(),
                IsReturn_CheckOutInfo:IsReturnCheckOutInfo,
                Is_UnionOrder:IsReturnCheckOutInfo,
                Timestamps:this.Timestamps
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            if(this.reasontContent==''){
                this.$alert('原因不能为空!', '提示', {
                    confirmButtonText: '确定',
                });
                return;
            }
            if(this.isReturn==false){
                if(this.disRadioOrder.Eat_Number<param.orderNum){
                    this.$alert('赠送数量不能超过点单数量!请修改赠送数量', '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.$refs.input.focus();
                        },
                    });
                }else{
                    /**赠送 */
                    const loading = this.$loading({
                        text: "赠送中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.GiveFoodFree",param).then((data)=>{
                        loading.close();
                        if(data?.ResponseHeader.ResultCode!=0){
                            this.$message.error(data?.ResponseHeader.ResultDesc);
                        }else{
                            ElMessage.success({ message: "赠送成功"});
                            this.$emit("GiveFoodFreeClick",this.isReturn,data)
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                }
            }else{
                if(this.disRadioOrder.Eat_AssSize !='' && this.isReturn==true){
                    if(parseFloat(this.disRadioOrder.Eat_AssNumber)<parseFloat(this.assSizeNum)){
                        this.$message.error('辅助退菜数量超过最大值!');
                        return
                    }
                }
                param = Object.assign(param,{Eat_AssNumber:Number(this.assSizeNum)})
                /**退菜 */
                if(this.disRadioOrder.Eat_Number<param.orderNum){
                    this.$alert('退菜数量不能超过点单数量!请修改退菜数量', '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.$refs.input.focus();
                        },
                    });
                }else{
                    const loading = this.$loading({
                        text: "退菜中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.RefundFood",param).then((data)=>{
                        loading.close();
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            ElMessage.success({ message: "退菜成功"});
                            this.$emit("GiveFoodFreeClick",this.isReturn,data)
                        }
                    }).catch((e)=>{
                        loading.close();
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    })
                }
            }
        },
        /**原因 */
        clickReason(item){
            if(this.isReturn==true){
                this.reasontype = item.RefundReason_AutoID;
                this.reasontContent = item.RefundReason_Name;
            }else{
                this.reasontype = item.OperateReason_AutoID;
                this.reasontContent = item.OperateReason_Name;
            }
        },
        /**获取订单时间戳 */
        Time(){
            this.Timestamps=''
            this.orderInfo.Order_ConsumeList.map(order=>{
                this.Timestamps = this.orderInfo.Timestamps.toString()
                if(order.Eat_TcItemList){
                    order.Eat_TcItemList.map(eat=>{
                        if(eat.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                            this.Timestamps = this.orderInfo.Timestamps.toString()
                        }
                    })
                }else{
                    if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                        this.Timestamps = this.orderInfo.Timestamps.toString()
                    }
                }
            })
            if(this.orderInfo.UnionOrderInfoList){
                this.orderInfo.UnionOrderInfoList.map(union=>{
                    union.Order_ConsumeList.map(order=>{
                        if(order.Eat_TcAutoID_Batch){
                            //如果是退菜,并且是联台套餐子项退菜
                            if(this.isReturn==true){
                                order.Eat_TcItemList.map(eat=>{
                                    if(eat.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                        this.Timestamps = union.Timestamps.toString()
                                    }
                                })
                            }else{
                                if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    this.Timestamps = union.Timestamps.toString()
                                }
                            }
                        }else{
                            if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    this.Timestamps = union.Timestamps.toString()
                            }
                        }
                        
                    })
                })
            }
        },
    }
}
</script>

<style lang="scss">
@import './giveReturnModel.scss';
</style>