<template>
  <modal class="fastYinShiScanCodePayModel" :isShow="isShow">
    <div class="header">智能POS支付
        <span class="close" @click="hide()">×</span>
    </div>
    <div class="modal-body">
        <div class="clearfix">
            <div class="left-box">
                <div class="money-info">
                    <div class="lable-txt">
                        待支付金额：
                        <b class="money red">{{Number(orderMoney).toFixed(2)}}</b>
                        <span class="edit-money" @click="isShowNumber=true">修改金额</span>
                    </div>
                    <div class="timer" v-show="timerCount>0">
                        <i class="iconfont icon-shijian"></i><span class="timer-shijian">{{timerCount}}秒</span>
                    </div>
                </div>
                <div class="tr-li flex-box" v-if="sysSetting?.RunningSmartPosSHowPaymentChannel">
                    <div class="lable-txt">选择支付方式:</div>
                    <div class="from-input">
                        <div class="payBnt" :class="{selected:item.Channel_ID==payChannel}" @click="payChannel=item.Channel_ID"
                            v-for="item in sysSetting?.SmartPosChannels" :key="item">{{item.Channel_Name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tip-box"> 
            {{errorTitle}}
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">
            <span v-show="this.ispaySuccess==false"><i class="iconfont icon-suo"></i></span>取消
        </button>
        <button class="btn btn-border" @click="select()">重新查询支付结果</button>
        <button class="btn btn-confirm" @click="confirm()">
            <span v-show="this.ispaySuccess==false"><i class="iconfont icon-suo"></i></span>提交支付
        </button>
    </div>
    <modal-load :isShow="isShowNumber">
        <number-modal :isShow="isShowNumber" :value="unPaidMoney" @close="isShowNumber=false" @confirm="moneyChange"/>
    </modal-load>
  </modal>
</template>

<script>
import {newGuid} from '/src/common' 

/**银石智能POS支付 */
export default {
    name:"scanCodePayModel",
    props:[
        'isShow',
        'orderMenuList',
        'payTypeSSPay',
        'unPaidMoney',
        'Channellike',
        'headingInfo',
        'MantissaMoney',
        'RoomAutoID',
        'DeskName',
        'paymentHistory',
        'EatAutoIDs',
        'Timestamps'
    ],
    computed:{
        //系统配置项
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
    },
    data(){
        return {
            payCode:"",//付款码
            /**是否付款成功 */
            ispaySuccess:true,
            /**变动时间戳 */
            ordertimestamps:'',
            /**返回的订单ID 查询支付结果使用 */
            eatAutoID:'',
            /**错误返回 */
            errorTitle:'',
            orderList:'',
            /**是否触发确定回车功能 */
            layer:false,
            /**默认倒计时 */
            timerCount:0,
            //付款渠道
            payChannel:null,
            /**待支付的订单金额 */
            orderMoney:0,
            //修改金额
            isShowNumber:false,
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.ispaySuccess=true;
            this.payCode='';
            this.errorTitle='';
            this.ordertimestamps='';
            this.EmptyDatas();
            this.orderMoney=this.unPaidMoney;
            if(this.sysSetting?.RunningSmartPosSHowPaymentChannel && this.sysSetting?.SmartPosChannels?.length>0){
                this.payChannel=this.sysSetting?.SmartPosChannels[0].Channel_ID;
            }
        })
    }, 
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
            if(this.isShow){
                this.ispaySuccess=true;
                this.payCode='';
                this.errorTitle='';
                this.ordertimestamps='';
                this.layer=false;
                this.EmptyDatas();
                this.orderMoney=this.unPaidMoney;
                this.payChannel=null;
                if(this.sysSetting?.RunningSmartPosSHowPaymentChannel && this.sysSetting?.SmartPosChannels?.length>0){
                    this.payChannel=this.sysSetting?.SmartPosChannels[0].Channel_ID;
                }
            }else{
                this.create();
            }
        },
        timerCount:{
            handler(value) {
                if(this._time){
                    clearTimeout(this._time);
                }
                this._time=setTimeout(() => {
                    if(this.timerCount>1){
                        this.timerCount--;
                    }else{
                        this.timerCount=0
                        this.ispaySuccess=true
                    }
                }, 1000);
            },
            immediate: true
        },
        ispaySuccess(newVal){//解锁
            if(newVal && this._timePay){
                clearTimeout(this._timePay);//清除定时器
            }
        }
    },
    methods:{
        /**安卓 扫码 */
		scanCode() {
			if (window.webview) {
				this.$webBrowser.hasCamera().then((isQr) => {
					if (isQr == "1") {
						this.$webBrowser.qr().then((d) => {
							this.payCode=d;
                            this.confirm();
						}).catch((e) => {
							this.$message({ message: "扫码失败:" + e, type: "warning" });
						});
					} 
				});
			} 
		},
        /**修改支付金额 */
        moneyChange(money){
            if(Number(money)>Number(Number(this.unPaidMoney).toFixed(2))){
                this.$message.warning("不能大于待支付金额!");
                return;
            }
            if(!(money>0)){
                this.$message.warning("金额必须大于0!");
                return;
            }
            this.orderMoney=money;
            this.isShowNumber=false;
        },
        /**默认input焦点 */
        EmptyDatas(){
            this.orderList = this.orderMenuList;
        },
        hide(){
            if(this.ispaySuccess==true){
                this.$emit('closeModel')
            }
        },
        /**查询支付结果 */
        select(){
            if(this.EatAutoIDs!=''){
                this.eatAutoID = this.EatAutoIDs
            }
            if(this.ordertimestamps==''){
                 this.ordertimestamps = this.Timestamps
            }
            if(this.eatAutoID=='' && !this.payCode){
                this.$message.error('没有需要查询的支付结果');
                return
            }
            if(this._timePay) clearTimeout(this._timePay);//清除定时器
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.eatAutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Site_Serial, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.ordertimestamps.toString(), //账单时间戳
            }
            if(!param.Eat_AutoID){//没有 结账单ID 时用支付码查询
                param.SsPay_QrCode=this.payCode;
            }
            if(this.ordertimestamps!=''){
                param.Timestamps = this.ordertimestamps.toString();
            }
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult", param).then((d)=>{
                loading.close()
                if(d.ResponseHeader.ResultCode!=0){
                    this.errorTitle = d.ResponseHeader.ResultDesc
                    if(this.timerCount<=0){
                        this.ispaySuccess=true
                    }else{
                        this.ispaySuccess=false
                    }
                    if(d.ResponseHeader.ResultCode==4003){
                        this.$message.error(d.ResponseHeader.ResultDesc);
                        this.ispaySuccess=true
                        this.timerCount=0
                        this.payCode=''
                        if(d.ResponseBody?.Timestamps){
                            this.ordertimestamps = d.ResponseBody?.Timestamps.toString();
                        }
                    }else if(d.ResponseHeader.ResultCode==4001){//重新查询
                        this.timeoutPayment();
                    }else if(d.ResponseHeader.ResultCode==-4){
                        this.ispaySuccess=true
                    }else if(d.ResponseHeader.ResultCode==3008){//已结账完成的订单，快餐异常单查询支付结果时，如果返回该状态码，说明账单已结账
                        if(d.ResponseBody?.VoiceNotify){//语音提醒内容，如果返回不为空，前端可以发起一个语音播报
                            this.$webBrowser.tts(d.ResponseBody.VoiceNotify);
                        }
                        this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                            confirmButtonText: '确定',
                            callback: () => {
                                this.ordertimestamps = d.ResponseBody.Timestamps;
                                this.ispaySuccess=true;
                                this.$emit("ThePaymentResultClic",d,this.payCode)
                            }
                        });
                        return;
                    }

                    this.$emit("ThePaymentResultTime",this.ordertimestamps)
                    return;
                }else{
                    this.errorTitle = d.ResponseHeader.ResultDesc;
                    this.$alert(d.ResponseHeader.ResultDesc, '提示', {
                        confirmButtonText: '确定',
                        callback: () => {
                            this.ordertimestamps = d.ResponseBody.Timestamps;
                            this.ispaySuccess=true;
                            this.$emit("ThePaymentResultClic",d,this.payCode)
                        }
                    });
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**确定和回车事件 */
        confirm(){
            if(Number(this.orderMoney).toFixed(2)==0){
                this.$message.error('单笔订单额度受限，建议使用其他付款方式');
                return;
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            if(this.ispaySuccess==true){
                if(this.layer==true){
                    return
                }
                if(this.sysSetting?.RunningSmartPosSHowPaymentChannel){
                    if(!this.payChannel){
                        this.$message.warning('请先选择支付方式');
                        return;
                    }
                }
                this.layer=true;
                this.timerCount=60;
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });

                let FactMoney=[];
                FactMoney.push({
                    Payment_ID:this.payTypeSSPay.PAY_ID, //支付方式ID
                    Payment_Code:this.payTypeSSPay.PAY_CODE,  //支付方式编号
                    Payment_Name:this.payTypeSSPay.PAY_NAME,  //支付方式名称
                    Payment_Type:this.payTypeSSPay.INTERFACE_TYPE,  //支付方式类型
                    Payment_Money:Number(this.orderMoney).toFixed(2), //支付方式金额
                    Payment_FactMoney:Number(this.orderMoney).toFixed(2),  //支付实收金额
                    Qr_Code:this.payCode, //扫码付款码值
                    Channel_ID:this.payChannel,//支付渠道
                    Card_No:"", //卡号
                    CouponInfo:null, //该支付金额核销的券信息
                    //聚合支付是否部分金额支付，true:部分支付false:全额支付
                    Is_PartialSsPay:Number(this.orderMoney)!=Number(Number(this.unPaidMoney).toFixed(2)),
                });
                /**结合其他付款方式 */
                if(this.paymentHistory){
                    this.paymentHistory.forEach(pay=>{
                        if(pay.is_pay==false){
                            FactMoney.push({
                                Payment_ID:pay.Payment_ID,  //支付方式ID
                                Payment_Code:pay.Payment_Code, //支付方式编号
                                Payment_Name:pay.Payment_Name, //支付方式名称
                                Payment_Type:pay.Payment_Type, //支付方式类型
                                Payment_Money: pay.Payment_Money, //支付金额
                                Payment_FactMoney: pay.Payment_FactMoney, //计营业额
                                Qr_Code:pay.Qr_Code, //扫码付款的码值
                                Card_No:pay.Card_No, //卡号
                                CouponInfo:pay.CouponInfo, //券的详情
                            })
                        }
                    })
                }       
                let _orderConsumes=this.$global.getFastOrderConsumes(this.orderList);
                
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID,  //用户id
                    Eat_AutoID:this.EatAutoID, //订单ID，当异常单重新处理时需要传入
                    Timestamps:this.Timestamps.toString(),//订单单时间戳，当异常单重新处理时需要传入
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Eat_PersonNum:this.headingInfo?.DinersNum,//人数
                    Channel_ID:this.Channellike,//订单渠道ID
                    Room_ID:this.RoomAutoID,//餐厅ID
                    Eat_DeskID:this.headingInfo?.DeskAutoID, //桌台ID
                    Eat_DeskName:this.DeskName, //桌台名称
                    Eat_TakeNo:this.headingInfo?.brandNumber,//牌号
                    Eat_AllRemark:'', //订单全单备注
                    Order_Remark:this.headingInfo.orderRemark,//订单备注
                    Order_BatchNo:newGuid(),//下单批次号，每次提交下单，调用方生成一个唯一批次号，接口判断防止重复下单
                    NotSendToKitPrint:false, //下单不送打厨房
                    MantissaAdjustment_Money: Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                    //Member:'',//会员信息
                    OrderConsumes:_orderConsumes, //订单消费详细
                    Payments:FactMoney,//支付方式
                }
                if(this.eatAutoID){
                    param.Eat_AutoID = this.eatAutoID
                }
                if(this.EatAutoIDs && this.eatAutoID==''){
                    param.Eat_AutoID = this.EatAutoIDs,
                    param.Timestamps = this.Timestamps
                }
                this.$httpAES.requestConfig({
                    timeout:8*1000//设置8秒钟 超时时间
                }).post("Bestech.CloudPos.SnackPayPreview", param).then((d)=>{
                    this.layer=false;
                    if(d.ResponseBody?.Timestamps){
                        this.ordertimestamps = d.ResponseBody?.Timestamps.toString();
                        this.eatAutoID = d.ResponseBody?.Eat_AutoID
                    }
                    if(d.ResponseHeader.ResultCode!=0){
                        loading.close()
                        let _error = d.ResponseHeader.ResultDesc
                        if(d.ResponseHeader.ResultDesc=='\r\n不支持的支付方式：,类型：0'){
                            _error ='该门店不支持聚合支付,请去后台设置相关功能'
                            this.timerCount=0
                        }
                        if(d.ResponseHeader.ResultCode==4001){
                            if(d.ResponseHeader.ResultDesc.indexOf("密码")>=0 && this.$cacheData.globalVariable?.GlobalSysSetting?.SilverStone_SsPay_NeedPwdVoice){//语音播报 请输入支付密码
                                this.$webBrowser.tts("请输入支付密码");
                            }
                            this.ispaySuccess=false;
                            this.timeoutPayment();
                        }
                        if(d.ResponseHeader.ResultCode==4003){
                            this.ispaySuccess=true
                            this.payCode=''
                            this.timerCount=0
                        }
                        if(d.ResponseBody?.Payments){
                            this.$emit('paymentHistoryDelete',d.ResponseBody?.Payments)
                        }
                        this.errorTitle=_error;
                        //this.$message.error(_error);
                        return;
                    } else {
                        
                        //是否需要调用提交接口 0不需要，1需要
                        if(d.ResponseBody.Is_NeedSubmit==1){
                            this.PaySubmit(d)
                        }else{
                            loading.close();
                            if(!d.ResponseBody?.IsMultipleCardPay){
                                if(d.ResponseBody?.PrintsInfo){
                                    this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                                }
                                this.$message.success({ message: "结账完成"});
                                this.$emit('scanPaySuccessConfirm')
                            }else{
                                this.$emit('scanPaySuccessConfirm',d.ResponseBody);
                            }
                        }

                        if(d.ResponseBody?.OpenCashBox){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        if(d.ResponseBody?.VoiceNotify){//语音提醒内容，如果返回不为空，前端可以发起一个语音播报
                            this.$webBrowser.tts(d.ResponseBody.VoiceNotify);
                        }
                        return;
                    }
                }).catch((e)=>{
                    loading.close()
                    this.timerCount=0
                    this.layer=false
                    this.$alert(e.message, "提示", {
                        confirmButtonText: "确定",
                        callback:()=>{
                            if(e.message=="请求超时，请稍后再试或检查当前网络状态"){//如果接口请求超时时 除非重新掉一次 查询支付结果
                                this.select();
                            }
                        }
                    });
                });
            }
        },
        /**支付提交接口 */
        PaySubmit(data){
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
             let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: data.ResponseBody?.Eat_AutoID,//结账单ID
                Member_Type:data.ResponseBody?.Member_Type,  //会员类型
                Biz_ID:data.ResponseBody?.Biz_ID, //业务交易流
                 MantissaAdjustMoney:data.ResponseBody?.MantissaAdjustMoney,//尾数调整金额
                Verify_Code:'', //验证码
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Timestamps:data.ResponseBody?.Timestamps.toString(),
                Payments:data.ResponseBody?.Payments
            }
            console.log('支付提交:'+JSON.stringify(param))
            this.$httpAES.post("Bestech.CloudPos.SnackPaySubmit", param).then((d)=>{
                loading.close()
                console.log('支付提交返回:'+JSON.stringify(d))
                if(d.ResponseHeader.ResultCode!=0){
                    if(d.ResponseHeader.ResultCode>0){
                        this.errorTitle = d.ResponseHeader.ResultDesc
                        this.ispaySuccess=false;
                        return;
                    }
                    this.$message.error(d.ResponseHeader.ResultDesc);
                    return;
                }else
                {
                    if(d.ResponseBody?.PrintsInfo){
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                        },100);
                    }
                    if(d.ResponseBody?.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    this.$message.success({ message: "结账完成"});
                    this.$emit('scanPaySuccessConfirm')
                    return;
                }
            }).catch((e)=>{
                loading.close()
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**定时查看结果 */
        timeoutPayment(){
            if(this._timePay) clearTimeout(this._timePay);
            this._timePay=setTimeout(()=>{
                if(!this.ispaySuccess){
                    this.select();
                }
            },5000);
        },
        create(){
            if(this._timePay) clearTimeout(this._timePay);
            if(this._time) clearTimeout(this._time);
        }
    },
    unmounted(){
        this.create();
    }
}
</script>

<style lang="sass">
@import "./fastYinShiScanCodePayModel.scss";
</style>