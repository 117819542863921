	<template>
	<modal class="makeTableModel" :isShow="isMakeShow">
			<div class="header" @click="posShow=false">
				<span>预订开台操作</span>
			</div>
			<div class="Popup-Basic" @click="posShow=false" v-if="PosDesks.ReserveDeskInfo">
			<div class="tr-li" style="height:60px;float:left;">
				<div class="tdTile">
					<i class="iconfont icon-zhuotai"></i>
					<div class="lable-txt">桌号</div>
				</div>
				<div class="Title-5">
					<div class="lable-txt">{{PosDesks.Desk_Name}}</div>
				</div>
			</div>
			<div class="tr-li" style="height:60px;float:right;padding-right:20px;">
				<div class="icotd"  style="width:80px;" @click="deleteClick(2)">
					<i class="iconfont icon-quxiaodingdan" style="padding-left:20px;"></i>
					<div class="lable-txt">取消本台预订</div>
				</div>
			</div>
			<div class="tr-li" style="height:60px;float:right;">
				<div class="icotd" @click="deleteClick(1)">
					<i class="iconfont icon-shanchu" style="padding-left:8px;"></i>
					<div class="lable-txt">删除订单</div>
				</div>
			</div>
			<div style="padding-top:1px;padding-bottom:20px;">
				<hr size=1 style="color:#b1afaf;border-style:dashed;width:94%;">
			</div>
			<div class="tr-li">
				<div class="td-5">
					<div class="lable-txt">预订单号:</div>
					<div class="from-input"  @click.stop="posShow=!posShow">
						<input type="text" placeholder="" ref="input" readonly v-model="PosDesks.ReserveDeskInfo.Reserve_OrderNo" />
						<i class="iconfont icon-icon_chakandingdan"></i>
					</div>
				</div>
				<div class="td-5">
					<div class="lable-txt">预订电话:</div>
					<div class="from-input">
						<input type="text" placeholder="" ref="input" readonly v-model="Mobile" />
					</div>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-5">
					<div class="lable-txt">宾客姓名:</div>
					<div class="from-input">
						<input type="text" placeholder="" ref="input" readonly v-model="PosDesks.ReserveDeskInfo.Cstm_Name" />
					</div>
				</div>
				<div class="td-5">
					<div class="lable-txt">性别:</div>
					<div class="from-input">
						<input type="text" placeholder="" ref="input" readonly v-model="PosDesks.ReserveDeskInfo.Cstm_Sex" />
					</div>
				</div>
			</div>
			<div style="padding-top:1px;padding-bottom:20px;">
				<hr size=1 style="color:#b1afaf;border-style:dashed;width:94%;">
			</div>
			<div class="tr-li">
				<div class="td-5">
					<div class="lable-txt">就餐人数:</div>
					<!--@click="waiSaleClick(1)"-->
					<div class="from-input">
						<input type="text" placeholder="请输入就餐人数" ref="input"  class="key-focus" data-model="DinersNum" v-model="DinersNum" oninput="value=value.replace(/[^\d]/g,'')"/>
					</div>
				</div>
				<div class="td-5">
					<div class="lable-txt">销售员:</div>
					<div class="from-input">
						<input type="text" placeholder="请选择销售员" v-model="SalesValue" maxlength="15"/>
						<i class="iconfont icon-kehu"  @click="waiSaleClick(2)"></i>
					</div>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-5">
					<div class="lable-txt">消费类型:</div>
					<div class="from-input" @click="consumeTypeClick()">
						<input type="text" placeholder="请选择" v-model="consumeAutoIDName" readonly />
						<i class="iconfont icon-gengduo"></i>
					</div>
				</div>
				<div class="td-5">
					<div class="lable-txt">订单渠道:</div>
					<div class="from-input" @click="orderChannelClick()">
						<input type="text" placeholder="请选择" v-model="orderChannelName" readonly />
						<i class="iconfont icon-yewuliucheng"></i>
					</div>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-6">
					<div class="lable-txt">备注:</div>
					<div class="from-input">
						<el-input cols="99" rows="3" type="textarea" v-model="orderRemark"></el-input>
					</div>
				</div>
			</div>
			</div>
			<div class="Popup-Bsic-bottom" @click="posShow=false" >
				<button class="Popup-Basic-left-bottom" @click="CloseClicks()" >取消</button>
				<button class="Popup-Basic-left-bottom" @click="UnscheduledClick()" >非预订开台</button>
				<button class="Popup-Basic-right-bottom" @click="SetDeskClick()">确定</button>
			</div>
			<div class="comment" v-if="posShow==true">
				<div class="title">
					<td style="width:150px;padding-top:5px;font-size:12px;" >名称</td>
					<td class="td-content">数量</td>
					<td class="td-content">单价</td>
					<td class="td-content">小计</td>
				</div>
				<div class="scroll" v-mouse-scroll ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper',paddingH:'25'}">
					<div class="slide-wrapper" style="padding-top:10px;" v-for="item in PosSubOrder" :key="item">
						<div class="posGeneral">
						{{item.SubCategory_Name}}共{{item.ConsumeList.length}}份
						</div>
						<div style="height:10px;"></div>
						<div class="posStyle" v-for="consume in item.ConsumeList" :key="consume">
							<tr>
								<td class="td-name">{{consume.EAT_XFName}}</td>
								<td class="td-content">{{consume.Eat_Number}}</td>
								<td class="td-content">¥{{consume.Eat_SalePrice}}/份</td>
								<td class="td-content">{{consume.Eat_Number * consume.Eat_SalePrice }}</td>
							</tr>
							<hr size=1 style="color:#b1afaf;border-style:outset;margin-left:-15px;">
						</div>
					</div>
				</div>
				<div class="footer orderFooter">
					<div class="float-left txt">
						<span class="money" v-html="consumeMoney"></span>&nbsp;
						<span class="small">共{{consumeNumber}}道 &nbsp; {{foodNumber}}份</span>
					</div>
					<div class="float-right" @mousedown="(e)=>{e.preventDefault()}">
						<div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiangquan-bottom"></i></div>
						<div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiangquan-top"></i></div>
					</div>
				</div>
			</div>
		<!--选择服务员或销售员-->
		<modal-load :isShow="waiSaleShow">
			<waiter-Sales-Model :isShow="waiSaleShow" v-on:closeModel="waiSaleShow=false" :WaiterList="WaiterList" :SalesList="SalesList" :waiSaleType="waiSaleType" @waiSaleConfirm="waiSaleConfirm" ></waiter-Sales-Model>
		</modal-load>
		<!--订单渠道-->
		<modal-load :isShow="orderChanneShow">
			<order-Channel-Model :isShow="orderChanneShow" v-on:closeModel="orderChanneShow=false" :Openorderclass="orderclass" :OrderList="OrderList" :orderChannel="orderChannel" @orderChanneConfirm="orderChanneConfirm" ></order-Channel-Model>
		</modal-load>
		<!--消费渠道-->
		<modal-load :isShow="consumeTypeShow">
			<consume-Types-Model :isShow="consumeTypeShow" v-on:closeModel="consumeTypeShow=false" :consumeTypes="consumeTypes" :ConsumptionList="ConsumptionList" @consumeTypeConfirm="consumeTypeConfirm"></consume-Types-Model>
		</modal-load>
		</modal>
	</template>

<script>
import waiterSalesModel from '../waiterSalesModel/waiterSalesModel.vue'
import orderChannelModel from '../orderChannelModel/orderChannelModel.vue'
import consumeTypesModel from '../consumeTypesModel/consumeTypesModel.vue'
import {initKeyBoard } from "/src/common";
import modal from "../../../../template/modal/modal";
import { httpAES } from '/src/services';
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

export default {
	name: 'makeTableModel',
	components:{
		modal,
		waiterSalesModel,
		orderChannelModel,
		consumeTypesModel
	},
	data() {
		return {
			waiSaleShow:false,
			orderChanneShow:false,
			consumeTypeShow:false,
			consumeTypes:'',
			DinersNum:'', //就餐人数
			orderRemark:'',//订单备注
			OrderList: [],
			orderChannel:'',//订单渠道,默认是0线下门店固定POS
			orderChannelName:'',
			/**销售员 */
			SalesList:[],
			SalesValue:'',//销售员
			ConsumptionList:[],
			ConsumptionType:'0', //消费类型
			/**服务员 */
			WaiterList:[],
			Waiter:'',//服务员
			waiSaleType:0, //1.选择的服务员 2.选择的销售员
			orderclass:'', //订单选择
			consumeAutoID:'', //消费选择
			consumeAutoIDName:'',
			posShow:false, //查看预定菜品
			PosDesks:'',
			PosSubOrder:[], //菜品大类
			consumeMoney:0,  //菜品总价
			consumeNumber:0, //菜品总道数
			foodNumber:0, //菜的总份数
			Mobile:0,
		}
	},
	props:{
		isMakeShow:Boolean,
		choicePosDesks:Object,
		/**传入桌台销售员数据 */
		Reserve_Operator:{
			typeof:String,
			default:''
		},
	},
	mounted(){
		this.$nextTick(() => {
			setTimeout(() => {
				this.$refs.input.focus();
			},100);
			
			initKeyBoard.call(this, this.$el);
			initKeyBoard.call(this,this.$refs.zf);
			this.EmptyDatas();
		});
	},
	watch: {
		//用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
		isMakeShow() { 
			if(this.isMakeShow==true){
				this.EmptyDatas();
				setTimeout(() => {
					this.$refs.input.focus();
				},100);
			}
		},
		DinersNum(){
			if(Number(this.DinersNum)>100000000){
				this.$message.warning('超过就餐人数的最大限制')
				this.DinersNum=''
				return
			}
		},
	},
	methods:{
		/**默认input焦点 */
		EmptyDatas(){
			this.PosDesks = this.choicePosDesks
			this.Mobile = this.PosDesks?.ReserveDeskInfo?.Mobile.replace(/^(.{3}).*(.{4})$/,'$1****$2')
			//this.PosDesks?.ReserveDeskInfo?.Mobile =this.PosDesks?.ReserveDeskInfo?.Mobile.replace(/^(.{3}).*(.{4})$/,'$1****$2')
			this.PosSubOrder=[],this.consumeMoney=0,this.consumeNumber=0,this.foodNumber=0
			/**菜品展示 */
			if(this.PosDesks.ReserveDeskInfo.ReserveOrderConsumes){
				this.PosDesks.ReserveDeskInfo.ReserveOrderConsumes.map(re=>{
					let order={SubCategory_ID:re.SubCategory_ID,SubCategory_Code:re.SubCategory_Code,SubCategory_Name:re.SubCategory_Name,ConsumeList:[]}
					this.PosSubOrder.push(order)
				})
				this.PosSubOrder = this.uniqueorder(this.PosSubOrder)
				this.PosSubOrder.map(order=>{
					this.PosDesks.ReserveDeskInfo.ReserveOrderConsumes.map(re=>{
						if(order.SubCategory_ID==re.SubCategory_ID){
							order.ConsumeList.push(re)
							this.consumeMoney = this.consumeMoney + (re.Eat_Number * re.Eat_SalePrice)
							this.consumeNumber++
							this.foodNumber = this.foodNumber+re.Eat_Number
						}
					})
				})
			}
			this.empty()
			this.DinersNum = this.PosDesks.ReserveDeskInfo.Reserve_PersonNum
			this.orderRemark = this.PosDesks.ReserveDeskInfo.Reserve_Remark
			this.SalesValue=this.Reserve_Operator;
			/**服务员*/
			this.WaiterList=[]
			this.SalesList=[]
			this.$cacheData.PosWaiters().then((d)=>{
				d?.map(item=>{
					this.WaiterList.push(item);
				})
			}).catch(()=>{
				this.$alert('暂无服务员', "提示", {confirmButtonText: "确定"});
			})
			/**销售员*/
			this.$cacheData.PosSellers().then((d)=>{
				d?.map(item=>{
					this.SalesList.push(item);
				})
			}).catch(()=>{
				this.$alert('暂无销售员', "提示", {confirmButtonText: "确定"});
			})
			let userInfo=this.$auth.getUserInfo(); //获取登录信息
			/**订单渠道 */
			this.orderclass=''
			this.OrderList=[]
			if(this.orderInfo){
				this.orderclass = this.orderInfo.CHANNEL_ID
				this.consumeAutoID = this.orderInfo.Consume_TypeID
			}
			this.$cacheData.OrderChannel().then((d)=>{
				this.orderChannel = d;
				this.orderChannel?.map(order=>{
					order.OrderChannelList.map(item=>{
					if(this.orderclass==''){
						if(item.OrderChannel_AutoID==userInfo?.Default_ChannelID){
							this.orderChannelName = item.OrderChannel_Name
							this.orderclass = item.OrderChannel_AutoID
						}
					}else{
						if(item.OrderChannel_AutoID==this.orderclass){
							this.orderChannelName = item.OrderChannel_Name
							this.orderclass = item.OrderChannel_AutoID
						}
					}
					item = Object.assign(item, {is_type:0,Kind_AutoID:order.OrderChannelKind_AutoID})
						this.OrderList.push(item)
					})
				})
			}).catch(()=>{
				this.$alert('暂无订单渠道', "提示", {confirmButtonText: "确定"});
			})
			/**消费类型*/
			this.OrderList = this.unique(this.OrderList);
			this.ConsumptionList=[]
			this.$cacheData.ConsumeTypes().then((d)=>{
				this.consumeTypes=d;
				this.consumeTypes?.map(consu=>{
					consu.ConsumeTypeList.map(item=>{
						if(this.consumeAutoID==item.ConsumeType_AutoID){
						this.consumeAutoID = item.ConsumeType_AutoID
						this.consumeAutoIDName = item.ConsumeType_Name
						}
						item = Object.assign(item, {is_type:0,Kind_AutoID:consu.ConsumeTypeKind_AutoID})
						this.ConsumptionList.push(item)
					})
				})
			}).catch(()=>{
				this.$alert('暂无消费类型', "提示", {confirmButtonText: "确定"});
			})
		},
		//关闭弹出框
		CloseClicks(){
			this.$emit("closeModel");
		},
		/**服务员或销售员点击 */
		waiSaleClick(type){
			this.waiSaleType = type
			this.waiSaleShow=true
		},
		/**服务员或销售返回 */
		waiSaleConfirm(seleUser){
			if(this.waiSaleType==1){
				this.Waiter = seleUser.User_Name
			}else{
				this.SalesValue = seleUser.User_Name
			}
			this.waiSaleShow=false
		},
		/**订单渠道点击 */
		orderChannelClick(){
			if(this.orderChanneShow==false){
				this.orderChanneShow =true
			}else{
				this.orderChanneShow =false
			}
		},
		/**订单渠道返回 */
		orderChanneConfirm(item){
			this.orderclass = item.OrderChannel_AutoID
			this.orderChannelName = item.OrderChannel_Name
			this.orderChanneShow =false
		},
		/**消费渠道点击 */
		consumeTypeClick(){
			if(this.consumeTypeShow==false){
				this.consumeTypeShow =true
			}else{
				this.consumeTypeShow =false
			}
		},
		/**消费渠道返回*/
		consumeTypeConfirm(item){
			this.consumeAutoID = item.ConsumeType_AutoID
			this.consumeAutoIDName = item.ConsumeType_Name
			this.consumeTypeShow =false
		},
		//提交开台数据
		SetDeskClick(){
			if(Number.isInteger(Number(this.DinersNum))==false){
				this.$message.error('人数不能为小数');
				return
			}
			let userInfo=this.$auth.getUserInfo(); //获取登录信息
			let param ={
				Body_ID:newGuid(), //BodyID
				User_ID:userInfo?.User_ID,
				Eat_DeskAutoID:this.PosDesks.Desk_AutoID, //桌台id
				Operator_Name:userInfo?.Login_Name, //操作员
				Eat_PersonNum:Number(this.DinersNum), //就餐人数
				Eat_Waiter:this.Waiter, //服务员
				Consume_TypeID:this.consumeAutoID, //消费类型
				Eat_Saler:this.SalesValue, //销售人员
				Order_Type:0, //订单类型
				Order_Source:1, //下单方式
				Order_ChannelID:this.orderclass, //订单渠道
				Order_Remark:this.orderRemark, //订单备注
				OpenDeskKind:1 //0:普通开台 1:预定开台
			}
			this.SetDeskMethod(param);
		},
		SetDeskMethod(param){
			const loading = this.$loading({
				text: "数据传输中",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			httpAES.post("Bestech.CloudPos.OpenDesk", param).then((d)=>{
				loading.close();
				if(d.ResponseHeader.ResultCode!=0){
					console.log('d:'+JSON.stringify(d));
					this.$message.error(d.ResponseHeader.ResultDesc);
					return;
				}else{
					let OpenDeskInfo =d.ResponseBody;
					let _OrderConsumes=''
					if(d.ResponseBody?.OrderConsumes){
					_OrderConsumes = d.ResponseBody?.OrderConsumes
					}
					this.$emit("SetOpenDesk",this.PosDesks.Desk_AutoID,1,OpenDeskInfo,_OrderConsumes);
					return;
				}
			}).catch((e)=>{
				loading.close();
				this.$alert(e.message, "提示", {confirmButtonText: "确定"});
			});
		},
		/**删除订单 */
		deleteClick(type){
			let userInfo=this.$auth.getUserInfo(); //获取登录信息
			let param={
				Body_ID:newGuid(), //BodyID
				User_ID:userInfo?.User_ID,
				Desk_AutoID:this.PosDesks.Desk_AutoID, //桌台id
				Operator_Name:userInfo?.Login_Name, //操作员
				Operate_Kind:type, //1.删除预订单 2.取消本桌预定
				Timestamps:this.PosDesks.Timestamps, //桌台时间戳
			}
			const loading = this.$loading({
				text: "数据传输中",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			httpAES.post("Bestech.CloudPos.ReserveDeskOperate", param).then((d)=>{
					loading.close();
				if(d.ResponseHeader.ResultCode!=0){
					console.log('d:'+JSON.stringify(d));
					this.$message.error(d.ResponseHeader.ResultDesc);
					return;
				}else{
					this.$emit("deleteCloseClick",this.PosDesks.Desk_AutoID); //关闭开台弹窗
					return;
				}
			}).catch((e)=>{
				loading.close();
				this.$alert(e.message, "提示", {confirmButtonText: "确定"});
			});
		},
		/**清空 */
		empty(){
			this.DinersNum='',this.SeatNumber=1,this.Waiter='',this.SalesValue='',this.consumeAutoIDName='',this.consumeAutoID=''
			this.orderChannelName = '',this.orderclass ='',this.orderRemark=''
		},
		/**去除重复 */
		unique(newarr) {
			const res = new Map();
			return newarr.filter((newarr) => !res.has(newarr.OrderChannel_AutoID) && res.set(newarr.OrderChannel_AutoID, 1));
		},
		/**去除重复 */
		uniqueorder(newarr) {
			const res = new Map();
			return newarr.filter((newarr) => !res.has(newarr.SubCategory_ID) && res.set(newarr.SubCategory_ID, 1));
		},
		/**非预定 */
		UnscheduledClick(){
			this.$emit("UnscheduledClick",this.PosDesks);
		}
	}
}
</script>
<style lang="scss">
@import "./makeTableModel.scss";
</style>