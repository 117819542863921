<template>
    <modal class="payRemarkModel" :isShow="isShow" :isMask="true" @click="hide()">
        <div class="header">付款结算备注<i class="close iconfont icon-a-guanbi11" @click="hide()"></i></div>
        <div class="modal-body">
            <textarea  class="text" type="text" v-model="Title" placeholder="最多支持可输入50个字符"></textarea>
        </div>
        <div class="footer-box">
            <button class="btn btn-confirm" @click="updateTitle()">确定</button>
        </div>
    </modal>
</template>
  
<script>
export default {
    components: { },
    name:'payRemarkModel',
    props:{
        isShow:Boolean,
        /**传入账单数据 */
        RemarkTitle:String
    },
    data(){
        return{
            Title:''
        }
    },
    watch:{
        isShow(value){
            if(value==true){//显示弹层时 初始化数据
                this.loadData();
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.userInfo = this.$auth.getUserInfo(); //获取用户id
            this.loadData();
        })
    },
    methods:{
        /**加载桌台*/
        loadData(){
            this.Title=this.RemarkTitle
        },
        updateTitle(){
            const chineseCount = (this.Title.match(/[\u4e00-\u9fa5]/g) || []).length;
            const englishCount = this.Title.length - chineseCount;
            if((chineseCount * 2 + englishCount)>50)
            {
                this.$message.error('最多不能超过50个字符！');
                return ;
            }
            this.$emit('payRemarkconfirm', this.Title);
        },
        hide(){
            this.$emit("closeModel");
        }
    }
}
</script>
<style lang="scss">
    @import "./payRemarkModel.scss"
</style>