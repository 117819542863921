<template>
  <modal class="discountModel" :isShow="isShow" v-if="isShow">
      <div class="header">折扣操作</div>
        <div class="modal-body">
            <div class="filter-nav">
                <div class="nav-box">
                    <div class="nav-li" :class="{selected:discountType==1}" @click="onclickZk(1)">折扣方案</div>
                    <div class="nav-li" :class="{selected:discountType==2}" @click="onclickZk(2)">全单折扣</div>
                    <div class="nav-li" :class="{selected:discountType==4}" @click="onclickZk(4)">单品折扣方案</div>
                    <div class="nav-li" :class="{selected:discountType==5}" @click="onclickZk(5)">金额折扣类型</div>
                    <div class="nav-li" :class="{selected:discountType==3}" @click="onclickZk(3)">折扣率折扣类型</div>
                </div>
            </div>
            <div class="clearfix">
                <div class="tab-content">
                    <div class="tab-pane" v-show="discountType==1">
                        <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',isRow:false}">
                            <div class="item-li category" :class="{selected:item?.DiscountScheme_Code==discountScheme?.DiscountScheme_Code}" v-for="item in memberDiscountSchemeList?.DiscountScheme_PlanList" :key="item" @click="onclickDiscountScheme(item)">
                                <div class="category-title" >{{item.DiscountScheme_Name}}</div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="item-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="item-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" v-show="discountType==2">
                        <div class="item-box" v-scroll-anime="{up:'item-up',down:'item-down',liClass:'.item-li',isRow:false}">
                            <div class="item-li category" :class="{selected:item?.DiscountScheme_Code==discountAll?.DiscountScheme_Code}" v-for="item in memberDiscountSchemeList?.DiscountScheme_RateList" :key="item" @click="onClickDisountAll(item)">
                                <div class="category-title" >{{item.DiscountScheme_Name}}</div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="item-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="item-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane clearfix" ref="zkSingleEat" v-show="discountType==4">
                        <div class="zksinlge-box">
                            <table>
                                <thead>
                                    <tr>
                                        <th style="width:110px;background-color:#ddd;border-radius:20px 0px 0px 0px">选择</th>
                                        <th style="background-color:#ddd;">方案名称</th>
                                        <th style="width:140px;background-color:#ddd;border-radius:0px 20px 0px 0px">折扣率%</th>
                                    </tr>
                                </thead>
                            </table>
                            <div class="table-body" v-scroll-anime="{up:'check-up',down:'check-down',liClass:'.checkbox',isRow:false}">
                                <table>
                                    <tbody>
                                        <tr style="height:0">
                                            <td style="width:110px;height:0"></td>
                                            <td style="height:0"></td>
                                            <td style="width:140px;height:0"></td>
                                        </tr>
                                        <tr style="border-bottom: 1px solid #ddd;" v-for="item in memberDiscountSchemeList?.DiscountScheme_SingleRateList" :key="item" :class="{selected:singledisRadioclass==item.DiscountScheme_AutoID}" @click="SingleradioClick(item)">
                                            <td>
                                                <label class="checkbox"><input type="radio" name="_fa_radio" :checked="singledisRadioclass==item.DiscountScheme_AutoID"  @click.stop="SingleradioClick(item)" />
                                                <i class="iconfont icon-iconoption"></i></label>
                                            </td>
                                            <td class="alignLeft">{{item.DiscountScheme_Code}}  {{item.DiscountScheme_Name}}</td>
                                            <td>{{item.DiscountScheme_ZkRate}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="zksinlge-item-operation">
                            <div class="lable">
                                折扣率：
                                <span class="money" v-if="singleeatDiscountType?.DiscountScheme_ZkRate" >{{singleeatDiscountType?.DiscountScheme_ZkRate}}%</span>
                                <span v-else>0%</span>
                            </div>
                            <div class="lable">
                                <span class="red">*</span>
                                折扣菜品
                                <el-select v-model="selectedOption" placeholder="请选择">
                                    <el-option v-for="option in filteredOrderMenuList" :key="option.Eat_ConsumeID" :label="option.EAT_XFName" :value="option.Eat_ConsumeID"></el-option>
                                </el-select>
                            </div>
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="check-up" ><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="check-down" ><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane clearfix" ref="zkMoney" v-show="discountType==5">
                        <div class="left-box">
                            <div class="table-box">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style="width:110px;background-color:#ddd;">选择</th>
                                            <th style="background-color:#ddd;">方案名称</th>
                                            <th style="width:140px;background-color:#ddd;">折扣金额（元）</th>
                                        </tr>
                                    </thead>
                                </table>
                                <div class="table-body" v-scroll-anime="{up:'check-up',down:'check-down',liClass:'.checkbox',isRow:false}">
                                    <table>
                                        <tbody>
                                            <tr style="height:0">
                                                <td style="width:110px;height:0"></td>
                                                <td style="height:0"></td>
                                                <td style="width:140px;height:0"></td>
                                            </tr>
                                            <tr style="border-bottom: 1px solid #ddd;" v-for="item in memberDiscountSchemeList?.DiscountScheme_MoneyList" :key="item" :class="{selected:disRadioclass==item.DiscountScheme_AutoID}" @click="radioClick(item)">
                                                <td>
                                                    <label class="checkbox"><input type="radio" name="_fa_radio" :checked="disRadioclass==item.DiscountScheme_AutoID"  @click.stop="radioClick(item)" />
                                                    <i class="iconfont icon-iconoption"></i></label>
                                                </td>
                                                <td class="alignLeft">{{item.DiscountScheme_Code}}  {{item.DiscountScheme_Name}}</td>
                                                <td>{{item.DiscountScheme_ZkMoney}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="item-operation">
                                <div class="lable">
                                    最大折扣金额：
                                    <span class="money" v-if="moneyDiscountType?.DiscountScheme_ZkMoney" >{{moneyDiscountType?.DiscountScheme_ZkMoney}}</span>
                                    <span v-else>0.00</span>
                                </div>
                                <div class="lable">
                                    输入折扣金额：<input type="text" ref="money" data-model='money'  v-model="money"  />
                                </div>
                                <div class="float-right">
                                    <div class="bnt-fangxiang" ref="check-up" ><i class="iconfont icon-fangxiang-up"></i></div>
                                    <div class="bnt-fangxiang" ref="check-down" ><i class="iconfont icon-fangxiang-down"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="right-box">
                            <div class="keyboard">
                                <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                        data-value="9">9</span></div>
                                <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                        data-value="6">6</span></div>
                                <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                        data-value="3">3</span></div>
                                <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                        data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane zk-box" v-show="discountType==3">
                        <div ref="zk">
                            <div class="left-box">
                                <div class="title">全单折扣</div>
                                <div class="tr-li">
                                    <div class="lable-txt">可折总额：</div>
                                    <div class="from-input" style="color: #F52E2E;">{{discountMoney.toFixed(2)}}</div>
                                </div>
                                <div class="tr-li">
                                    <div class="lable-txt" style="font-size:16px;position:relative;right:20px;width:80px;">折扣率%：</div>
                                    <div class="from-input" style="position:relative;right:20px;">
                                        <input type="text" ref="input" data-model='per'  v-model="per" />
                                    </div>
                                    <div class="from-btn" v-if="isreadCardBtn">
                                        <button class="btn" @click="readCard()">会员折扣</button>
                                    </div>
                                </div>
                                <div class="tr-li">
                                    <div class="lable-txt"></div>
                                    <div class="from-input">
                                        <span style="color:red;font-size:17px;">提示: 输入80代表八折</span>
                                    </div>
                                </div>
                            </div>
                            <div class="right-box">
                                <div class="keyboard">
                                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                            data-value="9">9</span></div>
                                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                            data-value="6">6</span></div>
                                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                            data-value="3">3</span></div>
                                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tip-box">
                    <!--提示：此处显示提示文本，没有提示则不显示-->
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">退出</button>
            <button class="btn btn-cancel" @click="cancelClick()">取消折扣</button>
            <button class="btn btn-cancel" @click="changeUser()">变更用户</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <!--权限控制-->
        <modal-load :isShow="checkShow" :isUnload="true">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="discounCheck" @checkbtnReturn="checkbtnReturn">
            </check-user-button-model>
        </modal-load>
        <modal-load :isShow="isshowCard" :isUnload="true">
            <discount-read-card-Model :isShow="isshowCard" v-on:closeModel="isshowCard=false" @MemberCardZKReturn="MemberCardZKReturn">
            </discount-read-card-Model>
        </modal-load>
  </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 
import { newGuid } from '/src/common/index.js'
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'

import discountReadCardModel from '../discountReadCardModel/discountReadCardModel.vue'

export default {
    name:'discountModel',
    props:{
        isShow:Boolean,
        discountSchemeList:Object,
        orderInfo:Object,
        orderMenuList:Object,
        discounCheck:Object,
        isCheckOut:Boolean,
        unionType:Boolean,
        disRadioOrder:Object,
        Running_ReadCardToDiscount_YN:Boolean
    },
    components:{
        checkUserButtonModel,
        discountReadCardModel
    },
    data(){
        return {
            /**权限按钮 */
            checkShow:false,
            /**折扣率 */
            per:'',
            /**折扣金额 */
            money:'',
            disRadioclass:'',
            /**选中的折扣方案 */
            discountScheme:'',
            /**全单折扣 */
            discountAll:null,
            /**选中的金额折扣类型 */
            moneyDiscountType:'',
            /**折扣类型 */
            discountType:1,
            /**可折总额 */
            discountMoney:0,
            //临时折扣数据
            memberDiscountSchemeList:'',
            /**权限验证临时保存ID和操作员 */
            userInfo:'',
            tempUserId:'',
            tempOperatorName:'',
            //单品折扣
            singledisRadioclass:'',
            singleeatDiscountType:'',
            selectedOption: '', // 用于存储选择的值
            isreadCardBtn:false,
            isshowCard:false,

            //会员全单折扣
            Member_Sys:0,
            Member_CardNo:'',
            Mobile:'',
            Member_Cardkind:'',
        }
    },
    computed:{
        filteredOrderMenuList() {
            // 根据条件动态过滤选项
            let list=[];
            this.orderMenuList?.forEach(option => {
                if(option.Food_Type!=4){
                    if(option.Eat_Number>0){
                        list.push(option);
                    }
                    option.Eat_ComboItemList?.forEach(it=>{
                        if(it.Eat_Number>0){
                            list.push(it);
                        }
                    })
                }
            });

            return list;
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            initKeyBoard.call(this,this.$refs.zkMoney,this.$refs.money);
            initKeyBoard.call(this,this.$refs.zk,this.$refs.input);
            initKeyBoard.call(this,this.$refs.zkSingleEat);
            this.discountMoney=0,this.discountScheme='',this.per='',this.money='',this.discountType=1;this.discountAll=null;
            this.moneyDiscountType='',this.disRadioclass='',this.tempUserId='',this.tempOperatorName=''
            this.singledisRadioclass='',this.selectedOption='',this.singleeatDiscountType=''
            this.memberDiscountSchemeList = JSON.parse(JSON.stringify(this.discountSchemeList||null))
            this.isreadCardBtn=this.Running_ReadCardToDiscount_YN;
            this.orderMenuList.forEach(item=>{
                if(item.Is_Discounted==true && item.Eat_Number>0){
                    if(item.Eat_CheckMoney!=null){
                        this.discountMoney += Number(item.Eat_CheckMoney);
                    }
                }
                
            })
            if(sessionStorage.getItem("tempUserID")){
                let _date={User_ID:sessionStorage.getItem("tempUserID"),Login_Name:sessionStorage.getItem("tempName")}
                this.checkbtnReturn(_date)
            }
            if(this.disRadioOrder){
                this.filteredOrderMenuList.map(item=>{
                    if(item.Eat_ConsumeID==this.disRadioOrder?.Eat_ConsumeID){
                        this.selectedOption=this.disRadioOrder?.Eat_ConsumeID
                        return
                    }
                })
            }
            
            console.log(this.orderMenuList)
        })
    },
    watch:{
        isShow(){
            if(this.isShow==true){
                this.discountScheme='',this.per='',this.money='',this.discountType=1
                this.moneyDiscountType='',this.disRadioclass='',this.tempUserId='',this.tempOperatorName='';
                this.discountAll=null;
                this.singledisRadioclass='',this.selectedOption='',this.singleeatDiscountType='';
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                this.discountMoney=0
                this.memberDiscountSchemeList = JSON.parse(JSON.stringify(this.discountSchemeList||null));
                this.isreadCardBtn=this.Running_ReadCardToDiscount_YN;
                this.orderMenuList.forEach(item=>{
                    if(item.Is_Discounted==true && item.Eat_Number>0){
                        if(item.Eat_CheckMoney!=null){
                            this.discountMoney += Number(item.Eat_CheckMoney)
                        }
                    }
                    
                })
                if(sessionStorage.getItem("tempUserID")){
                    let _date={User_ID:sessionStorage.getItem("tempUserID"),Login_Name:sessionStorage.getItem("tempName")}
                    this.checkbtnReturn(_date)
                }
                if(this.disRadioOrder) {
                    this.filteredOrderMenuList.forEach(item=>{
                        if(item.Eat_ConsumeID==this.disRadioOrder?.Eat_ConsumeID){
                            this.selectedOption=this.disRadioOrder?.Eat_ConsumeID
                        }
                    })
                }
            }
        }
    },
    methods:{
        //变更用户
        changeUser(){
            this.checkShow=true
        },
        //权限验证返回
        checkbtnReturn(data){
            this.checkShow = false;
            this.tempUserId = data.User_ID
            this.tempOperatorName = data.Login_Name
            let param={
                Body_ID:newGuid(),
                User_ID:data.User_ID,
                DataTypeNames:['DiscountScheme']
            }
            const loading = this.$loading({
                text: "提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetBaseData",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.discountScheme=''
                }else{
                    if(data.ResponseBody.DiscountSchemes){
                        this.memberDiscountSchemeList = data.ResponseBody.DiscountSchemes
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**折扣切换 */
        onclickZk(type){
            initKeyBoard.call(this,this.$refs.zkMoney,this.$refs.money);
            initKeyBoard.call(this,this.$refs.zk,this.$refs.input);
            initKeyBoard.call(this,this.$refs.zkSingleEat);
            this.discountType = type
        },
        /**折扣方案点击 */
        onclickDiscountScheme(item){
            this.discountScheme = item
            this.confirm()
        },
        /**全单则扣 */
        onClickDisountAll(item){
            this.discountAll = item;
            this.confirm()
        },
        /**金额折扣类型选择 */
        radioClick(item){
            this.disRadioclass=item.DiscountScheme_AutoID;
            this.moneyDiscountType=item;
        },
        /**单品折扣类型选择 */
        SingleradioClick(item){
            this.singledisRadioclass=item.DiscountScheme_AutoID;
            this.singleeatDiscountType=item;
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit("closeModel")
        },
        /**取消折扣*/
        cancelClick(){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_AutoID:this.orderInfo.Eat_AutoID,
                IsReturn_CheckOutInfo:true,
                Is_UnionOrder:true,
                Timestamps:this.orderInfo.Timestamps.toString()
            }
            const loading = this.$loading({
                text: "提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.CancelBillDiscount",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.$message.success({ message: "折扣已取消"});
                    if(sessionStorage.getItem("tempUserID")){
                        sessionStorage.removeItem("tempUserID")
                        sessionStorage.removeItem("tempName")
                    }
                    this.$emit("CancelBillDiscount",data)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        confirm(data){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                EAT_AUTOID:this.orderInfo.Eat_AutoID,
                Kind:this.discountType,
                DiscountScheme_AutoID:this.disRadioclass,
                DiscountScheme_ZkRate:Number(this.per),
                DiscountScheme_ZkMoney:Number(this.money),
                IsReturn_CheckOutInfo:true,
                Is_UnionOrder:true,
                Timestamps:this.orderInfo.Timestamps.toString(),
                SingleDiscount_ConsumeID:'',
                //会员全单折扣
                Member_Sys:0,
                Member_CardNo:'',
                Mobile:'',
                Member_Cardkind:'',
            }
            Object.assign(param,data);
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.tempOperatorName
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            if(this.discountType==1){
                param.DiscountScheme_AutoID =  this.discountScheme.DiscountScheme_AutoID;
            }else if(this.discountType==2){
                param.DiscountScheme_AutoID =  this.discountAll.DiscountScheme_AutoID;
                param.Kind=3;
            }else if(this.discountType==5){
                if(this.moneyDiscountType==''){
                    this.$message.warning('请先选择金额折扣类型');
                    return;
                }
                param.DiscountScheme_AutoID = this.moneyDiscountType.DiscountScheme_AutoID
                if(this.money>this.moneyDiscountType.DiscountScheme_ZkMoney){
                    this.$alert('不能超过最大折扣金额', '提示', {
                            confirmButtonText: '确定',
                    });
                    return;
                }
            }else if(this.discountType==4){

                if(this.singleeatDiscountType==''){
                    this.$message.warning('请先选择单品折扣类型');
                    return;
                }
                if(this.selectedOption=='') {
                    this.$message.warning('请先选择单品折扣菜品');
                    return;
                }
                let iserror=false
                let errormsg='';
                this.orderMenuList.forEach(item=>{
                    if(item.Eat_ConsumeID==this.selectedOption){
                        if(!item.Is_Discounted){
                            iserror=true;
                            errormsg='该菜品不可打折'
                        }
                        else if(item?.FoodDiscount_Dimension && item?.FoodDiscount_Dimension!='会'){
                            iserror=true;
                            errormsg='该菜品已'+item?.FoodDiscount_Dimension+'，不可重复折扣'
                        }
                    }
                })
                if(iserror){
                    this.$message.warning(errormsg);
                    return;
                }
                param.DiscountScheme_AutoID = this.singleeatDiscountType.DiscountScheme_AutoID
                param.DiscountScheme_ZkRate=this.singleeatDiscountType.DiscountScheme_ZkRate
                param.SingleDiscount_ConsumeID=this.selectedOption
            }
            if(this.discountType==3 && this.per==''){
                this.$message.warning('折扣率不能为空');
                return;
            }else{
                
                if((param?.DiscountScheme_AutoID=='' || !param?.DiscountScheme_AutoID) && this.discountType!=3){
                    this.$message.warning('请先选择对应的折扣规则');
                    return;
                }else{
                    if(this.discountType==5 && this.money==''){
                        this.$message.warning('折扣金额不能为空');
                        return 
                    }
                    if(this.discountType==4&&param.SingleDiscount_ConsumeID==''){
                        this.$message.warning('请先选择单品折扣菜品');
                        return;
                    }
                }
            }
            console.log('param:',param)
            const loading = this.$loading({
                text: "提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.DealWithTheDiscount",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.discountScheme=''
                }else{
                    this.$message.success({ message: "折扣设置完成"});
                    this.$emit("discountSchemeClic",data)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        readCard(){
            this.isshowCard=true;
        },
        MemberCardZKReturn(data){
            this.per=data.CardKind_DiscountRate;
            //会员全单折扣
            // this.Member_Sys=data.Member_Sys;
            // this.Member_CardNo=data.Card_No;
            // this.Mobile=data.Mobile;
            // this.Member_Cardkind=data.Bestech_Card_KindName;
            this.isshowCard=false;
            //直接会员折扣 传入会员信息
            this.confirm({
                Member_Sys:data.Member_Sys,
                Member_CardNo:data.Card_No,
                Mobile:data.Mobile,
                Member_Cardkind:data.Bestech_Card_KindName
            });
        }
    }
}
</script>

<style lang="sass">
@import './discountModel.scss';
</style>