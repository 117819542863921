<template>
  <modal class="servingSubItemModel" :isShow="isShow" :isMask="true" @click="hide()">
        <div class="header">选择打印
            <span class="header-x" @click="hide()">×</span>
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="item-box" v-scroll-anime="{up:'item-up2',down:'item-down2',liClass:'.item-li',marginH:10,paddingH:8,isRow:false}">
                        <div class="item-li" :class="{selected:item.ProjectSubItem_Key==ProjectSubItemKey}" v-for="(item,index) in otherFuncList" :key="index" @click="onDescClick(item,index)" >
                            <p class="title">{{item.ProjectSubItem_Name}}</p>
                            <span class="shortKey" v-if="item.ProjectSubItem_ShortcutKey">{{item.ProjectSubItem_ShortcutKey}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </modal>
</template>

<script>

export default {
    name:'servingSubItemModel',
    data(){
        return {
            ProjectSubItemKey:-1,
            /**数据,ProjectSubItem_ShortcutKey-快捷键 PrintType:1.消费清单2.结账清单8.客看单 */
            otherFuncList:[{ProjectSubItem_Name:'消费清单',ProjectSubItem_ShortcutKey:'',PrintType:1},{ProjectSubItem_Name:'客看单',ProjectSubItem_ShortcutKey:'',PrintType:8}]
        }
    },
    props:{
        isShow:Boolean,
    },
    mounted(){
        this.$nextTick(()=> {
           this.openimp()
        })
    },
    watch:{
        /**打开换单位页面 */
        isShow(){
            if(!this.isShow) return;
            this.openimp()
        }
    },
    methods:{
        /**打开执行 */
        openimp(){
            console.log('进来了呢')
            this.ProjectSubItemKey=-1
        },
        hide(){
            this.$emit("closeModel")
        },
        /**按钮点击 */
        onDescClick(item,index){
            this.ProjectSubItemKey = item.ProjectSubItem_Key
            this.$emit("GetPrintContent",item,index)
        },
        /**去除重复 */
        unique(newarr) {
            const res = new Map();
            return newarr.filter((newarr) => !res.has(newarr.SubCategory_ID) && res.set(newarr.SubCategory_ID, 1));
        }
    }
}
</script>

<style lang="scss">
@import "./servingSubItemModel.scss";
</style>