<template>
    <modal class="paymentCouponsModel" :isShow="isShow">
        <div class="header">
            <span>{{data?.PAY_NAME}}</span>
            <span class="unMoney" >待支付金额:<span class="money">¥{{unPaidMoney}}</span></span>
            <span class="payCoupons">已选券抵扣:<span class="money">¥{{counMoney}}</span></span>
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel">
                        <div class="panel-heading">
                             {{data?.PAY_NAME}}选择
                        </div>
                        <div class="panel-body item-box clearfix" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.item-li',isRow:false}">
                            <div class="item-li coupons" :class="{selected:item._selected}" @click="onTicket(item,1)"
                             v-for="item in ticketInfos" :key="item">
                                <template v-if="item.KIND==1 && item.CashCouponPrices?.length>0">
                                    <el-popover popper-class="popover-box" :placement="index%4==3?'left-start':'right-start'" :width="128" trigger="click">
                                        <template #reference>
                                            <div>
                                                <div class="coupons-name verticalCenter">
                                                    <template v-if="item.CashCouponPrices?.length>0">
                                                        <div class="name inlineNowrap2 money">组合券</div>
                                                    </template>
                                                    <template>
                                                        <div class="money-ico" v-if="data?.INTERFACE_TYPE==7">￥</div>
                                                        <div class="name inlineNowrap2 money" v-if="data?.INTERFACE_TYPE==7">
                                                            {{item.CashCouponPrices?.length>0?"组合券":item.EAT_PRICE}}
                                                        </div>
                                                        <div class="name inlineNowrap2" v-else>{{item.TK_NAME}}</div>
                                                    </template>
                                                </div>
                                                <div class="coupons-describe" v-if="data?.INTERFACE_TYPE==7">
                                                    <div class="nowrap2">
                                                        {{item.TK_NAME}}
                                                    </div>
                                                </div>
                                                <div class="coupons-describe nowrap" v-else>
                                                    {{({2:'无面值商品券',3:'有面值商品券'})[item.KIND]}}
                                                </div>
                                            </div>
                                        </template>
                                        <div class="pop-box">
                                            <div class="pop-li" :class="{selected:coupon._selected}" v-for="coupon in item.CashCouponPrices" :key="coupon" @click="onTicket(coupon)">
                                                <p class="money">￥{{coupon.Face_Price}}</p>
                                                <p class="money-explain">售价:<span class="unit">￥</span><span class="price">{{coupon.Sale_Price}}</span></p>
                                            </div>
                                        </div>
                                    </el-popover>
                                </template>
                                <template v-else>
                                    <div class="coupons-name verticalCenter">
                                        <div class="money-ico" v-if="data?.INTERFACE_TYPE==7">￥</div>
                                        <div class="name inlineNowrap2 money" v-if="data?.INTERFACE_TYPE==7">{{item.EAT_PRICE}}</div>
                                        <div class="name inlineNowrap2" v-else>{{item.TK_NAME}}</div>
                                    </div>
                                    <div class="coupons-describe" v-if="data?.INTERFACE_TYPE==7">
                                    <div class="nowrap2">
                                            {{item.TK_NAME}}
                                        </div>
                                    </div>
                                    <div class="coupons-describe nowrap" v-else>
                                        {{({2:'无面值商品券',3:'有面值商品券'})[item.KIND]}}
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="panel">
                        <div class="panel-heading">
                            已选{{data?.PAY_NAME}}
                        </div>
                        <div class="panel-body select-item-box" v-scroll-anime="{up:'right-order-up',down:'right-order-down',liClass:'.tr'}">
                            <div class="tr" v-for="(item,index) in selectTicketList" :key="item">
                                <div class="td num">{{index+1}}</div>
                                <div class="td name">
                                    <div class="nowrap2">
                                        {{item.TK_NAME}} 
                                        <p class="red" v-if="item.KIND==1">售价:{{item.EAT_SALEPRICE}}</p>
                                    </div>
                                </div>
                                <div class="td tally-box">
                                    <div class="bnt-jian" @click="setNumber(item,0,1)"><b class="iconfont icon-jian"></b></div>
                                    <div class="number">
                                          <key-board-input class="number-input"
                                            type="text" v-model="item.number" 
                                            pattern="money"
                                            @keydown.enter="(e)=>enter(e,item)"
                                            @blur.capture="enter('',item)"
                                            @click="foodClick(item)"
                                            isBlurClose isKeyEnterClose isFocusKeyBoard
                                            :keyOptions="{isKeyDownEnter:true,isNumber:true}"></key-board-input>
                                    </div>
                                    <div class="bnt-jia" @click="setNumber(item,2,1,false)"><b class="iconfont icon-jia"></b></div>
                                </div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="right-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="right-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </modal>
</template>

<script>

import { deepClone,initKeyBoard } from '/src/common' 
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

/**现金券 或 商品券 选择 */
export default {
    name:"paymentCouponsModel",
    props:{
        isShow:Boolean,
        orderMenuList:Object,
        choicePayType:Object,
        unionEatAutoID:Object,
        unPaidMoney:Number,
        data:{
            default:{
                INTERFACE_TYPE: 7,
                PAY_NAME: "现金券",
                ticketInfos:[]
            }
        }
    },
    data(){
        return {
            /**券数据 */
            ticketInfos:[],
            selectTicketList:[],
            memberorderInfo:'',  //临时订单数据
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**选择券的总金额 */
            counMoney:0,
            CouNumber:1,
            isKey:1,/**是否执触发过键盘修改券数量 */
            /**交易记录 */
            Payments:[],
            /**菜品和券绑定关系 */
            FoodticketList:[]

        }
    },
    mounted(){
        this.init();
    }, 
    watch: {
        isShow(newVal) {
            if(newVal){
                this.init();
            }
        },
        selectTicketList:{
            handler(newVal,oldVal){
                if(this.selectTicketList){
                    this.counMoney=0
                    this.selectTicketList.map(tick=>{
                        this.counMoney = Number(Number(this.counMoney + Number(tick.EAT_PRICE) * Number(tick.number)).toFixed(2))
                        this.selectCountNumber += tick.number
                    })
                }
            },
            immediate:true,
            deep:true
        }
    },
    methods:{
        init(){
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
            this.memberorderInfo.forEach(order=>{
                Object.assign(order,{deductionNumber:0})
            })
            this.ticketInfos=deepClone(this.data.ticketInfos);
            this.ticketInfos?.forEach(item=>{
                item.CashCouponPrices?.forEach(it=>{
                    it.TK_AUTOID=item.TK_AUTOID;
                    it.KIND=1;
                    it.TK_CODE=item.TK_CODE;
                    it.TK_NAME=item.TK_NAME;
                    it.EAT_PRICE=it.Face_Price;
                    it.EAT_SALEPRICE=it.Sale_Price
                    it.PAY_ID=item.PAY_ID;//对应支付方式ID
                    it.PAY_NAME=item.PAY_NAME;//对应支付方式名称
                    it.OLPOINTS=item.OLPOINTS;	//积分次数
                    it.TK_ONETOMANY=item.TK_ONETOMANY;//一张券可低多个商品
                    it.BEGIN_DATE=item.BEGIN_DATE;	//开始日期
                    it.END_DATE=item.END_DATE;	//结束日期
                    it.REMARK=item.REMARK;	//备注
                    it.AutoIDKind=0;//主键list类型 1：指定菜品ID， 2.指定菜品分类 ，3：排除菜品分类
                    it.isCashCoupon=true;//组合券
                })
            })
            this.selectTicketList=[];
            this.FoodticketList=[]
            this.Payments=[],
            this.counMoney=0
        },
        /**点击数量 */
        numberClick(el,item){
            //启动虚拟键盘
            if(this.isKey==1){
                this.CouNumber = item.number
                this.isKey=2
                this.showKeyBoard(el.currentTarget)
            }
        },
        search(el,item){
            this.isKey=2
            this.enter(el,item)
        },
        foodClick(item){
            this.CouNumber=Number(item.number)
        },
        /**键盘数量调整 */
        enter(el,item){
            if(item.number){
                if((item.number+'').indexOf('.')!=-1){
                    this.$message.warning('券数量不支持小数格式');
                    item.number=Number(this.CouNumber)
                    return
                }
                if(Number(item.number)>9999){
                    this.$message.warning('单张券单次数量最多为9999张');
                    item.number=Number(this.CouNumber)
                    return
                }
                let _number = 0
                //数量增加
                if(Number(item.number)>Number(this.CouNumber)){
                    //判断订单数量还剩多少可用
                    let mount= this.orderNumberUsemethod(item)
                    _number = Number(item.number)-Number(this.CouNumber)
                    if(mount<_number && item.KIND!=1){
                        this.$message.warning('该订单剩余可用券的菜品不足'+Number(Number(mount).toFixed(2))+'份');
                        item.number = Number(this.CouNumber)
                        return
                    }
                    this.setNumber(item,1,_number,true)
                }
                //数量减少
                if(Number(item.number)<Number(this.CouNumber)){
                    _number = Number(this.CouNumber) - Number(item.number)
                    item.number=Number(this.CouNumber)
                    this.setNumber(item,0,_number)
                }
            }else{
                this.$message.warning('数量不能为空');
                item.number=this.CouNumber
            }
            if(el){
                el.srcElement.blur();
            }
        },
        orderNumberUsemethod(item){
            let num=0,eatNum=0
            if(item.KIND!=1){
                this.memberorderInfo.map(order=>{
                    if(item.AutoIDKind==1){
                        if(item.AutoIDList.indexOf(order.EAT_XFBMID)!=-1){
                            eatNum += order.Eat_Number
                            num += order.deductionNumber
                        }
                    }
                    if(item.AutoIDKind==2){
                        if(item.AutoIDList.indexOf(order.SubCategory_ID)!=-1){
                            eatNum += order.Eat_Number
                            num += order.deductionNumber
                        }
                    }
                    if(item.AutoIDKind==3){
                        if(item.AutoIDList.indexOf(order.SubCategory_ID)==-1){
                            eatNum += order.Eat_Number
                            num += order.deductionNumber
                        }
                    }
                })
            }
            return eatNum-num
        },
        /**点击商品券 或 现金券 */
        onTicket(ticket,type){
            if(ticket._selected==true){
                this.tickPitch(ticket)
                return
            }
            let tickeClick=0
            if(type==1){
                /**1：现金券 2：无面值商品券 3.有面值商品券 */
                if(ticket.KIND!=1){
                    /**1：指定菜品ID  2.指定菜品分类  3.排除菜品分类 */
                    if(ticket.AutoIDKind==1){
                        let autotype=0
                        this.memberorderInfo.some(order=>{
                            if(ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1 && order.deductionNumber<order.Eat_Number){    
                                console                        
                                if(ticket.KIND==2){
                                    ticket.EAT_PRICE=order.Eat_CheckPrice
                                }
                                let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                this.FoodticketList.push(food)
                                order.deductionNumber++
                                this.counMoney += ticket.EAT_PRICE
                                autotype=1
                                return true
                            }
                        })
                        if(autotype==0 ){
                            this.$message.warning("不符合该券的使用规则");
                            tickeClick=1
                            return
                        }
                    }
                    if(ticket.AutoIDKind==2){
                        let _ticheType=0
                        this.memberorderInfo.some(order=>{
                            if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1 && order.deductionNumber<order.Eat_Number){
                                if(ticket.KIND==2){
                                    ticket.EAT_PRICE=order.Eat_CheckPrice
                                }
                                let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                this.FoodticketList.push(food)
                                order.deductionNumber++
                                this.counMoney += ticket.EAT_PRICE
                                _ticheType=1
                                return true;
                            }
                        })
                        if(_ticheType==0 ){
                            this.$message.warning("不符合该券的使用规则");
                            tickeClick=1
                            return true;
                        }
                    }
                    if(ticket.AutoIDKind==3){
                        let _orderType=0
                        this.memberorderInfo.some(order=>{
                            if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1){
                                tickeClick=1
                            }
                            else{
                                if(_orderType==0){
                                    if(order.deductionNumber<order.Eat_Number){
                                        tickeClick=0
                                        _orderType=1
                                        if(ticket.KIND==2){
                                            ticket.EAT_PRICE=order.Eat_CheckPrice
                                        }
                                        let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice: ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                        this.FoodticketList.push(food)
                                        this.counMoney += ticket.EAT_PRICE
                                        order.deductionNumber++
                                        return true
                                    }else{
                                        tickeClick=1
                                    }
                                }
                            }
                        })
                        if(tickeClick==1){
                            this.$message.warning("不符合该券的使用规则");
                        }
                    }
                }
            }
            if(tickeClick==0){
                this.tickPitch(ticket)
            }
        },
        //券的选中判断
        tickPitch(ticket){
            if(ticket._selected){
                let index=this.selectTicketList.findIndex(t=>t.TK_AUTOID==ticket.TK_AUTOID && t.EAT_PRICE==ticket.EAT_PRICE && t.EAT_SALEPRICE==ticket.EAT_SALEPRICE);
                if(index>=0){
                    this.selectTicketList.splice(index,1);
                    this.counMoney =this.accSub(ticket.EAT_PRICE,this.counMoney)
                    for(let i=0; i<this.FoodticketList.length; i++){
                        if(this.FoodticketList[i].TK_AUTOID==ticket.TK_AUTOID){
                            this.memberorderInfo.forEach(order=>{
                                if(order.deductionNumber>0 && order.Eat_ConsumeID==this.FoodticketList[i].Eat_ConsumeID){
                                    // if(order.deductionNumber>0 && order.deductionNumber<1){
                                    //     order.deductionNumber = this.accSub(order.deductionNumber,order.deductionNumber)
                                    // }else{
                                    order.deductionNumber = this.accSub(1,order.deductionNumber)
                                    //}
                                }
                            })
                            this.FoodticketList.splice(i, 1);
                            i -= 1
                        }
                    }
                }
            }else{
                ticket.number=1;
                this.CouNumber = ticket.number
                this.selectTicketList.push(ticket);
            }
            ticket._selected=!ticket._selected;
        },
        /**数量减少 */
        setNumber(item,type,keyNumber,methshow){
            if(type>0 || type==2){//加
                if(item.KIND!=1){
                    let _foodnumber = this.foodtickeAdd(item,type,keyNumber)
                    if(_foodnumber==0){
                        this.$message.warning("已达到本次订单最多可使用券的次数");
                        return 1
                    }
                    if(_foodnumber==2){
                        this.$message.warning("超过本次订单最多可使用商品券的次数,请重新去分配券的使用");
                        return 1
                    }
                }else{
                    if(methshow==false){
                        item.number=this.addNum(1,item.number);
                    }
                }
            }else{//减
                if(item.number>1){
                    let sometype=0,_keyNumber=Number(keyNumber)
                    //找到选择的券
                    let _number= _keyNumber
                    for (let i=0;i< _keyNumber;i++){
                        this.memberorderInfo.forEach(order=>{
                            if(order.deductionNumber>0 && _number>0){
                                this.FoodticketList.some((food,i)=>{
                                    if(order.Eat_ConsumeID==food.Eat_ConsumeID && food.TK_AUTOID==item.TK_AUTOID && _number>0){
                                        if(order.deductionNumber<1 && order.deductionNumber>0){
                                            order.deductionNumber=1
                                        }
                                        order.deductionNumber= this.accSub(1,order.deductionNumber)
                                        this.FoodticketList.splice(i,1)
                                        i--
                                        _number--
                                        if(order.deductionNumber==0){
                                            return true
                                        }
                                    }
                                })
                            }
                        })
                    }
                    if(!keyNumber){
                        item.number=this.accSub(1,item.number);
                        this.counMoney =this.accSub(item.EAT_PRICE,this.counMoney);
                    }else{
                        item.number = this.accSub(Number(keyNumber),item.number);
                        this.counMoney =this.counMoney - item.EAT_PRICE * Number(keyNumber)
                    }
                }else{//移除
                    this.onTicket(item,2)
                }
            }
            this.CouNumber = item.number
        },
        foodtickeAdd(ticket,type,keyNumber){
            let retNumber=0
            /**1：指定菜品ID  2.指定菜品分类  3.排除菜品分类 */
            if(ticket.AutoIDKind==1){
                this.memberorderInfo.forEach(order=>{
                    if(Number(keyNumber)>0){
                        if(ticket.AutoIDList.indexOf(order.EAT_XFBMID)!=-1 && order.deductionNumber<order.Eat_Number && Number(keyNumber)>=1){
                            if(ticket.KIND==2){
                                ticket.EAT_PRICE=order.Eat_CheckPrice
                            }
                            if(type==2){
                                ticket.number=this.addNum(1,ticket.number)
                            }
                           if(Number(keyNumber)>0){
                                let deduNumber = order.Eat_Number - order.deductionNumber
                                if(deduNumber>Number(keyNumber)){
                                    order.deductionNumber = order.deductionNumber + Number(keyNumber)
                                    for(let index = 0; index < Number(keyNumber); index++) {
                                       let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                       this.FoodticketList.push(food)
                                    }
                                    keyNumber = 0
                                }else{
                                    keyNumber =  this.accSub(deduNumber,Number(keyNumber))
                                    let _tempNumber=order.deductionNumber
                                    order.deductionNumber =order.Eat_Number
                                    for(let index = 0; index < deduNumber; index++) {
                                       let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                       this.FoodticketList.push(food)
                                    }
                                    let _ordertempNumber= this.accSub(_tempNumber,order.deductionNumber)
                                    if(_ordertempNumber>0 && _ordertempNumber<=1){
                                        _ordertempNumber=1
                                    }
                                }
                            }
                            retNumber = 1
                            return true
                        }
                    }
                })
            }
            if(ticket.AutoIDKind==2){
                this.memberorderInfo.forEach(order=>{
                    if(Number(keyNumber)>0){
                        if(ticket.AutoIDList.indexOf(order.SubCategory_ID)!=-1 && order.deductionNumber<order.Eat_Number && Number(keyNumber)>=1){                        
                            if(ticket.KIND==2){
                                ticket.EAT_PRICE=order.Eat_CheckPrice
                            }
                            if(type==2){
                                ticket.number=this.addNum(1,ticket.number)
                            }
                            if(Number(keyNumber)>0){
                                let deduNumber = this.accSub(order.deductionNumber,order.Eat_Number)
                                if(deduNumber>Number(keyNumber)){
                                    order.deductionNumber = this.addNum(Number(keyNumber),order.deductionNumber)
                                    for(let index = 0; index < Number(keyNumber); index++) {
                                    let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                    this.FoodticketList.push(food)
                                    }
                                    keyNumber = 0
                                }else{
                                    keyNumber = this.accSub(deduNumber,Number(keyNumber))
                                    let _tempNumber=order.deductionNumber
                                    order.deductionNumber =order.Eat_Number
                                    for(let index = 0; index < deduNumber; index++) {
                                    let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                    this.FoodticketList.push(food)
                                    }
                                    let _ordertempNumber= this.accSub(_tempNumber,order.deductionNumber)
                                    if(_ordertempNumber>0 && _ordertempNumber<=1){
                                        _ordertempNumber=1
                                    }
                                }
                            }
                            retNumber = 1
                            return true
                        }
                    }
                })
            }
            if(ticket.AutoIDKind==3){
                this.memberorderInfo.forEach(order=>{
                    if(Number(keyNumber)>0){
                        if(ticket.AutoIDList.indexOf(order.SubCategory_ID)==-1 && order.deductionNumber<order.Eat_Number && Number(keyNumber)>=1){     
                            if(ticket.KIND==2){
                                ticket.EAT_PRICE=order.Eat_CheckPrice
                            }
                            if(type==2){
                                ticket.number=this.addNum(1,ticket.number)
                            }
                            if(Number(keyNumber)>0){
                                let deduNumber =  this.accSub(order.deductionNumber,order.Eat_Number)
                                if(deduNumber>Number(keyNumber)){
                                    order.deductionNumber = this.addNum(Number(keyNumber),order.deductionNumber)
                                    for(let index = 0; index < Number(keyNumber); index++) {
                                       let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                       this.FoodticketList.push(food)
                                    }
                                    keyNumber = 0
                                }else{
                                    keyNumber =this.accSub(deduNumber, Number(keyNumber))
                                    let _tempNumber=order.deductionNumber
                                    order.deductionNumber =order.Eat_Number
                                    for(let index = 0; index < deduNumber; index++) {
                                       let food={Eat_ConsumeID:order.Eat_ConsumeID,Number:1,Eat_CheckPrice:ticket.EAT_PRICE,TK_AUTOID:ticket.TK_AUTOID}
                                       this.FoodticketList.push(food)
                                    }
                                    let _ordertempNumber= this.accSub(_tempNumber,order.deductionNumber)
                                    if(_ordertempNumber>0 && _ordertempNumber<=1){
                                        _ordertempNumber=1
                                    }
                                }
                            }
                            retNumber = 1
                            return true
                        }
                    }
                })
            }
            return retNumber
        },
        hide(){
            this.$emit('closeModel')
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return  Number(((arg2*m-arg1*m)/m).toFixed(n));
        },
        addNum(arg1, arg2) {
            var r1, r2, m, c;
            try {
                r1 = arg1.toString().split(".")[1].length;
            } catch (e) {
                r1 = 0;
            }
            try {
                r2 = arg2.toString().split(".")[1].length;
            } catch (e) {
                r2 = 0;
            }
            c = Math.abs(r1 - r2);
            m = Math.pow(10, Math.max(r1, r2));
            if (c > 0) {
                var cm = Math.pow(10, c);
                if (r1 > r2) {
                    arg1 = Number(arg1.toString().replace(".", ""));
                    arg2 = Number(arg2.toString().replace(".", "")) * cm;
                } else {
                    arg1 = Number(arg1.toString().replace(".", "")) * cm;
                    arg2 = Number(arg2.toString().replace(".", ""));
                }
            } else {
                arg1 = Number(arg1.toString().replace(".", ""));
                arg2 = Number(arg2.toString().replace(".", ""));
            }
            return (arg1 + arg2) / m;
        },
        /**确定和回车事件 */
        confirm(){
            if(this.selectTicketList.length==0){
                this.$message.warning('请先选择'+this.data?.PAY_NAME+"!");
                return;
            }
            let sltype=false;
            sltype=this.selectTicketList.some(sl=>{
                if(!sl.number || sl.number==0){
                    return true
                }
            })
            if(sltype==true){
                this.$message.warning('券的数量不能为0或者空');
                return;
            }
            if(this.counMoney<=this.unPaidMoney){
                this.$confirm('您确定使用券抵扣吗?', '提示',{
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    this.$emit('payCouponsConfirm',this.selectTicketList)
                }).catch(()=>{});
            }
            if(this.counMoney>this.unPaidMoney){
                let moreCouponsMoney = this.counMoney -this.unPaidMoney
                this.$confirm('您确定使用券抵扣吗?本次多收券:'+ Number(moreCouponsMoney).toFixed(2)+'', '提示',{
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    this.$emit('payCouponsConfirm',this.selectTicketList)
                }).catch(()=>{});
            }
        },
        /**支付提交方法 */
        PaySubmit(data){
            if(data.ResponseBody.Is_NeedSubmit==1){
               let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                    Member_Type:data.ResponseBody.Member_Type,  //会员类型
                    Biz_ID:data.ResponseBody.Biz_ID, //业务交易流
                    Verify_Code:'', //验证码
                    Operator_Name:this.userInfo?.Login_Name, //操作员
                    Timestamps:data.ResponseBody.Timestamps.toString(),
                    Payments:data.ResponseBody.Payments
                }
                console.log('支付提交参数:'+JSON.stringify(param))
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.PaySubmit",param).then((data)=>{
                    loading.close();
                    console.log('支付提交返回:'+JSON.stringify(data))
                    if(data.ResponseHeader.ResultCode!=0){
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        /**完成结账 */
                        if(this.counMoney>=this.memberorderInfo.UnPaid_Money){
                            this.PerformingCheckOut(data)
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },
        /**结账方法 */
        PerformingCheckOut(data){
            let EatAutoIDList=[]
            let orderEatAutoList={Order_ID:this.memberorderInfo.Eat_AutoID,Timestamps:data.ResponseBody.Timestamps.toString(),Service_Charge:this.memberorderInfo.Service_Money}
            EatAutoIDList.push(orderEatAutoList)
            if(this.memberorderInfo.UnionOrderInfoList!=null){
                this.memberorderInfo.UnionOrderInfoList.map(union=>{
                    orderEatAutoList = {Order_ID:union.Eat_AutoID,Timestamps:union.Timestamps.toString(),Service_Charge:union.Service_Money}
                    EatAutoIDList.push(orderEatAutoList)
                })
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID, //登陆用户ID
                CheckOutOrders:EatAutoIDList, //结账订单ID
                MantissaAdjust_Money:this.memberorderInfo.Mantissa_Money, //尾数调整金额
                Service_Charge:this.memberorderInfo.Service_Money,//服务费
                Print_YN:true, //是否返回结账单打印内容
                Operator_Name:this.userInfo?.Login_Name, //操作员
            }
            console.log('结账接口提交:'+JSON.stringify(param))
            const loading = this.$loading({
                text: "结账中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.PerformingCheckOut",param).then((data)=>{
                loading.close();
                console.log('结账接口返回:'+JSON.stringify(data))
                if(data.ResponseHeader.ResultCode!=0){
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    if(param.Print_YN==true){//返回结账单打印内容
                        this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                    }
                    ElMessage.success({ message: "结账成功"});
                    this.$emit("paymentCouponClick")
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        showKeyBoard(obj){
            this.$keyBoard.show(obj,{isNumber:true,isKeyDownEnter:true});
        },
    }
}
</script>

<style lang="sass">
@import "./paymentCouponsModel.scss";
</style>