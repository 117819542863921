<template>
    <modal class="withMenuSummaryModel" :isShow="isShow">
        <div class="header">汇总配菜单</div>
        <div class="modal-body">
            <div class="flex-box">
                <div class="left-box">
                    <div class="table-box grey-table">
                        <table-vir class="el-table--scrollable-y" style="width: 100%;" 
                            ref="elTable"
                            :data="listData"
                            highlight-current-row
                            @selection-change="selectFood"
                        >
                            <table-column-vir label="选择" type="selection" width="40"></table-column-vir>
                            <table-column-vir prop="Eat_MenuName" align="left" label="配菜单名称" min-width="100"></table-column-vir> 
                            <table-column-vir prop="Eat_SaleMoney" label="菜单金额" min-width="70"></table-column-vir>
                            <table-column-vir prop="Eat_DeskNum" label="席数" width="40"></table-column-vir>
                            <table-column-vir prop="CREAT_ID" label="创建人" min-width="80">
                                <template #default="scope">
                                    <div>{{scope.row.CREAT_ID}}</div>
                                    <div class="time">{{shortDateFormat(scope.row.CREAT_TIME)}}</div>
                                </template>
                            </table-column-vir>
                        </table-vir>
                    </div>
                </div>
                <div class="right-box" >
                    <div class="btn-box">
                        <span class="red">请勾选左边列表配菜单进行汇总</span>
                        <div class="bnt" @click="exportExcel"><i class="iconfont icon-daochu"></i>导出报表</div>
                    </div>
                    <div class="table-box grey-table">
                        <table-vir class="el-table--scrollable-y" style="width: 100%;" 
                            ref="elTable2"
                            highlight-current-row
                            :data="summaryFoodList"
                            showSummary
                            :summaryMethod="getSummaries"
                        >
                            <table-column-vir prop="Eat_XFCode" align="left" label="菜品编码" min-width="100"></table-column-vir> 
                            <table-column-vir prop="Eat_XFName" align="left" label="菜品名称" min-width="100"></table-column-vir>
                            <table-column-vir prop="Eat_XFSize" label="单位" width="50"></table-column-vir>
                            <table-column-vir prop="SubCategory_Name" align="left" label="上级分类" min-width="80"></table-column-vir>
                            <table-column-vir prop="Eat_Number" className="number" align="right" label="菜品总数" width="70" format="number"></table-column-vir>
                            <table-column-vir prop="totalNum" className="number" align="right" label="菜品按席数汇总数" width="70" format="number"></table-column-vir>
                        </table-vir>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
        </div>
    </modal>
</template>

<script>
// 汇总配菜单
export default {
    name:'withMenuSummaryModel',
    emits:["close"],
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            userInfo:null,
            listData:[],
            /**勾选要修改的菜品 */
            checkFoods:[],
        }
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.loadData();
            }
        },
    },
    computed:{
        /**汇总的菜品 */
        summaryFoodList(){
            let list=[];
            if(this.checkFoods?.length>0){
                let groupKey={};
                this.checkFoods.forEach(it=>{
                    console.log(it)
                    it.FoodSetmeals?.forEach(item=>{
                        item.totalNum=Number((it.Eat_DeskNum*item.Eat_Number).toFixed(4));//菜品按席数汇总数
                        
                        if(groupKey[item.Eat_XFBMID+item.Eat_XFName]){
                            groupKey[item.Eat_XFBMID+item.Eat_XFName].Eat_Number=Number((groupKey[item.Eat_XFBMID+item.Eat_XFName].Eat_Number+item.Eat_Number).toFixed(4));
                            groupKey[item.Eat_XFBMID+item.Eat_XFName].totalNum=Number((groupKey[item.Eat_XFBMID+item.Eat_XFName].totalNum+item.totalNum).toFixed(4));
                        }else{
                            let json=Object.assign({},item)
                            list.push(json);
                            groupKey[item.Eat_XFBMID+item.Eat_XFName]=json;
                        }

                        if(item.orderMenuTcItemList?.length>0){//套餐
                            item.orderMenuTcItemList.forEach(tcItem=>{
                                tcItem.totalNum=Number((it.Eat_DeskNum*tcItem.Eat_Number).toFixed(4));//菜品按席数汇总数
                                let key=tcItem.Eat_XFBMID+tcItem.Eat_XFName;
                                if(groupKey[key]){
                                    groupKey[key].Eat_Number=Number((groupKey[key].Eat_Number+tcItem.Eat_Number).toFixed(4));
                                    groupKey[key].totalNum=Number((groupKey[key].totalNum+tcItem.totalNum).toFixed(4));
                                }else{
                                    let json=Object.assign({},tcItem)
                                    list.push(json);
                                    groupKey[key]=json;
                                }
                            })
                        }
                    })
                })
                list.sort((d1,d2)=>{//排序 升序
                    if(d1.SubCategory_Code==d2.SubCategory_Code){
                        return (d1.Eat_XFCode||"").localeCompare(d2.Eat_XFCode||"");
                    }
                    return (d1.SubCategory_Code||"").localeCompare(d2.SubCategory_Code||"");
                })
            }
            return list;
        }
    },
    mounted(){
        this.loadData();
    },
    methods:{
        //勾选的菜品
        selectFood(currentRow){
            this.checkFoods=currentRow;
        },
        //加载数据
        loadData(){
            const loading = this.$loading({
                text: "获取配菜单数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            this.$httpAES.post("Bestech.CloudPos.GetComboDishProjects", {
                User_ID:userInfo.User_ID,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.listData=d.ResponseBody;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("配菜单数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /*时间格式 月/日 时分*/
        shortDateFormat(cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('MM/dd hh:mm');
        },
        hide(){
            this.$emit("close");
        },
        /**Excel导出 */
        exportExcel(){
            this.$refs.elTable2.export("汇总配菜单");
        },
        //合计 
        getSummaries({ columns }){
            const sums= [];
            let total={Eat_Number:0,totalNum:0};
            this.summaryFoodList.forEach(it=>{
                total.Eat_Number+=it.Eat_Number;
                total.totalNum+=it.totalNum;
            })
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]=parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
    }
}
</script>

<style lang="scss">
@import './withMenuSummaryModel.scss'
</style>