<template>
    <modal class="westSoftAccountModel" :isShow="isShow">
    <div class="header">
        <span >西软挂账接口功能<span class="close" @click="hide()">×</span></span>
        <span class="deskNo">桌号:&nbsp;{{memberorderInfo.Eat_DeskName}}</span>
    </div>
          <tabs class="modal-body" ref="CardMoney">
              <div class="clearfix">
                <div class="queryWhole">
                    <div class="query">查询模式:</div>
                    <div class="querymodel">
                        <!--:checked="tickChecke"-->
                        <label class="checkbox"><input type="radio" name="_fa_radio" @click="radio(0)" :checked="true" />
                        <i class="iconfont icon-a-danxuanweixuanzhong"></i>散客查询</label>
                    </div>
                    <div class="querymodel">
                        <!--:checked="tickChecke" -->
                        <label class="checkbox"><input type="radio" name="_fa_radio" @click="radio(1)"/>
                        <i class="iconfont icon-a-danxuanweixuanzhong"></i>团队查询</label>
                    </div>
                    <div class="querymodelAR">
                        <label class="checkbox"><input type="radio" name="_fa_radio" @click="radio(2)"/>
                        <i class="iconfont icon-a-danxuanweixuanzhong"></i>应收账(AR)查询</label>
                    </div>
                </div>
                <div class="conWhole">
                    <div class="condiv">
                        <div class="contitle">主单账号:</div>
                        <div class="frominput"><input type="text" v-model="orderAccount" @keyup.enter="onSelect(1)" @focus="(e)=>e.target.select()"/></div>
                    </div>
                    <div class="condiv">
                        <div class="contitle">宾客姓名:</div>
                        <div class="frominput"><input type="text" v-model="guestName"  @keyup.enter="onSelect(1)" @focus="(e)=>e.target.select()"/></div>
                    </div>
                    <div class="condiv">
                        <div class="contitle">房&nbsp;&nbsp;&nbsp;号:</div>
                        <div class="frominput"><input type="text" ref="roomNumber" v-model="roomNumber"  @keyup.enter="onSelect(1)" @focus="(e)=>e.target.select()"/></div>
                    </div>
                    <div class="condivbtn" @click="onSelect(1)">
                        <div>查询</div>
                    </div>
                </div>
                  <div class="left-box">
                      <tab-pane>
                            <div class="table-box grey-table" v-table-el-height="'tableEl'">
                                <el-table ref="tableEl" border :data="corpInfoList" @row-click="dataClick" :row-style="rowStyle"  style="width: 100%;"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                                    <el-table-column  label="选择" width="44">
                                        <template #default="scope">
                                            <el-checkbox v-model="scope.row.checked" @click="dataClick(scope)"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="账户编码" min-width="100">
                                        <template #default="scope" >
                                            <span style="float: left;color:#000000;font-weight:600;font-size:14px;">{{scope.row.master_info?.accnt}}</span>
                                        </template>
                                    </el-table-column> 
                                    <el-table-column label="宾客姓名" min-width="60" >
                                        <template #default="scope" >
                                            <span style="float: left;color:#000000;font-weight:600;font-size:14px;">{{scope.row.master_info.name}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="挂账余额" min-width="50"> 
                                        <template #default="scope">
                                            <span style="float:right;color:#000000;font-weight:600;font-size:14px;">{{Number(scope.row.master_info.balance).toFixed(2)}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="挂账限额" width="70">
                                        <template #default="scope">
                                            <span style="float:right;color:#000000;font-weight:600;font-size:14px;">{{Number(scope.row.master_info.accountlimit).toFixed(2)}}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                      </tab-pane> 
                  </div>
                  <div class="right-box">
                      <div>
                          <span class="titlespan">待支付金额:</span>
                          <span class="spanMoney">{{ Number(unPaidMoney).toFixed(2)}}</span>
                      </div>
                      <div class="table-box">
                              <div class="tr-li">
                                  <div class="lable-txt">账户编码：<span class="labelcorp" v-show="choiceAccount">{{choiceAccount?.accnt}}</span></div>
                              </div>
                              <div class="tr-li">
                                  <div class="lable-txt">账户名称：
                                      <span class="labelcorp" v-show="choiceAccount"> {{choiceAccount?.name}}</span>
                                  </div>
                              </div>
                              <div class="tr-li">
                                  <div class="lable-txt">挂账余额：<span class="labelcorp" v-show="choiceAccount">{{ Number(choiceAccount?.balance).toFixed(2)}}</span></div>
                              </div>
                              <div class="tr-li">
                                  <div class="lable-txt">挂账限额：<span class="labelcorp" v-show="choiceAccount">{{ Number(choiceAccount?.accountlimit).toFixed(2)}}</span></div>
                              </div>
                              <!-- <div class="tr-li">
                                  <div class="lable-txt">剩余可挂账金额：<span class="labelcorp" v-show="choiceAccount">{{ Number(choiceAccount.balance-choiceAccount.accountlimit).toFixed(2)}}</span></div>
                              </div> -->
                              <div class="tr-li">
                                  <div class="lable-txt">挂账金额：
                                          <span style="padding-left:100px;"><input type="text" ref="input" class="key-focus" v-model="chargeMoney" @focus="$event.target.select()" /></span>
                                      </div>
                              </div>
                      </div>
                      <div class="keyboard">
                          <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                  data-value="9">9</span></div>
                          <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                  data-value="6">6</span></div>
                          <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                  data-value="3">3</span></div>
                          <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                  data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                      </div>
                  </div>
              </div>
          </tabs>
          <div class="footer-box">
              <button class="btn btn-cancel" @click="hide()">取消</button>
              <button class="btn btn-confirm" @click="confirm()">确认</button>
          </div>
    </modal>
  </template>
  
  
  <script>
  import {initKeyBoard} from '/src/common' 
  import { httpAES } from '/src/services'
  import { newGuid } from '/src/common/index.js'
  import { ElMessage } from "element-plus"
  import resizeTable from '../../../report/reportModel/tabelleAnpassen.js'
  
  /**会员支付 */
  export default {
      name:"westSoftAccountModel",
      props:{
          isShow:Boolean,
          orderInfo:Object,
          choicePayType:Object,
          unionEatAutoID:Object,
          wlivePayExist:Boolean
      },
      data(){
          return {
              userInfo:'',//获取用户id
              queryMode:-1,//查询模式 0散客查询 ，1团队查询 ，2应收账查询
              queryType:-1,//查询类型
              queryCode:'',//查询码
              orderAccount:'',//主单账号
              guestName:'',//宾客姓名
              roomNumber:'',//房号
              corpInfoList:[],
              corpAutoID:'',
              /**临时订单数据 */
              memberorderInfo:'',  
              chargeMoney:'',
              unPaidMoney:0,
              /**支付记录 */
              Payments:[],
              /**是否执行支付提交 */
              isPaySubmit:0,
              choiceAccount:'',/**选择的账户 */
              choiparam:{},
              tempUnionEatAutoID:[],
          }
      },
      mounted(){
         this.$nextTick(()=> {
             initKeyBoard.call(this,this.$el);
             this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
             this.tempUnionEatAutoID = JSON.parse(JSON.stringify(this.unionEatAutoID))
             //+this.memberorderInfo.Mantissa_Money
             this.unPaidMoney = this.memberorderInfo.UnPaid_Money
             this.EmptyDatas()
             /**拖动表宽度 */
              resizeTable();
          })
      },
      watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听 +this.memberorderInfo.Mantissa_Money
        isShow(){ 
            if(this.isShow==true){
                this.isPaySubmit=0;
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                this.tempUnionEatAutoID = JSON.parse(JSON.stringify(this.unionEatAutoID))
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                this.EmptyDatas()
            }
        },
        orderInfo:{
            immediate:true,
            handler(vla){
                this.emptyData()
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            }
        }
      },
      methods:{
           /**默认input焦点 */
          EmptyDatas(){
            setTimeout(() => {
            this.$refs.roomNumber.focus();
            },100);
            this.radio(0)
          },
          hide(){
            this.$emit('closeModel')
            //this.$emit("arAccountReturn",this.Payments,this.memberorderInfo.Timestamps,this.isPaySubmit)
          },
          dataClick(item){
            this.choiceAccount=''
            this.corpInfoList.forEach(tb=>{
                if(tb.master_info?.accnt!=item.master_info?.accnt){
                    tb.checked=false
                }
            })
            if(item.checked==true){
                item.checked=false
            }else{
                item.checked=true
                this.choiceAccount=item.master_info
                this.chargeMoney = this.unPaidMoney.toFixed(2)
                this.$refs.input.focus();
                setTimeout(()=>{
                    this.$refs.input?.select();
                },100)
            }
          },
          radio(type){
            this.queryMode=type
          },
          /**获取西软用户信息GetXrXMSCorpInfo*/
          onSelect(){
            if(this.queryMode!=0 && this.roomNumber){
                this.$message.warning('房号只能在散客查询使用');
                return
            }
            if(!this.orderAccount && !this.guestName && !this.roomNumber){
                this.$message.warning('查询条件不能为空');
                return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Query_Mode:this.queryMode,//0散客查询 ，1团队查询 ，2应收账查询
                Query_Type:'',//queryType
                Query_Code:'',
            }
            //主单账号
            if(this.orderAccount){
                param.Query_Code=this.orderAccount
                param.Query_Type=1
            }
            //住客姓名
            if(this.guestName){
                param.Query_Code=this.guestName
                param.Query_Type=2
            }
            //房号
            if(this.roomNumber){
                param.Query_Code=this.roomNumber
                param.Query_Type=3
            }
            const loading = this.$loading({
                text: "账户信息查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetXrXMSCorpInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.corpInfoList=''
                    this.choiceAccount=''
                    this.choiparam = param
                    if(data.ResponseBody){
                        data.ResponseBody.forEach(d=>{
                            d = Object.assign(d,{checked:false})
                        })
                        this.corpInfoList=data.ResponseBody
                    }
                    //this.corpInfoList =this.corpInfoList.sort((a, b) => a.Corp_Code - b.Corp_Code)
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
          },
          /**确认 */
          confirm(){
            if(!this.choiceAccount){
                this.$message.warning('请先选择挂账账户');
                return
            }
            if(Number(this.chargeMoney)<=0){
                this.$message.warning('挂账金额不能为0');
                return
            }
            // if(Number(this.chargeMoney)>Number(this.choiceAccount.accountlimit)){
            //     this.$message.warning('挂账金额不能大于限额');
            //     return
            // }
            if(Number(this.chargeMoney)>Number(this.unPaidMoney.toFixed(2))){
                this.$message.warning('挂账金额不能大于待支付金额');
                return
            }
            this.PayPreview()
            //if(Number(this.choiceAccount.accountlimit)>Number(this.chargeMoney)>Number(this.choiceAccount.balance)){
            //    this.$confirm('是否强制转账', '提示', {
            //        confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
            //    .then(() =>{
            //        this.PayPreview()
            //    }).catch(() =>{});
            //}else if(Number(this.chargeMoney)<=Number(this.choiceAccount.balance)){
            //    this.PayPreview()
            //}else if(Number(this.chargeMoney)>Number(this.choiceAccount.balance)){
            //    this.$confirm('挂账余额和本次挂账金额大于了账户限额,是否强制转账', '提示', {
            //        confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
            //    .then(() =>{
            //        this.PayPreview()
            //    }).catch(() =>{});
            //}
          },
          PayPreview(){
                this.userInfo=this.$auth.getUserInfo(); //获取用户id
                /**支付方式 */
                let _payment=[]
                _payment.push({
                            Payment_ID:this.choicePayType.PAY_ID,  //支付方式ID
                            Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                            Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                            Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                            Payment_Money: Number(this.chargeMoney), //支付金额
                            Payment_FactMoney:0, //计营业额
                            Qr_Code:'', //扫码付款的码值
                            Card_No:this.memberorderInfo.Card_No, //卡号
                            Corp_AutoID:this.corpAutoID,//挂账账户ID
                            CouponInfo:null, //券的详情
                            XrXMSExtendInfo:{Query_Mode:this.choiparam.Query_Mode,accnt:this.choiceAccount?.accnt,roomno:this.choiceAccount?.roomno}
                        })
                let param={
                    Body_ID:newGuid(),
                    User_ID:this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                    UnionOrders:this.tempUnionEatAutoID,//联台订单ID集合
                    Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
                    Operator_Name:this.userInfo?.Login_Name,//操作员
                    Eat_DeskName:this.memberorderInfo.Eat_DeskName, //桌牌号
                    Service_Charge:this.memberorderInfo.Service_Money,  //服务费
                    MantissaAdjustment_Money:this.memberorderInfo.Mantissa_Money,  //尾数调整金额
                    Member:null,//西软挂账信息
                    Payments:_payment, //支付方式列表
                }
                this.$emit("publicPayPreview",param,'westShow')
          },
          rowStyle({row, rowIndex}){
              if (row.checked==true){
                  // 此处返回选中行的样式对象，按需设置
                  return {
                      'background-color': '#FFF5DF'
                  }
              }
          },
          /**清空页面数据 */
          emptyData(){
            this.queryMode=-1,//查询模式 0散客查询 ，1团队查询 ，2应收账查询
            this.queryType=-1,//查询类型
            this.queryCode='',//查询码
            this.orderAccount='',//主单账号
            this.guestName='',//宾客姓名
            this.roomNumber='',//房号
            this.corpInfoList=[]
            this.tempUnionEatAutoID=[]
            this.choiparam={}
          },
      }
  }
  </script>
  
  <style lang="scss">
  @import "./westSoftAccountModel.scss";
  </style>