<template>
    <div class="deskBox">
        <div class="data-box">
            <div class="category-nav">
                <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage',isNavFirstSticky:true}">
                    <div class="nav-li" :class="{selected:filternav==-1}" @click="NavigationClick()" style="background:#FFFFFF;">
                        <span class="lable-text">全部</span>
                    </div>
                    <div class="nav-li" :class="{ selected: filternav == index }" 
                        v-for="(item,index) in PosRooms" :key="item"  
                        @click="NavigationClick(item,index)" >
                    {{item.Room_Name}}
                    </div>
                </div>
                <div class="float-right page-box">
                    <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                    <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
                </div>
            </div>

            <div class="menu" >
                <!-- 菜单 -->
                <div class="scrollBox clearfix"  >
                    <!--0：空台 1：开台 2：预定 3：预结 4：超时 5：清台-->
                    <div class="desk-box" @click="clickDesk(posDesk)" 
                        v-for="posDesk in getPosDesks" :key="posDesk" 
                        :class="{selected:selectData[posDesk.Desk_AutoID]!=null,'opening':posDesk.Desk_Status!=-1}">
                        <p class="title">{{posDesk.Desk_Name}}</p>
                    </div>
                </div>
            </div>
            <div class="search-box">
                <div class="search">
                    <input type="text" placeholder="桌号检索"  v-model="varietySelect" ref="searchBox" @blur="searchInputBlur"/>
                    <div class="deskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()">
                        <span class="iconfont icon-jianpan"></span>
                    </div>
                </div>
                <div class="float-right">
                    <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                    <span class="page-number">{{ posDesksPage.pageIndex }}/{{ posDesksPage.pageSize }}页</span>
                    <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                </div>
            </div>
        </div>
        <analog-key-board ref="keyBoard" ></analog-key-board>
    </div>
</template>

<script>
import { differenceTime } from '/src/common';
/**锁台操作 */
export default {
    name:"deskBox",
    props:{
        /**是否多选 */
        isMultiple:Boolean,
        PosRooms:Array,
        /**桌台筛选方法 */
        deskFilter:Function,
        modelValue: Array,
        isFiter:{
            default:true,
            type:Boolean
        }
    },
    data(){
        return {
            /**导航 */
            filternav:-1,
            /**餐厅数据 */
            //PosRooms:[],
            /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            posDeskIndex:undefined,
            /**桌台数据*/
            posDesks:[],
            /**桌台页码 */
            posDesksPage:{
                pageIndex:1,
                pageSize:1
            },
            /**桌台检索 */
            varietySelect:'',
            /**当前桌台状态类型 筛选 */
            DeskType:-1,
            /**桌台各种类型数量 */
            RoomDeskType:{
                /**空台数 */
                emptyNum:0,
                /**开台 */
                openingNum:0,
                /**预定 */
                reserveNum:0,
                /**预结 */
                preKnotNum:0,
                /**超时 */
                overtimeNum:0,
                /**清台 */
                cleanNum:0
            },
            /**选中需要锁定的数据 */
            selectData:{}
        }
    },
    computed:{
        //过滤数据
        getDeskFilter(){
            let list=[];
            if(this.deskFilter){
                list=(this.posDesks||[]).filter(this.deskFilter);
            }else{
                list= (this.posDesks||[]);
            }
            if(this.DeskType!=-1){
                list=list.filter(item=>item.Desk_Status==this.DeskType);
            }
            return list;
        },
        /**菜品分页显示 */
        getPosDesks(){
            let size=6*7;
            return this.getDeskFilter?.slice((this.posDesksPage.pageIndex-1)*size,this.posDesksPage.pageIndex*size);
        },
    },
    mounted(){
        this.$nextTick(()=>{
            this.NavigationClick(-1);//全部 
        })
    }, 
    watch: {
        /**桌台搜索 */
        varietySelect(){
            if(this.varietySelect!=''){
                this.filternav=-1;
                this.mainCategoryIndex=0;
                let MenuTxt=this.varietySelect.toLowerCase();
                this.posDesks =this.PosRooms._allDesk.filter(pos=>(pos.Desk_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Desk_Code||"").toLowerCase().indexOf(MenuTxt)>=0);
            }else{
                this.posDesks =this.PosRooms._allDesk;
            }
        },
        posDesks(){
            this.posDesksPage.pageIndex=1;
        },
        getPosDesks(){
            let size=6*7;
            let pageSize=Math.ceil(this.getDeskFilter.length/size);
            if(pageSize==0){pageSize=1;}
            this.posDesksPage.pageSize=pageSize;
        },
        getDeskFilter(){
            ///**获取全部桌台数量,并根据数量展示分页，总页数,桌台数等 */
            let emptyNum=0,openingNum=0,reserveNum=0,preKnotNum=0,overtimeNum=0,cleanNum=0, RoomDeskSum=0;
            this.getDeskFilter.forEach((desk)=>{
                if(desk.Desk_Status==0){
                    emptyNum++;
                }else if(desk.Desk_Status==1){
                    openingNum++;
                }else if(desk.Desk_Status==2){
                    reserveNum++;
                }else if(desk.Desk_Status==3){
                    preKnotNum++;
                }else if(desk.Desk_Status==4){
                    overtimeNum++;
                }else if(desk.Desk_Status==5){
                    cleanNum++;
                }
                RoomDeskSum++;
            });
            this.RoomDeskType.emptyNum=emptyNum;
            this.RoomDeskType.openingNum=openingNum;
            this.RoomDeskType.reserveNum=reserveNum;
            this.RoomDeskType.preKnotNum=preKnotNum;
            this.RoomDeskType.overtimeNum=overtimeNum;
            this.RoomDeskType.cleanNum=cleanNum;
            this.RoomDeskType.RoomDeskSum=RoomDeskSum;
        }
    },
    methods:{
         /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. posDesksPage.pageIndex<this.posDesksPage.pageSize){
                    this.posDesksPage.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.posDesksPage.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.posDesksPage.pageIndex<=1?1:this.posDesksPage.pageIndex--;
            }
        },
        
        // getPosRoomsCount(){
            
        //     return RoomDeskSum;
        // },
        //桌台类型检索
        DeskTypeClick(index) {
            this.DeskType = index;
        },
        
        //餐厅名称导航条切换
        NavigationClick(item,index) {
            if(index>=0){
                this.filternav = index;
                this.posDesks=this.PosRooms[index].PosDesks;                
            }else{
                this.filternav=-1;
                this.posDesks=this.PosRooms._allDesk;
            }
        },
        //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            let _time =differenceTime(beginTime);
            if(_time=='-1:-1'){
                _time='0:0'
            }
            return _time;
        },
        clickDesk(item){
            console.log('选择的桌台:'+JSON.stringify(item))
             this.$emit('select',item);
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
                this.$refs.searchBox.focus();
            }
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        }
    }
}
</script>

<style lang="sass">
@import "./deskBox.scss";
</style>