<template>
  <input type="text" v-model="val" ref="input" @focus="select" @keydown="keydown" @copy="handleDisable" @paste="handleDisable" @cut="handleDisable" @contextmenu="handleDisable"/>
</template>

<script>
export default {
    name:'inputPattern',
    emits:['update:modelValue',"keydown","barcode"],
    props:{
        modelValue: {
            type: [String, Number],
            default: ''
        },
        /**过滤类型 money 类型 正数 小数位不能超过2位  number //数字类型  phone手机号类型
         * 正则 /^/g.test()
        */
        pattern:[String,RegExp],
        /**最小范围 */
        min:{
            type:Number,
            default:null
        },
        /**最大范围 */
        max:{
            type:Number,
            default:null
        },
        /**小数位保留多少 类型只能是number时限制 默认不限制小数位*/
        dplaces:{
            type:Number,
            default:null
        },
        /**设置 字符串类型长度  默认不限制*/
        maxlength:{
            type:Number,
            default:null
        },
        /**是否启动检测 扫描枪输入 */
        isBarcode:{
            type:Boolean,
            default:false
        },
        /**是否获取焦点时 全选 */
        isFocSelect:{
            type:Boolean,
            default:true
        }
    },
    data(){
        return {
            val:this.modelValue,
            //输入框监听 卡号
            inputCard:"",
            //第一次输入时间
            inputStartTime:0,
            // 输入间隔 800毫秒
            inoutSpaceTime:800
        }
    },
    watch:{
        modelValue(newVal){
            this.val=newVal;
        },
        val(newVal,oldVal){
            this.changeVal(newVal,oldVal);

            if(this.pattern=="money" || this.pattern=="number"){
                return;
            }
            if(!oldVal || (oldVal && (newVal||"").indexOf(oldVal)==0)){//监听输入速度
                let time=(new Date()).getTime();
                if(this.inputStartTime+this.inoutSpaceTime<time){
                    this.inputStartTime=time;
                    this.inputCard="";
                }
                let oldLeng=oldVal?.length||0;
                this.inputCard+=(newVal||"").substring(oldLeng);
            }
        }
    },
    mounted(){
        this.changeVal(this.val,"");
    },
    methods:{
        handleDisable(event) {
            if(event.type== "paste"){
                if(this.pattern=="money" || this.pattern=="number"){
                    if (event.clipboardData?.items?.length>0) {
                        event.clipboardData.items[0].getAsString((str)=>{
                            if(!/^[0-9.]$/.test(str)){//非数字
                                event.preventDefault();// 阻止粘贴操作
                            }
                        })
                    }
                    //event.preventDefault(); // 阻止粘贴操作
                }
            }else{
                event.preventDefault(); // 阻止粘贴操作
            }
            event.stopPropagation();
        },
        changeVal(newVal,oldVal){
            if(this.pattern=="money"){//money 类型 正数 小数位不能超过2位
                if(!/^((([1-9]\d*)|\d?)([.]\d{0,2})?$)/g.test(newVal)){
                    if(/^((([0-9]\d*)|\d?)([.]\d{0,2})?$)/g.test(newVal)){
                        if(/^0+$/g.test(newVal)){
                            this.val="0";
                        }else{
                            this.val=newVal.replace(/^0*(?![.])/g,"");
                        }
                    }else{
                        this.val=oldVal;
                    }
                }
            }else if(this.pattern=="number"){//数字
                if(!/^(([-]?([1-9]\d*)|\d?)([.]\d*)?$)/g.test(newVal)){
                    if(/^((([0-9]\d*)|\d?)([.]\d*)?$)/g.test(newVal)){
                        if(/^0+$/g.test(newVal)){
                            this.val="0";
                        }else{
                            this.val=newVal.replace(/^0*(?![.])/g,"");
                        }
                    }else{
                        this.val=oldVal;
                    }
                }
                if(this.dplaces===0){
                    if(this.val!="" && this.val!=null && this.val!=undefined){
                        this.val=parseInt(this.val);
                    }
                }
                else if(this.val && this.dplaces>0){
                    let str=this.val+"";
                    if(str.indexOf('.')>=0){
                        let val= str.substr(0,str.indexOf('.')+this.dplaces+1);
                        if(val!=this.val){
                            this.val=val;
                        }
                    }
                }
            }else if(this.pattern=="phone"){//手机号类型
                if(newVal!="" && !/^[1][0-9]{0,10}$/g.test(newVal)){
                    this.val=oldVal;
                }
            }else if(this.pattern=="code"){//只能英文或数字
                if(newVal && !/^[A-z0-9]*$/g.test(newVal)){
                    this.val=oldVal;
                }
                if(this.maxlength>0 && this.val){
                     this.val= this.val.substr(0,this.maxlength);
                }
            }else if(this.pattern instanceof RegExp){//正则过滤
                if(newVal && !this.pattern.test(newVal)){
                    this.val=oldVal;
                }
            }
            if(this.pattern=="money" || this.pattern=="number"){
                if(this.min && parseFloat(this.val)< this.min){
                    this.val=oldVal;
                }
                if(this.max && parseFloat(this.val)>this.max){
                    this.val=oldVal;
                }
            }
            this.$emit('update:modelValue', this.val);
        },
        focus(){
            this.$refs.input.focus();
        },
        select(e){
            if(this.isFocSelect){
                setTimeout(()=>{
                    e.target.select();
                },0)
            }
        },
        addEventListener(type,event){
            this.$refs.input?.addEventListener(type,event);
        },
        keydown(e){
            if(this.readonly) return;
            if(e.key== 'Enter'){
                if(this.isBarcode){//是否启动检测 扫描枪输入 
                    console.log(e)
                    let time=(new Date()).getTime();
                    if(this.inputCard?.length>=5 && this.inputStartTime+this.inoutSpaceTime>time){//扫描枪输入
                        console.log("扫描枪输入 :"+this.inputCard)
                        this.inputStartTime=time;
                        this.inputCard="";
                        this.val=this.inputCard.trim();
                        this.$emit("barcode",e)
                        return;
                    }
                    this.inputStartTime=time;
                    this.inputCard="";
                }
            }
            this.$emit("keydown",e)
        }
    }

}
</script>
