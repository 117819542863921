<template>
    <modal class="ipdSetMealModel" :isShow="isShow">
        <div class="header">
            套餐选规格
            <div class="close-bnt" @click="hide()"><i class="iconfont icon-guanbi"></i></div>
        </div>
        <div class="modal-body">
            <div class="tc-box">
                <div class="picture">
                    <el-image :src="data?.Food_SmallImage" fit="fill">
                        <template #placeholder>
                            <div class="image-slot">Loading<span class="dot">...</span></div>
                        </template>
                        <template #error>
                            <div class="errBg" :style="{backgroundImage:'url(/images/notimg.png)'}"></div>
                        </template>
                    </el-image>
                    <div class="soldout"><img src="/images/soldout.png"></div>
                </div>
                <div class="tc-flex">
                    
                    <div class="displayFlex">
                        <div class="name nowrap2">{{setMealInfo?.Eat_TcName}}</div>
                        <div class="price"><span class="unit">￥</span>{{setMealInfo?.Eat_TcPrice}}<span class="unit">/份</span></div>
                    </div>
                    <div class="bnt-box">
                        <div class="remove" @click="setMealNumreduce()" :class="{disabled:!(setMealNum>1)}" v-if="setMealNum>0"><i class="iconfont icon-jian"></i></div>
                        <div class="number" v-if="setMealNum>0">{{setMealNum||0}}</div>
                        <div class="add" @click="setMealNumAdd()"><i class="iconfont icon-jia"></i></div>
                    </div>
                </div>
            </div>
            <el-scrollbar class="scroll-box" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
                <div class="item-box" v-for="setMeal in setMealInfo?.SetMealKinds" :key="setMeal">
                    <div class="title">{{setMeal.Kind_Name}}（必选项，最少选{{setMeal.Select_Number}}份，最多选{{setMeal.Count_Number}}份）</div>
                    <div class="tr-li" v-for="item in setMeal.SetMealDetails" :key="item">
                        <div class="display-flex">
                            <div class="left-flex-box" :class="{itemSellout:item.Is_SoldOut || (!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0)}">
                                <div class="name">{{item.Food_Name}}</div>
                                <div class="addPrice" v-if="item.Food_TcPrice>0">+<span class="unit">￥</span>{{item.Food_TcPrice}}元</div>
                                <div class="num-box" v-if="item.Is_Limited && item.Is_LimitedProject && item.RemainNum>0">剩: {{item.RemainNum}}</div>
                                <div class="sellout"><img src="/images/soldout.png"></div>
                            </div>
                            <div class="demand" @click="itemDemand(item)" v-show="item.Is_BindEatMas && item.Food_Number>0"><i class="iconfont icon-a-beizhu2"></i><span>要求</span></div>
                            <div class="bnt-box" v-if="item.Is_Fixed">
                                <div class="right-number">x{{item.Food_Number}}</div>
                            </div>
                            <div class="bnt-box" v-else>
                                <div class="remove" @click="setMealNumreduce(item)" :class="{disabled:!(item.Food_Number>1)}" v-if="item.Food_Number>0 && (!item.Is_Fixed || (item.Is_Fixed && item.Food_Number>1))"><i class="iconfont icon-jian"></i></div>
                                <div class="number" v-if="item.Food_Number>0">{{item.Food_Number||0}}</div>
                                <div class="add" @click="setMealNumAdd(item,setMeal)"><i class="iconfont icon-jia"></i></div>
                            </div>
                        </div>
                        <div class="details" v-show="item._Eat_MasName">要求:{{item._Eat_MasName}}</div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="footer-box">
            <div class="flex-box">
                <div class="describe nowrap3">已选：{{describe}}</div>
                <div class="money"><span class="unit">￥</span>{{money}}</div>
            </div>
            <div class="btn btn-confirm" @click="confirm()">选好了</div>
        </div>
        <!--口味做法-->
        <modal-load :isShow="isDemandShow">
            <ipd-demand-model ref="demandModel" :isShow="isDemandShow" @close="isDemandShow=false" :data="demandFood" @confirm="demandClick"></ipd-demand-model>
        </modal-load>
    </modal>
</template>

<script>
import { deepClone } from '/src/common'
import ipdDemandModel from '../ipdDemandModel/ipdDemandModel.vue' 
/**套餐 */
export default {
    name:"ipdSetMealModel",
    components:{ipdDemandModel},
    emits:["close","confirm"],
    props:{
        isShow:Boolean,
        data:{
            default:null,
            type:Object
        },
    },
    data(){
        return {
            //菜品数据
            info:null,
            //套餐数据
            setMealInfo:null,
            masKinds:null,
            eatMas:{},
            setMealNum:1,
            /**口味要求弹层 */
            isDemandShow:false,
            demandFood:null
        }
    },
    computed:{
        /**已选金额 */
        money(){
            let totalMoney=0;
            if(this.setMealInfo){
                totalMoney=this.setMealNum*this.setMealInfo?.Eat_TcPrice;
                this.setMealInfo?.SetMealKinds?.forEach(it=>{
                    it.SetMealDetails?.forEach((item)=>{
                        if(item.Food_Number>0 && item.Food_TcPrice>0){
                            totalMoney+=(item.Food_Number*this.setMealNum)*item.Food_TcPrice;
                        }
                        
                    })
                })
            }
            return parseFloat(totalMoney.toFixed(2));
        },
        /**已选数据 */
        describe(){
            let text=[];
            this.setMealInfo?.SetMealKinds?.forEach(it=>{
                it.SetMealDetails?.forEach((item)=>{
                    if(item.Food_Number>0)
                    text.push(item.Food_Name+"x"+item.Food_Number);
                })
            })
            return text.join("/");
        }
    },
    watch:{
        isShow(){
            if(this.isShow==true){
               this.init();
            }
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        })
    },
    methods:{
        init(){
            this.setMealNum=1;
            this.info=null;
            this.setMealInfo=null;
            if(this.data && this.data.SetMealInfo){
                this.info= deepClone(this.data);
                this.setMealInfo=this.info?.SetMealInfo;
                this.setMealInfo?.SetMealKinds?.forEach(it=>{
                    it.SetMealDetails?.forEach((item)=>{
                        if(!item.Is_Selected){
                            item.Food_Number=0;
                        }
                    })
                })
            }
        },
        hide(){
            this.$emit("close");
        },
        confirm(){
            let typecount=0;
            let isSoldOut=false;//选择的菜品中有 是否有沽清
            let error=[];
            this.setMealInfo?.SetMealKinds?.forEach(item=>{
                let itemNumCount=0;
                item.SetMealDetails?.forEach(set=>{
                   
                    if(set.Food_Number>0){
                        if(set.Is_SoldOut){
                            isSoldOut=true;
                        }
                        itemNumCount+=set.Food_Number

                        if(set.Is_BindEatMas && !(set._modifys?.length>0)){//有口味做法要求
                            error.push("【"+set.Food_Name+"】");
                        }
                    }
                })
                if(itemNumCount<item.Select_Number){//套餐分类下 最少选择数量
                    typecount=1;
                    return 
                }
                if(itemNumCount>item.Count_Number){//套餐分类下 最多选择数量
                    typecount=2;
                    return
                }
            })
            if(isSoldOut){
                this.$message.warning('选择的菜品中有沽清的菜品,无法添加到点菜列表中');
                return;
            }
            if(typecount==1){
                this.$message.warning('选择数量不满足套餐限制条件');
                return;
            }else if(typecount==2){
                this.$message.warning('选择数量超过套餐限制');
                return;
            }
            if(error.length>0){
                this.$confirm("套餐中"+error.join(",")+'菜品未选择做法，是否继续点单？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    this.$emit("confirm",this.setMealNum,this.info);
                }).catch(() => {});
                return;
            }
            this.$emit("confirm",this.setMealNum,this.info);
        },
        /**数量增加 */
        setMealNumAdd(item,setMeal){
            if(item){
                if(item.Is_Fixed==true){
                    return
                }
                let itemNumCount=0;
            
                setMeal.SetMealDetails.map(i=>{
                    itemNumCount+=i.Food_Number
                })
                if(itemNumCount>=setMeal.Count_Number || item.Food_Number>=setMeal.Count_Number){
                    this.$message.warning('选择数量超过套餐限制');
                }else{
                    item.Food_Number++;
                }
            }else{
                this.setMealNum++;
            }
        },
        /**数量减少 */
        setMealNumreduce(item){
            if(item){
                if(item.Is_Fixed){//是否固定项，false: 否，true:是 ，如果为true 标识该子项菜品必须存在，不允许换菜
                    return
                }
                item.Food_Number--;
                if(item.Food_Number<0){
                    item.Food_Number=0
                }
            }else{
                if(this.setMealNum>1){
                    this.setMealNum--;
                }
            }
        },
        /**点击口味要求 */
        itemDemand(item){
            this.demandFood=item;
            this.isDemandShow=true;
        },
        /**口味要求 返回 */
        demandClick(optFlavors){
            if(optFlavors?.length>0){
                this.demandFood._Eat_MasName=optFlavors.map(it=>it.Mas_Name).join(",")
                this.demandFood._modifys=optFlavors;
            }else{
                this.demandFood._Eat_MasName="";
                this.demandFood._modifys=null;
            }
            this.isDemandShow=false;
        }
    }
}
</script>

<style lang="scss">
    @import './ipdSetMealModel.scss'
</style>