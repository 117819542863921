<template>
  <modal class="readyToUsePackageModel" :isShow="isShow" @keyup.stop="keyup">
    <div class="header" v-if="readyShowType==0">即配套餐</div>
    <div class="header" v-else>即配套餐换菜</div>
    <div class="modal-body">
        <div class="clearfix flex-box">
            <div class="left-box">
                <div class="table-box">
                    <div class="panel panel-table">
                        <div class="panel-heading">
                            <div class="tr">
                                <div class="td name" style="padding-left:15px;">名称</div>
                                <div class="tdnum">数量</div>
                                <div class="td">单价</div>
                                <div class="td">小计</div>
                                <div class="td">菜品类别</div>
                                <!-- <div class="td">操作</div> -->
                            </div>
                        </div>
                        <div class="panel-body" ref="box"  v-mouse-scroll  v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper',paddingH:'25'}" v-if="readyorderMenuList">
                            <div class="slide-wrapper"  :class="{selected:item.Food_Status>=0,selectedRow:this.Recordindex==index}" v-for="(item,index) in readyorderMenuList"  :key="item">
                                <div class="animate-slide-start">
                                    <!--:class="{selected:this.Recordindex==index}"-->
                                    <div class="slide-content"  @click="readyClick(item,index)" :title="item.EAT_XFName" >
                                        <div style="position: relative">
                                            <div class="tr">
                                                <div class="td name nowrap" style="font-weight:600;">{{item.EAT_XFName}}</div>
                                                <div class="tdnum" style="font-weight:600;">{{item.Eat_Number}}</div>
                                                <div class="td" style="font-size: 13px;font-weight:600;">{{Number((item.Eat_CheckPrice).toFixed(2))}}/{{item.Eat_XFSize}}</div>
                                                <div class="td" style="font-size: 13px;font-weight:600;">{{Number((item.Eat_CheckPrice * item.Eat_Number).toFixed(2))}}</div>
                                                <div class="td" style="font-size: 13px;font-weight:600;">{{item.SubCategory_Name}}</div>
                                            </div>
                                            <div class="describe" :class="{selected:this.Recordindex==index}">
                                                <div class="inlineBlock float nowrap" 
                                                    style="width:59%;max-width: calc(100% - 111px);font-size:12px;padding-left:10px;padding-top:10px;">
                                                    <span v-if="item.Eat_MasName!=''" :title="item.Eat_MasName" >
                                                        <i class="iconfont icon-a-beizhu2"></i>
                                                        口味:<span>{{item.Eat_MasName}}</span>
                                                    </span>
                                                </div>
                                                <div class="inlineBlock tag-box">
                                                    <!--厨房状态-->
                                                    <span class="tag delimit" v-if="item.KitSpeed_Dimension">{{item.KitSpeed_Dimension}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="orderFooter">
                        <div class="float-left txt">
                            <span class="money" v-html="deskCount(1)"></span>&nbsp;
                            <span class="small">共&nbsp;{{deskCount(3)}}道 &nbsp; {{deskCount(2)}}份</span>
                        </div>
                        <div class="float-right" @mousedown="(e)=>{e.preventDefault()}">
                            <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiangquan-top"></i></div>
                            <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiangquan-bottom"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="operate-button">
                <div class="scroll scrollnot">
                    <div class="button-group">
                        <!---->
                        <button class="btn" @click="Delete(Recordindex,Recordvarie)" ><div>单删</div></button>
                        <button class="btn" @click="kitchenClick()"><div>厨房</div></button>
                        <button class="btn" @click="cancelDoSoldOut(Recordvarie)"><div>要求</div></button>
                        <button class="btn" @click="Add()"><div><b class="iconfont icon-jia"></b></div></button>
                        <button class="btn" @click="reduce()"><div><b class="iconfont icon-jian"></b></div></button>
                        <button class="btn" @click="Numberadjust()"><div>数量</div></button>
                        <button class="btn" @click="withMenuShow=true"><div>配菜台</div></button>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="panel-body">
                    <div class="filter-nav"  v-if="orderMap_Type==2 ||orderMap_Type==1">
                        <div class="nav-box" v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'foodNavPage',isNavFirstSticky:true}">
                            <div class="nav-li" :class="{selected:mainCategoryIndex==0}" @click="onFoodNav()">全部大类</div>
                            <div class="nav-li" :class="{selected:mainCategoryIndex==index+1}" v-for="(item,index) in foodMainCategory" :key="item" @click="onFoodNav(item,index+1)">{{item.MainCategory_Name}}</div>
                        </div>
                        <div class="float-right page-box">
                            <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                            <span class="page-number">{{foodNavPage.pageIndex}}/{{foodNavPage.pageSize}}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down"></span>
                        </div>
                    </div>
                    <div>
                        <div class="filter-nav" v-if="orderMap_Type==3 ||orderMap_Type==1">
                            <div class="nav-box" v-scroll-anime="{up:'nav-sub-up',down:'nav-sub-down',fx:'x',liClass:'.nav-li',page:'foodNavPageSub',isNavFirstSticky:true}">
                                <div class="nav-li" :class="{selected:subCategoryIndex==0}" @click="onFoodSubNav()">全部小类</div>
                                <div class="nav-li" :class="{selected:subCategoryIndex==index+1}" v-for="(item,index) in foodSubCategorys" :key="item" @click="onFoodSubNav(item,index+1)">{{item.SubCategory_Name}}</div>
                            </div>
                            <div class="float-right page-box">
                                <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-sub-up" ></span>
                                <span class="page-number">{{foodNavPageSub.pageIndex}}/{{foodNavPageSub.pageSize}}页</span>
                                <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-sub-down"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel menu" :style="menuHeight">
                    <!-- 菜单 -->
                    <div class="item-box clearfix" v-if="posFoods!=''" >
                        <div class="item-li " :class="{selected:item.Food_ID==choicePosFoods}" v-for="item in foodsPages"  :key="item" @click="onPlaceFoods(item,1)">
                            <div class="title">
                                {{item.Food_Name}}
                            </div>
                            <div class="bottom-box">
                                <span class="text-left" v-if="item.Is_Limited  && item.Is_LimitedProject">剩: {{item.RemainNum}}</span>
                                <span class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</span>
                            </div>
                            <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                            <div class="tag tag-grey" v-if="!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0">售罄</div>
                            <div class="tag" v-if="item.isDiy">自定义</div>
                        </div>
                    </div>
                </div>
                <div class="search-box">
                    <key-board-input class="search"
                        type="text" v-model="varietySelect" ref="searchBox" placeholder="菜品模糊检索"
                        @keydown="keyCursor" isBlurClose
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                    <div class="float-right">
                        <span class="last-page iconfont icon-fangxiangquan-left" @click="pageBotton()"></span>
                        <span class="page-number">{{ posFoodsPage.pageIndex }}/{{ posFoodsPage.pageSize }}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" @click="pageBotton(1)" ></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="tr-li">
            <div class="lable-txt">套餐名称：</div>
            <div class="from-input"><input type="text" v-model="FoodName" v-board></div>
            <div class="lable-txt">套餐售价：</div>
            <key-board-input class="from-input"
                type="text" v-model="FoodPrice" pattern="number" :dplaces="2" :min="0" 
                :isBoard="true"
                :isKeyEnterClose="true"
                :isBlurClose="true"
                :keyOptions="{isKeyDownEnter:true,isNumber:true}"
            ></key-board-input>
            <div class="lable-txt">溢价金额</div>
            <div class="from-inputTxt">{{Number(PremiumAmount).toFixed(2)}}</div>
            <div class="lable-txt" v-show="ProfitRate==true">GM:</div>
            <div class="from-inputTxt" style="color:red;" v-show="ProfitRate==true">{{orderComplete}}%</div>
        </div>
    </div>
    <div class="footer-box">
        <button class="btn btn-cancel" @click="hide()">取消</button>
        <button class="btn btn-confirm" @click="confirm()">确认</button>
    </div>
     <!--菜品换单位-->
    <modal-load :isShow="unitShow">
        <change-Unit-Model :isShow="unitShow" v-on:closeModel="unitShow=false,this.$refs.searchBox.focus()" :multipleSizeFoods="multipleSizeFoods" @onPlaceFoods="onPlaceFoods" ></change-Unit-Model>
    </modal-load>
    <!-- 套餐 -->
    <modal-load :isShow="setMealShow">
        <set-Meal-model :isShow="setMealShow" v-on:closeModel="setMealShow=false,this.$refs.searchBox.focus()" :posFoodSetMealInfos="posFoodSetMealInfos" @setMealClick="setMealClick" > </set-Meal-model>
    </modal-load>
    <!--口味要求-->
    <modal-load :isShow="demandFullShow">
        <readydemand-model :isShow="demandFullShow" v-on:closeModel="demandFullShow=false,this.$refs.searchBox.focus()" :allfoodSubCategorys="readyAllfoodSubCategorys" :readyorderMenuList="readyorderMenuList" :masKindsList="masKindsList" :disRadioOrder="Recordvarie" @confirmall="confirmall" >
        </readydemand-model>
    </modal-load>
    <!-- 自定义菜品 -->
    <modal-load :isShow="diyFoodsShow">
        <diy-foods-model :isShow="diyFoodsShow" v-on:closeModel="diyFoodsShow=false,this.$refs.searchBox.focus()" :data="diyFoodInfo" @confirm="difConfirm" > </diy-foods-model>
    </modal-load>
    <!--数量调整-->
    <modal-load :isShow="adjustShow">
        <adjust-quantity-model :isShow="adjustShow" v-on:closeModel="adjustShow=false,this.$refs.searchBox.focus();" :Recordvarie="Recordvarie" @quantityReturn="quantityReturn"></adjust-quantity-model>
    </modal-load>
    <!--厨房-->
    <modal-load :isShow="kitchenShow">
        <ready-kitchen-model :isShow="kitchenShow"  v-on:closeModel="kitchenShow=false,this.$refs.searchBox.focus();" :readyorderMenuList="readyorderMenuList" :allfoodSubCategorys="readyAllfoodSubCategorys" @kitchenClickBack="kitchenClickBack"></ready-kitchen-model>
    </modal-load>
    <!--配菜单 只引用菜品-->
    <modal-load :isShow="withMenuShow" isUnload>
        <with-menu-read-model :isShow="withMenuShow" :isQuote="true" v-on:closeModel="withMenuShow=false,this.$refs.searchBox?.focus();" @quoteReturn="quoteReturn"></with-menu-read-model>
    </modal-load>
  </modal>
</template>

<script>
import { newGuid,deepClone,animation } from '/src/common/index.js'
import {cacheData} from '/src/services'
import changeUnitModel from '../changeUnitModel/changeUnitModel.vue'
import setMealModel from '../setMealModel/setMealModel.vue'
//import demandFullModel from '../demandFullModel/demandFullModel.vue'
import diyFoodsModel from './diyFoodsModel/diyFoodsModel.vue'
import adjustQuantityModel from './adjustQuantityModel/adjustQuantityModel.vue'
import readyKitchenModel from './readyKitchenModel/readyKitchenModel.vue'
import readydemandModel from './readydemandModel/readydemandModel.vue'
import withMenuReadModel from './../withMenuModel/withMenuReadModel.vue'

/**全局初始化数据 */
let _data={
    arg:{row:5,col:4,marginW:'6',marginH:'6'},
    args:{row:3,col:6,marginW:'8',marginH:'8'},
    RunOrderDeviceShowBillProfitRate:false /**启用点单设备显示账单毛利率，true: 显示，false:不显示 */
};

/**即配套餐 */
export default {
    name:"readyToUsePackageModel",
    components:{
        changeUnitModel,
        setMealModel,
        // demandFullModel,
        diyFoodsModel,
        adjustQuantityModel,
        readyKitchenModel,
        readydemandModel,
        withMenuReadModel,
    },
    props:['isShow','disRadioOrder','readyShowType','orderInfo','readyAllfoodSubCategorys','isNoFilter'],
    data(){
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.RunOrderDeviceShowBillProfitRate = globalSysSetting.RunOrderDeviceShowBillProfitRate;
        }
        return {
            unitShow:false,
            setMealShow:false,
            demandFullShow:false,
            adjustShow:false,
            kitchenShow:false,
            multipleSizeFoods:'',
            //自定义菜品数据
            PosDiyFoodsData:[],
            //pos菜品数据
            PosFoodsData:{},
            /**大类数据 */
            //foodMainCategory:[],
            mainCategoryIndex:0,
            /**小类数据 */
            foodSubCategorys:[],
            subCategoryIndex:0,
            /**菜品数据  */
            posFoods:[],
            /**菜品页码 */
            posFoodsPage:{
                pageIndex:1,
                pageSize:1
            },
            arg:_data.arg,
            args:_data.args,
            /**对应套餐数据 */
            posFoodSetMealInfos:[],
            /**菜品口味 */
            masKindsList:'',
            /**下单菜品数据 */
            foodNavPage:{pageIndex:0,pageSize:0},
            foodNavPageSub:{pageIndex:0,pageSize:0},
            menuHeight:'height:calc(100% - 35px - 35px - 38px)',
             /**菜品检索 */
            varietySelect:'',
            orderMap_Type:'',
            /**选择的菜品 */
            choicePosFoods:0,
            readyorderMenuList:[],
            /**选择的菜品信息*/
            ConsumeList:'',
            /**套餐名称 */
            FoodName:'',
            /**套餐售价 */
            FoodPrice:'',
            /**选择的套餐总价 */
            placeFoodsMoney:0,
            FoodSetmeals:[],
            /**即配套餐模板 */
            DiyTcInfo:'',
            /**临时时间戳 */
            tempTimestamps:'',
            //自定义菜品弹层是否显示
            diyFoodsShow:false,
            //选中的自定义菜品 
            diyFoodInfo:null,
            readydisRadioOrder:'',
            //diyfoodType:0,
            //记录左边点击的菜品
            Recordvarie:'',
            Recordindex:-1,
            ProfitRate:false,//启用点单设备显示账单毛利率，true: 显示，false:不显示
            /**配菜单弹层 */
            withMenuShow:false
        }
    },
    mounted(){
       this.$nextTick(()=> {
            this.$cacheData.DeskPosconfigure().then((d)=>{
                this.orderMap_Type = d.OrderMap_Type
                this.DiyTcInfo={
                    SubCategory_ID:d.DiyTcInfo?.BRAND_ID,
                    EAT_XFBMID:d.DiyTcInfo?.EAT_XFBMID,
                    EAT_XFCode:d.DiyTcInfo?.EAT_XFCODE,
                    EAT_XFName:'',
                    Eat_Number:1,
                    Eat_CheckPrice:0,
                    ModiPrice:0,
                    Eat_SalePrice:0,
                    Eat_MasName:'',
                    Food_Status:-1,
                    Food_Speed:'',
                    Eat_XFSize:d.DiyTcInfo?.EAT_XFSIZE,
                    FoodModifys:[],
                    FoodSetmeals:null,
                    Is_Discounted:false,
                    Is_Seasonal:false,
                    RemainNum:0,
                    Is_Limited:false,
                    Is_ChangedNum:false,
                    Is_ChargeForService:false,
                    Is_SetMeal:true,
                    Eat_ConsumeID:'',
                    Food_Type:3,
                    DiyTcType:3,
                    FoodProperty_Dimension:'套',
                    Is_SetMealCountAvgPrice:false
                }
                if(d.DiyTcInfo?.IS_PERAVGPRICETC || d.DiyTcInfo?.IS_PERAVGPRICETC==0){
                    if(d.DiyTcInfo?.IS_PERAVGPRICETC==1){
                        this.DiyTcInfo.Is_SetMealCountAvgPrice=true
                    }
                    //this.DiyTcInfo.Is_SetMealCountAvgPrice = d.DiyTcInfo?.Is_SetMealCountAvgPrice
                }
                if(this.orderMap_Type==2 || this.orderMap_Type==3){
                    this.menuHeight='height:calc(100% - 35px - 38px)'
                }
            }).catch(e=>{
                this.$alert("基本配置获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
            this.init()
        })
    }, 
    computed:{
        //达成率
        orderComplete(){
            let achieving=0 //达成率
            let costMonye=0 //账单总成本金额
            let saleMoney=0 //账单总销售金额
            let foodMoney=0 //口味要求价格
            if(this.FoodPrice>0){
                 if(this.readyorderMenuList.length>0){
                    this.readyorderMenuList.map(read=>{
                        if(read?.Food_CostPrice){
                            costMonye += Number(read.Food_CostPrice) * Number(read.Eat_Number)
                        }
                        if(read?.FoodModifys){
                            read?.FoodModifys.map(food=>{
                                if(food.Mas_Price>0){
                                    if(food.Price_Kind==0){
                                        foodMoney += Number(food.Mas_Price) * Number(read.Eat_Number)
                                    }else if(food.Price_Kind==1){
                                        foodMoney += Number(food.Mas_Price) * Number(food.Mas_Num)
                                    }
                                }
                            })
                        }
                    })
                    saleMoney +=Number(this.FoodPrice)+foodMoney
                }
                if(saleMoney>0 || costMonye>0){
                    achieving =(saleMoney-costMonye)/saleMoney
                }
            }
            return Number(Number(achieving) * 100).toFixed(2)
        },
        //大类数据
        foodMainCategory(){
            let data=[];
            let d=this.PosFoodsData?.data;
            if(d && d.length>0){
                data=JSON.parse(JSON.stringify(d));
                let diyFoodSubCategorys=Object.assign([],this.PosDiyFoodsData);//未合并进去的自定义菜品
                diyFoodSubCategorys.forEach(it=> 
                    it.PosFoods?.forEach((f)=>{
                        f.isDiy=true;
                    })
                )
                if(this.PosDiyFoodsData && this.PosDiyFoodsData.length>0){//合并 自定义菜品
                    data.forEach(category=> {
                        category?.FoodSubCategorys?.forEach(sub => {
                            let index=diyFoodSubCategorys.findIndex(f=>f.SubCategory_ID==sub.SubCategory_ID);
                            if(index>=0){
                                let difSub=diyFoodSubCategorys[index];
                                diyFoodSubCategorys.splice(index,1);//剔除
                                if(difSub && difSub.PosFoods?.length>0){
                                    sub.PosFoods=sub.PosFoods.concat(difSub.PosFoods);
                                }
                            }
                        });
                    });
                }

                let all=[];//全部大类
                data.forEach(category=> {
                    if(category.FoodSubCategorys && category.FoodSubCategorys.length>0){
                        all=all.concat(category.FoodSubCategorys);
                    }
                });

                if(diyFoodSubCategorys?.length>0){//将未合并进去的自定义菜品 分类都合并到全部小类中
                    all=all.concat(diyFoodSubCategorys);
                }
                all._all=[];//全部小类
                if(all.length>0){
                    all.forEach(sub => {
                        if(sub.PosFoods && sub.PosFoods.length>0)
                        all._all=all._all.concat(sub.PosFoods)
                    });
                }
                data._all=all; //小分类
            }
            return data;
        },
        /**临时菜品数据 */
        TempposFoods(){
            return this.foodMainCategory?._all||[];
        },
        //全部小类数据
        allfoodSubCategorys(){
            let subCategorys=[];
            this.foodMainCategory?.forEach((category)=>{
                category?.FoodSubCategorys?.forEach(sub=>{
                    if(sub){
                        let _SubCategory={SubCategory_Name:sub.SubCategory_Name,SubCategory_ID:sub.SubCategory_ID}
                         subCategorys.push(_SubCategory);
                    }
                })
            })
            return subCategorys;
        },
        /**菜品分页显示 */
        foodsPages(){
             let size=5*4;
            return this.posFoods.slice((this.posFoodsPage.pageIndex-1)*size,this.posFoodsPage.pageIndex*size);
        },
        /**溢价金额 */
        PremiumAmount(){
            let PremiumAmount='';
            if(Number(this.FoodPrice)>0){
                if(this.readyShowType==0){
                    PremiumAmount = Number(this.FoodPrice) - Number(this.placeFoodsMoney)
                }else{
                    PremiumAmount = Number(Number(this.FoodPrice) * this.disRadioOrder.Eat_Number) - Number(this.placeFoodsMoney)
                }
            }
            return PremiumAmount
        }
    },
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() { 
             if(this.isShow==true){
                 this.init()
             }
        },
        /**菜品搜索 */
        varietySelect(newVal,oldVal){
            if(newVal && (newVal.indexOf('+')>=0 || newVal.indexOf('-')>=0 || newVal.indexOf('=')>=0 || newVal.indexOf('_')>=0 )){
                this.$nextTick(()=>{
                    this.varietySelect=oldVal;
                })
                return;
            }
            if(this.varietySelect!=''){
                this.subCategoryIndex=0;
                this.mainCategoryIndex=0;
                this.posFoods=[];
                let MenuTxt=this.varietySelect.toLowerCase();
                this.$nextTick(()=>{
                    if(this.foodsPages && this.foodsPages.length>0){
                        this.choicePosFoods=this.foodsPages[0].Food_ID;
                    }
                })
                this.foodMainCategory.map(item=>{
                    item.FoodSubCategorys.map(food=>{
                        food.PosFoods.map(pos=>{
                            if((pos.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0){
                                this.posFoods.push(pos)
                            }
                        })
                    })
                })
            }else{
                this.posFoods =this.foodMainCategory?._all?._all||[];
            }
        },
        posFoods(){
            let size=5*4;
            this.posFoodsPage.pageIndex=1;
            let pageSize=Math.ceil(this.posFoods.length/size);
            if(pageSize==0){
                pageSize=1;
            }
            this.posFoodsPage.pageSize=pageSize;
        }
    },
    methods:{
        keyup(e){
            if(e.key=="+" || e.code=="NumpadAdd"){
                this.Add()
                this.$msgbox.close();
            }else if(e.key=="-" || e.code=="NumpadSubtract"){
                this.reduce()
                this.$msgbox.close();
            }else if(e.key=="Escape"){
                this.hide()
            }
        },
         /**初始化 */
        init(){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            this.choicePosFoods=0
            this.readyorderMenuList=[]
            this.FoodSetmeals=[]
            this.FoodName='即配套餐';
            this.FoodPrice=''
            this.ProfitRate = true;
           // this.PremiumAmount=0
            //this.diyfoodType=0
            this.Recordvarie='',this.Recordindex=-1
            if(this.orderInfo){
                this.tempTimestamps = this.orderInfo.Timestamps.toString()
            }
            this.readydisRadioOrder = JSON.parse(JSON.stringify(this.disRadioOrder))
            console.log(this.disRadioOrder)
            /**即配套餐下单前获取数据 */
            if(this.readyShowType==1){
                if(this.readydisRadioOrder.FoodSetmeals){
                    this.FoodName=this.readydisRadioOrder.EAT_XFName
                    this.FoodPrice = this.readydisRadioOrder.Eat_SalePrice
                    
                }
            }
            let isLoadFoods=false,isLoadDiyFoods=false;
            let nextFun=()=>{
                if(isLoadFoods && isLoadDiyFoods){
                    this.$nextTick(()=>{
                        this.onFoodNav();
                        this.readyType();
                        /**即配套餐下单前获取数据 */
                        if(this.readyShowType==1){
                            this.readydisRadioOrder?.FoodSetmeals?.map(item=>{
                                this.TempposFoods._all.find(pos=>{
                                    if(item.Eat_XFBMID === pos.Food_ID){
                                        item.Eat_CheckPrice = pos.Food_Price
                                        if(pos?.isDiy){
                                            if(pos?.Eat_SetPrice){
                                                item.Eat_CheckPrice = pos?.Eat_SetPrice
                                            }
                                        }
                                        item.base_Number=1;
                                        this.readyorderMenuList.push(item);
                                        this.FoodSetmeals.push({
                                            FoodSetmeal_ID:item.EAT_XFBMID,
                                            FoodSetmeal_Code:item.EAT_XFCode,
                                            FoodSetmeal_Name:item.EAT_XFName,
                                            FoodSetmeal_Number:item.Eat_Number,
                                            FoodSetmeal_Price:0,
                                            FoodSetmeal_SetPrice:item.Eat_CheckPrice,
                                            FoodSetmeal_Size:item.Eat_XFSize,
                                            FoodSaleMoney:0,
                                            FoodModifys:item.FoodModifys,
                                            Eat_MasName:item.Eat_MasName,
                                            ModiPrice:0,
                                            Food_Status:-1,
                                            base_Number:item.base_Number,
                                            Eat_ConsumeID:item.Eat_ConsumeID,
                                        })
                                        return true;
                                    }
                                    return false;
                                })
                            })
                        }
                    })
                }
            }
            //菜品数据结构
            this.$cacheData.PosFoods().then((d)=>{
                this.PosFoodsData=d;
                isLoadFoods=true;
                nextFun();
            }).catch(e=>{
                isLoadFoods=true;
                console.log('e:'+JSON.stringify(e))
                this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
            })

            /**自定义菜品数据结构 */
            this.$cacheData.PosDiyFoods().then((d)=>{
                this.PosDiyFoodsData=d;
                isLoadDiyFoods=true;
                nextFun();
            }).catch(e=>{
                isLoadDiyFoods=true;
                console.log('e:'+e)
                this.$alert('未找到自定义菜品数据', "提示", {confirmButtonText: "确定"});
            })

            /**菜品口味 */
            this.$cacheData.MasKinds().then((d)=>{
                this.masKindsList = d;
            }).catch(e=>{
                this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**控制菜品搜索框 上下左右光标 */
        keyCursor(e){
            if(e.keyCode=='37' || e.keyCode=='38' || e.keyCode=='39' || e.keyCode=='40' || e.keyCode=='13'){
                e.preventDefault();
                if(this.foodsPages && this.foodsPages.length>0){
                    let index=this.foodsPages.findIndex(item=>item.Food_ID==this.choicePosFoods)||0;
                    if(e.keyCode=='37'){//左边
                        index--;
                    }else if(e.keyCode=='38'){//上
                        index=index-this.arg.col
                    }else if(e.keyCode=='39'){//右边
                        index++;
                    }else if(e.keyCode=='40'){//下
                        index=index+this.arg.col;
                    }else if(e.keyCode=='13'){
                        if(this.foodsPages.length>0){
                            this.onPlaceFoods(this.foodsPages[index],1)
                        }else{
                            this.$message.warning('未找到菜品');
                            return
                        }
                        return;
                    }
                    if(index>=0 && index<this.foodsPages.length){
                        this.choicePosFoods=this.foodsPages[index].Food_ID;
                    }
                }
            }
        },
        readyType(){
            if(this.readyShowType==2){
                this.FoodName=this.readydisRadioOrder.EAT_XFName
                this.FoodPrice = this.readydisRadioOrder.Eat_SalePrice
                this.readydisRadioOrder.Eat_TcItemList.map(item=>{
                    this.posFoods.map(pos=>{
                        if(item.EAT_XFBMID === pos.Food_ID && item.Eat_Number>0){
                            item.Eat_CheckPrice = pos.Food_Price
                            if(pos?.isDiy){
                                if(pos?.Eat_SetPrice){
                                    item.Eat_CheckPrice = pos?.Eat_SetPrice
                                }
                            }
                            this.readyorderMenuList.push(item)
                        }
                    })
                })
            }
        },
        /**数据分页 */
        pageBotton(type){
            if(type==1){
                if(this. posFoodsPage.pageIndex<this.posFoodsPage.pageSize){
                    this.posFoodsPage.pageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.posFoodsPage.pageIndex==1){
                    this.$message.warning('已到第一页');
                }
                this.posFoodsPage.pageIndex<=1?1:this.posFoodsPage.pageIndex--;
            }
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            //if(this.diyfoodType==0){
            this.$emit("ReadycloseModel",this.tempTimestamps);
            //}
            // else{
            //     this.confirm()
            // }
            
        },
        /**确定和回车事件 readyShowType:2 已下单的即配套餐,1未下单的即配套餐,0新增即配套餐 */
        confirm(){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            let FoodDiyTc=''
            if(this.FoodSetmeals.length==0 && this.readyShowType!=2){
                this.$message.error('自定义的套餐菜品不能为空');
                 return
            }
            this.FoodSetmeals.forEach(it=>{
                it.FoodModifys?.forEach(modify=>{
                    if(modify.Price_Kind==0){//按量加价
                        modify.Mas_Num=it.FoodSetmeal_Number;
                    }
                })
            })
            if(!this.FoodName){
                 this.$message.error('套餐名不能为空');
                 return
            }
            if(!this.FoodPrice){
                 this.$message.error('价格不能为空');
                 return
            }
            if(this.readyShowType==2){
                let userInfo=this.$auth.getUserInfo();
                let param={
                    Body_ID:newGuid(),
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Eat_ConsumeID:this.readydisRadioOrder.Eat_ConsumeID, //套餐头主键
                    Eat_TcName:this.FoodName,  //套餐的名称
                    Eat_SaleMoney: Number(this.FoodPrice) * Number(this.disRadioOrder.Eat_Number), //套餐的价格
                    OrderConsumeList:this.FoodSetmeals, //重构后临时套餐
                    Eat_AutoID:this.orderInfo.Eat_AutoID,//订单的主键
                    OrderTimestamps:this.tempTimestamps.toString(), //本桌台订单时间戳
                }
                if(sessionStorage.getItem("tempUserID")){
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                const loading = this.$loading({
                    text: "即配套餐重构中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.DiySetmealRefactor",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc)
                    }else{
                        this.$emit("readyPackageUpdateReturn",data)
                        return
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else{
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
                this.DiyTcInfo.FoodSetmeals=this.FoodSetmeals
                this.DiyTcInfo.Eat_ConsumeID = newGuid()
                this.DiyTcInfo.EAT_XFName = this.FoodName
                this.DiyTcInfo.Eat_CheckPrice = Number(this.FoodPrice)
                this.DiyTcInfo.Eat_SalePrice = Number(this.FoodPrice)
                FoodDiyTc = deepClone(this.DiyTcInfo);
                this.$emit("readyPackageReturn",FoodDiyTc)
            }
        },
        /**点击大类*/
        onFoodNav(item,index){
            if(item){
                if(!item.FoodSubCategorys._all){
                    item.FoodSubCategorys._all=[];//全部菜品
                    item.FoodSubCategorys.forEach(sub => {
                        if(sub.PosFoods && sub.PosFoods.length>0)
                        item.FoodSubCategorys._all=item.FoodSubCategorys._all.concat(sub.PosFoods)
                    });
                }
                this.foodSubCategorys=item.FoodSubCategorys;
                this.mainCategoryIndex=index;
                this.onFoodSubNav();
            }else if(this.foodMainCategory && this.foodMainCategory._all){//全部
                this.foodSubCategorys=this.foodMainCategory._all;
                this.mainCategoryIndex=0;
                this.onFoodSubNav();
            }
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**点击小类*/
        onFoodSubNav(item,index){
            if(item){
                this.posFoods=item.PosFoods;
                this.subCategoryIndex=index;
            }else if(this.foodSubCategorys && this.foodSubCategorys._all){//全部
                this.posFoods=this.foodSubCategorys._all;
                this.subCategoryIndex=0
            }
            this.varietySelect="";
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**菜品点击*/
        onPlaceFoods(item,type){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            if(item?.isDiy && type==1){//点击自定菜品
                this.diyFoodsShow=true;
                this.diyFoodInfo=item;
                this.choicePosFoods = item.Food_ID;
                return;
            }
            let _SubName=''
            this.allfoodSubCategorys?.map(allfood=>{
                if(item.SubCategory_ID==allfood.SubCategory_ID){
                    _SubName=allfood.SubCategory_Name
                }
            })
            // let _RemainNum=0
            // if(!this.isNoFilter){//菜品加入是否做判断
            //     if(item.Is_SoldOut){
            //         this.$message({message: '该菜品已售完',type: 'warning'});
            //         return
            //     }
            //     if(item.Is_Limited==true && item?.Is_LimitedProject){
            //         if(item.RemainNum==0){
            //             this.$message({message: '该菜品已售完',type: 'warning'});
            //             return
            //         }else{
            //             this.readyorderMenuList.map(order=>{
            //                 if(order.EAT_XFBMID==item.Food_ID){
            //                     _RemainNum++
            //                 }
            //             })
            //             if(_RemainNum>=item.RemainNum){
            //                 this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
            //                 return
            //             }
            //         }
            //     }
            // }
            /**判断是否多规格菜品 */
            if(item.Is_MultipleSize){
                if(item.Is_MultipleSize==true){
                    this.unitShow=true
                    this.multipleSizeFoods = item
                    return
                }
            }
            this.unitShow=false
            let _item='';
            this.choicePosFoods = item.Food_ID;
            /**是否是套餐 */
            if(item.Is_SetMeal){
                this.posFoodSetMealInfos=[]
                this.posFoodSetMealInfos.push(item);
                this.setMealShow=true;
            }else{
                let readType=0
                if(!item?.isDiy){
                    this.readyorderMenuList.map((read,i)=>{
                        if(read.EAT_XFBMID==item.Food_ID && read.Food_Status==-1){
                            read.Eat_Number= Number(read.Eat_Number)+1
                            readType=1
                            this.readyClick(read,i);
                        }
                    })
                }
                if((type==1 || type==2) || this.readyShowType!=2){
                    let foodtype=0
                    this.FoodSetmeals.map(food=>{
                        if(food.FoodSetmeal_ID==item.Food_ID && food.FoodSetmeal_Name==item.Food_Name){
                            food.FoodSetmeal_Number= food.FoodSetmeal_Number+1
                            foodtype=1
                        }
                    })
                    if(foodtype==0){
                        let _num = 1
                        let foodModi =null
                        let _Price=0
                        if(item?.Eat_Number){
                            _num = item?.Eat_Number
                            foodModi = item?.FoodModifys
                            _Price = item?.Food_Price
                        }
                        this.FoodSetmeals.push({
                            FoodSetmeal_ID:item.Food_ID,
                            FoodSetmeal_Code:item.Food_Code,
                            FoodSetmeal_Name:item.Food_Name,
                            FoodSetmeal_Number:_num,
                            FoodSetmeal_Price:0,
                            FoodSetmeal_SetPrice:_Price,
                            FoodSetmeal_Size:item?.Food_Size,
                            FoodSaleMoney:0,
                            Food_CostPrice:item?.Food_CostPrice,
                            FoodModifys:foodModi,
                            Eat_MasName:'',
                            ModiPrice:0,
                            Food_Status:-1,
                            base_Number:_num,
                            Eat_ConsumeID:newGuid()
                        })
                    }
                }
                if(readType==0){
                    _item ={SubCategory_ID:item.SubCategory_ID,
                        EAT_XFBMID:item.Food_ID,
                        EAT_XFCode:item.Food_Code,
                        EAT_XFName:item.Food_Name,
                        Eat_Number:1,
                        Eat_CheckPrice:item.Food_Price,
                        PriceSubtotal: item.Food_Price,
                        ModiPrice:0,
                        Eat_SalePrice:item.Food_Price,
                        Food_CostPrice:item?.Food_CostPrice,
                        Eat_MasName:'',
                        Food_Status:-1,
                        Eat_XFSize:item.Food_Size,
                        FoodModifys:[],
                        FoodSetmeals:null,
                        Is_Discounted:item.Is_Discounted,
                        Is_Seasonal:item.Is_Seasonal,
                        RemainNum:item.RemainNum,
                        Is_Limited:item.Is_Limited,
                        Is_ChangedNum:item.Is_ChangedNum,
                        Is_ChargeForService:item.Is_ChargeForService,
                        SubCategory_Name:_SubName,
                        Is_SetMeal:item.Is_SetMeal,
                        base_Number:1,
                        indenttion:newGuid()
                    };
                    if(item.isDiy){
                        _item.Eat_Number= item.Eat_Number;
                        _item.Eat_MasName=item.Eat_MasName;
                        _item.FoodModifys=item.FoodModifys;
                    }
                    this.readyorderMenuList.push(_item);
                    this.readyClick(_item,this.readyorderMenuList.length-1);
                }
            }
            this.varietySelect=''
        },
        /**左边菜单点击 */
        readyClick(item,index){
            this.Recordvarie=item
            this.Recordindex = index
            this.$nextTick(()=>{
                animation.scrollTopAnchor(this.$refs.box,".slide-wrapper.selectedRow")
            })
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        /**套餐返回 */
        setMealClick(setMealNum,posFoodSetMeal){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            console.log(posFoodSetMeal)
            let _posFoodSetMeal =[]
            _posFoodSetMeal.push(posFoodSetMeal)
            let _SubName=''
            let _item=''
            _posFoodSetMeal.forEach(pos=>{
                this.allfoodSubCategorys?.forEach(allfood=>{
                    if(pos.SubCategory_ID==allfood.SubCategory_ID){
                        _SubName=allfood.SubCategory_Name
                    }
                })
                pos.SetMealInfo.SetMealKinds?.forEach(Kind=>{
                    Kind.SetMealDetails.forEach(set=>{
                        this.TempposFoods.forEach(food=>{
                            food.PosFoods.map(pos=>{
                                if(set.Food_ID==pos.Food_ID){//判断套餐明细菜品 必须是菜品中有的才能加入
                                    if(set.Is_Selected){
                                        let foodtype=0
                                        this.FoodSetmeals.map(foods=>{
                                            if(foods.FoodSetmeal_ID==pos.Food_ID){
                                                foods.FoodSetmeal_Number= foods.FoodSetmeal_Number+1
                                                foodtype=1
                                            }
                                        })
                                        if(foodtype==0){
                                            //set?.Food_TcPrice
                                            this.FoodSetmeals.push({FoodSetmeal_ID:set.Food_ID,
                                                FoodSetmeal_Code:set.Food_Code,
                                                FoodSetmeal_Name:set.Food_Name,
                                                FoodSetmeal_Number:set.Food_Number * setMealNum,
                                                FoodSetmeal_Price:0,
                                                FoodSaleMoney:0,
                                                Food_CostPrice:set?.Food_CostPrice,
                                                FoodSetmeal_Size:set?.Food_Size,
                                                FoodModifys:[],
                                                Eat_MasName:'',
                                                Food_Status:-1,
                                                base_Number:set.Food_Number * setMealNum,
                                                Eat_ConsumeID:newGuid()
                                            })
                                        }
                                        let readType=0
                                        if(this.readyorderMenuList){
                                            this.readyorderMenuList.map(read=>{
                                                if(read.EAT_XFBMID==pos.Food_ID && read.Food_Status==-1){
                                                    read.Eat_Number=read.Eat_Number+1
                                                    readType=1
                                                }
                                            })
                                        }
                                        if(readType==0){
                                            _item ={
                                                SubCategory_ID:pos.SubCategory_ID,
                                                EAT_XFBMID:pos.Food_ID,
                                                EAT_XFCode:pos.Food_Code,
                                                EAT_XFName:pos.Food_Name,
                                                Eat_Number:(set.Food_Number * setMealNum),
                                                Eat_CheckPrice:pos.Food_Price,
                                                PriceSubtotal:pos.Food_Price * (set.Food_Number * setMealNum),
                                                ModiPrice:0,
                                                Eat_SalePrice:pos.Food_Price,
                                                Food_CostPrice:pos?.Food_CostPrice,
                                                Eat_MasName:'',
                                                Food_Status:-1,
                                                Eat_XFSize:pos.Food_Size,
                                                FoodModifys:[],
                                                FoodSetmeals:null,
                                                Is_Discounted:pos.Is_Discounted,
                                                Is_Seasonal:pos.Is_Seasonal,
                                                RemainNum:pos.RemainNum,
                                                Is_Limited:pos.Is_Limited,
                                                Is_ChangedNum:pos.Is_ChangedNum,
                                                Is_ChargeForService:pos.Is_ChargeForService,
                                                SubCategory_Name:_SubName,
                                                Is_SetMeal:pos.Is_SetMeal,
                                                base_Number:(set.Food_Number * setMealNum),
                                                indenttion:newGuid()
                                            };
                                            this.readyorderMenuList.push(_item);
                                            return
                                        }
                                    }
                                }
                            })
                        })
                    })
                })
            })
            this.setMealShow = false;
        },
        /**要求 */
        cancelDoSoldOut(row){
            if(this.Recordvarie){
                if(this.Recordvarie.Food_Status>=0){
                    this.$message.warning('只能调整未下单的菜品');
                    setTimeout(() => {
                        this.$refs.searchBox.focus();
                    },100);
                    return
                }
                this.demandFullShow = true
                this.ConsumeList=row
            }else{
                this.$message.warning('请先选择对应的菜品');
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
                return
            }
        },
        /**菜品添加 */
        Add(){
            if(this.Recordvarie){
                if(this.Recordvarie.Food_Status>=0){
                    this.$message.warning('只能调整未下单的菜品');
                    setTimeout(() => {
                        this.$refs.searchBox.focus();
                    },100);
                    return
                }
                this.Recordvarie.Eat_Number = Number(Number(Number(this.Recordvarie.Eat_Number) + Number(this.Recordvarie.base_Number)).toFixed(2))
                this.FoodSetmeals.map(food=>{
                    if(food.FoodSetmeal_ID==this.Recordvarie.EAT_XFBMID && food.FoodSetmeal_Name==this.Recordvarie.EAT_XFName ){
                        food.FoodSetmeal_Number= Number(Number(Number(food.FoodSetmeal_Number)+Number(food.base_Number)).toFixed(2))
                    }
                })
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
            }else{
                this.$message.warning('请先选择对应的菜品');
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
                return
            }
        },
        /**菜品减少 */
        reduce(){
            if(this.Recordvarie){
                if(this.Recordvarie.Food_Status>=0){
                    this.$message.warning('只能调整未下单的菜品');
                    setTimeout(() => {
                        this.$refs.searchBox.focus();
                    },100);
                    return
                }
                if(Number(this.Recordvarie.Eat_Number)>Number(this.Recordvarie.base_Number)){
                    this.Recordvarie.Eat_Number = this.accSub(Number(this.Recordvarie.base_Number),this.Recordvarie.Eat_Number);
                    this.FoodSetmeals.map(food=>{
                        if(food.FoodSetmeal_ID==this.Recordvarie.EAT_XFBMID && food.FoodSetmeal_Name==this.Recordvarie.EAT_XFName ){
                            food.FoodSetmeal_Number= this.accSub(Number(food.base_Number), Number(food.FoodSetmeal_Number));
                        }
                    })
                }
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
            }else{
                this.$message.warning('请先选择对应的菜品');
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
                return
            }
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return  Number(((arg2*m-arg1*m)/m).toFixed(n));
        },
        /**菜品要求返回 */
        confirmall(optFlavors,temporderMenuList){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            this.readyorderMenuList.map(item=>{
                if(item.indenttion==this.ConsumeList?.indenttion){
                    let _foodModifys=[];
                    let _Eat_MasName ='';
                    optFlavors.map(num=>{
                        _Eat_MasName += num.Mas_Name+'/';
                        if(num.Is_AddMoney==true){
                            //按量加价
                            if(num.Price_Kind==0){
                                _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:item.Eat_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                            }
                            if(num.Price_Kind==1){
                                _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                            }
                        }else{
                            _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                        }
                        
                    })
                    item.Eat_MasName = _Eat_MasName;
                    item.FoodModifys=_foodModifys;
                }
            })
            this.FoodSetmeals.map(food=>{
                if(food.FoodSetmeal_ID==this.ConsumeList?.EAT_XFBMID && food.FoodSetmeal_Name==this.ConsumeList?.EAT_XFName){
                    let _foodModifys=[];
                    let _Eat_MasName ='';
                     optFlavors.map(num=>{
                         _Eat_MasName += num.Mas_Name+'/';
                        if(num.Is_AddMoney==true){
                            //按量加价
                            if(num.Price_Kind==0){
                                _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:food.FoodSetmeal_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                            }
                            if(num.Price_Kind==1){
                                _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                            }
                        }else{
                            _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                        }
                     })
                    food.Eat_MasName = _Eat_MasName;
                    food.FoodModifys=_foodModifys;
                }
            })
            if(temporderMenuList){
                temporderMenuList.forEach(tm=>{
                    if(tm.indenttion==this.ConsumeList?.indenttion){
                        tm.is_type=0
                    }
                })
                this.synchMethods(optFlavors,temporderMenuList)
            }
            this.demandFullShow = false
        },
        synchMethods(optFlavors,temporderMenuList){
            /**同步菜品 */
            if(temporderMenuList.length>0){
                temporderMenuList.forEach(temp=>{
                    this.readyorderMenuList.forEach(order=>{
                        if(temp?.indenttion==order?.indenttion && temp.is_type==1 && order.Food_Status==-1){
                            let _foodModifys=[];
                            let _Eat_MasName ='';
                            optFlavors.forEach(num=>{
                                _Eat_MasName += num.Mas_Name+'/';
                                if(num.Is_AddMoney==true){
                                    //按量加价
                                    if(num.Price_Kind==0){
                                        _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:order.Eat_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                    }
                                    if(num.Price_Kind==1){
                                        _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                    }
                                }else{
                                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                }
                                
                            })
                            order.Eat_MasName += _Eat_MasName;
                            //order.FoodModifys=_foodModifys;
                            if(!order.FoodModifys){
                                order.FoodModifys=[]
                            }
                            _foodModifys.forEach(fm=>{
                                order.FoodModifys.push(fm);
                            })
                        }
                    })
                    this.FoodSetmeals.forEach(food=>{
                        if(food.FoodSetmeal_ID==temp.EAT_XFBMID && temp.is_type==1){
                            let _foodModifys=[];
                            let _Eat_MasName ='';
                            optFlavors.forEach(num=>{
                                _Eat_MasName += num.Mas_Name+'/';
                                if(num.Is_AddMoney==true){
                                    //按量加价
                                    if(num.Price_Kind==0){
                                        _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:food.FoodSetmeal_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                    }
                                    if(num.Price_Kind==1){
                                        _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                    }
                                }else{
                                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                                }
                                
                            })
                            food.Eat_MasName += _Eat_MasName;
                            if(!food.FoodModifys){
                                food.FoodModifys=[]
                            }
                            //food.FoodModifys =_foodModifys
                            _foodModifys.forEach(fm=>{
                                food.FoodModifys.push(fm);
                            })
                        }
                    })
                })
            }
            console.log('readyorderMenuList:'+JSON.stringify(this.readyorderMenuList))
        },
        /**删除 */
        Delete(index,item){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            /**清理掉之前的即配套餐数据 */
            if(this.readyShowType==2 && item.Food_Status>=0){
                if(this.tempTimestamps==''){
                    this.tempTimestamps=this.orderInfo.Timestamps.toString()
                }
                let userInfo=this.$auth.getUserInfo()
                let param={
                    Body_ID:newGuid(),
                    User_ID:userInfo?.User_ID,
                    Operator_Name:userInfo?.Login_Name,
                    Eat_ConsumeID:item.Eat_ConsumeID,
                    Eat_AutoID:this.readydisRadioOrder.Eat_AutoID,
                    OrderTimestamps:this.tempTimestamps.toString()
                }
                const loading = this.$loading({
                    text: "删除中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.DiySetmealFoodCancel",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        if(data.ResponseBody?.Timestamps){
                            this.tempTimestamps = data.ResponseBody?.Timestamps.toString()
                        }
                        //this.diyfoodType=1
                        this.readyorderMenuList.splice(index,1);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }else{
                this.FoodSetmeals.map((food,i)=>{
                    if(food.FoodSetmeal_ID==item.EAT_XFBMID && food.FoodSetmeal_Name==item.EAT_XFName && food.Food_Status==-1){
                        this.FoodSetmeals.splice(i,1);
                    }
                })
                this.readyorderMenuList.splice(index,1);
            }
            this.readyorderMenuList.map((read,index)=>{
                if(index == this.readyorderMenuList.length-1){
                    this.readyClick(read,this.readyorderMenuList.length-1);
                }
            })
            if(this.readyorderMenuList.length==0){
                this.Recordvarie=''
                this.Recordindex = -1
            }
        },
        /**计算账单总金额 数量 份数 */
        deskCount(type){
            let placeFoodsTotal = 0;
            let placeFoodsSumPortion = 0;
            let placeFoodsSum=0;
            this.readyorderMenuList.map(item=>{
                if(item.Eat_Number>0){
                    placeFoodsSum++; //总数量
                    if(item.Eat_TcItemList){
                        item.Eat_TcItemList.map(eat=>{
                            placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(eat.Eat_Number); //总份量
                            if(item.ModiPrice){
                                placeFoodsTotal = placeFoodsTotal+((eat.Eat_CheckPrice+eat.ModiPrice) * eat.Eat_Number)  //总价格
                            }else{
                            placeFoodsTotal = placeFoodsTotal+(eat.Eat_CheckPrice * eat.Eat_Number)  //总价格
                            }
                        })
                    }
                    if(item.FoodSetmeals){
                        item.FoodSetmeals.map(food=>{
                            placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(food.FoodSetmeal_Number); //总份量
                            placeFoodsTotal = placeFoodsTotal+((food.FoodSetmeal_Price+food.FoodSaleMoney) * food.FoodSetmeal_Number)  //总价格
                        })
                    }
                    placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(item.Eat_Number); //总份量
                    if(item.ModiPrice){
                        placeFoodsTotal = placeFoodsTotal+((item.Eat_CheckPrice+item.ModiPrice) * item.Eat_Number)  //总价格
                    }else{
                        placeFoodsTotal = placeFoodsTotal+(item.Eat_CheckPrice * item.Eat_Number)  //总价格
                    }
                }
            })
            if(type==1){
                this.placeFoodsMoney = placeFoodsTotal.toFixed(2);
                return '<i>￥</i>'+placeFoodsTotal.toFixed(2);
            }
            if(type==2){
                return placeFoodsSumPortion;
            }
            if(type==3){
                return Number(placeFoodsSum.toFixed(2));
            }
        },
        /**厨房点击 */
        kitchenClick(){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            if(this.FoodSetmeals){
                this.kitchenShow=true
            }else{
                this.$message.warning('请先选择对应的菜品');
                return
            }
        },
        /**厨房返回 */
        kitchenClickBack(temporderMenuList){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            temporderMenuList.forEach(item=>{
                if(item.Is_SetMeal==true){
                    this.readyorderMenuList.forEach(order=>{
                        if(item.EAT_XFBMID==order.EAT_XFBMID && order.Food_Status==-1){
                            order.Food_Speed = item.Food_Speed
                            order.KitSpeed_Dimension = item.Food_Speed
                            if(order.FoodSetmeals!=null){
                                order.Food_Speed = temporderMenuList[0].Food_Speed
                                order.KitSpeed_Dimension = temporderMenuList[0].Food_Speed
                            }
                            this.FoodSetmeals.forEach(food=>{
                                if(food.FoodSetmeal_ID==order.EAT_XFBMID && food.FoodSetmeal_Name==order.EAT_XFName){
                                     food = Object.assign(food,{Food_Speed:item.Food_Speed,KitSpeed_Dimension:item.Food_Speed})
                                }
                            })
                        }
                    })
                }else{
                    this.readyorderMenuList.forEach(order=>{
                        if(item.EAT_XFBMID==order.EAT_XFBMID && order.Food_Status==-1 && !order.Food_Speed){
                            order = Object.assign(order,{Food_Speed:item.Food_Speed,KitSpeed_Dimension:item.Food_Speed})
                            this.FoodSetmeals.forEach(food=>{
                                if(food.FoodSetmeal_ID==order.EAT_XFBMID && food.FoodSetmeal_Name==order.EAT_XFName){
                                     food = Object.assign(food,{Food_Speed:item.Food_Speed,KitSpeed_Dimension:item.Food_Speed})
                                }
                            })
                        }else if(order.Food_Speed!='' && order.Food_Status==-1 && item.EAT_XFBMID==order.EAT_XFBMID){
                            order.Food_Speed = item.Food_Speed
                            order.KitSpeed_Dimension = item.Food_Speed
                        }
                    })
                }
            })
            this.kitchenShow =false;
        },
        Numberadjust(){
            if(this.Recordvarie){
                if(this.Recordvarie.Food_Status>=0){
                    this.$message.warning('只能调整未下单的菜品');
                    setTimeout(() => {
                        this.$refs.searchBox.focus();
                    },100);
                    return
                }
                this.adjustShow=true
            }else{
                this.$message.warning('请先选择对应的菜品');
                setTimeout(() => {
                    this.$refs.searchBox.focus();
                },100);
                return
            }
        },
        /**数量返回 */
        quantityReturn(number,assNumber){
            this.adjustShow = false;
            this.Recordvarie.base_Number = 1
            this.Recordvarie.Eat_Number=number
            this.FoodSetmeals.forEach(food=>{
                if(food.FoodSetmeal_ID==this.Recordvarie.EAT_XFBMID && food.FoodSetmeal_Name==this.Recordvarie.EAT_XFName){
                    food.FoodSetmeal_Number= number
                    food.base_Number = 1
                }
            })
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
        },
        //编辑自定义菜品点击确认
        difConfirm(item){
            this.diyFoodsShow=false;
            item.isDiy=true;
            this.onPlaceFoods(item,2);
        },
        /**引用配菜单数据 
         * @param data 引用的菜品数据
         * @param AllFoods 所以菜品数据
         * @param AllMasKinds 口味做法数据
         * @param DiyTcInfo 自定义菜品数据
        */
        quoteReturn({data,AllFoods,AllMasKinds,DiyTcInfo}){
            let foodData=AllFoods;//全部菜品数据 包含自定义菜品
            let errMessage=[];
            let orderList=[];
            //自定义菜品
            let diyEatMas=this.$cacheData.globalVariable?.GlobalSysSetting?.DiyEatMas||{};
            data.forEach(item=>{
                let info={};
                if(item.FoodSetmeals?.length>0){//套餐
                    item.FoodSetmeals.forEach((foods)=>{
                        let food=foodData.find(food=>food.Food_ID==foods.Eat_XFBMID || (food.Is_MultipleSize && food.MultipleSizeFoods.find(it=>it.Food_ID==foods.Eat_XFBMID)));
                        if(!food){
                            errMessage.push(foods.Eat_XFName+"该菜品未查询到!")
                            return
                        }
                        orderList.push({
                            SubCategory_ID:food.SubCategory_ID,
                            SubCategory_Name:item.SubCategory_Name,
                            EAT_XFBMID:food.Food_ID,
                            EAT_XFCode:food.Food_Code,
                            EAT_XFName:food.Food_Name,
                            Eat_Number: foods.Eat_Number,
                            Eat_CheckPrice:food.Food_Price,
                            PriceSubtotal:food.Food_Price * item.Eat_Number,
                            ModiPrice:0,
                            Eat_SalePrice:food.Food_Price,
                            Food_CostPrice:food?.Food_CostPrice,
                            Eat_MasName:'',
                            Food_Status:-1,
                            Eat_XFSize:food.Food_Size,
                            FoodModifys:foods.FoodModifys,
                            Food_Speed:foods.Food_Speed,
                            KitSpeed_Dimension:foods.Food_Speed,
                            FoodSetmeals:null,
                            Is_Discounted:food.Is_Discounted,
                            Is_Seasonal:food.Is_Seasonal,
                            RemainNum:food.RemainNum,
                            Is_Limited:food.Is_Limited,
                            Is_ChangedNum:food.Is_ChangedNum,
                            Is_ChargeForService:food.Is_ChargeForService,
                            Is_SetMeal:food.Is_SetMeal,
                            base_Number:foods.Eat_Number,
                            indenttion:newGuid()
                        })
                    });
                }else{
                    let food=foodData.find(food=>food.Food_ID==item.Eat_XFBMID || (food.Is_MultipleSize && food.MultipleSizeFoods.find(it=>it.Food_ID==item.Eat_XFBMID)));
                    if(!food){
                        errMessage.push(item.Eat_XFName+"该菜品未查询到!")
                        return
                    }
                    
                    info={
                        indenttion:newGuid(),
                        SubCategory_ID:item.SubCategory_ID,
                        SubCategory_Name:item.SubCategory_Name,
                        EAT_XFBMID:item.Eat_XFBMID,
                        EAT_XFCode:item.Eat_XFCode,
                        EAT_XFName:item.Eat_XFName,
                        Eat_Number:item.Eat_Number,
                        Eat_CheckPrice:item.Eat_SalePrice,
                        ModiPrice:0,
                        Eat_SalePrice:item.Eat_SalePrice,
                        Food_CostPrice:food?.Food_CostPrice,
                        Eat_MasName:item.Eat_MasName,
                        Food_Status:-1,
                        Food_AssSize:item.Eat_AssSize,
                        AssSizeNumber:item.Eat_AssNum,
                        Eat_XFSize:item.Eat_XFSize,
                        FoodModifys:item.FoodModifys,
                        Food_Speed:item.Food_Speed,
                        KitSpeed_Dimension:item.Food_Speed,
                        FoodSetmeals:null,
                        Is_Discounted:food.Is_Discounted,
                        Is_Seasonal:food.Is_Seasonal,
                        RemainNum:food.RemainNum,
                        Is_Limited:food.Is_Limited,
                        Is_ChangedNum:food.Is_ChangedNum,
                        Is_ChargeForService:food.Is_ChargeForService,
                        Is_SetMeal:food.Is_SetMeal,
                        base_Number:1,
                    };
                    if(food?.Is_SetMealCountAvgPrice){
                        info.Is_SetMealCountAvgPrice = food?.Is_SetMealCountAvgPrice
                    }
                    orderList.push(info);
                }
            })
            orderList.forEach(info=>{
                if(info.FoodModifys){
                    let MasNames=(info.Eat_MasName||"").split('/');
                    let FoodModifys=[];
                    let Eat_MasName=[];
                    let isBo=false;//是否有查询不到的口味做法
                    info.FoodModifys?.forEach((modifys,index)=>{
                        if(diyEatMas.MAS_AUTOID==modifys.Mas_AutoID){//自定义口味
                            Eat_MasName.push(MasNames[index]);
                            FoodModifys.push(Object.assign({
                                MAS_AUTOID:diyEatMas.MAS_AUTOID,
                                Mas_Code:diyEatMas.MAS_CODE,
                                Mas_Name: MasNames[index],
                                Is_AddMoney:diyEatMas.IS_ADDMONEY,
                                Price_Kind:diyEatMas.PRICE_KIND,
                                Mas_Price:diyEatMas.MAS_PRICE,
                            },{Mas_Num:diyEatMas.Price_Kind==1?1:info.Eat_Number}));
                            return;
                        }else{
                            let kind=AllMasKinds.find(kind=>kind.Mas_AutoID==modifys.Mas_AutoID);
                            if(kind){
                                Eat_MasName.push(kind.Mas_Name);
                                FoodModifys.push(Object.assign({},kind,{Mas_Num:kind.Price_Kind==1?1:info.Eat_Number,Mas_Name:kind.Mas_Name}));
                            }else{
                                isBo=true;
                            }
                        }
                    })
                    //把口味要求临时存起来

                    if(isBo){
                        errMessage.push(info.EAT_XFName+"该菜品做法未查询到!")
                    }
                    info.FoodModifys=FoodModifys;
                    info.Eat_MasName=Eat_MasName.join("/");
                }
            })
            if(errMessage.length>0){
                this.$confirm(errMessage.join(";")+" 是否继续引用,点击继续报错菜品则不会被引用！","报错", {
                    type:"warning",
                    confirmButtonText: "继续",
                    cancelButtonText:"取消",
                    callback: (name) => {
                        if(name=='confirm'){
                            this.quoteAddItem(orderList);
                        }
                    },
                })
            }else{
                this.quoteAddItem(orderList);
            }
            this.withMenuShow=false;
        },
        quoteAddItem(orderList){
            if(this.readyorderMenuList.length>0){
                let readyorderList=this.readyorderMenuList.filter(it=>!it.Eat_ConsumeID);//排除已下单数据
                orderList.forEach(_item=>{
                    let info=readyorderList.find(it=>it.EAT_XFBMID==_item.EAT_XFBMID);
                    if(info){
                        info.Eat_Number=Number(info.Eat_Number)+_item.Eat_Number
                        if(_item.Eat_AssSize){
                            info.AssSizeNumber =Number(info.AssSizeNumber)+_item.Eat_AssNum;
                        }
                        if(_item.FoodModifys?.length>0){
                            _item.FoodModifys.forEach(it=>{
                                if(!info.FoodModifys?.some(m=>m.Mas_AutoID==it.Mas_AutoID)){
                                    info.FoodModifys.push(it);
                                }
                            })
                            info.Eat_MasName=info.FoodModifys.map(it=>it.Mas_Name).join("/");
                        }else{
                            info.FoodModifys=_item.FoodModifys;
                            info.Eat_MasName=_item.Eat_MasName;
                        }
                        this.FoodSetmeals.find(it=>{
                            if(it.FoodSetmeal_ID==info.EAT_XFBMID){
                                it.FoodSetmeal_Number=info.Eat_Number;
                                it.FoodModifys=info.FoodModifys?.length>0?_item.FoodModifys:null,
                                it.Eat_MasName=info.Eat_MasName;
                                return true;
                            }
                            return false;
                        })


                    }else{
                        this.readyorderMenuList.push(_item);
                        this.FoodSetmeals.push({
                            FoodSetmeal_ID:_item.EAT_XFBMID,
                            FoodSetmeal_Code:_item.EAT_XFCode,
                            FoodSetmeal_Name:_item.EAT_XFName,
                            FoodSetmeal_Number:_item.Eat_Number,
                            FoodSetmeal_Price:0,
                            FoodSetmeal_SetPrice:_item.Eat_CheckPrice,
                            Food_CostPrice:_item.Food_CostPrice,
                            FoodSetmeal_Size:_item.Eat_XFSize,
                            FoodSaleMoney:0,
                            FoodModifys:_item.FoodModifys?.length>0?_item.FoodModifys:null,
                            Eat_MasName:_item.Eat_MasName,
                            ModiPrice:0,
                            Food_Status:-1,
                            base_Number:_item.base_Number,
                            Food_Speed:_item.Food_Speed,
                            KitSpeed_Dimension:_item.Food_Speed,
                            Eat_ConsumeID:newGuid()
                        })
                    }
                })
            }else{
                this.readyorderMenuList=orderList;
                orderList.forEach(_item=>{
                    this.FoodSetmeals.push({
                       FoodSetmeal_ID:_item.EAT_XFBMID,
                        FoodSetmeal_Code:_item.EAT_XFCode,
                        FoodSetmeal_Name:_item.EAT_XFName,
                        FoodSetmeal_Number:_item.Eat_Number,
                        FoodSetmeal_Price:0,
                        FoodSetmeal_SetPrice:_item.Eat_CheckPrice,
                        Food_CostPrice:_item.Food_CostPrice,
                        FoodSetmeal_Size:_item.Eat_XFSize,
                        FoodSaleMoney:0,
                        FoodModifys:_item.FoodModifys?.length>0?_item.FoodModifys:null,
                        Eat_MasName:_item.Eat_MasName,
                        ModiPrice:0,
                        Food_Status:-1,
                        base_Number:_item.base_Number,
                        Food_Speed:_item.Food_Speed,
                        KitSpeed_Dimension:_item.Food_Speed,
                        Eat_ConsumeID:newGuid()
                    })
                })
            }
        }
    }
}
</script>

<style lang="sass">
@import "./readyToUsePackageModel.scss";
</style>