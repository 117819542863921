<template>
    <div class="foodDisplay" v-dom-resize @resize="resize()" :style="style">
        <el-scrollbar class="scroll-box"  max-height="100%"  ref="scrollBox" v-mouse-scroll="{scrollName:'.el-scrollbar__wrap'}">
            <div class="visual-box" ref="layoutCol" :style="{height:scrollH+'px',paddingTop:marginTop+'px'}">
                <div class="category-box" v-for="(category,index) in visualData" :key="index">
                    <div class="title" v-if="category.isTitle">{{category.SubCategory_Name}}</div>
                    <div class="food-box clearfix">
                        <div class="li" v-for="item in category.PosFoods" :key="item" :class="{itemSellout:item.Is_SoldOut || (!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0)}">
                            <div class="name nowrap2"><i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}</div>
                            <div class="money" :class="{lineThrough:item.Is_Promotion}">
                                <span class="price"><span class="unit">￥</span>{{item.Food_Price}}</span><span class="unit">/{{item.Food_Size}}</span>
                                <div class="num-box" v-if="item.Is_Limited && item.Is_LimitedProject && item.RemainNum>0">剩: {{item.RemainNum}}{{item.Food_Size}}</div>
                            </div>
                            <div class="tag-box">
                                <div class="proMoney" v-if="item.Is_Promotion">
                                    <span class="tag">促</span>
                                    <span class="unit">￥</span>
                                    <span>{{item.Promotion_Price}}</span>
                                </div>
                                <!-- vip 价格 -->
                                <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                    <div class="vipTag">VIP</div>
                                    <div class="price"> 
                                        <div class="unit">￥</div>{{item.Food_VipPrice}}
                                    </div>
                                </div>
                            </div>
                            <div class="bnt-box" v-if="item.Is_MultipleSize">
                                <div class="nultiUnit-bnt" @click="addItem(item)">选规格<span class="num" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</span></div>
                            </div>
                            <div class="bnt-box" v-else>
                                <div class="remove" @click="removeItem(item)" :class="{disabled:!(cartNumber[item.Food_ID]?.Eat_Number>1)}" v-if="cartNumber[item.Food_ID]?.Eat_Number>0"><i class="iconfont icon-jian"></i></div>
                                <div class="number" v-if="cartNumber[item.Food_ID]?.Eat_Number>0">{{cartNumber[item.Food_ID]?.Eat_Number||0}}</div>
                                <div class="add" @click="addItem(item)"><i class="iconfont icon-jia"></i></div>
                            </div>
                            <div class="sellout"><img src="/images/soldout.png"></div>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
/**小图模式 */
export default {
    name:"foodDisplay",
    emits:["currentChange","removeItem","addItem"],
    props:{
        /**菜品 */
        foodCategoryData:{
            type:Array,
            default:null
        },
        /**当前选中分类 */
        currentIndex:{
            type:Number,
            default:0
        },
        /**购物车 菜品 数量 */
        cartNumber:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            style:{},
            /**每行菜品高度 */
            trH:61,
            visualH:768,
            /**滚动条位置 */
            scrollTop:0,
            visualData:[]
        }
    },
    computed:{
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        /**滚动条高度 */
        scrollH(){
            let height=0;
            this.foodCategoryData?.forEach(it=>{
                height+=30;//分类高
                let num=Math.ceil(it.PosFoods.length/2);
                height+=this.trH*num+10;
            })
            return height;
        },
        marginTop(){
            if(this.visualData?.length>0){
                return this.visualData[0].top;
            }
            return 0;
        },
        /**分类 */
        navIndex(){
            if(this.scrollTop>0 && this.foodCategoryData.length>0){
                let height=0;
                for(let i=0;i<this.foodCategoryData.length;i++){
                    let category=this.foodCategoryData[i];
                    let trH=30;//分类高
                    let num=Math.ceil(category.PosFoods.length/2);
                    let fooodH=this.trH*num+10;
                    if(this.scrollTop>=height && this.scrollTop<height+trH+fooodH){
                        return i;
                    }
                    height+=trH+fooodH;
                }
            }
            return 0;
        }
    },
    watch:{
        currentIndex:{
            handler(){
                this.$nextTick(()=>{
                    if(this.navIndex!=this.currentIndex && this.foodCategoryData.length>0){
                        let height=0;
                        for(let i=0;i<this.currentIndex;i++){
                            let category=this.foodCategoryData[i];
                            let trH=30;//分类高
                            let num=Math.ceil(category.PosFoods.length/2);
                            let fooodH=this.trH*num+10;
                            height+=trH+fooodH;
                        }
                        if(this.$refs.scrollBox?.wrap){
                            this.$refs.scrollBox.wrap.scrollTop=height
                        }
                    }
                })
            },
            immediate:true
        },
        scrollH(){
            this.setVisualData();
        },
        scrollTop(){
            this.setVisualData();
        },
        visualH(){
            this.setVisualData();
        },
        navIndex(){
            if(this.navTime){
                clearTimeout(this.navTime);
            }
            this.navTime=setTimeout(() => {
                this.$emit("currentChange",this.navIndex);
            }, 100);
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.resize();
            this.setVisualData();
            if(this.$refs.scrollBox.wrap){
                this.$refs.scrollBox.wrap.addEventListener("scroll",(e)=>{
                    this.scrollTop=e.srcElement.scrollTop;
                })
            }
        })
    },
    methods:{
        /**计算宽高 */
        resize(){
            if(this.$refs.scrollBox?.scrollbar){
                let scrollbar=this.$refs.scrollBox?.scrollbar;
                let height= scrollbar.offsetHeight;
                if(height>0){
                    this.visualH=height;
                }
            
            }
        },
        /**可视数据 */
        setVisualData(){
            if(this.time){clearTimeout(this.time);}
            this.time=setTimeout(()=>{
                let diffH=this.visualH;//缓冲区高度
                if(this.foodCategoryData?.length>0){
                    let data=[];
                    let height=0;
                    for(let i=0;i<this.foodCategoryData?.length;i++){
                        let category=this.foodCategoryData[i];
                        let json={SubCategory_Name:category.SubCategory_Name,isTitle:false}
                        if(this.scrollTop-diffH<=height && this.scrollTop+this.visualH+diffH>=height){
                            json.isTitle=true;
                            json.PosFoods=[];
                            json.top=height;
                            data.push(json);
                        }
                        height+=30;//分类高
                        
                        let num=Math.ceil(category.PosFoods.length/2);
                        for(let j=0;j<num;j++){
                            if(this.scrollTop-diffH<=height && this.scrollTop+this.visualH+diffH>=height){
                                if(!json.isTitle && !json.PosFoods){
                                    json.PosFoods=[];
                                    json.top=height;
                                    data.push(json);
                                }
                                json.PosFoods=json.PosFoods.concat(category.PosFoods.slice(j*2,(j+1)*2));
                            }
                            height+=this.trH;
                        }
                        height+=10;//底间距
                    }
                    if(data.length!=this.visualData.length || (data.length>0 && data[0].top!=this.marginTop)){
                        this.visualData=data;
                    }
                }else{
                    this.visualData=[];
                }
            },10);
            
        },
        removeItem(item){
            this.$emit("removeItem",item)
        },
        addItem(item){
            this.$emit("addItem",item)
        }
    }
}
</script>

<style lang="scss">
    @import './foodDisplay.scss'
</style>