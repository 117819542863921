<template>
    <div>
        <modal class="enterCloseModel" :isShow="isShow">
            <div class="header">企业结算<span class="close" @click="hide()">×</span></div>
            <tabs class="modal-body">
                <div class="clearfix" ref="CardMoney">
                    <div class="left-box">
                        <tab-pane>
                            <div class="search">
                                <!-- <input type="text" placeholder="企业编码" ref="idConding" @keyup.enter="onSelect()"  v-model="idConinput" /> -->
                                <key-board-input type="text" placeholder="扫企业用户动态身份码" ref="idConding" isBlurClose isKeyEnterClose
                                    :keyOptions="{ isKeyDownEnter: true }" @focus="(e) => e.target.select()"
                                    class="key-focus" v-model='idConinput' isBarcode @barcode="onSelect(true)"
                                    @keydown.enter="onSelect()" />
                                <!-- <div class="deskAuto" @click="showKeyBoard($refs.idConding)" @mousedown="(e)=>e.preventDefault()">
                                <span class="iconfont icon-jianpan"></span>
                            </div> -->
                            </div>
                            <!-- <div class="from-input" style="width:325px" >
                                <input-pattern type="text" :disabled="isInputCardNo" ref="input" 
                                @focus="(e)=>e.target.select()" class="key-focus" data-model='idCode' 
                                venter-model="idCode" isBarcode @barcode="onMemberInfo(true)" 
                                @keydown.="onMemberInfo()" />
                                <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                            </div> -->
                            <div class="from-input">
                                <!-- <div class="front-channe" :class="{select:this.enterState!=1}"  @click="enterType(1)">按企业编码查询</div> -->
                                <div class="be-channe" :class="{ select: true }" @click="manualinput(2)">
                                    手工输入企业编码查询
                                </div>
                            </div>
                            <div class="table-box">
                                <el-table class="el-table--scrollable-y" ref="tableEl" :data="corpInfoList"
                                    highlight-current-row style="width: 100%;"
                                    v-mouse-scroll="{ scrollName: '.el-table__body-wrapper' }"
                                    @current-change="handleCurrentChange">
                                    <el-table-column style="float:left;" label="序号" min-width="20">
                                        <template #default="scope">
                                            <span style="text-align:center;">{{ scope.$index + 1 }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="企业编码" min-width="30">
                                        <template #default="scope">
                                            <span
                                                style="overflow: hidden;text-overflow: ellipsis; white-space: nowrap;text-align:center;">{{
                                                    scope.row.enterpriseCode }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="企业名称" min-width="100">
                                        <template #default="scope">
                                            <div style="text-align:center;">{{ scope.row.enterpriseName }}</div>
                                            <div style="text-align: center;color: #999999;font-size: 11px;">
                                                预警额：¥{{ scope.row?.creditLimitWarning }} 最低额¥{{ scope.row?.creditLimitMin }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="余额" min-width="50">
                                        <template #default="scope">
                                            <span style="text-align:center;color:red;">¥{{
                                                Number(scope.row.creditLimitRemain).toFixed(2) }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="折扣率" min-width="20">
                                        <template #default="scope">
                                            <span style="text-align:center;">{{ scope.row.enterpriseDiscount }}%</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="Link_Man" label="是否允许结算" min-width="50">
                                        <template #default="scope">
                                            <div v-if="scope.row.settlementFlag == 1 || scope.row.settlementFlag == 2"
                                                class="gou">
                                                <i class="iconfont icon-gou1"></i>
                                            </div>
                                            <div v-if="scope.row.settlementFlag == 0" class="cha">
                                                <i class="iconfont icon-cha"></i>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="是否允许折扣" min-width="50">
                                        <template #default="scope">
                                            <div v-if="scope.row.discountFlag == 0" class="cha">
                                                <i class="iconfont icon-cha"></i>
                                            </div>
                                            <div v-if="scope.row.discountFlag == 1" class="gou">
                                                <i class="iconfont icon-gou1"></i>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </tab-pane>
                    </div>
                </div>
            </tabs>
            <div class="btn-money">
                <div class="money">待支付金额:<span class="moneyNumber">¥{{ Number(unPaidMoney).toFixed(2) }}</span></div>
                <div class="btn-input">扣减金额:

                    <key-board-input type="text" :min="0" pattern="money" ref="searchBox" isBlurClose isKeyEnterClose
                        :keyOptions="{ isKeyDownEnter: true, isNumber: true }" @focus="(e) => e.target.select()"
                        class="key-focus" v-model='payMoney' />
                    <!-- <input class="text" type="text" ref="searchBox" @keyup.enter="onSelect(1)" v-model="payMoney"/>
                <div class="deskAuto" @click="showKeyBoard($refs.searchBox)" @mousedown="(e)=>e.preventDefault()">
                    <span class="iconfont icon-jianpan"></span>
                </div> -->
                </div>
            </div>
            <div class="footer-box">
                <button class="btn btn-cancel" @click="hide()">取消</button>
                <!-- <button class="btn btn-cancelzk" @click="zk()">执行企业折扣</button> -->
                <button class="btn btn-confirm" @click="confirm()">确认</button>
            </div>
            <analog-key-board ref="keyBoard" :isKeyDownEnter="true"></analog-key-board>
        </modal>

        <modal class="handinput" :isShow="ishandbox">
            <div class="header">企业结算<span class="close" @click="hidehandbox">×</span></div>
            <tabs class="modal-body">
                <div class="row-box">
                    <div class="row-title">企业编码:</div>
                    <div class="row-input">
                        <key-board-input type="text" ref="QYCode" isBlurClose isKeyEnterClose
                            :keyOptions="{ isKeyDownEnter: true }" @focus="(e) => e.target.select()" class="key-focus"
                            v-model='QYCode' @keydown.enter="$refs.QYPhone.focus()"  />
                    </div>
                </div>
                <div class="row-box">
                    <div class="row-title">用户手机号:</div>
                    <div class="row-input">
                        <key-board-input type="text" ref="QYPhone" pattern="phone" isBlurClose isKeyEnterClose
                            :keyOptions="{ isKeyDownEnter: true, isNumber: true }" @focus="(e) => e.target.select()"
                            class="key-focus" v-model='QYPhone' @keydown.enter="handconfirm()"  />
                    </div>
                </div>
            </tabs>
            <div class="footer-box">
                <button class="btn btn-cancel" @click="hidehandbox()">取消</button>
                <button class="btn btn-confirm" @click="handconfirm()">确认</button>
            </div>
        </modal>
        <!--权限控制--> 
        <modal-load :isShow="checkShow" :isUnload="true">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="{ProjectItem_Key:'ModifyQSYYC'}" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
    </div>
</template>


<script>
import { initKeyBoard } from '/src/common'
import { httpAES, cacheData } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"
import modal from '../../../../template/modal/modal.vue'
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'


/**会员支付 */
export default {
    components: { modal,checkUserButtonModel },
    name: "enterCloseModel",
    props: {
        isShow: Boolean,
        orderInfo: Object,
        choicePayType: Object,
        unionEatAutoID: Object
    },
    data() {
        return {
            //当前选中列
            currentInfo: null,
            corpInfoList: [],
            //选择的查询模式
            enterState: 2,
            //企业编码/用户动态码
            idConinput: '',
            userInfo: '',
            unPaidMoney: 0,
            /**临时订单数据 */
            memberorderInfo: '',
            /**支付记录 */
            Payments: [],
            /**是否执行支付提交 */
            isPaySubmit: 0,
            /**支付金额 */
            payMoney: '',
            /**是否折扣 */
            isZk: 0,
            
            ISModifyQSYYC:false,/**允许手输企业码支付*/
            ishandbox: false,/**手动输入弹框 */
            QYCode: '',
            QYPhone: '',
            checkShow:false
        }
    },
    mounted() {
        this.$nextTick(() => {
            initKeyBoard.call(this, this.$el);
            //this.isZk = 0
            this.userInfo = this.$auth.getUserInfo(); //获取用户id
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
            this.unPaidMoney = this.memberorderInfo.UnPaid_Money
            this.EmptyDatas()
            if (this.memberorderInfo?.Enterprise_Code) {
                this.idConinput = this.memberorderInfo?.Enterprise_Code
                this.enterState=2;
                this.getRdARCorpInfo()
            }
        })
        /**自定义功能按钮 */
        let SysProjects=this.$cacheData.SysProjects.get();
        if(SysProjects && SysProjects.length>0){
            SysProjects.forEach(element=> {
            if(element.Project_Key=='Config'){
                element.SysProjectGroups.forEach(project=>{
                    /**桌台界面自定义按钮 */
                    if(project.Group_Key=='BaseSet_CloudPos'){
                        project.SysProjectItems.forEach((item)=>{
                            if(item.ProjectItem_Key=='SystemSet'){
                                item.SysProjectSubItems.forEach((sys)=>{
                                    if(sys.ProjectSubItem_Key=='ModifyQSYYC')
                                    {
                                        console.log(sys.Permission);
                                        this.ISModifyQSYYC=sys.Permission;
                                    }
                                })
                            }
                        })
                    }
                })
            }
            });
        }
    },
    watch: {
        orderInfo: {
            immediate: true,
            handler(vla) {
                this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
                this.unPaidMoney = this.memberorderInfo.UnPaid_Money
                if (this.currentInfo) {
                    if (this.currentInfo.creditLimitRemain > Number(Number(this.unPaidMoney).toFixed(2))) {
                        this.payMoney = Number(this.unPaidMoney).toFixed(2)
                    }
                    else{
                        this.payMoney = Number(this.currentInfo.creditLimitRemain).toFixed(2)
                    }
                    this.$refs.searchBox.focus();
                    setTimeout(() => {
                        this.$refs.searchBox?.focus();
                    }, 100)
                }
                this.EmptyDatas();
            }
        },
        corpInfoList(){
            this.$nextTick(function() {
                this.$refs.tableEl.setCurrentRow(this.corpInfoList[0])
            })
        }
    },
    methods: {
        /**默认input焦点 */
        EmptyDatas() {
            setTimeout(() => {
                this.$refs.idConding.focus();
            }, 100);
        },
        hide() {
            if (this.isZk == 1) {
                let param = {
                    Body_ID: newGuid(),
                    User_ID: this.userInfo?.User_ID,
                    Operator_Name: this.userInfo?.Login_Name,
                    Eat_AutoID: this.memberorderInfo.Eat_AutoID,
                    IsReturn_CheckOutInfo: true,
                    Is_UnionOrder: true,
                    Timestamps: this.memberorderInfo.Timestamps.toString()
                }
                this.$httpAES.post("Bestech.CloudPos.CancelBillDiscount", param).then((data) => {
                    if (data.ResponseHeader.ResultCode != 0) {
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    } else {
                        this.isZk = 0;
                        this.$emit("discountSchemeClic", data)
                    }
                }).catch((e) => {
                    this.$alert(e.message, "提示", { confirmButtonText: "确定" });
                })
            }
            this.$emit("enterPayReturn", '', 2)
        },
        handleCurrentChange(currentRow) {
            if(currentRow==null){return}
            this.currentInfo = currentRow
            this.zk();
            if (this.currentInfo.creditLimitRemain > Number(Number(this.unPaidMoney).toFixed(2))) {
                this.payMoney = Number(this.unPaidMoney).toFixed(2)
            }
            else{
                this.payMoney = Number(this.currentInfo.creditLimitRemain).toFixed(2)
            }
            this.$refs.searchBox.focus();
            setTimeout(() => {
                this.$refs.searchBox?.focus();
            }, 100)
        },
        /**切换查询模式 */
        enterType(type) {
            this.enterState = type;
            setTimeout(() => {
                this.$refs.idConding.focus();
            }, 100);
            this.getRdARCorpInfo()
        },
        /**获取账户信息GetARCorpInfo */
        onSelect(islimit) {
            console.log(this.idConinput);
            this.enterState=2;
            this.getRdARCorpInfo()
        },
        //执行折扣
        zk() {
            if (this.currentInfo) {
                if (this.currentInfo.discountFlag == 0) {
                    //this.$message.warning('该企业不允许企业折扣');
                    return
                }
                let param = {
                    Body_ID: newGuid(),
                    User_ID: this.userInfo?.User_ID,
                    Operator_Name: this.userInfo?.Login_Name,
                    EAT_AUTOID: this.memberorderInfo.Eat_AutoID,
                    Kind: 3,//1：折扣方案，5：金额折扣 ，3：全单折扣
                    DiscountScheme_ZkRate: Number(this.currentInfo.enterpriseDiscount),//折扣比率,全单折扣8折则为80
                    IsReturn_CheckOutInfo: true,
                    Is_UnionOrder: true,
                    Is_EnterpriseDiscount: true, //是否企事业折扣
                    Timestamps: this.memberorderInfo.Timestamps.toString()
                }
                if (sessionStorage.getItem("tempUserID")) {
                    param.User_ID = sessionStorage.getItem("tempUserID")
                    param.Operator_Name = sessionStorage.getItem("tempName")
                    sessionStorage.removeItem("tempUserID")
                    sessionStorage.removeItem("tempName")
                }
                const loading = this.$loading({
                    text: "企业折扣中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.DealWithTheDiscount", param).then((data) => {
                    loading.close();
                    if (data.ResponseHeader.ResultCode != 0) {
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    } else {
                        //ElMessage.success({ message: "企业折扣成功" });
                        this.isZk = 1
                        this.$emit("discountSchemeClic", data)
                    }
                }).catch((e) => {
                    loading.close();
                    this.$alert(e.message, "提示", { confirmButtonText: "确定" });
                })
            } else {
                this.$message.warning('请先选择团餐企业');
            }
        },
        /**查询基本数据 */
        getRdARCorpInfo(callback) {

            let param = {
                Body_ID: newGuid(),
                User_ID: this.userInfo?.User_ID, //登录用户ID
                Query_Type: this.enterState,//1.按企业码查询 2.按企业用户动态码查询
                Query_Code: this.enterState==1?this.QYCode:this.idConinput,//查询码
                Operator_Name: this.userInfo?.Login_Name,//操作员
            }
            /**获取数据中 */
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetRdARCorpInfo", param).then((data) => {
                loading.close();
                this.corpInfoList = []
                this.currentInfo = ''
                if (data?.ResponseHeader.ResultCode != 0) {
                    this.$message.error(data?.ResponseHeader.ResultDesc);
                } else {
                    this.corpInfoList.push(data.ResponseBody)
                    if(callback){
                        this.ishandbox = false;
                        callback();
                    }
                }
            }).catch((e) => {
                loading.close();
                this.$alert(e.message, "提示", { confirmButtonText: "确定" });
            })
        },
        /**确认 */
        confirm() {
            if (this.currentInfo) {
                if (this.currentInfo.settlementFlag == 0) {
                    this.$message.warning('不允许企业结算');
                    return
                }
                if (Number(this.payMoney) <= 0 || this.payMoney == '') {
                    this.$message.warning('支付金额不允许为空');
                    return
                }
                if (Number(this.payMoney) > Number(this.currentInfo.creditLimitRemain)) {
                    this.$message.warning('支付金额超过账户余额');
                    return
                }
                //可以结算, 不允许超额结算
                // if(Number(this.payMoney)>Number(Number(this.unPaidMoney).toFixed(2))){
                //     this.$message.warning('支付金额超过结算金额');
                //     return
                // }

                //可以结算,允许超额结算
                // if(this.currentInfo.settlementFlag==1){
                //     let creditLimitMin = this.currentInfo.creditLimitMin
                //     if(creditLimitMin<0){
                //         creditLimitMin = creditLimitMin * -1;
                //     }
                //     if((Number(this.currentInfo.creditLimitRemain)-Number(creditLimitMin))<Number(this.payMoney)){
                //         this.$message.warning('支付金额超过企业结算最低额度');
                //         return
                //     }
                // }
                /**支付方式 */
                let _payment = []
                _payment.push({
                    Payment_ID: this.choicePayType.PAY_ID,  //支付方式ID
                    Payment_Code: this.choicePayType.PAY_CODE, //支付方式编号
                    Payment_Name: this.choicePayType.PAY_NAME, //支付方式名称
                    Payment_Type: this.choicePayType.INTERFACE_TYPE, //支付方式类型
                    Payment_Money: Number(this.payMoney), //支付金额
                    Payment_FactMoney: 0, //计营业额
                    Qr_Code: '', //扫码付款的码值
                    Card_No: this.memberorderInfo.Card_No, //卡号
                    CouponInfo: null, //券的详情
                    //RdArExtendInfo: { enterpriseCode: this.currentInfo.enterpriseCode, customerPhone: this.currentInfo.enterprisePhone }
                    RdArExtendInfo: { enterpriseCode: this.enterState==1?this.QYCode:this.currentInfo.enterpriseCode, customerPhone: this.enterState==1?this.QYPhone:this.currentInfo.enterprisePhone }
                })
                let param = {
                    Body_ID: newGuid(),
                    User_ID: this.userInfo?.User_ID, //登陆用户ID
                    Eat_AutoID: this.memberorderInfo.Eat_AutoID,//结账单ID
                    UnionOrders: this.unionEatAutoID,//联台订单ID集合
                    Timestamps: this.memberorderInfo.Timestamps.toString(), //账单时间戳
                    Operator_Name: this.userInfo?.Login_Name,//操作员
                    Eat_DeskName: this.memberorderInfo.Eat_DeskName, //桌牌号
                    Service_Charge: this.memberorderInfo.Service_Money,  //服务费
                    MantissaAdjustment_Money: this.memberorderInfo.Mantissa_Money,  //尾数调整金额
                    Member: null,//会员信息
                    Payments: _payment, //支付方式列表
                }
                /**支付预览 */
                const loading = this.$loading({
                    text: "支付预览中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$emit("publicPayPreview", param, 'enterCloseShow')
            } else {
                this.$message.warning('请先选择团餐企业');
            }
        },
        showKeyBoard(obj) {
            console.log('refs:' + this.$refs)
            if (this.$refs.keyBoard.isOpen) {
                this.$refs.keyBoard.close();
            } else {
                this.$refs.keyBoard.show(obj);
                this.$refs.searchBox.focus();
            }

        },
        /**清空页面数据 */
        emptyData() {
            this.corpInfoList = []
            this.currentInfo = null
            this.isZk = 0
        },
        hidehandbox() {
            this.enterState=2;
            this.QYCode='';
            this.QYPhone='';
            this.ishandbox = false;
        },
        /**切换查询模式 */
        manualinput(type) {
            if (this.ISModifyQSYYC) {
                this.ishandbox = true;
                this.$refs.QYCode.focus();
                setTimeout(() => {
                    this.$refs.QYCode?.focus();
                }, 100)
            }else
            {
                this.checkShow=true;
            }
        },
        handconfirm(){
            if(this.QYCode.length<=0){
                this.$message.warning('请输入企业编号');
                this.$refs.QYCode.focus();
                return
            }
            const  reg = /^1[0-9]{10}$/
            if(this.QYPhone.length<10 || !reg.test(this.QYPhone)){
                this.$message.warning('请输入正确的手机号');    
                console.log(this.$refs);
                this.$refs.QYPhone.focus();
                return
            }
            this.enterState=1;
            this.getRdARCorpInfo(function(){});
        },
        checkbtnReturn(data){
            if(data.User_ID)
            {
                this.checkShow=false;
                this.ISModifyQSYYC=true;
                this.manualinput()
            }
        }
    }
}
</script>

<style lang="scss">
@import "./enterCloseModel.scss";
</style>