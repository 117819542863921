<template>
    <modal class="withMenuModel withMenuReadModel" :isShow="isShow" @keyup.stop="keyup">
        <div class="header">配菜单</div>
        <div class="modal-body">
            <div class="flex-box" >
                <div class="left-box">
                    <div class="table-box">
                        <el-table class="el-table--scrollable-y" ref="singleTableRef" :data="billPageList"  highlight-current-row style="width: 100%;" 
                            @current-change="handleCurrentChange"
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                            <el-table-column  label="序号" width="38">
                                <template #default="scope">
                                    {{scope.$index+((pageIndex-1)*pageSize)+1}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="Eat_MenuName" align="left" label="配菜单名称" min-width="100">
                                <template #default="scope">
                                    <span>{{scope.row.Eat_MenuName}}</span>
                                    <i class="iconfont icon-suo" v-if="scope.row.IsPrivateMenu && scope.row.CREAT_ID!=userInfo?.Login_Name"></i>
                                </template>
                            </el-table-column> 
                            <el-table-column prop="Eat_SaleMoney" label="菜单金额" min-width="70"></el-table-column>
                            <el-table-column prop="Eat_DeskNum" label="席数" min-width="50"></el-table-column>
                            <el-table-column prop="CREAT_ID" label="创建人" min-width="80">
                                <template #default="scope">
                                    <div>{{scope.row.CREAT_ID}}</div>
                                    <div class="time">{{shortDateFormat(scope.row.CREAT_TIME)}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="bottom-search-box">
                        <key-board-input class="search"
                            type="text" v-model="varietySelect" placeholder="按名称检索" ref="searchBox"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                        <div class="page-box-bottom">
                            <page-turning class="last-page" :pageCount="pageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                            <span class="page-number">{{pageIndex}}/{{pageCount}}页</span>
                            <page-turning class="next-page" :pageCount="pageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                        </div>
                    </div>
                </div>
                <div class="right-box" v-if="currentInfo">
                    <div class="title-box">
                        {{currentInfo.Eat_MenuName}}
                        <div class="right-bnt">
                            <div class="bnt" @click="print()"><i class="iconfont icon-a-dayinmoban1"></i>打印</div>
                            <div class="bnt" v-if="isQuote" @click="quoteClick"><i class="iconfont icon-yinyong"></i>引用</div>
                        </div>
                    </div>
                    <div class="content-box">
                        <div class="tr-box">
                            <div class="td-box">
                                创建人：
                                <span class="text">
                                    <i class="iconfont icon-suo" v-if="currentInfo.IsPrivateMenu && currentInfo.CREAT_ID!=userInfo?.Login_Name"></i>
                                    {{currentInfo.CREAT_ID}}({{shortDateFormat(currentInfo.CREAT_TIME) }})
                                </span>
                            </div>
                            <div class="td-box">
                                最后更新人：
                                <span class="text">{{currentInfo.UPDATE_ID}}({{shortDateFormat(currentInfo.UPDATE_TIME)}})</span>
                            </div>
                        </div>
                        <div class="tr-box">
                            <div class="td-box">
                                备注：
                                <span class="text">{{currentInfo.Order_Remark}}</span>
                            </div>
                        </div>
                        <div class="stretch-box">
                            <div class="panel-heading">
                                <div class="td name">名称</div>
                                <div class="td tdnum">数量</div>
                                <div class="td tds">单价</div>
                                <div class="td subtotal">小计</div>
                            </div>
                            <div class="scroll-box" v-mouse-scroll v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.slide-wrapper'}">
                                <div class="slide-wrapper" v-for="item in currentInfo.FoodSetmeals" :key="item">
                                    <div class="slide-content" >
                                        <div class="tr">
                                            <div class="td name nowrap">{{item.Eat_XFName}}

                                                <div class="more" v-if="item.FoodSetmeals?.length>0" style="display:inline-block;">
                                                    <div class="more-bnt" v-if="eatXFBMID!=item.Eat_ConsumeID" @click="eatXFBMID=item.Eat_ConsumeID">
                                                        <i class="iconfont icon-xiala"></i>
                                                    </div>
                                                    <div class="more-bnt" v-else @click="eatXFBMID=null">
                                                        <i class="iconfont icon-shangla"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="td tdnum">{{item.Eat_Number}}</div>
                                            <div class="td tds">¥{{Conversion(item.Eat_CheckPrice)}}/{{(item.Eat_XFSize||"").substring(0,1)}}</div>
                                            <div class="td subtotal nowrap">{{Conversion((item.Eat_CheckPrice * item.Eat_Number))}}</div>
                                        </div>
                                        <div class="describe flex-box">
                                            <div class="inlineBlock nowrap2 flavor">
                                                <span v-if="item.Eat_MasName" :title="item.Eat_MasName">
                                                    <i class="iconfont icon-a-beizhu2" ></i>
                                                    口味:<span>{{item.Eat_MasName}}</span>
                                                </span>
                                            </div>
                                            <div class="inlineBlock tag-box">
                                                <!--套-->
                                                <span class="tag tao" :style="{visibility:(item.FoodProperty_Dimension?'':'hidden')}" >{{item.FoodProperty_Dimension||'套'}}</span>
                                                <!--厨房状态-->
                                                <span class="tag delimit" v-if="item.Food_Speed">{{item.Food_Speed}}</span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="border"></div>
                                    <el-collapse-transition>
                                    <div class="more-box" v-show="item.FoodSetmeals?.length>0 && eatXFBMID==item.Eat_ConsumeID">
                                        <div class="slide-content" :title="item.Eat_XFName" v-for="(food,foodindex) in item.FoodSetmeals" :key="foodindex">
                                            <div class="tr">
                                                <div class="td name nowrap">{{food.Eat_XFName}}</div>
                                                <div class="td tdnum">{{food.Eat_Number}}</div>
                                                <div class="td tds" >¥{{Conversion(food.Eat_CheckPrice)}}/{{(food?.Eat_XFSize||"份").substring(0,1)}}</div>
                                                <div class="td subtotal nowrap" >{{Conversion(food.Eat_CheckPrice * food.Eat_Number) }}</div>
                                            </div>
                                            <div class="describe flex-box">
                                                <div class="inlineBlock flavor nowrap2" >
                                                    <span v-if="food.Eat_MasName!=''" :title="food.Eat_MasName">
                                                        <i class="iconfont icon-a-beizhu2" ></i>
                                                        口味:<span>{{food.Eat_MasName}}</span>
                                                    </span>
                                                </div>
                                                <div class="inlineBlock tag-box">
                                                    <!--厨房状态-->
                                                    <span class="tag delimit" v-if="food.Food_Speed">{{food.Food_Speed}}</span>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    </el-collapse-transition>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer orderFooter">
                        <div class="float-left txt">
                            <span class="money" v-html="deskTotal.total"></span>&nbsp;
                            <span class="small">共{{deskTotal.sum}}道 &nbsp; {{deskTotal.sumPortion}}份</span>
                        </div>
                        <div class="float-right" @mousedown="(e)=>{e.preventDefault()}">
                            <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiangquan-top"></i></div>
                            <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiangquan-bottom"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn cancel-btn" @click="hide()">取消</button>
        </div>
        <analog-key-board ref="keyBoard" :isKeyDownEnter="true"></analog-key-board>
    </modal>
</template>

<script>
import {NumberConversion,config ,deepClone} from '/src/common' 
// 配菜单 只读
export default {
    name:'withMenuReadModel',
    emits:["quoteReturn","closeModel"],
    props:{
        isShow:Boolean,
        /**是否需要引用 */
        isQuote:{
            type:Boolean,
            default:false
        },
    },
    data(){
        return{
            userInfo:null,
            listData:[],
            varietySelect:null,
            //套餐 下拉id
            eatXFBMID:null,
            //是否编辑
            isEdit:false,
            
            //当前选中列
            currentInfo:null,
            //自定义菜品数据
            PosDiyFoodsData:[],
            //pos菜品数据
            PosFoodsData:{},
            /**菜品口味 */
            masKindsList:null,
            pageSize:10,
            pageIndex:1,
            DiyTcInfo:false
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.loadData();
        })
        this.userInfo= this.$auth.getUserInfo();
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.PosFoodsData=d;
        }).catch(e=>{
            console.log('e:'+JSON.stringify(e))
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        //即配套餐属性
        this.$cacheData.DeskPosconfigure().then((d)=>{
            if(d.DiyTcInfo?.IS_PERAVGPRICETC || d.DiyTcInfo?.IS_PERAVGPRICETC==0){
                if(d.DiyTcInfo?.IS_PERAVGPRICETC==1){
                    this.DiyTcInfo=true
                }
            }
        }).catch(e=>{
            console.log('e:'+e)
        })
        /**自定义菜品数据结构 */
        this.$cacheData.PosDiyFoods().then((d)=>{
            this.PosDiyFoodsData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到自定义菜品数据', "提示", {confirmButtonText: "确定"});
        })

        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.pageIndex=1;
                this.currentInfo=null;
                this.eatXFBMID=null;
                this.varietySelect=null;
                this.isEdit=false;
                this.listData=[];
                this.loadData();
            }
        },
        isEdit(newValue){
            if(!newValue && this.currentInfo){
                this.$nextTick(()=>{
                    this.$refs.singleTableRef?.setCurrentRow(this.currentInfo);
                })
            }
        },
        varietySelect(){
            if(this.varietySelect){
                this.pageIndex=1;
            }
        }
    },
    computed:{
        //当前设备id
        EquipmentID(){
            return config.UUID;
        },
        /**全部菜品数据 菜品 + 自定义菜品*/
        AllFoods(){
            let foods=[];
            let data=[];
            let d=  this.PosFoodsData?.data;
            if(d && d.length>0){
                data=deepClone(d);
                if(this.PosDiyFoodsData && this.PosDiyFoodsData.length>0){//合并 自定义菜品
                    data.forEach(category=> {
                        category?.FoodSubCategorys?.forEach(sub => {
                            let difSub=this.PosDiyFoodsData.find(f=>f.SubCategory_ID==sub.SubCategory_ID);
                            if(difSub && difSub.PosFoods?.length>0){
                                difSub.PosFoods.forEach((f)=>{
                                    f.isDiy=true;
                                })
                                sub.PosFoods=sub.PosFoods.concat(difSub.PosFoods);
                            }
                        });
                        category.FoodSubCategorys?.forEach((sub)=>{
                            if(sub.PosFoods && sub.PosFoods.length>0)
                            foods=foods.concat(sub.PosFoods)
                        })
                    });
                }else{
                    data.forEach(category=> {
                        category.FoodSubCategorys?.forEach((sub)=>{
                            if(sub.PosFoods && sub.PosFoods.length>0)
                            foods=foods.concat(sub.PosFoods)
                        })
                    });
                }
            }
            return foods||[];
        },
        /**全部 口味数据 */
        AllMasKinds(){
            let list=[];
            this.masKindsList?.forEach((kinds)=>{
                list=list.concat(kinds.EatMasList);
            })
            return list;
        },
        /**计算账单总金额 数量 份数 */
        deskTotal(){
            let placeFoodsTotal = 0;
            let placeFoodsSumPortion = 0;
            let placeFoodsSum=0;
            this.currentInfo?.FoodSetmeals?.map(item=>{
                if(item.Eat_Number>0){
                    placeFoodsSum++; //总数量
                    if(item.FoodSetmeals){
                        item.FoodSetmeals.map(eat=>{
                            if(item.Food_Type!=4){
                                placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(eat.Eat_Number); //总份量
                            }
                            if(item.ModiPrice){
                                placeFoodsTotal = placeFoodsTotal+((eat.Eat_CheckPrice+eat.ModiPrice) * eat.Eat_Number)  //总价格
                            }else{
                            placeFoodsTotal = placeFoodsTotal+(eat.Eat_CheckPrice * eat.Eat_Number)  //总价格
                            }
                        })
                    }
                    if(item.Food_Type!=4){
                        placeFoodsSumPortion = Number(placeFoodsSumPortion) + Number(item.Eat_Number); //总份量
                    }
                    if(item.ModiPrice){
                        placeFoodsTotal = placeFoodsTotal+((item.Eat_CheckPrice+item.ModiPrice) * item.Eat_Number)  //总价格
                    }else{
                        placeFoodsTotal = placeFoodsTotal+(item.Eat_CheckPrice * item.Eat_Number)  //总价格
                    }
                }
            })
            return {
                total:'<i>￥</i>'+placeFoodsTotal.toFixed(2).replace(/[.]\d*/g,(s)=>{return '<b>'+s+'</b>'}),//总金额
                sumPortion: parseFloat(placeFoodsSumPortion.toFixed(2)),//份数
                sum:placeFoodsSum//数量
            }
        },
        /**过滤数据 */
        billFilter(){
            let list=this.listData;
            if(list && list.length>0){
                if(this.varietySelect){//搜索
                    let seach=this.varietySelect.toUpperCase();
                    list=list.filter((item)=>(item.Eat_MenuName||'').toUpperCase().indexOf(seach)>=0);
                }
            }
            return list;
        },
        /**分页数据 */
        billPageList(){
            if(this.billFilter && this.billFilter.length>0){
                 return this.billFilter.slice(((this.pageIndex-1)*this.pageSize),this.pageIndex*this.pageSize);
            }
            return [];
        },
        pageCount(){
            let lenth=1;
            if(this.billFilter && this.billFilter.length>0){
                lenth=Math.ceil(this.billFilter.length/this.pageSize)||1;
            }
            return lenth;
        }
    },
    methods:{
        keyup(e){
            if(e.key=="Escape" && this.isEdit==false){
                this.hide()
            }else if(e.key=="Escape" && this.isEdit==true){
                this.isEdit=false
                setTimeout(() => {
                    this.$refs.searchBox?.focus();
                },100);
            }
        },
        //加载数据
        loadData(){
            setTimeout(() => {
                this.$refs.searchBox?.focus();
            },100);
            const loading = this.$loading({
                text: "获取配菜单数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            this.$httpAES.post("Bestech.CloudPos.GetComboDishProjects", {
                User_ID:userInfo.User_ID,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.listData=d.ResponseBody;
                    this.listData.map((info)=>{
                        info.FoodSetmeals?.forEach((item)=>{
                            item.FoodSetmeals=item.orderMenuTcItemList;
                            item.Eat_CheckPrice=item.Eat_SalePrice;
                            item.Eat_ConsumeID=item.Eat_MenuConsumeID;
                            item.FoodProperty_Dimension=item.FoodSetmeals?.length>0?"套":undefined;
                            item.FoodSetmeals?.forEach((food)=>{
                                food.Eat_CheckPrice=food.Eat_SalePrice;
                            })
                        })
                    })
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("配菜单数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /*时间格式 月/日 时分*/
        shortDateFormat(cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('MM/dd hh:mm');
        },
        hide(){
            this.$emit("closeModel");
        },
        handleCurrentChange(currentRow){
            this.currentInfo=currentRow;
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        
        //点击引用
        quoteClick(){
            let data=deepClone(this.currentInfo?.FoodSetmeals);
            data.map((info)=>{
                if(info.orderMenuTcItemList?.length==0){
                    info.orderMenuTcItemList=null;
                }
                info.FoodSetmeals=info.orderMenuTcItemList;
            })

            this.$emit("quoteReturn",{data:data,AllFoods: deepClone(this.AllFoods),AllMasKinds:this.AllMasKinds,DiyTcInfo:this.DiyTcInfo});
        },
        /**打印配菜单 */
        print(){
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },100);
            const loading = this.$loading({
                text: "请求印配菜单打印数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                UserID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Rpt_Date:(new Date()),
                /**业务， 1：触摸屏POS   2：扫码点单   3：外卖 */
                PrintBusiness:1,
                /**打印类型， 打印类型， 
                 */
                PrintType:11,
                Order_ID:this.currentInfo.Eat_MenuAutoid,//印配菜单ID
            }
            this.$httpAES.post("Bestech.CloudPos.GetPrintContent",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$webBrowser.posPrint(d.ResponseBody);
                    this.$message.success("已发送配菜单打印指令");
                }else{
                    this.$message.error("请求配菜单打印数据失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求配菜单打印数据失败：'+e.message);
                console.log('请求配菜单打印数据失败：',e);
            })
        },
    }
}
</script>

<style lang="scss">
@import './withMenuModel.scss';
</style>
<style lang="scss">
.withMenuReadModel{
    .footer-box{
        .btn.cancel-btn{
            width: 400px;
        }
    }
}
</style>