<template>
  <modal class="swipeCardModel" :isShow="isShow">
      <div class="header">会员刷卡</div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <!-- <div class="title">会员刷卡</div> -->
                    <div class="sk">
                        请刷卡...
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt">请输入会员卡号</div>
                        <div class="from-input">
                            <input-pattern type="text" ref="input" @focus="(e)=>e.target.select()" @blur="(e)=>{if(isShow){e.target.focus()}}" data-model='idCode' v-model="idCode" isBarcode @barcode="onswipeMember(true)" />
                        </div>
                    </div>
                </div>
                <!-- <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div> -->
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <!-- <button class="btn btn-confirm">确认</button> -->
        </div>
  </modal>
</template>

<script>

import {initKeyBoard} from '/src/common'

export default {
    name:'swipeCardModel',
    props:{
        isShow:Boolean
    },
    data(){
        return{
            /**会员卡号*/
            idCode:''
        }
    },
    watch: {
        isShow() { 
            if(this.isShow==true){
                this.idCode=''
                this.EmptyDatas();
            }
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.idCode=''
            initKeyBoard.call(this,this.$el,this.$refs.recharRight);
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        })
    },
    methods:{
        EmptyDatas(){
            initKeyBoard.call(this,this.$el,this.$refs.recharRight);
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        onswipeMember(){
             this.$emit("swipeConfirm",this.idCode)
        },
        /**取消 */
        hide(){
            this.$emit("closeModel")
        }
    }
}
</script>

<style lang="sass">
@import './swipeCardModel.scss';
</style>