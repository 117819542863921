<template>
    <modal class="multipleBillingModel" :isShow="isShow">
        <div class="header">多台开单</div>
        <div class="modal-body">
            <div class="bill-box">
                <div class="filter-nav">
                    <div class="nav-box" v-mouse-scroll v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'navPage',isNavFirstSticky:true}"  ref="nav">
                        <div class="nav-li" :class="{selected:filternav==-1}" @click="filternav=-1">全部</div>
                        <div class="nav-li" :class="{selected:filternav==index}" v-for="(item,index) in PosRooms" :key="item"  @click="filternav=index"> {{item.Room_Name}} </div>
                    </div>
                    <div class="page-box">
                        <span class="last-page iconfont icon-zuo" ref="nav-up" ></span>
                        <span class="page-number">{{navPage.pageIndex}}/{{navPage.pageSize}} 页</span>
                        <span class="next-page iconfont icon-you" ref="nav-down" ></span>
                    </div>
                </div>
                <!-- 桌台 -->
                <div class="content-box">
                    <layout-rc :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" :isVarCss="true">
                        <div class="item-li" :class="{selected:selectDeskKey[item.Desk_AutoID]}" rc-item v-for="item in deskPageList" :key="item"  @click="addDesk(item)">
                            <div class="title">
                                {{item.Desk_Name}}
                            </div>
                            <i class="iconfont icon-gou"></i>
                        </div>
                    </layout-rc>
                </div>
                <div class="item-operation">
                    <key-board-input class="search"
                        type="text" v-model="filterText" placeholder="按桌台名称检索"
                        isKeyEnterClose isBlurClose
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                    <div class="flex-1">
                    </div>
                    <div class="page-box">
                        <page-turning class="last-page iconfont icon-zuo" type="up" v-model="deskPageIndex" :pageCount="deskTotalSize"></page-turning>
                        <span class="page-number">{{deskPageIndex}}/{{deskTotalSize}} 页</span>
                        <page-turning class="next-page iconfont icon-you" v-model="deskPageIndex" :pageCount="deskTotalSize"></page-turning>
                    </div>
                </div>
            </div>
            <div class="bill-list">
                <div class="title">
                    共<span>{{(selectDeskList||[]).length}}</span>桌
                </div>
                <div class="content-box">
                    <div class="item-li" v-for="(item,index) in selectDeskPageList" :key="index"  @click="removeDesk(item)">
                        <span class="tag" v-if="item.Desk_AutoID==data.openDesk?.Desk_AutoID">主台</span>
                        <div class="name">
                            {{item.Desk_Name}}
                        </div>
                    </div>
                </div>
                <div class="item-operation">
                    <div class="flex-1">
                    </div>
                    <div class="page-box">
                        <page-turning class="last-page iconfont icon-zuo" type="up" v-model="selectPageIndex" :pageCount="selectTotalSize"></page-turning>
                        <span class="page-number">{{selectPageIndex}}/{{selectTotalSize}} 页</span>
                        <page-turning class="next-page iconfont icon-you" v-model="selectPageIndex" :pageCount="selectTotalSize"></page-turning>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>
//多台开单
export default {
    name: 'multipleBillingModel',
    emits:['close','confirm'],
    props:{
        isShow:Boolean,
        //传入的数据 {openDesk:"当前桌台信息",Eat_UnionDeskList:"已选桌台"}
        data:{
            type:Object,
            default:null
        }
    },
    data() {
        return {
            //分类
            filternav:-1,
            //分类 分页
            navPage:{
                pageIndex:1,
                pageSize:1
            },
            //桌台检索
            filterText:"",
            deskPageIndex:1,
            arg:{
                row:8,
                col:6
            },
            selectDeskList:[],
            selectPageIndex:1
        }
    },
    computed:{
        //桌台
        PosRooms(){
            return this.$global.PosRooms||[];
        },
        //全部桌台
        deskAll(){
            let list=[];
            this.PosRooms?.forEach(it => {
                it.PosDesks?.forEach(item=>{
                    list.push(item);
                })
            });
            return list;
        },
        //筛选桌台
        deskFilter(){
            let list=[];
            if(this.filternav>=0){
                list=this.PosRooms[this.filternav]?.PosDesks;
            }else{
                list=this.deskAll;
            }

            let filterText=(this.filterText||"").trim().toUpperCase();
            if(filterText){
                //检索
                list=this.deskAll.filter(item=>(item.Desk_Code||"").toUpperCase().indexOf(filterText)>=0 || (item.Desk_Name||"").toUpperCase().indexOf(filterText)>=0);
            }
            if(this.data?.openDesk){
                return list.filter(it=>it.Desk_Status==0 && it.Desk_AutoID!=this.data.openDesk.Desk_AutoID);//空台数据 排除当前桌台
            }
            return list.filter(it=>it.Desk_Status==0);//空台数据 
        },
        //一页多少条
        deskPageSize(){
            return this.arg.row*this.arg.col;
        },
        //总页数
        deskTotalSize(){
            return Math.ceil(this.deskFilter.length/this.deskPageSize)||1;
        },
        //桌台分页
        deskPageList(){
            return this.deskFilter.slice((this.deskPageIndex-1)* this.deskPageSize, this.deskPageIndex * this.deskPageSize);
        },
        selectDeskKey(){
            let key={};
            this.selectDeskList?.forEach(it=>{
                key[it.Desk_AutoID]=it;
            })
            return key;
        },
        //总页数
        selectTotalSize(){
            let pageSize=8;
            return Math.ceil(this.selectDeskList?.length/pageSize)||1;
        },
        selectDeskPageList(){
            let pageSize=8;
            return this.selectDeskList?.slice((this.selectPageIndex-1)* pageSize, this.selectPageIndex * pageSize);
        }
    },
    watch:{
        isShow:{
            handler(newVal){
                if(newVal){
                    this.filternav=-1;
                    this.navPage.pageIndex=1;
                    this.filterText="";
                    this.deskPageIndex=1;
                    
                    let deskList=[];
                    if(this.data?.openDesk){//主桌台信息
                        let it=this.data?.openDesk;
                        deskList.push({Desk_AutoID:it.Desk_AutoID,Desk_Code:it.Desk_Code,Desk_Name:it.Desk_Name})
                    }
                    this.data?.Eat_UnionDeskList?.forEach(it=>{
                        deskList.push({Desk_AutoID:it.Desk_AutoID,Desk_Code:it.Desk_Code,Desk_Name:it.Desk_Name});
                    })
                    this.selectDeskList=deskList;
                }
            },
            immediate:true
        },
        selectTotalSize(){
            if(this.selectTotalSize<this.selectPageIndex){
                this.selectPageIndex=this.selectTotalSize;
            }
        },
        filternav(){
            this.deskPageIndex=1;
        },
        filterText(){
            this.deskPageIndex=1;
        }
    },
    mounted(){
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        addDesk(item){
            let index=this.selectDeskList.findIndex(it=>it.Desk_AutoID==item.Desk_AutoID);
            if(index>=0){
                this.selectDeskList.splice(index,1);
            }else{
                this.selectDeskList.push({Desk_AutoID:item.Desk_AutoID,Desk_Code:item.Desk_Code,Desk_Name:item.Desk_Name});
            }
        },
        removeDesk(item){
            let index=this.selectDeskList.findIndex(it=>it.Desk_AutoID==item.Desk_AutoID);
            if(index>0){
                this.selectDeskList.splice(index,1);
            }
        },
        confirm(){
            let list=this.selectDeskList?.filter(it=>it.Desk_AutoID!==this.data?.openDesk?.Desk_AutoID);
            if(list.length>0){
                this.$emit("confirm",list);
            }else{
                this.$message.warning('请先选择需要联台的桌台！');
            }
        }
    }
}

</script>

<style lang="scss">
@import "./multipleBillingModel.scss";
</style>