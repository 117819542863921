<template>
  <modal class="assistSizeModel" :isShow="isShow">
      <div class="header">数量确认</div>
        <div class="modal-body">
            <div class="clearfix" ref="recharRight">
                <div class="left-box">
                    <div class="title">基本信息</div>
                    <div class="tr-li">
                        <div class="lable-txt">菜品名称：</div>
                        <div class="from-input" style="width:310px"><input type="text" :value="assistSizeInfo.Food_Name" readonly /></div>
                    </div>
                    <div class="tr-li">
                        <div class="lable-txt">点单数量</div>
                        <div class="from-input" style="width:115px"><input type="text" class="key-focus"  ref="priceInput" v-model="priceNum" @keyup.enter="inputCursor(1)" onkeyup="value=this.value.replace(/\D+.\D+/g,'')" /></div>
                    </div>
                    <div class="title">辅助单位:{{this.assistSizeInfo.Food_AssSize}}</div>
                    <div class="tr-li">
                        <div class="lable-txt">辅助数量</div>
                        <div class="from-input" style="width:115px"><input type="text" class="key-focus" ref="numInput" v-model="assSizeNum" @keyup.enter="inputCursor(2)" onkeyup="value=this.value.replace(/\D+.\D+/g,'')" /></div>{{assistSizeInfo.Food_AssSize}}
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                </div>
            </div>
            <div class="tip-box">
                <!--提示：此处显示提示文本，没有提示则不显示-->
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
  </modal>
</template>

<script>

import {initKeyBoard} from '/src/common'
import { httpAES } from '/src/services'
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"

export default {
    name:'assistSizeModel',
    props:{
        isShow:Boolean,
        assistSizeInfo:Object,
        orderNumber:String
    },
    data(){
        return{
            /**数量 */
            priceNum:1,
            /**辅助数量 */
            assSizeNum:0,
        }
    },
    watch: {
        isShow() { 
            if(this.isShow==true){
                if(Number(this.orderNumber)>0){
                    this.priceNum=Number(this.orderNumber)
                }else{
                    this.priceNum=1
                }
                this.assSizeNum=0
                this.EmptyDatas();
            }
        }
    },
    mounted(){
        this.$nextTick(()=> {
            if(Number(this.orderNumber)>0){
                this.priceNum=Number(this.orderNumber)
            }else{
                this.priceNum=1
            }
            this.assSizeNum=0
            initKeyBoard.call(this,this.$el,this.$refs.recharRight);
            this.EmptyDatas();
        })
    },
    methods:{
        changeRate(e,input){
            e.target.value=(e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
            this.input=e.target.value
        },
        inputCursor(type){
            if(type==1){
                this.$refs.numInput.focus();
            }
            if(type==2){
                this.confirm()
            }
        },
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.numInput.focus();
            },100);
            this.assSizeNum=''
        },
        /**确认 */
        confirm(){
            if(Number(this.assSizeNum)==0){
                    this.$alert('辅助数量不能为0!', '提示', {confirmButtonText: '确定',});
                return
            }
            this.$emit("assSizeConfirm",this.assistSizeInfo, Number(this.priceNum),Number(this.assSizeNum).toFixed(2))
        },
        /**取消 */
        hide(){
            this.$emit("closeModel")
        }
    }
}
</script>

<style lang="sass">
@import './assistSizeModel.scss';
</style>