import { reactive,watch } from 'vue'
import { aesUtil,newGuid,getDiffMinutes,webBrowser} from '../common'
import httpAES from './http'
import auth from './auth'
import mqTTClient from './mqTTClient'
import { Date, Object } from 'core-js';
import localforage from 'localforage'

// 配置不同的驱动优先级
localforage.config({
    driver: [localforage.INDEXEDDB,
            localforage.WEBSQL,
            localforage.LOCALSTORAGE],
    name: 'BaseData'
});

let key = '6A6F7C8F712C46CCA2D2FAE240F96D9D';
let cacheData={};
let storage={
    set(name,data){
        let str=JSON.stringify(data);
        cacheData[name]=str;
        localStorage.setItem(name,str);
    },
    get(name){
        try {
            if(!cacheData[name]){
                cacheData[name]=localStorage.getItem(name);
            }
            if(cacheData[name]){
                return JSON.parse(cacheData[name]);
            }
        } catch (e) {console.log(e)}
    },
    create(name){
        if(name){
            delete cacheData[name];
            localStorage.removeItem(name);
        }else{
            cacheData={};
            localStorage.clear();
        }
    },
    /**异步缓存   */
    setAsync(name,data){
        cacheData[name]=JSON.stringify(data);
        return localforage.setItem(name,data).catch(e=>{
            console.log("error:setItem",e);
        });
    },
    /**返回 异步缓存   */
    getAsync(name){
        return new Promise((resolve,reject)=> {
            try {
                if(!cacheData[name]){
                    localforage.getItem(name).then(d=>resolve(d)).catch(e=>reject(e));
                }
                if(cacheData[name]){
                    resolve(JSON.parse(cacheData[name]));
                }
            } catch (e) {
                console.log(e)
                reject(e);
            }
        });
    },
    /**清除 异步缓存   */
    createAsync(name){
        return new Promise((resolve,reject)=>{
            if(name){
                localforage.removeItem(name).then(d=>{
                    delete cacheData[name];
                    resolve(d);
                }).catch(e=>reject(e));
            }else{
                
                localforage.clear().then((d)=>{
                    // 当数据库被全部删除后，此处代码运行
                    cacheData={};
                    resolve(d);
                }).catch((err)=>{
                    // 当出错时，此处代码运行
                    console.log(err);
                    reject(err)
                });
                
            }
        })
        
    },
}

//比较 登录人 拿缓存数据
async function storageGet(name){
    return storage.getAsync(name).then(data=>{
        let userID=auth.getUserID();
        if(data && data._cacheDif && data._cacheDif.userID!=userID){
            return null;
        }
        return data;
    }).catch(e=>{
        return null
    })
}

/**
 * 缓存基础类型数据
 * @param {*} DataTypeName 要求的 DataTypeNames 数据名称 也是缓存用的key
 * @param dataName  d.ResponseBody[dataName] 返回数据中  ResponseBody 下对应的数据
 * @param {*} checkFun 判断缓存是否未失效  true 未失效 false  失效
 * then 返回的数据格式 {data:数据,DataMaxTime:时间搓}
 */
 async function cacheBase(DataTypeName,dataName,checkFun,isRpt_Date){
    if(!DataTypeName || DataTypeName==""){
        return new Promise(resolve => {
            setTimeout(()=>{resolve()},0);
        });
    }
    let cacheName=DataTypeName;
    let cacheData= await storageGet(cacheName);
    let userInfo=auth.getUserInfo();
    let userID=userInfo?.User_ID;
    let Rpt_Date=userInfo?.Rpt_Date;
    /**最新数据的时间戳 */
    let DataMaxTimes=[];
    if(cacheData && cacheData._cacheDif && cacheData._cacheDif.userID==userID && cacheData.DataMaxTime && (!isRpt_Date || (isRpt_Date && cacheData.Rpt_Date==Rpt_Date)) ){
        DataMaxTimes=[{DataTypeName:DataTypeName,MaxTime:cacheData.DataMaxTime}];
        if(typeof(checkFun)=='function' && checkFun(cacheData._cacheDif)){
            return new Promise(resolve => {
                setTimeout(()=>{resolve(cacheData.body)},0);
            });
        }
    }else{
        cacheData=null
    }
    
    
    return httpAES.post("Bestech.CloudPos.GetBaseData",{
        Body_ID:newGuid(),
        User_ID:userID,
        DataTypeNames:[DataTypeName],
        DataMaxTimes:DataMaxTimes
    }).then(d=>{
        if(d && d.ResponseHeader && d.ResponseHeader.ResultCode==0){
            let MaxTimestamps=d.ResponseBody?.DataMaxTimes?.find(t=>t.DataTypeName==DataTypeName)?.MaxTimestamps;//最大时间搓 用于数据推送
            let DataMaxTime=d.ResponseBody?.DataMaxTimes?.find(t=>t.DataTypeName==DataTypeName)?.MaxTime;//数据变化时最大时间搓
            if(cacheData && cacheData.DataMaxTime && new Date(DataMaxTime)<=new Date(cacheData.DataMaxTime)){//判断 后端数据无变化时传入的时间与返回的时间一致
                cacheData._cacheDif={time:(new Date()).getTime(),userID:userID};//更新本地缓存时间
                storage.setAsync(cacheName,cacheData); //缓存 
                return new Promise(resolve => {
                    setTimeout(()=>{resolve(cacheData.body)},0);
                });
            }
            if(d.ResponseBody[dataName] || (Array.isArray(d.ResponseBody[dataName]) && d.ResponseBody[dataName].length>0)){
                let data={
                    body:{data:d.ResponseBody[dataName],MaxTimestamps:MaxTimestamps},
                    DataMaxTime:DataMaxTime,//最大时间搓
                    _cacheDif:{time:(new Date()).getTime(),userID:userID}
                }
                if(isRpt_Date){
                    data._cacheDif["Rpt_Date"]=Rpt_Date;//营业日期
                }
                storage.setAsync(cacheName,data); 
            }
            return {data:d.ResponseBody[dataName],MaxTimestamps:MaxTimestamps};
        }else{
            throw d.ResponseHeader.ResultDesc;
        }
    });
}
/**
 * 缓存基础类型数据
 * @param {*} DataTypeNames 数组 要求的 DataTypeNames 数据名称 也是缓存用的key
 * @param {*} dataNames  数组 d.ResponseBody[dataName] 返回数据中  ResponseBody 下对应的数据
 * @param {*} checkFun 判断缓存是否未失效  true 未失效 false  失效
 * then 返回的数据格式 [DataTypeNames:{data:数据,DataMaxTime:时间搓}] DataTypeNames对应传入的数据名称
 */
 async function cacheBaseAll(DataTypeNames,dataNames,checkFun){
    if(!DataTypeNames || DataTypeNames.length==0){
        return new Promise(resolve => {
            setTimeout(()=>{resolve()},0);
        });
    }
    let cacheDataAll={};
    /**最新数据的时间戳 */
    let DataMaxTimes=[];
    let dataAll={};
    let dataAllLength=0;
    let httpTypeNames=[];
    let httpDataNames=[];
    
    DataTypeNames.forEach(async (name,index)=>{
        cacheDataAll[name]=await storageGet(name);
        if(cacheDataAll[name] && cacheDataAll[name]._cacheDif && cacheDataAll[name].DataMaxTime){
            
            if(typeof(checkFun)=='function' && checkFun(cacheDataAll[name]._cacheDif)){
                dataAll[name]=cacheDataAll[name].body;
                dataAllLength++;
            }else{
                DataMaxTimes.push({DataTypeName:name,MaxTime:cacheDataAll[name].DataMaxTime});
            }
        }
        if(!dataAll[name]){
            httpTypeNames.push(name);
            httpDataNames.push(dataNames[index]);
        }
    })
    if(dataAllLength==DataTypeNames.length){
        return new Promise(resolve => {
            setTimeout(()=>{resolve(dataAll)},0);
        });
    }

    let userID=auth.getUserID();
    return httpAES.post("Bestech.CloudPos.GetBaseData",{
        Body_ID:newGuid(),
        User_ID:userID,
        DataTypeNames:httpTypeNames,
        DataMaxTimes:DataMaxTimes
    }).then(d=>{
        if(d && d.ResponseHeader && d.ResponseHeader.ResultCode==0){
            httpTypeNames.forEach((DataTypeName,index)=>{
                let dataName=httpDataNames[index];
                let MaxTimestamps=d.ResponseBody?.DataMaxTimes?.find(t=>t.DataTypeName==DataTypeName)?.MaxTimestamps;//最大时间搓 用于数据推送
                let DataMaxTime=d.ResponseBody?.DataMaxTimes?.find(t=>t.DataTypeName==DataTypeName)?.MaxTime;//数据变化时最大时间搓

                let json={data:d.ResponseBody[dataName],MaxTimestamps:MaxTimestamps};
                let cacheData=cacheDataAll[DataTypeName];
                if(cacheData && cacheData.DataMaxTime && new Date(DataMaxTime)<=new Date(cacheData.DataMaxTime)){//判断 后端数据无变化时传入的时间与返回的时间一致
                    cacheData._cacheDif={time:(new Date()).getTime(),userID:userID};//更新本地缓存时间
                    storage.setAsync(DataTypeName,cacheData); //缓存 
                    json=cacheData.body;
                }
                else if(d.ResponseBody[dataName] || (Array.isArray(d.ResponseBody[dataName]) && d.ResponseBody[dataName].length>0)){
                    let data={
                        body:{data:d.ResponseBody[dataName],MaxTimestamps:MaxTimestamps},
                        DataMaxTime:DataMaxTime,//最大时间搓
                        _cacheDif:{time:(new Date()).getTime(),userID:userID}
                    }
                    storage.setAsync(DataTypeName,data); 
                }

                dataAll[DataTypeName]=json
            })
            return new Promise(resolve => {
                setTimeout(()=>{resolve(dataAll)},0);
            });
        }else{
            throw d.ResponseHeader.ResultDesc;
        }
    });
}
/**
 * 缓存基础类型数据
 * @param {*} DataTypeNames 参数 ["PosFoods","MasKinds","ConsumeTypes","KitIssuingTypes","PosWaiters","DiscountScheme","OrderChannel"]
 * @param {*} checkFun 判断缓存是否未失效  true 未失效 false  失效
 */
 async function cacheBaseData(DataTypeName,dataName,checkFun){
     return cacheBase(DataTypeName,dataName,checkFun).then(d=>{
        setGlobalVariable(DataTypeName,d?.data);
        return d?.data;
     })
}

/**比较登录 即每次登录才重新取数据 */
function diffLogin(data){
    let user=auth.getUserInfo();
    if(user && data.time>=user._time){
        return true;
    }
    return false;
}

/** 全局 基础数据*/
const globalVariable={
    /**桌台和菜单配置 */
    GlobalSysSetting:{}
}
/**全局 初始化 基础数据 */
function setGlobalVariable(key,data){
    if(key=='GlobalSysSetting') {
        globalVariable[key]=data;
    }
    /**促销方案 */
    if(key=="SalesPromotion"){
        promotionsData.data=data;
    }
}

/** 菜品数据结构 全局变量缓存*/
let posFoodData=reactive({data:[],MaxTimestamps:0});
/** 桌台数据结构 全局变量缓存*/
let roomDeskData=reactive({data:{},MaxTimestamps:0});

let RefreshSoldTime=0;
/**促销方案数据 */
let promotionsData=reactive({data:[]});
watch(posFoodData,()=>{
    updatePromotionFood();
})
watch(promotionsData,()=>{
    if((promotionsData.data||[]).length==0){//清除促销数据
        posFoodData.data.forEach(sub=>
            sub.FoodSubCategorys.forEach(food=>
                food.PosFoods.forEach(item=>{
                    if(item.Is_MultipleSize){//多规格
                        item.MultipleSizeFoods.forEach(mItem=>{
                            if(mItem.Is_Promotion){
                                mItem.Is_Promotion=false;
                                mItem.Promotion_Price=0;
                            }
                        })
                    }
                    if(item.Is_Promotion){
                        item.Is_Promotion=false;
                        item.Promotion_Price=0;
                    }
                    return item;
                })
            )
        )
    }
    updatePromotionFood();
})

let isDelayedStart=false;
function funDelayed(fun){
    if(typeof fun =="function" && !isDelayedStart){
        isDelayedStart=true;
        setTimeout(()=>{
            fun();
            setTimeout(()=>{
                isDelayedStart=false;
            },0)
        },500);//500毫秒 执行一次
    }
}
/**更新 促销方案 菜品价格 */
function updatePromotionFood(){
    funDelayed(()=>{
        if(posFoodData.data?.length>0 && promotionsData.data?.length>0){
            console.log("updatePromotionFood")
            let date=new Date();
            let hour=date.getHours();//时
            let minute=date.getMinutes();//分
            let foods=[];
            posFoodData.data.forEach(sub=>
                sub.FoodSubCategorys.forEach(food=>
                    food.PosFoods.forEach(item=>{
                        if(item.Is_MultipleSize){//多规格
                            item.MultipleSizeFoods.forEach(mItem=>{
                                if(mItem.Is_Promotion){
                                    mItem.Is_Promotion=false;
                                    mItem.Promotion_Price=0;
                                }
                                foods.push(mItem);
                            })
                        }
                        if(item.Is_Promotion){
                            item.Is_Promotion=false;
                            item.Promotion_Price=0;
                        }
                        foods.push(item);
                        return item;
                    })
                )
            )
            promotionsData.data.forEach((d)=>{
                let begin_Time=d.Begin_Time.split(":");
                if(begin_Time.length==2){
                    begin_Time[0]=parseInt(begin_Time[0]);
                    begin_Time[1]=parseInt(begin_Time[1]);
                    if(!(begin_Time[0]<hour || (begin_Time[0]==hour && begin_Time[1]<=minute))){//当前时间小于开始时间 
                        return;
                    }
                }
                let end_Time=d.End_Time.split(":");
                if(end_Time.length==2){
                    end_Time[0]=parseInt(end_Time[0]);
                    end_Time[1]=parseInt(end_Time[1]);
                    if(!(end_Time[0]>hour || (end_Time[0]==hour && end_Time[1]>=minute))){//当前时间大于结束时间
                        return;
                    }
                }

                //时间断符合促销方案
                d.PromotionFoods.forEach(it=>{
                    foods.find(item=>{
                        if(it.Food_ID==item.Food_ID){
                            item.Is_Promotion=true;//是否促销
                            item.Promotion_Price=it.Promotion_Price;//促销价格
                            return true;
                        }
                        return false;
                    })
                })

            })
        }
    })
}

//计算桌台超时
const updateRoomDesk=(()=>{
    let time;
    function loopFun(){
        if(time){
            clearTimeout(time);
        }
        //开台后N分钟后提醒结账， 即超时状态（不填或0为不启用）
        let OpenNotifyCheckOutAfterFewMinutes=globalVariable.GlobalSysSetting?.OpenNotifyCheckOutAfterFewMinutes;
        if(OpenNotifyCheckOutAfterFewMinutes>0){
            let newDate=new Date();
            roomDeskData.data?.PosRooms?.forEach(item=>{
                item.PosDesks?.forEach(it=>{
                    if(it.OpenDeskInfo){
                        let overTime=getDiffMinutes(new Date(it.OpenDeskInfo.OpenDesk_Time),newDate);//计算超时间 分钟
                        if(OpenNotifyCheckOutAfterFewMinutes<=overTime){//超时
                            let minute=overTime%60;
                            let hour=parseInt(overTime/60);
                            let str="【"+it.Desk_Name+"】台号已开台";
                            if(hour>0){
                                str+=hour+"小时";
                            }
                            if(minute>0){
                                str+=minute+"分钟";
                            }
                            it.OpenDeskInfo.timeoutTip=str;
                            it.Desk_Status=4;//超时状态
                        }
                    }
                })
            })
        }
        setTimeout(loopFun,30*1000);
    }
    loopFun();
    return loopFun;
})()
//语音播报开关
let isVoiceBroadcast=true;
let BroadcastList=[];//语音播报数据
let isBroadcast=false;
function broadcast(){//语音播报
    if(isVoiceBroadcast){
        isBroadcast=true;
        if(BroadcastList.length>0){
            let title=BroadcastList[BroadcastList.length-1];

            setTimeout(()=>{
                console.log("语音播报开始:",title);
                webBrowser.tts(title).then(()=>{
                    console.log("语音播报结束");
                    BroadcastList.shift();
                    broadcast();
                }).catch((error)=>{
                    BroadcastList.shift();
                    broadcast();
                    console.log("语音播报失败:",error);
                })
            },1000);
            
        }else{
            isBroadcast=false;
        }
    }else{//关闭语音播报
        BroadcastList=[];
        isBroadcast=false;
    }
}
/**添加语音播报 数据 */
function addBroadcast(data){
    if(!isVoiceBroadcast){//关闭语音播报
        return;
    }
    BroadcastList.push(data);
    if(!isBroadcast){
        broadcast();
    }
}


export default {
    ...storage,
    /**添加语音播报 数据 @param data 播报数据 */
    addBroadcast,
    getBroadCast(){
        return isVoiceBroadcast;
    },
    /**语音播报 开关 */
    setBroadCast(isBo){
        isVoiceBroadcast=isBo;
        if(!isBo){//关闭语音播报
            BroadcastList=[];
            isBroadcast=false;
        }
    },
    /** 全局 基础数据*/
    globalVariable,
    /**更新 促销方案 菜品价格 */
    updatePromotionFood,
    //登录记住密码用
    rememberLogin:{
        get(){
           try {
                let str=storage.get("rememberLogin");
                if(str){
                    return JSON.parse(aesUtil.decrypt(str,key));
                }
           } catch (error) {
               console.log(error);
           }
        },
        set(info){
            let d=aesUtil.encrypt(JSON.stringify(info),key);
            storage.set("rememberLogin",d);
        },
        create(){
            storage.create("rememberLogin");
        }
    },
    //系统项目列表 缓存数据
    SysProjects:{
        get(){
            return storage.get("SysProjects");
        },
        set(info){
            storage.set("SysProjects",info);
        },
        create(){
            storage.create("SysProjects");
        }
    },
    /**点单桌台横向数字键盘是否开启 */
    Numberkey:{
        /**保存数据 */
        set(type){
            storage.set('Numberkey',type);
        },
        getNumberKeyOff(){
            let NumberKeyOff=storage.get('Numberkey');
            return NumberKeyOff
        }
    },
    /**上桌顺序缓存 */
    servingRoomsList:{
        /**保存数据 */
        set(data){
            storage.set('servingRooms',data);
        },
        /**返回暂存列表数据*/
        getRoomsList(){
            let servingRooms=storage.get('servingRooms')||[];//数组数据
            return servingRooms;
        },
        /**删除指定暂存数据 */
        delete(id){
            let servingRooms=storage.get('servingRooms')||[];//数组数据
            let index= servingRooms.findIndex(item=>item.Desk_AutoID==id);
            if(index>=0){
                storage.create(servingRooms[index].Desk_AutoID);
                servingRooms.splice(index,1);
                storage.set('servingRooms',servingRooms);
            }
        }
    },
    /**点单暂存操作 */
    lockersCache:{
        /**保存数据
         * @info {保存人姓名}
         */
        set(data){
            let Login_Name=auth.getUserInfo()?.Login_Name;
            let info={
                name:Login_Name,
                time:new Date(),
                id:"lockers"+(new Date()).getTime()
            }
            let lockersList=storage.get('lockersList')||[];//数组数据
            lockersList.push(info);
            storage.set('lockersList',lockersList);
            storage.set(info.id,data);
        },
        /**返回暂存列表数据 @param outTime 过期时间 分钟 */
        getLockersList(outTime){
            let lockersList=storage.get('lockersList')||[];//数组数据
            for(let i=lockersList.length-1;i>=0;i--){
                let data=lockersList[i];
                if((new Date()).getTime()>(new Date(data.time)).getTime()+outTime*1000*60){
                    this.delete(data.id);
                }
            }
            return lockersList;
        },
        /**返回指定 暂存菜品数据 */
        getLockersData(id){
            return storage.get(id)||[];//数组数据
        },
        /**删除指定暂存数据 */
        delete(id){
            let lockersList=storage.get('lockersList')||[];//数组数据
            let index= lockersList.findIndex(item=>item.id==id);
            if(index>=0){
                storage.create(lockersList[index].id);
                lockersList.splice(index,1);
                storage.set('lockersList',lockersList);
            }
        }
    },
    /**
     * 缓存基础类型数据
     * @param {*} dataTypeNames 数组 要求的 dataTypeNames 数据名称 也是缓存用的key
     * then 返回的数据格式 {dataTypeNames:{data:数据,DataMaxTime:时间搓}} dataTypeNames对应传入的数据名称
     */
    cacheBaseAll(dataTypeNames){
        let typeKey={"MasKinds":"EatMasKinds","ConsumeTypes":'ConsumeTypeKinds',"KitIssuingTypes":'KitIssuingTypes',
        "PosWaiters":'PosWaiters',"PosSellers":'PosSellers',"DiscountScheme":'DiscountSchemes',
        "OrderChannel":'OrderChannelKinds',"RefundReasons":'RefundReasons',"OperateReasons":'OperateReasons',
        "GlobalSysSetting":'GlobalSysSetting',"PayType":'PayKinds',"PosTickets":"TicketInfos","EatPhase":'EatPhases',"PosUsers":'PosUsers',
        "ChhDepart":'ChhDepart','KitIssuePlace':'KitIssuePlace','UserRooms':'UserRooms','PosCookers':'PosCookers',
        'KdsSettings':'KdsSettings','SalesPromotion':"SalesPromotions",'ConsumePersonAvgRange':"ConsumePersonAvgRangeInfo",
        'ComboFood':'BaseComboFoods','Relation':'RelationList','ComDay':"ComDayList","CstmLevel":"CstmLevelList","CstmModuleInfo":"CstmModuleList",
        "OnlinePromotions":'PromotionList'
    }
        let dataNames=[];
        dataTypeNames.forEach((name)=>{
            dataNames.push(typeKey[name]);
        })
        return cacheBaseAll(dataTypeNames,dataNames,diffLogin).then((d)=>{
            if(d){
                for(let key in d){
                    setGlobalVariable(key,d[key].data);
                }
            }
            return d;
        });
    },
    /**强制刷新菜品 */
    RefreshPosFoods(){
        return cacheBase("PosFoods",'FoodMainCategorys',null,true).then((d)=>{
            if(d && d.data){
                let userInfo=auth.getUserInfo();
                posFoodData.MaxTimestamps=d?.MaxTimestamps||0;
                posFoodData.data=d.data||[];
                posFoodData.Rpt_Date=userInfo?.Rpt_Date;
            }
            return d;
        })
    },
    /**菜品数据结构    大类 ---> 小类 ---> 菜品*/
    PosFoods(){
        return new Promise((resolve, reject)=> {
            let userInfo=auth.getUserInfo();
            if(posFoodData?.data && posFoodData.data.length>0 && posFoodData.Rpt_Date==userInfo?.Rpt_Date){ resolve(posFoodData);return;}
            mqTTClient.mqCloudPosData(()=>{
                return cacheBase("PosFoods",'FoodMainCategorys',diffLogin,true).then((d)=>{
                    return d;
                }).catch((e)=>{
                    reject(e);
                });
            },'Food_Data',(d,newFoodList)=>{
                console.log("Food_Data",d,newFoodList)
                storage.createAsync("PosFoods");
                if(posFoodData.MaxTimestamps==d.HisDataVersion || (posFoodData.MaxTimestamps>d.HisDataVersion && posFoodData.MaxTimestamps<d.NowDataVersion)){//数据有变化
                    if(newFoodList){
                        newFoodList.forEach((newFood)=>{
                            if(posFoodData.MaxTimestamps>=newFood.Timestamps){
                                //当 当前数据的时间戳 大于推送历史时间戳 小于推送当前时间戳 表示推送数据中有 数据要更新
                                //判断这类数据中 时间戳是否大于当前数据 大于表示最新数据要跟新 否则忽略
                                return;
                            }
                            posFoodData.data.forEach(mainCategorys=>{
                                mainCategorys.FoodSubCategorys.forEach(subCategorys=>{
                                    subCategorys.PosFoods.forEach(item=>{
                                        if(newFood.Food_ID==item.Food_ID){
                                            if(newFood.Operate_Type==0){//0：沽清操作，  1：限量操作
                                                //SoldOut_Status 0：未沽清，  1：沽清  
                                                item.Is_SoldOut=newFood.SoldOut_Status==1;
                                            }else{//限量操作 
                                                if(newFood.YX){//取消限量
                                                    item.Is_LimitedProject=true;
                                                    item.RemainNum=newFood.Remain_Num;//限售菜品剩余数量
                                                }else{
                                                    item.Is_LimitedProject=false;
                                                    item.RemainNum=0;
                                                }
                                            }
                                        }
                                    
                                        //套餐 数据更新
                                        item.SetMealInfo?.SetMealKinds?.some(kinds=>
                                            kinds.SetMealDetails?.some(it=>{
                                                if(newFood.Food_ID==it.Food_ID){
                                                    if(newFood.Operate_Type==0){//0：沽清操作，  1：限量操作
                                                        //SoldOut_Status 0：未沽清，  1：沽清  
                                                        it.Is_SoldOut=newFood.SoldOut_Status==1;
                                                    }
                                                    return true;
                                                }
                                            })
                                        ) ;
                                    });
                                });
                            });
                        })
                        posFoodData.MaxTimestamps=d.NowDataVersion;
                    }
                }else if(posFoodData.MaxTimestamps<d.HisDataVersion){
                    //当前数据与推送数据中间 有遗漏 返回 false 重新加载数据
                    return false;
                }
            },(d)=>{
                if(d && d.data){
                    posFoodData.MaxTimestamps=d?.MaxTimestamps||0;
                    posFoodData.data=d.data||[];
                    posFoodData.Rpt_Date=userInfo?.Rpt_Date;
                }
                resolve(posFoodData);
            },true)
        });
    },
    /**更新菜品售卖信息 */
    RefreshSoldInfo(){
        
        return new Promise((resolve, reject)=> {
            let dateTime=(new Date()).getTime();
            if(RefreshSoldTime>dateTime-(1000*5)){
                reject({message:'操作太频繁,请稍后在刷新'});
                return;
            }
            RefreshSoldTime=dateTime
            let userInfo=auth.getUserInfo();
            httpAES.post("Bestech.CloudPos.RefreshSoldInfo", {
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo.Login_Name,
            }).then((d)=>{
                if(d.ResponseHeader.ResultCode!=0){
                    reject({message:d.ResponseHeader.ResultDesc});
                    return;
                }else{
                    resolve();
                    let SoldOutInfos=d.ResponseBody?.SoldOutInfos;//沽清菜品状态信息
                    let SoldLimitedInfos=d.ResponseBody?.SoldLimitedInfos;//当前限售菜品销量信息

                    if(SoldOutInfos?.length>0 || SoldLimitedInfos?.length>0){
                        posFoodData?.data?.forEach(mainCategorys=>{
                            mainCategorys.FoodSubCategorys.forEach(subCategorys=>{
                                subCategorys.PosFoods.forEach(item=>{

                                    //沽清菜品状态信息
                                    SoldOutInfos?.forEach(it=>{
                                        if(it.Food_ID==item.Food_ID){
                                            item.Is_SoldOut=it.SoldOut_Status==1;
                                            if(posFoodData.MaxTimestamps<it.Timestamps){
                                                posFoodData.MaxTimestamps=it.Timestamps;
                                            }
                                        }
                                        //套餐 数据更新
                                        item.SetMealInfo?.SetMealKinds?.some(kinds=>
                                            kinds.SetMealDetails?.some(detail=>{
                                                if(it.Food_ID==detail.Food_ID){
                                                    detail.Is_SoldOut=it.SoldOut_Status==1;//SoldOut_Status 0：未沽清，  1：沽清  
                                                    if(posFoodData.MaxTimestamps<it.Timestamps){
                                                        posFoodData.MaxTimestamps=it.Timestamps;
                                                    }
                                                    return true;
                                                }
                                            })
                                        ) 
                                    });
                                    
                                    //当前限售菜品销量信息
                                    let limitedD=SoldLimitedInfos?.find(it=>{
                                        if(it.Food_ID==item.Food_ID){
                                            item.Is_Limited=true;
                                            item.Is_LimitedProject=true;
                                            item.RemainNum=it.Remain_Num;//限售菜品剩余数量
                                            if(posFoodData.MaxTimestamps<it.Timestamps){
                                                posFoodData.MaxTimestamps=it.Timestamps;
                                            }
                                            return true;
                                        }
                                        return false
                                    });
                                    if(!limitedD && item.Is_LimitedProject){
                                        item.Is_LimitedProject=false;
                                        item.RemainNum=0;
                                    }
                                })
                            })
                        });
                    }
                }
            }).catch((e)=>{ 
                reject(e);
            });
        })
    },
    /**菜品数据结构    大类 ---> 小类 ---> 菜品 */
    AllPosFoods(){
        return cacheBaseData("AllPosFoods",'AllFoodMainCategorys')
    },
    /**自定义菜品数据结构*/
    PosDiyFoods(){
        return cacheBaseData("PosDiyFoods",'DiyFoodSubCategorys',diffLogin);
    },
    /**菜品口味做法    做法类型 ---> 做法详情*/
    MasKinds(){
        return cacheBaseData("MasKinds",'EatMasKinds',diffLogin);
    },
    /**当前操作员可管理的餐厅区域 */
    UserRooms(){
        return cacheBaseData("UserRooms",'UserRooms',diffLogin);
    },
    /**消费类型*/
    ConsumeTypes(){
        return cacheBaseData("ConsumeTypes",'ConsumeTypeKinds',diffLogin);
    },
    /**厨房出单类型*/
    KitIssuingTypes(){
        return cacheBaseData("KitIssuingTypes",'KitIssuingTypes',diffLogin);
    },
    /**服务员*/
    PosWaiters(){
        return cacheBaseData("PosWaiters",'PosWaiters',diffLogin);
    },
    /**销售员 */
    PosSellers(){
        return cacheBaseData("PosSellers",'PosSellers',diffLogin);
    },
    /**折扣列表 */
    DiscountScheme(){
        return cacheBaseData("DiscountScheme",'DiscountSchemes',diffLogin);
    },
    /**渠道 */
    OrderChannel(){
        return cacheBaseData("OrderChannel",'OrderChannelKinds',diffLogin);
    },
    /**餐厅区域数据 */
    EatRooms(){
        return cacheBaseData("EatRooms",'PosRooms',diffLogin);
    },
    /**退菜原因 */
    RefundReasons(){
        return cacheBaseData("RefundReasons",'RefundReasons',diffLogin);
    },
    /**操作原因数据 */
    OperateReasons(){
        return cacheBaseData("OperateReasons",'OperateReasons',diffLogin);
    },
    /**桌台和菜单配置 */
    DeskPosconfigure(){
        return cacheBaseData("GlobalSysSetting",'GlobalSysSetting',diffLogin);
    },
    /**支付方式 */
    PayType(){
        return cacheBaseData("PayType",'PayKinds',diffLogin);
    },
    /**全部付款方式 */
    AllPayTypes(){
        return cacheBaseData("AllPayTypes",'AllPayKinds',diffLogin);
    },
    /**票券信息 */
    PosTickets(){
        return cacheBaseData("PosTickets",'TicketInfos',diffLogin);
    },
    /**获取时段 */
    EatPhase(){
        return cacheBaseData("EatPhase",'EatPhases',diffLogin);
    },
    /**开卡人 */
    PosUsers(){
        return cacheBaseData("PosUsers",'PosUsers',diffLogin);
    },
    /**出品部门 */
    ChhDepart(){
        return cacheBaseData("ChhDepart",'ChhDepart',diffLogin);
    },
    /**出单位置 */
    KitIssuePlace(isRefresh){
        return cacheBaseData("KitIssuePlace",'KitIssuePlaces',isRefresh===true?null:diffLogin);
    },
    /**获取打印机 */
    Printers(){
        return cacheBaseData("Printers",'BasePrinters');
    },
    /**获取打印模板 */
    PrintModels(){
        return cacheBaseData("PrintModels",'BasePrintModels');
    },
    /**获取设备信息 */
    PosEquipments(){
        return cacheBaseData("PosEquipments",'PosEquipments');
    },
    /**获取厨师 */
    PosCookers(){
        return cacheBaseData("PosCookers",'PosCookers');
    },
    /**获取用户组别 */
    UserGroups(){
        return cacheBaseData("UserGroups",'UserGroups');
    },
    /**ksd 配置数据*/
    KdsSettings(){
        return cacheBaseData("KdsSettings",'KdsSettings',diffLogin)
    },
    /**促销方案 @param isRefresh 是否刷新缓存 重新请求*/
    SalesPromotions(isRefresh){
        return cacheBaseData("SalesPromotion",'SalesPromotions',isRefresh===true?null:diffLogin);
    },
    /**桌台数据 mq实时数据 */
    DeskActiveMap(isRefresh){
        return new Promise((resolve, reject)=> {
            let userInfo=auth.getUserInfo();
            if(!isRefresh && roomDeskData?.data?.PosRooms && roomDeskData.Rpt_Date==userInfo?.Rpt_Date && roomDeskData.User_ID==userInfo?.User_ID){ 
                //已经开启监听 返回已有数据
                resolve(roomDeskData);return;
            }
            //监听mq 
            mqTTClient.mqCloudPosData(()=>{
                //获取数据
                return httpAES.post("Bestech.CloudPos.DeskActiveMap", {
                    User_ID:userInfo.User_ID,
                    DeskRange_Type:0,
                    Eat_RoomIds:'',
                }).then((d)=>{
                    console.log("Bestech.CloudPos.DeskActiveMap:",d)
                    if(d.ResponseHeader.ResultCode!=0){
                        reject({message:d.ResponseHeader.ResultDesc});
                        return;
                    }else{
                        return d.ResponseBody;
                    }
                }).catch((e)=>{ 
                    reject(e);
                });
            },'Desk_MapData',(d,newDeskList)=>{
                console.log("Desk_MapData:",newDeskList)
                if(roomDeskData.MaxTimestamps==d.HisDataVersion || (roomDeskData.MaxTimestamps>d.HisDataVersion && roomDeskData.MaxTimestamps<d.NowDataVersion)){//数据有变化
                    if(newDeskList){
                        newDeskList.forEach((newDesk)=>{
                            if(roomDeskData.MaxTimestamps>=newDesk.Timestamps){
                                //当 当前数据的时间戳 大于推送历史时间戳 小于推送当前时间戳 表示推送数据中有 数据要更新
                                //判断这类数据中 时间戳是否大于当前数据 大于表示最新数据要跟新 否则忽略
                                return;
                            }
                            let desk=null;
                            roomDeskData?.data?.PosRooms?.find(item=>{
                                for(let i=0;i<item.PosDesks.length;i++){
                                    if(item.PosDesks[i].Desk_AutoID==newDesk.Desk_AutoID){
                                        desk=item.PosDesks[i];
                                        return true;
                                    }
                                }
                                return false;
                            });

                            if(desk){
                                if(newDesk.Is_Desk_YX){//修改
                                    desk.Desk_Status=newDesk.Desk_Status;//桌台状态
                                    desk.Seat_Numbers=newDesk.Seat_Numbers;//座位数
                                    desk.KitDesk_Status=newDesk.KitDesk_Status;//厨房桌台显示状态
                                    desk.Is_VisualDesk=newDesk.Is_VisualDesk;//是否虚拟桌台
                                    desk.Desk_Speed=newDesk.Desk_Speed;//桌台速度
                                    desk.Is_Desk_Locked=newDesk.Is_Desk_Locked;//桌台锁定
                                    desk.OpenDesk_ProgramID=newDesk.OpenDesk_ProgramID;//开台方案ID
                                    desk.Timestamps=newDesk.Timestamps;
                                    if(desk.Desk_Status==1 || desk.Desk_Status==3 || desk.Desk_Status==4){//开台
                                        if(!desk.OpenDeskInfo){desk.OpenDeskInfo={};}
                                        delete desk.ReserveDeskInfo;
                                        desk.OpenDeskInfo.Eat_AutoID=newDesk.Eat_AutoID;//开台订单ID
                                        desk.OpenDeskInfo.Person_Num=newDesk.Person_Num;//开台就餐人数
                                        desk.OpenDeskInfo.OpenDesk_Time=newDesk.OpenDesk_Time;//开台时间
                                        desk.OpenDeskInfo.Eat_CheckMoney=newDesk.Eat_CheckMoney;// 开台 点菜总结算金额
                                        desk.OpenDeskInfo.Eat_SaleMoney=newDesk.Eat_SaleMoney;//点菜总金额
                                        desk.OpenDeskInfo.Eat_FoodCounts=newDesk.Eat_FoodCounts;//点菜总数量
                                        desk.OpenDeskInfo.Crossed_FoodCounts=newDesk.Crossed_FoodCounts; //开台 已划菜数量
                                        desk.OpenDeskInfo.Eat_UnionID = newDesk.Eat_UnionID;
                                        desk.OpenDeskInfo.Eat_Saler= newDesk.Eat_Saler;//销售员
                                        desk.OpenDeskInfo.ShowRemarkFlag=newDesk.ShowRemarkFlag;//桌台显示备注标识，true:显示，false:不显示
                                    }else if(desk.Desk_Status==2){
                                        delete desk.OpenDeskInfo;
                                        if(!desk.ReserveDeskInfo){desk.ReserveDeskInfo={};}
                                        desk.ReserveDeskInfo.Reserve_AutoID=newDesk.Reserve_AutoID;//预订单ID
                                        desk.ReserveDeskInfo.Reserve_OrderNo=newDesk.Reserve_OrderNo;//预定单号
                                        desk.ReserveDeskInfo.Cstm_Name=newDesk.Reserve_Cstm_Name;//预定信息 姓名
                                        desk.ReserveDeskInfo.Mobile=newDesk.Reserve_Mobile;//预定信息 手机号
                                        desk.ReserveDeskInfo.Reserve_Operator=newDesk.Reserve_Operator;//销售员
                                        desk.ReserveDeskInfo.Reserve_PersonNum=newDesk.Reserve_PersonNum;//预定人数
                                        desk.ReserveDeskInfo.Cstm_Sex = newDesk.Cstm_Sex; //预定性别
                                        desk.ReserveDeskInfo.ReserveOrderConsumes = newDesk.ReserveOrderConsumes; //预定菜品明细
                                        desk.ReserveDeskInfo.Reserve_Remark = newDesk.Reserve_Remark; //备注
                                    }else{
                                        delete desk.ReserveDeskInfo;
                                        delete desk.OpenDeskInfo;
                                    }
                                }else{//删除
                                    roomDeskData?.data?.PosRooms?.find(item=>{
                                        for(let i=0;i<item.PosDesks.length;i++){
                                            if(item.PosDesks[i].Desk_AutoID==newDesk.Desk_AutoID){
                                                item.PosDesks.splice(i,1);
                                                return true;
                                            }
                                        }
                                        return false;
                                    });
                                }
                            }else if(newDesk.Src_DeskID && newDesk.Is_Desk_YX==true){//新增桌台
                                roomDeskData?.data?.PosRooms?.find(item=>{
                                    for(let i=0;i<item.PosDesks.length;i++){
                                        if(item.PosDesks[i].Desk_AutoID==newDesk.Src_DeskID){
                                            item.PosDesks.splice(i+1,0,newDesk);
                                            return true;
                                        }
                                    }
                                    return false;
                                });
                            }
                        })
                        roomDeskData.MaxTimestamps=d.NowDataVersion;
                        updateRoomDesk();
                    }
                  }else if(roomDeskData.MaxTimestamps<d.HisDataVersion){
                    //当前数据与推送数据中间 有遗漏 返回 false 重新加载数据
                    return false;
                  }
            },(d)=>{
                if(d){
                    console.log("roomDeskData:",roomDeskData)
                    roomDeskData.MaxTimestamps=d?.DeskMaxTimestamps||0;
                    if(roomDeskData.data){
                        let PosRooms=roomDeskData.data.PosRooms;
                        if(PosRooms?.length>=0){
                            PosRooms.splice(0,PosRooms.length);//清空数据
                            if(d.PosRooms){
                                Object.assign(PosRooms,d.PosRooms||[]);
                                delete d.PosRooms;
                            }
                        }
                        Object.assign(roomDeskData.data,d);
                    }else{
                        roomDeskData.data=d||{};
                    }
                    
                    roomDeskData.User_ID=userInfo?.User_ID;//登陆用户id
                    roomDeskData.Rpt_Date=userInfo?.Rpt_Date;//营业日期
                    updateRoomDesk();
                }
                resolve(roomDeskData);
            },true)
        });
    },
    /**人均消费区间*/
    ConsumePersonAvgRange(){
        return cacheBaseData("ConsumePersonAvgRange",'ConsumePersonAvgRangeInfo',diffLogin)
    },
    /**菜品组合信息 */
    ComboFood(){
        return cacheBaseData("ComboFood",'BaseComboFoods',diffLogin)
    },
    /**关系类型*/
    Relation(){
        return cacheBaseData("Relation",'RelationList',diffLogin)
    },
    /**纪念日类型*/
    ComDay(){
        return cacheBaseData("ComDay",'ComDayList',diffLogin)
    },
    /**客户等级*/
    CstmLevel(){
        return cacheBaseData("CstmLevel",'CstmLevelList',diffLogin)
    },
    /**短信模板*/
    CstmModuleInfo(){
        return cacheBaseData("CstmModuleInfo",'CstmModuleList',diffLogin)
    },
    /**活动方案 */
    OnlinePromotions(){
        return cacheBaseData("OnlinePromotions",'PromotionList',diffLogin);
    },
    /**初始化缓存 */
    initCache(){
        posFoodData.data=[];
        posFoodData.MaxTimestamps=0;
        roomDeskData.data={};
        roomDeskData.MaxTimestamps=0;
        let typeKey=["MasKinds","UserRooms","ConsumeTypes","KitIssuingTypes",
        "PosWaiters","PosSellers","DiscountScheme",
        "OrderChannel","EatRooms","RefundReasons","OperateReasons","DeskPosconfigure",
        "GlobalSysSetting","PayType","AllPayTypes","PosTickets","EatPhase","PosUsers","PosFoods","AllPosFoods",
        "ChhDepart",'KitIssuePlace',"Printers","PrintModels","PosEquipments",'PosCookers',"UserGroups",'ComboFood',
        'KdsSettings','SalesPromotions','ConsumePersonAvgRange','ComDay','ComDay','CstmLevel','CstmModuleInfo','OnlinePromotions']
        typeKey.map((key)=>{
            storage.create(key);
            storage.createAsync(key);
        })
    }
}