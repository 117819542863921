<template>
    <modal class="saveCouponsModel" :isShow="isShow">
        <div class="header">昆仑票据核销<span class="close" @click="hide()">×</span></div>
        <div class="The-head">
            请输入或扫码：
            <div class="from-input">
                <i class="iconfont icon-a-saoma13x"></i>
                <input type="text" ref="input" class="key-focus" data-model='idCode' v-model="idCode" @keyup.enter="readCard()" />
            </div>
            <button class="btn" @click="readCard()">查询</button>
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel">
                        <div class="panel-heading">券列表</div>
                        <div class="panel-body item-box clearfix" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.item-li',isRow:false}">
                            <div class="item-li coupons" :class="{selected:item._selected}" @click="onTicket(item)"
                            v-for="item in ticketInfos" :key="item">
                                <div class="coupons-name">
                                    <div class="coupon-left" :class="{selected:item.Check==true}">
                                        <div class="left-txt">
                                            <span class="m">¥</span>
                                            <span class="y">{{item.Coupon_Amount}}</span>
                                        </div>
                                    </div>
                                    <div class="coupon-right" :class="{selected:item.Check==true}">
                                        <div class="coupon-top">
                                            <span class="tk-txt">{{item.Coupon_Name}}</span>
                                            <span class="tk-time">有效期:{{new Date(item.Start_Time).Format("yyyy-MM-dd")}}至{{new Date(item.End_Time).Format("yyyy-MM-dd")}}</span>
                                        </div>
                                        <span class="cou-ge"></span>
                                        <div class="coupon-bottom">
                                            详细信息
                                            <div class="triangle" v-show="item.extendShow==false" @click="extenClick(item)"></div>
                                            <div class="triangles" v-show="item.extendShow==true" @click="extenClick(item)"></div>
                                        </div>
                                        <div class="extend" v-show="item.extendShow==true">
                                            <span style="margin-left:-120px;" v-show="item.Consume_MinMoney>0">使用说明</span>
                                            <span class="ex-span" v-show="item.Consume_MinMoney>0" >消费满{{item.Consume_MinMoney}}元可使用此券</span>
                                            <!-- <span class="ex-span" >消费满100元可使用此券</span>
                                            <span class="ex-span" >消费满100元可使用此券</span>
                                            <span class="ex-span" >消费满100元可使用此券</span>
                                            <span class="ex-span" >消费满100元可使用此券</span> -->
                                        </div>
                                        <i class="iconfont icon-gou-fine"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="panel">
                        <div class="panel-heading">
                            菜品信息
                        </div>
                        <!--@blur="popShow=false; @mousedown="(e)=>{e.preventDefault()}""-->
                        <div class="right-pop" :style="{top:popY,left:popX}" v-show="popShow==true">
                            <div class="pop-title">
                                <div class="pop-txt">套餐换菜一{{Infood.Food_Name}}</div>
                                <span class="pop-x" @click="popShow=false">×</span>
                            </div>
                            <div class="pop-content" v-scroll-anime="{up:'content-up',down:'content-down',liClass:'.div-tr'}">
                                    <div class="div-tr" v-for="fl in InfoodList" :key="fl" v-show="fl.Is_Selected==false" :class="{selected:radioclass==fl.Food_ID}" @click="radioClick($event,fl)" >
                                        <td >
                                            <label class="checkbox"><input type="radio" name="_fa_radio" :checked="radioclass==fl.Food_ID"  />
                                            <i class="iconfont icon-a-danxuanweixuanzhong"></i></label>
                                        </td>
                                        <td class="tr-td" :class="{SoldOut:fl?.Is_SoldOut==true}">{{fl.Food_Name}}</td>
                                    </div>
                            </div>
                            <div class="item-operation">
                                <div class="float-right">
                                    <div class="bnt-fangxiang" ref="content-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                    <div class="bnt-fangxiang" ref="content-down"><i class="iconfont icon-fangxiang-down"></i></div>
                                </div>
                            </div>
                        </div>
                        <div v-show="posFoodSetMealInfos==''" class="panel-body select-item-box"></div>
                        <!--套餐展示-->
                        <div v-show="posFoodSetMealInfos?.Is_SetMeal==true && posFoodSetMealInfos!=''" class="panel-body select-item-box" v-scroll-anime="{up:'right-order-up',down:'right-order-down',liClass:'.tr'}">
                            <div  class="tr" v-for="set in posFoodSetMealInfos?.SetMealInfo?.SetMealKinds" :key="set">
                                <ul class="right-ul" >
                                    <li >
                                        <span class="kin-name">{{set.Kind_Name}}</span>
                                        <div v-for="food in set?.SetMealDetails" :key="food" style="padding-top: 5px;margin-left:-5px;" v-show="food?.Is_Selected==true">
                                            <div class="li-name" :class="{SoldOut:food?.Is_SoldOut==true}">{{food?.Food_Name}}</div>
                                            <div class="exchange" @blur="popShow=false;" @click="exClick($event,food,set)" >换</div>
                                            <div class="number-size" :class="{SoldOut:food?.Is_SoldOut==true}">x{{food?.Food_Number}}/{{food?.Food_Size}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!--单品展示-->
                        <div v-show="posFoodSetMealInfos?.Is_SetMeal==false && posFoodSetMealInfos!=''" class="panel-body select-item-box" v-scroll-anime="{up:'right-order-up',down:'right-order-down',liClass:'.tr'}">
                            <div  class="tr">
                                <ul class="right-ul">
                                    <li>
                                        <span class="kin-name">主食1</span>
                                        <div style="padding-top: 5px;margin-left:-5px;">
                                            <div class="li-name" >{{posFoodSetMealInfos?.Food_Name}}</div>
                                            <div class="number-size" >x1/{{posFoodSetMealInfos?.Food_Size}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="box-bottom">
                            <span class="cou-ge"></span>
                            <div class="taste">下单备注:</div>
                            <div class="masKind" @click="masClick()">请选择口味与做法></div>
                            <div class="deman" v-show="choosemasKind">
                                <span class="dema-div" v-for="mas in choosemasKind" :key="mas">
                                    {{mas?.Mas_Name}}
                                    <div class="dema-x" @click="deleteMasClick(mas)">x</div>
                                </span>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="right-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="right-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <!-- <button class="btn btn-border" @click="select()">重新查询核销结果</button> -->
            <button class="btn btn-confirm" @click="cancelConfirm()">核销</button>
        </div>
        <!-- 菜品要求操作 -->
        <modal-load :isShow="demandShow">
            <demand-model :isShow="demandShow" v-on:closeModel="demandShow=false,this.$keyavail=false"
            :masKindsList="masKindsList" :orderMenuList="orderMenuList" :allfoodSubCategorys="allfoodSubCategorys" :disRadioOrder="posFoodSetMealInfos" @confirm="confirm" ></demand-model>
        </modal-load>
    </modal>
</template>

<script>

/**全局初始化数据 */
let _data={
    CouponPlatform_Type:6 //系统券平台类型4:翼码5:正品6：昆仑
};

import { newGuid } from '/src/common/index.js'
import DemandModel from '../../diningDetail/demandModel/demandModel.vue'

/**现金券 或 商品券 选择 */
export default {
    name:"kunlunCouponspayModel",
    components:{
        DemandModel
    },
    props:{
        isShow:Boolean,
        RoomAutoID:String,
        orderInfo:Object,
        allfoodSubCategorys:Object
    },
    data(){
        // let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        // if(globalSysSetting){
        //     if(globalSysSetting.CouponPlatform_Type){
        //         if(globalSysSetting.CouponPlatform_Type==4 || globalSysSetting.CouponPlatform_Type==5){
        //             _data.CouponPlatform_Type = globalSysSetting.CouponPlatform_Type;
        //         }
        //     }
        // }
        return {
            masKindsList:'', //菜品口味
            orderMenuList:'', //订单菜品数据
            //查询码
            idCode:'',
            /**券数据 */
            ticketInfos:[],
            /**选中的券 */
            selectCoupon:'',
            /**菜品信息 */
            posFoodData:'',
            chooseFood:'', /**展示的菜品 */
            demandShow:false,
            extendShow:false,
            popShow:false,
            popY:'',
            popX:'',
            choosemasKind:[], //选择的口味
            posFoodSetMealInfos:'',//临时菜品数据
            Infood:'', //换菜
            InfoodList:'', //套餐换菜集合
            radioclass:'', //换菜单选
            /**临时订单数据 */
            memberorderInfo:'', 
        }
    },
    mounted(){
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData = d.data
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        this.init();
    }, 
    watch: {
        isShow(newVal) {
            if(newVal){
                this.init();
            }   
        }
    },
    methods:{
        init(){
            this.selectCoupon=''
            this.chooseFood=''
            this.choosemasKind=[]
            this.posFoodSetMealInfos='',this.demandShow=false
            this.idCode='',this.Infood='',this.popX='',this.popY='',this.extendShow=false
            this.ticketInfos=[],this.popShow=false,this.radioclass='',this.InfoodList=''
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
            this.orderMenuList = this.orderInfo.Order_ConsumeList
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderInfo))
        },
        //重新查询核销结果
        select(){
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.memberorderInfo.Eat_AutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Default_ChannelID, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.memberorderInfo.Timestamps.toString(), //账单时间戳
            }
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    if(data.ResponseBody?.Timestamps){
                        this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    }
                    //订单时间戳已变更需要去刷新订单时间,并且把锁解开
                    if(data.ResponseHeader.ResultCode==3002){
                        this.$emit("refreshOrder")
                    }
                }else{
                    if(data.ResponseHeader.ResultDesc){
                        this.$message.success({ message:data.ResponseHeader.ResultDesc});
                    }
                    this.memberorderInfo.Timestamps = data.ResponseBody.Timestamps.toString();
                    return;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        exClick(e,food,set){
            this.Infood = food
            this.InfoodList = set.SetMealDetails
            let _left = e.target.getBoundingClientRect().x
            let _top = e.target.getBoundingClientRect().y
            this.popX = _left - 185 + 'px'
            this.popY = _top - 5 + 'px'
            if(this.popShow==false){
                this.popShow=true
            }else{
                this.popShow=false
            }
        },
        readCard(){
            if(!this.idCode){
                this.$message.warning('查询码不能为空');
                return
            }
            let userInfo=this.$auth.getUserInfo()
            //获取平台券信息
            let param={
                Body_ID:newGuid(),
                User_ID:userInfo?.User_ID,
                Platform_Type:_data.CouponPlatform_Type, //平台类型 1:抖音 2:美团 3:口碑 4: 翼码
                Dynamic_Code:this.idCode,
                Dynamic_Type:'',//查询码类型，mobile : 按手机号查询 ，不传按默认查询类型
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
            }
            const loading = this.$loading({
                text: "获取券信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CouponPlatform.GetCouponInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    this.ticketInfos = data?.ResponseBody.filter(x=>x.Coupon_Status==0);
                    this.ticketInfos.map(tick=>{
                        tick = Object.assign(tick,{Check:false,extendShow:false})
                    })
                    this.choosemasKind=[],this.posFoodSetMealInfos='',this.selectCoupon=''
                    return
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**口味选择返回 */
        confirm(optFlavors,disRadioOrder){
            this.demandShow=false
            if(optFlavors){
                this.choosemasKind=[]
                optFlavors.map(op=>{
                    let flavor ={
                        Mas_AutoID:op.Mas_AutoID,
                        Mas_Code:op.Mas_Code,
                        Mas_Name:op.Mas_Name,
                        Mas_Num:this.orderInfo.Eat_ManNum,
                        Mas_Price:op.Mas_Price,
                        // Is_AddMoney:op.Is_AddMoney,
                        // Price_Kind:op.Price_Kind
                    }
                    //Is_AddMoney是否加价做法,Price_Kind 加价类型，0:按量加价，1：按次加价，2：按位加价
                    // if(op.Is_AddMoney==true && op.Price_Kind==0){
                    //     flavor.Mas_Num=1
                    // }
                    this.choosemasKind.push(flavor)
                })
            }
            this.posFoodSetMealInfos.FoodModifys = this.choosemasKind
            
        },
        /**删除口味选择 */
        deleteMasClick(mas){
            this.choosemasKind.map((kind,i)=>{
                /**找到选择口味对应的菜品 */
                if(kind.Mas_AutoID==mas.Mas_AutoID){
                    this.choosemasKind.splice(i,1)
                }
            })
            this.posFoodSetMealInfos.FoodModifys = this.choosemasKind
        },
        //核销
        cancelConfirm(){
            if(!this.posFoodSetMealInfos){
                this.$message.warning('请先选择需要核销的商品券!');
                return
            }
            let userInfo=this.$auth.getUserInfo()
            let param={
                Body_ID:newGuid(),
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name, //操作员
                Room_ID:this.RoomAutoID,
                Order_BatchNo:newGuid(),
                OrderConsumes:this.orderConsumeData(),//订单消费明细
                Platform_Type:_data.CouponPlatform_Type, //平台类型 1:抖音 2:美团 3:口碑 4: 翼码
                Channel_ID:this.selectCoupon?.Channel_ID,//渠道ID this.selectCoupon?.Channel_ID
                Coupon_Code:this.selectCoupon?.Coupon_Code,//选择核销的券码
                Coupon_VerifyToken:this.selectCoupon?.Coupon_VerifyToken,//验券的标识
                Scene_Type:1, //可用场景
                Merchant_FactAmount:this.selectCoupon?.Merchant_FactAmount,//券商家实收金额
            }
            if(sessionStorage.getItem("tempUserID")){
                param.User_ID = sessionStorage.getItem("tempUserID")
                param.Operator_Name = sessionStorage.getItem("tempName")
                sessionStorage.removeItem("tempUserID")
                sessionStorage.removeItem("tempName")
            }
            console.log(param);
            const loading = this.$loading({
                text: "核销中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CouponPlatform.KunLunTicketVerifyInOrder",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    this.$emit('KunLunCouponsReturn',data)
                    return
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
         /**订单消费数据 */
        orderConsumeData(){
            let _foodType=1
            if(this.posFoodSetMealInfos.Is_SetMeal==true){
                _foodType=2
            }
            let order=[{
                Food_ID:this.posFoodSetMealInfos.Food_ID,//菜品ID
                Food_Code:this.posFoodSetMealInfos.Food_Code,//菜品编号
                Food_Name:this.posFoodSetMealInfos.Food_Name,//菜品名称
                Food_Size:this.posFoodSetMealInfos.Food_Size,//菜品单位
                Food_Type:_foodType,//菜品类型 ，1：单菜 2：套餐 3：临时套餐 5：拼盘
                Food_Speed:'即',//菜品厨房速度， 即、等叫、加急，先上，后上
                Food_Number:1,//菜品数量
                Food_AssNumber:0, //菜品辅助数量
                Food_Price:this.posFoodSetMealInfos.Food_Price, //菜品单价
                FoodModifys:this.choosemasKind, //菜品做法要求
                FoodSetmeals:[], //套餐子项菜品
            }]
            /**如果是套餐 */
            if(_foodType==2){
                let _SetMeal = []
                this.posFoodSetMealInfos?.SetMealInfo?.SetMealKinds.map(set=>{
                    set.SetMealDetails.map(item=>{
                        if(item.Is_Selected==true){
                            let foodSet={
                                FoodSetmeal_ID:item.Food_ID,//套餐菜品ID
                                FoodSetmeal_Code:item.Food_Code,//套餐菜品编号
                                FoodSetmeal_Name:item.Food_Name,//套餐菜品的名称
                                FoodSetmeal_Size:item.Food_Size,//套餐菜品单位
                                FoodSetmeal_Number:item.Food_Number,//套餐菜品数量
                                FoodSetmeal_AssNumber:0,//辅助数量
                                FoodSetmeal_Price:item.Food_TcPrice, //套餐加价
                                FoodSetmeal_SetPrice:item.Food_SetPrice,
                                FoodModifys:[] //菜品做法要求
                            }
                            //核销券套餐子项有做法
                            if(item.FoodEatMasList){
                                //foodSet =  Object.assign(foodSet,{FoodModifys:[]})
                                item.FoodEatMasList.forEach(fe=>{
                                    let feitem={
                                        Mas_AutoID:fe.Mas_AutoID,
                                        Mas_Code:fe.Mas_Code,
                                        Mas_Name:fe.Mas_Name,
                                        Mas_Num:item.Food_Number,
                                        Mas_Price:fe.Mas_Price
                                    }
                                    //Is_AddMoney是否加价做法,Price_Kind 加价类型，0:按量加价，1：按次加价，2：按位加价
                                    if(fe.Is_AddMoney==true && fe.Price_Kind==0){
                                        feitem.Mas_Num=1
                                    }
                                    foodSet.FoodModifys.push(feitem)
                                })
                            }
                            
                            //如果选择有口味要求
                            if(this.choosemasKind){
                                this.choosemasKind.forEach(cho=>{
                                    let feitem={
                                        Mas_AutoID:cho.Mas_AutoID,
                                        Mas_Code:cho.Mas_Code,
                                        Mas_Name:cho.Mas_Name,
                                        Mas_Num:item.Food_Number,
                                        Mas_Price:cho.Mas_Price
                                    }
                                    //Is_AddMoney是否加价做法,Price_Kind 加价类型，0:按量加价，1：按次加价，2：按位加价
                                    if(cho.Is_AddMoney==true && cho.Price_Kind==0){
                                        feitem.Mas_Num=1
                                    }
                                    foodSet.FoodModifys.push(feitem)
                                })
                            }
                            _SetMeal.push(foodSet)
                        }
                    })
                })
                 order[0].FoodSetmeals=_SetMeal
            }
            console.log('order:'+JSON.stringify(order))
            return order
        },
        //选择口味和做法
        masClick(){
            if(!this.posFoodSetMealInfos){
                this.$message.warning('请先选择商品券!');
                return
            }
            this.demandShow=true
        },
        //关闭
        hide(){
            sessionStorage.removeItem("tempUserID")
            sessionStorage.removeItem("tempName")
            this.$emit('closeModel')
        },
        //套餐换菜选择
        radioClick(e,item){
            if(e.target.tagName === 'INPUT'){
                return
            }
            if(item.Is_SoldOut==false){
                this.radioclass = item.Food_ID
                this.$confirm('您确定将套餐中的【'+this.Infood.Food_Name+'】换成【'+item.Food_Name+'】', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    this.InfoodList.map(info=>{
                        if(info.Food_ID==item.Food_ID && item.Is_Selected==false){
                            item.Is_Selected=true
                        }
                        if(this.Infood.Food_ID==info.Food_ID && info.Is_Selected==true){
                            info.Is_Selected=false
                        }
                    })
                    this.popShow=false
                }).catch(() => {
                });
            }
        },
        extenClick(item){
            if(item.extendShow==true){
                item.extendShow=false
            }else{
                item.extendShow=true
            }
        },
        //点击券
        onTicket(item){
            if(item.Coupon_Type==1 || item.Coupon_Type==2){
                this.$message.warning('卡券核销只支持商品券!');
                return
            }
            //券的最低消费额，0 不限制
            if(item.Consume_MinMoney!=0){
                if(this.orderInfo.Eat_CheckMoney<item.Consume_MinMoney){
                    this.$message.warning('账单未达到使用该券的金额!');
                    return
                }
            }
            //时间段控制 0所有时段,1指定时段
            let date = new Date(); //当前时间
            // if(new Date(item.Start_Time)>date || date > new Date(item.End_Time)){
            //     this.$message.warning('不在该券可使用的时间范围内!');
            //     return
            // }
            this.ticketInfos.forEach(tick=>{
                if(tick.Check==true){
                    tick.Check=false
                    this.selectCoupon=''
                }
            })
            //FoodRange_Type适用类型 1:通用券，所有商品适用，2:部分商品券
            if(item.FoodRange_Type==2){
                let title =''
                let _types = 0
                this.posFoodData.map(pos=>{
                    pos.FoodSubCategorys.map(sub=>{
                        sub.PosFoods.map(food=>{
                            if(item.Exchange_FoodCodes.indexOf(food.Food_Code)!=-1){
                                _types=1
                                this.posFoodSetMealInfos = food
                                this.posFoodSetMealInfos = Object.assign(this.posFoodSetMealInfos,{FoodModifys:[]})
                                this.selectCoupon=''
                                item.Check=true
                                this.selectCoupon = item
                            }else{
                                title = '未找到编号包括'+item.Exchange_FoodCodes+'的菜品'
                            }
                            if(_types==1){
                                return
                            }
                        })
                    })
                })
                if(_types==0){
                    this.$message.warning(title);
                    return
                }
                if(this.posFoodSetMealInfos?.Is_SetMeal==true){
                    this.posFoodSet()
                }
            }
        },
        //套餐券里套餐沽清查询
        posFoodSet(){
            //tick = Object.assign(tick,{extendShow:false})
            this.posFoodSetMealInfos?.SetMealInfo?.SetMealKinds.map(set=>{
                set.SetMealDetails.map(mea=>{
                    this.posFoodData.map(data=>{
                        data.FoodSubCategorys.map(food=>{
                            food.PosFoods.map(pos=>{
                                if(pos.Food_ID==mea.Food_ID){
                                    mea =  Object.assign(mea,{Is_SoldOut:pos.Is_SoldOut})
                                }
                            })
                        })
                    })
                })
            })
            // this.posFoodData
        }
    }
}
</script>

<style lang="sass">
@import "./kunlunCouponspayModel.scss";
</style>